import { Flex } from 'antd'
import useStyles from './SidebarListItem.styles'
import { SidebarActionsLayout, IconButton } from '@components'
import { FilePlus01 } from '@untitled-ui/icons-react'

export type BaseItem = {
  id: number | string
  name: string
  icon?: React.ReactNode
  disabled?: boolean
}

export interface SidebarListItemProps<T> {
  onClick?: (item: T, e: React.MouseEvent<HTMLDivElement>) => void
  item: T
  isSelected?: boolean
  actions?: React.ReactNode
  icon?: React.ReactNode
  className?: string
  renderLabel?: (item: T) => React.ReactNode
  onAddClick?: (item: T) => void
}

const SidebarListItem = <T extends BaseItem>({
  item,
  onClick,
  isSelected,
  actions,
  className,
  icon,
  renderLabel,
  onAddClick,
}: SidebarListItemProps<T>) => {
  const { styles, cx } = useStyles()

  const handleSelectItem = (e: React.MouseEvent<HTMLDivElement>) => {
    onClick?.(item, e)
  }

  return (
    <div
      onClick={!item.disabled ? handleSelectItem : undefined}
      className={cx(styles.listItemRoot, className, {
        [styles.selected]: isSelected,
        [styles.clickable]: !!onClick,
        [styles.disabled]: item.disabled,
      })}
      aria-label={item.name}
    >
      {renderLabel ? (
        renderLabel(item)
      ) : (
        <Flex gap={4} align="center">
          {icon || item.icon}
          {item.name}
        </Flex>
      )}
      {!item.disabled && (
        <>
          {actions}
          {onAddClick && (
            <SidebarActionsLayout>
              <IconButton onClick={() => onAddClick(item)}>
                <FilePlus01 width={16} />
              </IconButton>
            </SidebarActionsLayout>
          )}
        </>
      )}
    </div>
  )
}

export default SidebarListItem
