import {
  SidebarFilterRowLayout,
  SidebarFilterSelect,
  SidebarToolbarLayout,
} from '@components'
import { Category, useConfigPageContext } from '@context/ConfigPageContext'
import { BaseItem } from '@components/SidebarList/SidebarListItem'

interface ConfigSidebarToolbarProps {
  categories?: Category[]
  createButton: React.ReactNode
}
const ConfigSidebarToolbar = <T extends BaseItem>({
  categories,
  createButton,
}: ConfigSidebarToolbarProps) => {
  const { setSelectedCategory, selectedCategory } = useConfigPageContext<
    T,
    Category
  >()

  const categoryOptions = [{ id: '', name: 'All' }, ...(categories || [])]

  const handleChange = (value: Category['id']) => {
    const category =
      value === '' ? null : categoryOptions.find((c) => c.id === value) || null
    setSelectedCategory(category as Category | null)
  }
  return (
    <SidebarToolbarLayout>
      <SidebarFilterRowLayout>
        <SidebarFilterSelect
          label="Category"
          options={categoryOptions}
          onChange={handleChange}
          value={selectedCategory?.id || ''}
          fieldNames={{ label: 'name', value: 'id' }}
          disabled={!categories}
        />
        {createButton}
      </SidebarFilterRowLayout>
    </SidebarToolbarLayout>
  )
}

export default ConfigSidebarToolbar
