import { FC } from 'react'
import { createColumnHelper, ColumnDef } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import { type TableWidgetProps } from '@components/TableWidget'
import { Account } from '@server/interfaces/account'
import { useConfigPageContext } from '@context/ConfigPageContext'
import { AccountCategory } from '@modules/account/account.types'
import { ACCOUNT_TYPES_OPTIONS } from '@constants/accountTypes'

const columnHelper = createColumnHelper<Account>()

// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<Account, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
  columnHelper.accessor((row) => row.metric.class, {
    id: 'account_class',
    header: () => 'Account Class',
  }),
  columnHelper.accessor((row) => row.metric.type, {
    id: 'account_type',
    header: () => 'Account Type',
  }),
]

interface AccountsTableProps extends Partial<TableWidgetProps<Account>> {}

const AccountsTable: FC<AccountsTableProps> = ({ ...rest }) => {
  const { selectedCategory, setSelectedCategory } = useConfigPageContext<
    Account,
    AccountCategory
  >()

  const { data = [] } = trpc.api.accounts.listWithMetrics.useQuery(undefined, {
    select: (data) =>
      data.filter(
        (account) =>
          !selectedCategory ||
          account.metric[selectedCategory.propertyType] === selectedCategory.id,
      ) as Account[],
  })

  const changeFilter = (type: string) => {
    setSelectedCategory({
      id: type,
      name: type,
      propertyType: 'type',
    })
  }

  return (
    <TableWidget<Account>
      {...rest}
      data={data}
      columns={columns}
      getRowId={(row) => row.id}
      filterOptions={ACCOUNT_TYPES_OPTIONS}
      currentFilter={String(selectedCategory?.id || '')}
      onFilterChange={changeFilter}
      withIndex
    />
  )
}

export default AccountsTable
