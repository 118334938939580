import { FC, useEffect } from 'react'
import { Checkbox, DrawerProps, Form, Input, message, Radio } from 'antd'
import { trpc } from '@services/trpc'
import { PropertiesAccordion, PropertiesDrawer } from '@components'
import type { DatatagInput } from '@modules/datagroup/datagroup.types'
import { View } from '@server/interfaces/view'
import { ViewInput } from '@modules/view/view.types'

interface ViewPropertiesProps extends DrawerProps {
  view: View | null
  onClose?: () => void
}

const ViewProperties: FC<ViewPropertiesProps> = ({
  view,
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { refetch } = trpc.api.views.list.useQuery()

  const handleSuccess = () => {
    message.success('View Metric updated successfully')
    refetch()
    form.resetFields()
    onClose?.()
  }

  const { mutate: save, isPending } =
    trpc.api.datatags.createOrUpdateViewMetric.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = (values: ViewInput) => {
    save(values)
  }

  useEffect(() => {
    if (!view) return
    form.setFieldsValue(view)
  }, [form, view])

  return (
    <PropertiesDrawer<DatatagInput>
      {...props}
      onSubmit={handleSubmit}
      form={form}
      initialValues={view as View}
      confirmLoading={isPending}
    >
      <PropertiesAccordion label={'View Metric'}>
        <Form.Item<View>
          name="name"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input the view metric name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item<View> name="description" label="Description">
          <Input placeholder="Enter description" />
        </Form.Item>

        <Form.Item<View> name="memo" label="Memo">
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder="Enter memo"
          />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="View Basis">
        <Form.Item<View> name="modelClass" label="Model Class">
          <Input placeholder="Enter model class" />
        </Form.Item>
        <Form.Item<View> name="category" label="Category">
          <Input placeholder="Enter category" />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="Members Display Options">
        <Form.Item<View> name="membersDisplay">
          <Radio.Group>
            <Radio value="name">Name</Radio>
            <Radio value="description">Description</Radio>
            <Radio value="name_description">Name + Description</Radio>
          </Radio.Group>
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="Other Settings">
        <Form.Item
          valuePropName="checked"
          name="inactive"
          aria-label="Inactive"
        >
          <Checkbox>Inactive</Checkbox>
        </Form.Item>
      </PropertiesAccordion>
    </PropertiesDrawer>
  )
}

export default ViewProperties
