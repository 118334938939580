import { FC } from 'react'
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWithDataProps } from '@components/TableWithData'
import { DataModel } from '@modules/datamodel'

const columnHelper = createColumnHelper<DataModel>()

// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<DataModel, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
]

interface DataModelTableProps extends Partial<TableWithDataProps<DataModel>> {
  datamodelGroup: 'income' | 'computed' | 'lookup' | 'opex' | 'ledger'
}

const DataModelTable: FC<DataModelTableProps> = ({
  datamodelGroup,
  ...rest
}) => {
  const { data = [] } = trpc.api.datamodel.listByType.useQuery({
    type: datamodelGroup,
  })

  return (
    <TableWidget<DataModel>
      {...rest}
      data={data}
      columns={columns}
      getRowId={(row) => row.id}
      withIndex
    />
  )
}

export default DataModelTable
