import { CompanyInput } from '@modules/company'
import { Form, FormInstance, Input, Select } from 'antd'
import useStyles from './CreateCompanyForm.styles'
import { BulkFormList } from '@components'
import type { BulkFormListValues } from '@components/BulkFormList'

type CreateCompanyModalProps = {
  form: FormInstance
  onSubmit: (values: BulkFormListValues<CompanyInput>) => void
}

const CreateCompanyModal: React.FC<CreateCompanyModalProps> = ({
  form,
  onSubmit,
}) => {
  const { styles } = useStyles()

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      className={styles.form}
    >
      <BulkFormList>
        <Form.Item
          name="name"
          label="Name | ID"
          rules={[
            { required: true, message: 'Please input the company name or ID!' },
          ]}
          className={styles.formItem}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item
          name="company_region"
          label="Region"
          className={styles.formItem}
        >
          <Select placeholder="Select region" disabled />
        </Form.Item>

        <Form.Item
          name="company_type"
          label="Company type"
          className={styles.formItem}
        >
          <Select placeholder="Select company type" disabled />
        </Form.Item>
      </BulkFormList>
    </Form>
  )
}

export default CreateCompanyModal
