import useStyles from './SidebarCollapsibleItem.styles'
import { FaCaretRight } from 'react-icons/fa'
import { useEffect, useRef } from 'react'
import useModalState from '@hooks/useModalState'
import { IconButton, SidebarActionsLayout } from '..'
import { FilePlus01 } from '@untitled-ui/icons-react'

type SidebarCollapsibleItemProps = {
  label: string | React.ReactNode
  children: React.ReactNode
  actions?: React.ReactNode
  onCreateClick?: () => void
  onLabelClick?: (e: React.MouseEvent<HTMLDivElement>) => void
  isSelected?: boolean
  defaultOpen?: boolean
}

const SidebarCollapsibleItem: React.FC<SidebarCollapsibleItemProps> = ({
  label,
  children,
  actions,
  onCreateClick,
  onLabelClick,
  isSelected,
  defaultOpen = true,
}) => {
  const state = useModalState(defaultOpen)
  const { styles, cx } = useStyles({ isOpen: state.isOpen })
  const contentRef = useRef<HTMLDivElement>(null)

  const handleCreateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    onCreateClick?.()
  }

  const handleCollapseClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    state.toggle()
  }

  useEffect(() => {
    if (contentRef.current) {
      const timeoutId = setTimeout(() => {
        contentRef.current!.style.maxHeight = state.isOpen
          ? `${contentRef.current!.scrollHeight}px`
          : '0'
      }, 0)

      return () => clearTimeout(timeoutId)
    }
  }, [state.isOpen, children])

  return (
    <div className={styles.collapseContainer}>
      <div
        className={cx(
          styles.collapseHeader,
          state.isOpen && styles.collapseHeaderOpened,
          isSelected && styles.collapseHeaderSelected,
        )}
        onClick={onLabelClick || state.toggle}
      >
        <span className={styles.collapseHeaderLabel}>
          <span
            className={styles.collapseHeaderIconWrapper}
            onClick={onLabelClick ? handleCollapseClick : undefined}
          >
            <FaCaretRight
              className={cx(
                styles.collapseHeaderIcon,
                state.isOpen && styles.collapseHeaderIconOpened,
              )}
            />
          </span>
          {label}
        </span>

        {actions}

        {onCreateClick && (
          <SidebarActionsLayout>
            <IconButton onClick={handleCreateClick} aria-label="Add new">
              <FilePlus01 width={16} />
            </IconButton>
          </SidebarActionsLayout>
        )}
      </div>
      <div className={styles.collapseContentWrapper}>
        <div
          className={cx(
            styles.collapseContent,
            state.isOpen && styles.collapseContentOpened,
          )}
          ref={contentRef}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default SidebarCollapsibleItem
