import { SearchToolbar, SidebarContentLayout } from '@components'
import useStyles from './SidebarContent.styles.ts'

interface SidebarContentProps {
  children?: React.ReactNode
  className?: string
  onSearch?: (value: string) => void
}

const SidebarContent: React.FC<SidebarContentProps> = ({
  children,
  className,
  // TODO:
  // onSearch,
}) => {
  const { styles, cx } = useStyles()

  return (
    <SidebarContentLayout>
      <SearchToolbar />
      <div className={cx(styles.content, className)}>{children}</div>
    </SidebarContentLayout>
  )
}

export default SidebarContent
