import {
  ACCOUNT_CATEGORIES,
  AccountClassValues,
  getCategoryOptionsByClass,
} from '@constants/accountCategories'
import { ACCOUNT_CLASSES } from '@constants/accountClasses'
import {
  getAccountInterpretations,
  getCashFlowByValue,
} from '@constants/accountInterpretations'
import {
  AccountCategoryValues,
  getTypeOptionsByCategory,
} from '@constants/accountTypes'
import { VALUE_TYPES, getValueTypeOptionsByClass } from '@constants/valueTypes'
import { trpc } from '@services/trpc'
import { Form, FormInstance } from 'antd'
import { useEffect, useMemo } from 'react'
import {
  ACCOUNT_AGGREGATION,
  getAccountAggregationOptions,
} from '@constants/accountAggregation'
import { getAccountCurrencyFXRateOptions } from '@constants/currencyFXRate'

type Options = {
  value: string
  label: string
}[]

type HookOptions = {
  form: FormInstance
  formNamePath?: [string, number] | []
  isMetric?: boolean
  isCustomComputed?: boolean
  isEditMode?: boolean
  metricId?: number
  isAccount?: boolean
}

const useAccountFormOptions = ({
  form,
  formNamePath = [],
  isMetric = false,
  isCustomComputed,
  isAccount,
  isEditMode = false,
  metricId,
}: HookOptions) => {
  const selectedClass = Form.useWatch(
    [...formNamePath, 'class'],
    form,
  ) as AccountClassValues

  const isFinancial = selectedClass === ACCOUNT_CLASSES.financial

  const selectedCategory = Form.useWatch(
    [...formNamePath, 'category'],
    form,
  ) as AccountCategoryValues

  const selectedType = Form.useWatch([...formNamePath, 'type'], form)

  const selectedAccountInterpretation = Form.useWatch(
    [...formNamePath, 'account_interpretation'],
    form,
  )

  const selectedMetricId = Form.useWatch([...formNamePath, 'metric_id'], form)

  const { data: allMetrics = [] } = trpc.api.metrics.list.useQuery(undefined, {
    staleTime: 0,
  })

  const metrics = isEditMode
    ? allMetrics.filter(
        (metric) => metric.source === 'metric' || metric.id === metricId,
      )
    : allMetrics

  const selectedMetric = metrics.find(
    (metric) => metric.id === selectedMetricId,
  )

  const metricOptions = metrics
    .filter((metric) => metric.category === selectedCategory)
    .map((metric) => ({
      label: metric.name,
      value: metric.id,
    }))

  const accountInterpretationOptions = useMemo<Options>(
    () =>
      getAccountInterpretations(selectedClass, selectedCategory, selectedType),
    [selectedClass, selectedCategory, isMetric, selectedType],
  )

  const cashFlowMetricOptions = useMemo<Options>(
    () => getCashFlowByValue(selectedAccountInterpretation),
    [selectedAccountInterpretation],
  )

  const currencyFxRateOptions = getAccountCurrencyFXRateOptions()

  const aggregationOptions = getAccountAggregationOptions()

  const categoryOptions = useMemo<Options>(
    () => getCategoryOptionsByClass(selectedClass, isMetric),
    [selectedClass, isMetric],
  )

  const typeOptions = useMemo<Options>(
    () => getTypeOptionsByCategory(selectedCategory),
    [selectedCategory],
  )
  const valueOptions = useMemo<Options>(
    () => getValueTypeOptionsByClass(selectedClass),
    [selectedClass],
  )
  useEffect(() => {
    if (isMetric && isEditMode && isFinancial) {
      form.setFieldValue(
        [...formNamePath, 'reset_annualy'],
        selectedCategory === ACCOUNT_CATEGORIES.profitsAndLoss ? true : false,
      )
      form.setFieldValue(
        [...formNamePath, 'report_display'],
        ['Liability', 'Equity', 'Revenue'].includes(selectedType),
      )
      form.setFieldValue(
        [...formNamePath, 'increase_favorable'],
        ['Asset', 'Equity', 'Revenue'].includes(selectedType),
      )
      form.setFieldValue(
        [...formNamePath, 'account_aggregation'],
        ACCOUNT_AGGREGATION.sum,
      )
    } else if (!isMetric) {
      form.setFieldValue(
        [...formNamePath, 'reset_annualy'],
        selectedMetric?.reset_annualy,
      )
    }
  }, [
    isMetric,
    selectedType,
    isFinancial,
    selectedCategory,
    form,
    formNamePath,
  ])

  useEffect(() => {
    if (isFinancial && !isCustomComputed) {
      form.setFieldValue([...formNamePath, 'value_type'], VALUE_TYPES.monetary)

      const currentClassCategories = categoryOptions.map(
        (option) => option.value,
      )
      if (!currentClassCategories.includes(selectedCategory)) {
        form.setFieldValue([...formNamePath, 'category'], '')
      }
    }

    if (selectedClass === ACCOUNT_CLASSES.operational) {
      form.setFieldValue(
        [...formNamePath, 'category'],
        ACCOUNT_CATEGORIES.statistical,
      )
    }
  }, [selectedClass, form, formNamePath, categoryOptions, selectedCategory])

  useEffect(() => {
    if (metricOptions.length > 0) {
      const currentCategoryMetrics = metricOptions.map((option) => option.value)
      if (!selectedCategory) {
        form.setFieldValue([...formNamePath, 'type'], '')
        form.setFieldValue([...formNamePath, 'value_type'], '')
        form.setFieldValue([...formNamePath, 'metric_id'], '')
      } else if (
        !currentCategoryMetrics.includes(selectedMetricId) &&
        !isMetric
      ) {
        form.setFieldValue([...formNamePath, 'metric_id'], '')
      }
    }
  }, [
    selectedCategory,
    form,
    formNamePath,
    typeOptions,
    metricOptions,
    selectedMetricId,
    isMetric,
  ])

  useEffect(() => {
    if (selectedType && isEditMode) {
      const currentCategoryTypes = typeOptions.map((option) => option.value)
      if (!currentCategoryTypes.includes(selectedType)) {
        form.setFieldValue([...formNamePath, 'type'], '')
        form.setFieldValue([...formNamePath, 'account_interpretation'], '')
      }
    }
  }, [selectedType, form, formNamePath, typeOptions])

  useEffect(() => {
    if (
      isFinancial &&
      isAccount &&
      selectedCategory !== ACCOUNT_CATEGORIES.statistical
    ) {
      form.setFieldValue(
        [...formNamePath, 'account_type'],
        selectedMetric?.type,
      )
    }
  }, [
    selectedCategory,
    isFinancial,
    isAccount,
    form,
    formNamePath,
    selectedMetric,
  ])

  return {
    categoryOptions,
    typeOptions,
    valueOptions,
    metricOptions,
    accountInterpretationOptions,
    cashFlowMetricOptions,
    currencyFxRateOptions,
    aggregationOptions,
  }
}

export default useAccountFormOptions
