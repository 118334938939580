import { Button, ButtonProps } from 'antd'
import { SwitchHorizontal01 } from '@untitled-ui/icons-react'

interface CompareButtonProps extends ButtonProps {}

const CompareButton: React.FC<CompareButtonProps> = (props) => {
  return (
    <Button type="link" {...props}>
      <SwitchHorizontal01 width={16} />
      Compare
    </Button>
  )
}

export default CompareButton
