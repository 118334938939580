import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: token.padding,

      '> *': {
        borderRadius: token.borderRadius,
        backgroundColor: token.colorWhite,
        border: `1px solid ${token.colorBorder}`,
        padding: `${token.paddingXS}px 0px`,

        '.ant-form-item': {
          '&:not(:last-child)': {
            borderRight: `1px solid ${token.colorBorder}`,
          },
          padding: `0 ${token.paddingContentHorizontalSM}px`,
        },
      },
    },
  }
})
