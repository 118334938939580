import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'auto',
      backgroundColor: token.colorWhite,
      padding: token.paddingXS,
      borderRadius: token.borderRadiusLG,
    },
  }
})

const ViewContentLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { styles } = useStyles()

  return <div className={styles.root}>{children}</div>
}

export default ViewContentLayout
