import { DatePicker, Form, FormInstance, Select } from 'antd'
import useStyles from './CreateTransactionForm.styles'
import { BulkFormLayout, BulkFormList } from '@components'
import { AccountId } from '@server/interfaces/base'
import dayjs, { Dayjs } from 'dayjs'
import { trpc } from '@services/trpc'
import { useDataModelPageContext } from '@modules/datamodel'
import { skipToken } from '@tanstack/react-query'
import TransactionFormRow from './TransactionFormRow'
import { useEffect } from 'react'
import { usePeriodOptions } from '@hooks/usePeriodOptions'
import { useCurrencyOptions } from '@hooks/useCurrency'
import { Datagroup, Datatag } from '@modules/datagroup'

export type TransactionFormValues = {
  datamodel_id: number
  date: Dayjs
  periodClass: string
  values: {
    account: AccountId
    name: string
    DR: number
    CR: number
    datatags?: Record<Datagroup['name'], Datatag['id']>
  }[]
}

type CreateTransactionFormProps = {
  form: FormInstance
  onSubmit: (values: TransactionFormValues) => void
}

const CreateTransactionForm: React.FC<CreateTransactionFormProps> = ({
  form,
  onSubmit,
}) => {
  const { styles } = useStyles()
  const { selectedDataModel } = useDataModelPageContext()

  const { data: datagroupData = [] } =
    trpc.api.datatags.getDatamodelDatagroups.useQuery(
      selectedDataModel ? { id: selectedDataModel.id } : skipToken,
    )

  const { currenciesOptions, currencySymbol } = useCurrencyOptions(form)

  const usdCurrency = currenciesOptions.find(
    (currency) => currency.label === 'USD',
  )

  const { periodClassOptions, periodClassValues } = usePeriodOptions()

  //TODO FY
  // const [filtersParams] = useSearchParams()

  // const { fiscalYear } = Object.fromEntries(filtersParams.entries()) as Record<
  //   (typeof filtersList)[number],
  //   string
  // >
  // const { data: fiscalYearData = [] } =
  //   trpc.api.fiscalYears.getById.useQuery(fiscalYear)

  const selectedPeriodClass = Form.useWatch('periodClass', form)

  // const handleDateChange = (date: Dayjs | null) => {
  //   if (date && selectedPeriodClass === periodClassValues.operating) {
  //     form.setFieldValue('date', date.endOf('month'))
  //   }
  // }

  // 2024 should be replaced with actual FY
  useEffect(() => {
    if (selectedPeriodClass === periodClassValues.open) {
      form.setFieldValue('date', dayjs('2024').startOf('month').startOf('day'))
    } else if (selectedPeriodClass === periodClassValues.closing) {
      form.setFieldValue('date', dayjs('2024').endOf('year').endOf('day'))
    } else {
      form.setFieldValue('date', '')
    }
  }, [selectedPeriodClass, form, periodClassValues])

  const isDataPickerDisabled =
    selectedPeriodClass === periodClassValues.open ||
    selectedPeriodClass === periodClassValues.closing

  return (
    <>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <BulkFormLayout className={styles.bulkFormLayout}>
          <div className={styles.header}>
            <div className={styles.headerRow}>
              <Form.Item
                name="periodClass"
                label="Period Class"
                initialValue="operating"
              >
                <Select
                  disabled={selectedDataModel?.type === 'lookup'}
                  options={periodClassOptions}
                />
              </Form.Item>
              <Form.Item
                name="date"
                label="Transaction Date"
                rules={[{ required: true }]}
              >
                <DatePicker
                  format="MMM D, YYYY"
                  className={styles.datePicker}
                  disabled={isDataPickerDisabled}
                />
              </Form.Item>

              <Form.Item
                name="currency"
                label="Currency"
                initialValue={usdCurrency?.value}
                rules={[{ required: true }]}
              >
                <Select options={currenciesOptions} />
              </Form.Item>
            </div>
          </div>
          <BulkFormList name="values" wrapped rowClassName={styles.row}>
            {(index) => (
              <div className={styles.transactionFormWrapper}>
                <TransactionFormRow
                  currencySymbol={currencySymbol}
                  index={index}
                  form={form}
                  key={index}
                  formName="values"
                  datagroupData={datagroupData}
                />
              </div>
            )}
          </BulkFormList>
        </BulkFormLayout>
      </Form>
    </>
  )
}

export default CreateTransactionForm
