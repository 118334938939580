import { SearchOutlined } from '@ant-design/icons'
import useStyles from './SearchToolbar.styles'
import { Input, Space } from 'antd'

// dummy component
const SearchToolbar: React.FC = () => {
  const { styles } = useStyles()
  return (
    <div className={styles.searchToolbar}>
      <Input
        variant="borderless"
        suffix={
          <Space>
            Search
            <SearchOutlined />
          </Space>
        }
        className={styles.input}
        disabled
      />
    </div>
  )
}

export default SearchToolbar
