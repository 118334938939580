import { useState, useCallback } from 'react';

export type ModalStateType = {
  isOpen: boolean
  open: () => void
  close: () => void
  toggle: () => void
}

const useModalState = (initialState: boolean = false): ModalStateType => {
  const [isOpen, setIsOpen] = useState(initialState);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen(prev => !prev), []);

  return { isOpen, open, close, toggle };
};

export default useModalState;