import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    modal: {
      maxHeight: 'calc(100vh - 100px)',
      overflow: 'auto',
    },
    modalBody: {
      // maxWidth: '70%',
      backgroundColor: token.colorWhite,
    },
    info: {
      margin: 0,
      paddingBottom: 12,
      borderBottom: `1px solid ${token.colorBorder}`,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,

      '& .ant-select, .ant-picker': {
        width: 190,
      },
    },
    cell: {
      padding: `0 ${token.paddingSM}px`,
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    dates: {
      display: 'flex',
      gap: 12,
    },
    options: {
      display: 'flex',
      padding: '0 4px',
      gap: 24,
      '&&& label': {
        fontWeight: 400,
      },
    },
    checkbox: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
  }
})
