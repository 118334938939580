import { FC } from 'react'
import {
  type ColumnDef,
  createColumnHelper,
  OnChangeFn,
  RowSelectionState,
} from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import {
  ACCOUNT_TYPES_LABELS,
  ALL_ACCOUNT_TYPES,
} from '@constants/accountTypes'
import { capitalize } from '@utils/strings'
import { useDatatagColumnsContext } from '@modules/datamodel/DatatagColumnsContext'
import { COLUMN_GROUPS } from '@modules/compoundModel/compoundModel.constants'
import { Datagroup, Datatag } from '@modules/datagroup'
import { mapSelectedRowIdsToTableSelectionState } from '@utils/tables'

const metricOptions = [
  { value: '', label: 'All' },
  ...Object.values(ALL_ACCOUNT_TYPES).map((value) => ({
    value,
    label: ACCOUNT_TYPES_LABELS[value],
  })),
]

const filterOptions = [
  {
    type: 'account_category',
    label: 'Account Category',
    options: metricOptions,
  },
]

// type DataTagItem = {
//   id: number
//   name: string
// }

const columnHelper = createColumnHelper<Datatag>()

// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<Datatag, any>[] = [
  columnHelper.accessor((row) => row.value, {
    id: 'name',
    header: 'Name | ID',
  }),
]

interface DatatagsTableWidgetProps extends Partial<TableWidgetProps<Datatag>> {
  datagroup: Datagroup
}

const ColumnsSubtable: FC<DatatagsTableWidgetProps> = ({
  datagroup,
  ...rest
}) => {
  const { data: datatags = [] } =
    trpc.api.datatags.listDatagroupMembers.useQuery({
      id: datagroup.id,
    })
  const {
    setSelectedDatatagColumns,
    selectedDatatagColumns,
    setIsDatatagColumnsDirty,
  } = useDatatagColumnsContext()

  const handleSelect: OnChangeFn<RowSelectionState> = (updaterOrValue) => {
    setIsDatatagColumnsDirty(true)

    setSelectedDatatagColumns((prevDatatags) => {
      const existingItem = prevDatatags.find((d) => d.id === datagroup.id)
      const relatedItem = existingItem || {
        id: datagroup.id,
        name: datagroup.name,
        tagType: 'Data Group',
        allocate: false,
        members: [],
        itemGroup: COLUMN_GROUPS.columns,
      }

      const newSelection =
        typeof updaterOrValue === 'function'
          ? updaterOrValue(
              mapSelectedRowIdsToTableSelectionState(relatedItem.members || []),
            )
          : updaterOrValue

      const newSelectedRows = Object.keys(newSelection)
        .filter((key) => newSelection[key] === true)
        .map((m) => Number(m))

      if (newSelectedRows.length === 0) {
        return prevDatatags.filter((d) => d.id !== datagroup.id)
      }

      relatedItem.members = newSelectedRows
      return [...prevDatatags, ...(existingItem ? [] : [relatedItem])]
    })
  }

  const selectedRows = mapSelectedRowIdsToTableSelectionState(
    selectedDatatagColumns.find((d) => d.id === datagroup.id)?.members || [],
  )

  return (
    <TableWidget<Datatag>
      {...rest}
      data={datatags}
      columns={columns}
      filterOptions={filterOptions}
      title={capitalize(datagroup.name)}
      onSelect={handleSelect}
      selectedRows={selectedRows}
      getRowId={(row) => row.id}
      withCheckbox
      withIndex
    />
  )
}

export default ColumnsSubtable
