import { FC, useEffect } from 'react'
import { Checkbox, Form, Input, Select, message } from 'antd'
import { trpc } from '@services/trpc'
import { type Company } from '@modules/company'
import PropertiesAccordion from '@components/PropertiesAccordion'
import PropertiesDrawer from '@components/PropertiesDrawer'

type CompanyPropertiesProps = {
  values: Company | null
  onClose: () => void
}

const CompanyProperties: FC<CompanyPropertiesProps> = ({ values, onClose }) => {
  const [form] = Form.useForm()
  const { refetch } = trpc.api.companies.list.useQuery()

  const { data: accounts = [] } = trpc.api.accounts.listWithMetrics.useQuery()
  const ctaAccountsOptions = accounts
    .filter((account) => account.metric.name === 'Currency Translation')
    .map((account) => ({
      value: account.id,
      label: account.name,
    }))

  const { data: calendars = [] } = trpc.api.calendar.list.useQuery()

  const handleSuccess = () => {
    message.success('Company updated successfully')
    refetch()
    form.resetFields()
    onClose()
  }

  const { mutate: save, isPending } =
    trpc.api.companies.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = (formValues: Company) => {
    save({
      ...formValues,
      id: values!.id,
    })
  }

  useEffect(() => {
    if (!values) return
    form.setFieldsValue(values)
  }, [form, values])

  return (
    <PropertiesDrawer<Company>
      onSubmit={handleSubmit}
      form={form}
      initialValues={values as Company}
      confirmLoading={isPending}
    >
      <PropertiesAccordion label="Company">
        <Form.Item
          name="name"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input the company name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input placeholder="Enter a description..." />
        </Form.Item>
        <Form.Item name="memo" label="Memo/Keywords">
          <Input.TextArea placeholder="Enter memo/keywords..." />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="General">
        <Form.Item name="calendar" label="Calendar basis" initialValue={1}>
          <Select
            options={calendars}
            fieldNames={{ label: 'name', value: 'id' }}
            disabled
          />
        </Form.Item>
        <Form.Item name="currency" label="Currency basis">
          <Select value="USD" disabled />
        </Form.Item>
        <Form.Item name="cta_id" label="CTA Account">
          <Select options={ctaAccountsOptions} />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="Company Categories">
        <Form.Item name="name" label="Company">
          <Input disabled />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="Other Settings">
        <Form.Item
          valuePropName="checked"
          name="inactive"
          aria-label="Inactive"
        >
          <Checkbox>Inactive</Checkbox>
        </Form.Item>
      </PropertiesAccordion>
    </PropertiesDrawer>
  )
}

export default CompanyProperties
