import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    tableWidgetHeader: {
      display: 'flex',
      alignItems: 'center',
      height: 46,
      borderBottom: `1px solid ${token.gray300}`,
      padding: `0 ${token.paddingContentHorizontal}px`,
    },
    tableWidgetHeaderTitle: {
      fontSize: token.fontSizeLG,
      fontFamily: token.fontFamily,
      fontWeight: token.fontWeightStrong,
      lineHeight: token.lineHeightLG,
    },
  }
})
