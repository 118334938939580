import { CreateModal } from '@components'
import CreateAccountForm from './CreateAccountForm'
import { Form, message } from 'antd'
import useStyles from './CreateAccountModal.styles'
import { trpc } from '@services/trpc'
import type { AccountInput } from '@modules/account'
import type { BulkFormListValues } from '@components/BulkFormList'
import { CreateModalProps } from '@components/CreateModal'

const CreateAccountModal: React.FC<CreateModalProps> = ({
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { styles } = useStyles()
  const { refetch: fetchAccounts } = trpc.api.accounts.list.useQuery()
  const { refetch: fetchAccountsWithMetrics } =
    trpc.api.accounts.listWithMetrics.useQuery()

  const handleSuccess = () => {
    message.success('Account created successfully')
    fetchAccounts()
    fetchAccountsWithMetrics()
    form.resetFields()
    onClose?.()
  }
  const { mutate: saveAccount, isPending } =
    trpc.api.accounts.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
    })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: BulkFormListValues<AccountInput>) => {
    values.items.forEach((item) => {
      const { metric_id, ...rest } = item
      saveAccount(
        metric_id
          ? { ...item, source: 'account' }
          : { ...rest, source: 'account' },
      )
    })
  }

  const handleCancel = () => {
    form.resetFields()
    onClose?.()
  }

  return (
    <CreateModal
      {...props}
      title="Create account"
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      centered
      width="clamp(700px, 85%, 1600px)"
      className={styles.modal}
      confirmLoading={isPending}
    >
      <CreateAccountForm form={form} onSubmit={handleSubmit} />
    </CreateModal>
  )
}

export default CreateAccountModal
