import { Sidebar, SidebarList } from '@components'
import { useConfigurationMode } from '@context/ConfigurationContext'
import { useState } from 'react'
import { LookupSidebarDataModelsTab } from './tabs'
import { SidebarContent, SidebarListByDatatag } from '@components'
import {
  LookupSidebarDataModelsToolbar,
  LookupSidebarToolbar,
} from './toolbars'
import useModalState from '@hooks/useModalState'
import { DataModel, useDataModelPageContext } from '@modules/datamodel'
import { trpc } from '@services/trpc'
import { Tab } from '@context/ConfigPageContext'

const sidebarTabs = {
  dataModels: 'Data Models',
  views: 'Views',
} as { [key: string]: Tab }

const sidebarTabsList = Object.values(sidebarTabs)

const LookupSidebar = () => {
  const [selectedTab, setSelectedTab] = useState(sidebarTabs.dataModels)
  const { isConfigurationMode } = useConfigurationMode()
  const { selectedDataModel, changeSelectedDatamodelId } =
    useDataModelPageContext()
  const assumptionModalState = useModalState()
  const [currentDataTagClass, setCurrentDataTagClass] = useState('company')
  const { data: datamodels = [] } = trpc.api.datamodel.listByType.useQuery({
    type: 'lookup',
  })

  // TODO: extract separate sidebars for config/normal
  return (
    <Sidebar
      title="Lookup tables"
      tabs={isConfigurationMode ? sidebarTabsList : undefined}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
    >
      {isConfigurationMode ? (
        <>
          {selectedTab === sidebarTabs.dataModels && (
            <>
              <LookupSidebarDataModelsToolbar />
              <SidebarContent>
                <LookupSidebarDataModelsTab />
              </SidebarContent>
            </>
          )}
          {selectedTab === sidebarTabs.views && <>Views placeholder</>}
        </>
      ) : (
        <>
          <LookupSidebarToolbar
            onNewAssumptionClick={assumptionModalState.open}
            onDataTagClassChange={setCurrentDataTagClass}
            currentDataTagClass={currentDataTagClass}
          />
          <SidebarContent>
            {selectedDataModel ? (
              <SidebarListByDatatag
                currentDataTagClass={currentDataTagClass || null}
              />
            ) : (
              <SidebarList<DataModel>
                items={datamodels}
                onItemClick={(datamodel) => {
                  changeSelectedDatamodelId(datamodel.id)
                }}
              />
            )}
          </SidebarContent>
        </>
      )}
    </Sidebar>
  )
}

export default LookupSidebar
