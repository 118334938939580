import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    modalContent: {
      height: 'calc(100vh - 100px)',
    },
    formWrapper: {
      padding: token.paddingSM,
      borderRadius: token.borderRadius,
      backgroundColor: token.colorWhite,
      border: `1px solid ${token.colorBorder}`,
    },
  }
})
