import { ViewMetric } from '@server/interfaces/view'

export const VIEW_METRICS_CATEGORY_ITEM = {
  id: 'view-metric',
  name: 'View Metric',
} as const

export const mockViewMetric: ViewMetric = {
  id: 1,
  datagroup_id: 1,
  name: 'test view metric',
  description: 'test view metric description',
} as const
