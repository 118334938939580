import { Form, FormInstance, Input, Radio, Select } from 'antd'
import useStyles from './MetricFormRow.styles'
import {
  ACCOUNT_CLASSES,
  ACCOUNT_CLASSES_OPTIONS,
} from '@constants/accountClasses'
import { useAccountFormOptions } from '@hooks'

interface MetricFormRowProps {
  index: number
  form: FormInstance
  formName?: string
}

const MetricFormRow: React.FC<MetricFormRowProps> = ({
  index,
  form,
  formName = 'items',
}) => {
  const { styles } = useStyles()

  const selectedClass = Form.useWatch([formName, index, 'class'], form)
  const selectedCategory = Form.useWatch([formName, index, 'category'], form)

  const { categoryOptions, typeOptions, valueOptions } = useAccountFormOptions({
    form,
    formNamePath: [formName, index],
    isMetric: true,
  })

  return (
    <>
      <Form.Item
        name={[index, 'name']}
        label="Name | ID"
        rules={[
          {
            required: true,
            message: 'Please input the account name or ID!',
          },
        ]}
        className={styles.formItem}
      >
        <Input placeholder="Enter name or ID" />
      </Form.Item>

      <Form.Item
        name={[index, 'class']}
        label="Account Class"
        rules={[
          {
            required: true,
            message: 'Please select the account class!',
          },
        ]}
        className={styles.formItem}
        initialValue="financial"
      >
        <Radio.Group options={ACCOUNT_CLASSES_OPTIONS} />
      </Form.Item>

      <Form.Item
        name={[index, 'category']}
        label="Account Category"
        rules={[
          {
            required: true,
            message: 'Please select the account category!',
          },
        ]}
        className={styles.formItem}
      >
        <Select
          aria-label="Metric Account Category"
          disabled={selectedClass === ACCOUNT_CLASSES.operational}
          placeholder="Select category"
          options={categoryOptions}
        />
      </Form.Item>

      <Form.Item
        name={[index, 'value_type']}
        label="Value type"
        rules={[
          {
            required: true,
            message: 'Please select the account category!',
          },
        ]}
        className={styles.formItem}
      >
        <Select
          aria-label="Metric Value Type"
          disabled={selectedClass === ACCOUNT_CLASSES.financial}
          placeholder="Select value type"
          options={valueOptions}
        />
      </Form.Item>

      <Form.Item
        name={[index, 'type']}
        label="Account Type"
        rules={[
          {
            required: true,
            message: 'Please select the account type!',
          },
        ]}
        className={styles.formItem}
      >
        <Select
          aria-label="Metric Account Type"
          placeholder="Select type"
          options={typeOptions}
          disabled={!selectedCategory}
        />
      </Form.Item>
    </>
  )
}

export default MetricFormRow
