import { Navigate } from 'react-router-dom'
import DummyPage from '@components/DummyPage'

const assetsRoutes = [
  {
    index: true,
    element: <Navigate to="/operational/assets/capex" replace />,
  },
  {
    path: 'capex',
    handle: { title: 'CAPEX' },
    Component: DummyPage,
  },
  {
    path: 'financing',
    handle: { title: 'Financing' },
    Component: DummyPage,
  },
  {
    path: 'vat',
    handle: { title: 'VAT' },
    Component: DummyPage,
  },
]

export default assetsRoutes
