import { createStyles } from 'antd-style'

export default createStyles(() => {
  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
  }
})
