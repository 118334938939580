import { useSearchParams } from 'react-router-dom'
import { trpc } from '@services/trpc'
import { capitalize } from '@utils/strings'
import { SidebarList, SidebarCollapsibleItem } from '@components'
import { Flex } from 'antd'
import { File01 } from '@untitled-ui/icons-react'
import useStyles from './SidebarListByDatatag.styles'
import { NoData } from '@components'
import { ACCOUNT_CATEGORIES } from '@constants/accountCategories'

type SidebarListItem = {
  id: string
  name: string
}

const SidebarListByDatatag = ({
  currentDataTagClass,
}: {
  currentDataTagClass: string | null
}) => {
  const { styles } = useStyles()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: allDatatags = {} } = trpc.api.datatags.allDomains.useQuery()
  const datatags =
    currentDataTagClass === 'accountcategory'
      ? Object.values(ACCOUNT_CATEGORIES).map((category) => ({
          id: category,
          name: category,
        }))
      : (allDatatags[currentDataTagClass!] || []).map((datatag) => ({
          id: String(datatag.id),
          name: datatag.name,
        }))

  const handleFilterChange = ({ id }: SidebarListItem) => {
    setSearchParams(
      (prev) => {
        const current = Object.fromEntries(prev.entries())
        return {
          ...current,
          [currentDataTagClass!]: String(id),
        }
      },
      { replace: true },
    )
  }

  if (!currentDataTagClass)
    return (
      <div className={styles.emptyWrapper}>
        <NoData description="Please select a data tag" small />
      </div>
    )

  return (
    <SidebarCollapsibleItem
      label={
        <Flex gap={4} align="center">
          <File01 width={16} />
          {capitalize(currentDataTagClass)} ({datatags.length})
        </Flex>
      }
    >
      <SidebarList<SidebarListItem>
        items={datatags}
        onItemClick={handleFilterChange}
        selectedItemId={searchParams.get(currentDataTagClass) || undefined}
      />
    </SidebarCollapsibleItem>
  )
}

export default SidebarListByDatatag
