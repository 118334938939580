import { ColumnDef, Row, createColumnHelper } from '@tanstack/react-table'
import { memo, useMemo } from 'react'
import { TableWidget } from '@components'
import type { TableWidgetProps } from '@components/TableWidget'
import {
  TransactionDatatagsEditableCell,
  AssumptionDatatagsEditableCell,
} from './EditableCell'
import { DataModel } from '@modules/datamodel'

export type AssumptionRow = {
  assumption_id: {
    type: string
    value: number
  }
  assumption_name: {
    type: string
    value: string
  }
  accountdriver_id: {
    type: string
    value: number
  }
  accountdriver_name: {
    type: string
    value: string
  }
  id: string | number
}

export type TableLayoutDef = {
  key: string
  label: string
  children?: Array<TableLayoutDef>
}

type Datatags = (number | null)[]

interface AssumptionsTableProps<T extends AssumptionRow>
  extends Omit<TableWidgetProps<T>, 'columns'> {
  onRowClick?: (row: Row<T>) => void
  data: T[]
  layout: TableLayoutDef[]
  isLoading: boolean
  refetch: () => void
  onChange: (datatagsId: string, value: string) => void
  datamodel?: DataModel | null
  disabled?: boolean
}

interface TableCellProps {
  type: string
  value: number
  id?: number
  datatags: Datatags
  onSave: ({ datatags, value }: { datatags: Datatags; value: string }) => void
  isEditable: boolean
  datamodel?: DataModel | null
}

// TODO: refactor it
const TableCell = memo((props: TableCellProps) => {
  switch (props.type) {
    case 'transaction_coords':
      return (
        <TransactionDatatagsEditableCell
          value={props.value}
          onSave={props.onSave}
          datatags={props.datatags}
          isEditable={props.isEditable}
        />
      )
    case 'datagroups':
      return (
        <AssumptionDatatagsEditableCell
          value={props.value as any}
          datamodel={props.datamodel}
        />
      )

    default:
      return <span>{props.value}</span>
  }
})

const DEPRECATED_AssumptionsTable = <T extends AssumptionRow>({
  onRowClick,
  data,
  layout = [],
  isLoading,
  onChange,
  datamodel,
  disabled,
  ...restTableProps
}: AssumptionsTableProps<T>) => {
  const columnHelper = createColumnHelper<T>()

  const mapColumnDefs =
    (path: Array<string>) =>
    (
      c: TableLayoutDef,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): ColumnDef<T, any> => {
      if (c.children) {
        return columnHelper.group({
          id: c.key,
          header: () => <span>{c.label}</span>,
          columns: c.children.map(mapColumnDefs([...path, c.key])),
        })
      } else {
        const pathKeys = path.concat(c.key)
        // Yeah, wierd logic, need to fix it someday
        return columnHelper.accessor(
          (row) =>
            (pathKeys.length > 1 ? pathKeys.slice(1) : pathKeys).reduce(
              (val: any, key) => val[key] || {},
              row,
            ),
          {
            id: pathKeys.join('-'),
            cell: (props) => {
              const value = props.getValue()
              const key = value.datatags ? value.datatags.join('-') : value.id

              return (
                <TableCell
                  value={value.value}
                  type={value.type}
                  datatags={value.datatags}
                  isEditable={value.isEditable && !disabled}
                  onSave={({ datatags, value }) =>
                    onChange(JSON.stringify(datatags), value)
                  }
                  key={key}
                  datamodel={datamodel}
                />
              )
            },
            header: c.label,
          },
        )
      }
    }

  const columns = useMemo(() => layout.map(mapColumnDefs([])), [layout])

  return (
    <>
      <TableWidget<T>
        {...restTableProps}
        data={data}
        columns={columns}
        onRowClick={onRowClick}
        isLoading={isLoading}
        withIndex
      />
    </>
  )
}

export default DEPRECATED_AssumptionsTable
