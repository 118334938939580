import { TimeMetricViewConfig } from '@server/interfaces/period'
import { useState } from 'react'

const initialConfig = {
  calendar: '',
  from: '2024-01-01',
  to: '2024-12-31',
  time_span: {
    all: {
      days: false,
      weeks: false,
      months: true,
      quarters: false,
      years: true,
    },
    // TODO: add years
    // 'FY 2022': {
    //   days: true,
    //   weeks: true,
    //   months: true,
    //   quarters: true,
    //   years: true,
    // },
    // 'FY 2023': {
    //   days: true,
    //   weeks: true,
    //   months: true,
    //   quarters: true,
    //   years: true,
    // },
    // 'FY 2024': {
    //   days: true,
    //   weeks: true,
    //   months: true,
    //   quarters: true,
    //   years: true,
    // },
    // 'FY 2025': {
    //   days: true,
    //   weeks: true,
    //   months: true,
    //   quarters: true,
    //   years: true,
    // },
    // 'FY 2026': {
    //   days: true,
    //   weeks: true,
    //   months: true,
    //   quarters: true,
    //   years: true,
    // },
    // 'FY 2027': {
    //   days: true,
    //   weeks: true,
    //   months: true,
    //   quarters: true,
    //   years: true,
    // },
    // 'FY 2028': {
    //   days: true,
    //   weeks: true,
    //   months: true,
    //   quarters: true,
    //   years: true,
    // },
    // 'FY 2029': {
    //   days: true,
    //   weeks: true,
    //   months: true,
    //   quarters: true,
    //   years: true,
    // },
    // 'FY 2030': {
    //   days: true,
    //   weeks: true,
    //   months: true,
    //   quarters: true,
    //   years: true,
    // },
  },
  suppress_zero_rows: true,
  display_period_total: true,
  default: true,
}

export type TimeMetricConfig = Omit<TimeMetricViewConfig, 'calendar'> & {
  calendar: string | number
}

const useTimeMetricConfig = (config: TimeMetricConfig = initialConfig) => {
  const state = useState(config)

  return state
}

export default useTimeMetricConfig
