import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    totalsRow: {
      backgroundColor: token.blue50,
      width: '100%',
      display: 'flex',
    },
    totalsCell: {
      '&&': {
        fontWeight: 500,
        textAlign: 'left',
        width: 90,
      },
    },
    balanced: {
      backgroundColor: token.green100,
    },
    imbalanced: {
      backgroundColor: token['red-2'],
    },
    totalsLabel: {
      textAlign: 'right',
    },
  }
})
