import { useConfigurationMode } from '@context/ConfigurationContext'
import AccountsNormalView from './AccountsNormalView'
import AccountsConfigView from './AccountsConfigView'

const AccountView: React.FC = () => {
  const { isConfigurationMode } = useConfigurationMode()

  if (isConfigurationMode) {
    return <AccountsConfigView />
  }

  return <AccountsNormalView />
}

export default AccountView
