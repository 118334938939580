import { FC } from 'react'
import { PropertiesSidebar } from '@components'
import AccountPropertiesContent, {
  AccountPropertiesContentProps,
} from '../AccountPropertiesContent'

interface AccountPropertiesProps extends AccountPropertiesContentProps {}

const AccountProperties: FC<AccountPropertiesProps> = ({ ...props }) => {
  const { account } = props
  return (
    <PropertiesSidebar name={account?.name}>
      <AccountPropertiesContent {...props} />
    </PropertiesSidebar>
  )
}

export default AccountProperties
