import useStyles from './SidebarFilterRowLayout.styles'

interface SidebarFilterRowLayoutProps {
  children: React.ReactNode
  isDisabled?: boolean
  className?: string
}

const SidebarFilterRowLayout: React.FC<SidebarFilterRowLayoutProps> = ({
  children,
  isDisabled,
  className,
}) => {
  const { styles, cx } = useStyles({ isDisabled })

  return <div className={cx(styles.container, className)}>{children}</div>
}

export default SidebarFilterRowLayout
