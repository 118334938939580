import { Sidebar } from '@components'
import { useState } from 'react'
import LedgerSidebarCompaniesTab from './LedgerSidebarCompaniesTab'
import { Tab } from '@context/ConfigPageContext'

const sidebarTabs = {
  ledgers: 'Ledgers',
  views: 'Views',
} as { [key: string]: Tab }

const sidebarTabsList = Object.values(sidebarTabs)

const LedgerSidebar = () => {
  const [selectedTab, setSelectedTab] = useState(sidebarTabs.ledgers)

  return (
    <Sidebar
      title="Ledger"
      tabs={sidebarTabsList}
      selectedTab={selectedTab}
      onTabChange={setSelectedTab}
    >
      {selectedTab === sidebarTabs.ledgers && <LedgerSidebarCompaniesTab />}
      {selectedTab === sidebarTabs.views && <>Views placeholder</>}
    </Sidebar>
  )
}

export default LedgerSidebar
