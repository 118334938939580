import { createStyles } from 'antd-style'

export default createStyles(({ token }, { row }: { row?: boolean }) => {
  return {
    container: {
      padding: `${token.paddingContentVerticalSM}px ${token.paddingContentHorizontalSM}px`,
      borderRadius: token.borderRadius,
      backgroundColor: token.colorWhite,
      display: 'flex',
      flexDirection: row ? 'row' : 'column',
      gap: token.padding,

      '> *': row
        ? {
            flex: 1,
            '&:not(:last-child)': {
              borderRight: `1px solid ${token.colorBorder}`,
              marginRight: token.paddingContentHorizontalSM,
              paddingRight: token.paddingContentHorizontalSM,
            },
          }
        : {},

      // '.ant-form-item': row
      //   ? {
      //       '&:not(:last-child)': {
      //         borderRight: `1px solid ${token.colorBorder}`,
      //       },
      //       padding: `0 ${token.paddingContentHorizontalSM}px`,
      //     }
      //   : {},
    },
  }
})
