import EditableCell, { type EditableCellProps } from './EditableCell'
import { useCallback } from 'react'

type Datatags = (number | null)[]
interface TransactionDatatagsEditableCellProps
  extends Omit<EditableCellProps, 'onSave'> {
  datatags: Datatags
  onSave: ({ datatags, value }: { datatags: Datatags; value: string }) => void
}

export const TransactionDatatagsEditableCell: React.FC<
  TransactionDatatagsEditableCellProps
> = ({ onSave, datatags, ...props }) => {
  const handleSave = useCallback(
    (value: string) => {
      onSave({ datatags, value })
    },
    [datatags, onSave],
  )

  return <EditableCell {...props} onSave={handleSave} />
}
