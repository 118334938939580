import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    tableWidget: {
      backgroundColor: token.colorWhite,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',

      '> *': {
        flexShrink: 0,
      },
    },
    tableWrapper: {
      overflow: 'auto',
      display: 'flex',
      flex: 1,
      position: 'relative',
    },
    table: {
      '& tr:first-child': {
        th: {
          borderTop: 'none',
        },
      },
      '& :is(td, th):first-child': {
        borderLeft: 'none',
      },
      '& :is(td, th):last-child': {
        borderRight: 'none',
      },
    },
    updating: {
      position: 'absolute',
      zIndex: 10,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    noData: {
      alignSelf: 'center',
      width: '100%',
    },
    skeleton: {
      padding: `0 ${token.padding}px`,
    },
    toolbarWrapper: {
      borderBottom: `1px solid ${token.colorBorder}`,
    },
  }
})

export default useStyles
