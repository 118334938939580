import { FC, useEffect, useMemo } from 'react'
import { CollapseProps, Form, Input, message } from 'antd'
import { trpc } from '@services/trpc'
import { skipToken } from '@tanstack/react-query'
import { type DataModel } from '@modules/datamodel'
import PropertiesDrawer from '@components/DEPRECATED_PropertiesDrawer'

type DataModelPropertiesProps = {
  selectedId: number
  onClose: () => void
  isOpen: boolean
}

const DataModelPropertiesDrawer: FC<DataModelPropertiesProps> = ({
  selectedId,
  onClose,
  isOpen,
}) => {
  const [form] = Form.useForm()
  const { refetch } = trpc.api.datamodel.list.useQuery()
  const { data: model, isFetching } = trpc.api.datamodel.getById.useQuery(
    selectedId ? { id: selectedId } : skipToken,
  )

  const handleSuccess = () => {
    message.success('Model updated successfully')
    refetch()
    form.resetFields()
    onClose()
  }

  const { mutate: save } = trpc.api.datamodel.createOrUpdateById.useMutation({
    onSuccess: handleSuccess,
    onError: (error) => {
      message.error(error.message)
    },
  })

  const items: CollapseProps['items'] = useMemo(
    () => [
      {
        key: 'model',
        label: model?.name || 'DATA MODEL',
        children: (
          <>
            <Form.Item
              name="name"
              label="Name | ID"
              rules={[
                {
                  required: true,
                  message: 'Please input the model name or ID!',
                },
              ]}
            >
              <Input placeholder="Enter name or ID" />
            </Form.Item>
          </>
        ),
      },
    ],
    [model],
  )

  const handleSubmit = (values: DataModel) => {
    save({
      ...values,
      id: selectedId,
    })
  }

  useEffect(() => {
    form.setFieldsValue(model)
  }, [form, model])

  return (
    <PropertiesDrawer<DataModel>
      data={model!}
      form={form}
      formItems={items}
      onSubmit={handleSubmit}
      onClose={onClose}
      isOpen={isOpen}
      isLoading={isFetching}
    />
  )
}

export default DataModelPropertiesDrawer
