import { createStyles } from 'antd-style'

export default createStyles(({ token }, props) => {
  const { isDisabled } = props as { isDisabled: boolean }
  return {
    container: {
      backgroundColor: isDisabled
        ? token.colorBgContainerDisabled
        : token.colorWhite,
      borderRadius: token.borderRadiusSM,
      border: `1px solid ${token.colorBorder}`,
      display: 'flex',
      alignItems: 'center',
      gap: token.marginXS,
      padding: `0 ${token.paddingXXS}px 0 ${token.paddingXS}px`,
      height: 36,

      '>:is(:only-child, :not(:last-child))': {
        flex: 1,
      },
      '>:not(:last-child)': {
        borderRight: `1px solid ${token.colorBorder}`,
      },
    },
  }
})
