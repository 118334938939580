import {
  AssumptionProperties,
  AssumptionsView,
  CreateTransactionModal,
  ViewContentLayout,
  ViewToolbar,
  ViewWithPropertiesLayout,
} from '@components'
import { useDataModelPageContext } from '@modules/datamodel'
import { useColumnsLayout, useFilterParams, useModalState } from '@hooks'
import { trpc } from '@services/trpc'
import { skipToken } from '@tanstack/react-query'
import { useTablePeriodContext } from '@context/TablePeriodContext'
import { AssumptionTableItem } from '@server/interfaces/assumptions'

const rows = [
  {
    id: 'assumption_name',
    label: 'Assumption Name',
  },
  {
    id: 'account_name',
    label: 'Account Name',
  },
  {
    id: 'accountdriver_name',
    label: 'Account Driver',
  },
]

const LookupNormalView: React.FC = () => {
  const { selectedDataModel } = useDataModelPageContext()
  const { filters } = useFilterParams()
  const { periods } = useTablePeriodContext()
  const transactionModalState = useModalState()

  const tableQuery = selectedDataModel
    ? {
        preset: 'newassumptions',
        options: {
          datamodelId: selectedDataModel.id,
          periods,
          filters,
        },
      }
    : skipToken

  const {
    data: initialQueryData = [] as AssumptionTableItem[],
    isLoading,
    refetch,
  } = trpc.api.transactions.tableQuery.useQuery(tableQuery)

  const layout = useColumnsLayout({
    datamodel: selectedDataModel,
    tableQuery,
    rows,
  })

  return (
    <>
      <ViewWithPropertiesLayout>
        <ViewContentLayout>
          <ViewToolbar onAdd={transactionModalState.open} />
          <AssumptionsView
            data={initialQueryData}
            layout={layout}
            isLoading={isLoading}
            refetch={refetch}
            datamodel={selectedDataModel}
          />
        </ViewContentLayout>

        <AssumptionProperties onRefetch={refetch} />
      </ViewWithPropertiesLayout>

      <CreateTransactionModal
        isOpen={transactionModalState.isOpen}
        onClose={transactionModalState.close}
        onSuccess={refetch}
        title={`Create ${selectedDataModel?.name} Lookup Transaction`}
      />
    </>
  )
}

export default LookupNormalView
