import { useState } from 'react'
import {
  type ColumnDef,
  createColumnHelper,
  RowSelectionState,
  OnChangeFn,
} from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import { Button } from 'antd'
import { FaListUl } from 'react-icons/fa'
import { useDatatagColumnsContext } from '@modules/datamodel/DatatagColumnsContext'
import ColumnsSubtable from './ColumnsSubtable'
import { COLUMN_GROUPS } from '@modules/compoundModel/compoundModel.constants'
import type { BaseItem } from '@components/SelectedListWidget/index.types'
import { Datagroup, Datatag } from '@modules/datagroup'
import { DatamodelGroup, useDataModelPageContext } from '@modules/datamodel'
import { CornerDownRight } from '@untitled-ui/icons-react'

const getSelectedRows = <T extends BaseItem>(selectedDatatagColumns: T[]) => {
  return selectedDatatagColumns.reduce(
    (acc, d) => {
      acc[d.id] = true
      return acc
    },
    {} as Record<string, boolean>,
  )
}

// TODO: TBD full list of filter options
type FilterOptionConfig = {
  type: string
  label: string
  options: { value: string; label: string }[]
}

const createFilterOptions = ({ type, label, options }: FilterOptionConfig) => [
  {
    type: type,
    label: label,
    options: options,
  },
]

const dataTagClassViewOptions = [{ value: 'data_group', label: 'Data Group' }]

const filterOptions = createFilterOptions({
  type: 'tag',
  label: 'Tag type',
  options: dataTagClassViewOptions,
})

type DatagroupWithMembers = Datagroup & {
  members: string
}

interface DatatagsTableWidgetProps
  extends Partial<TableWidgetProps<DatagroupWithMembers>> {
  allowedDatagroups?: string[]
}

const columnHelper = createColumnHelper<DatagroupWithMembers>()

const DatatagsTableWidget = <T extends BaseItem>({
  allowedDatagroups,
  ...rest
}: DatatagsTableWidgetProps) => {
  type DatagroupItem = T & Datagroup
  const {
    setSelectedDatatagColumns,
    selectedDatatagColumns,
    setIsDatatagColumnsDirty,
  } = useDatatagColumnsContext<DatagroupItem>()
  const { datamodelGroup } = useDataModelPageContext()

  const [subtableDatagroup, setSubtableDatagroup] = useState<Datagroup | null>(
    null,
  )

  const { data: datagroups = [] } =
    trpc.api.datatags.listDatagroupsByType.useQuery(
      datamodelGroup === DatamodelGroup.LOOKUP
        ? ['datagroup', 'category', 'picklist']
        : 'datagroup',
    )

  const datatagQueries = trpc.useQueries((t) =>
    datagroups.map((d) =>
      t.api.datatags.listDatagroupMembers({ id: Number(d.id) }),
    ),
  )
  const datatagsByDatagroupId = datatagQueries.reduce(
    (acc, query, i) => {
      acc[datagroups[i].id] = query.data ?? []
      return acc
    },
    {} as Record<string, Datatag[]>,
  )

  const selectedMembersCounts = selectedDatatagColumns.reduce(
    (acc, item) => {
      acc[item.id] = item.members?.length || 0
      return acc
    },
    {} as Record<string, number>,
  )

  const tableData = datagroups
    .filter(
      (datagroup) =>
        !allowedDatagroups || allowedDatagroups.includes(datagroup.name),
    )
    .map((datagroup) => ({
      ...datagroup,
      members: selectedMembersCounts[datagroup.id]
        ? `(${selectedMembersCounts[datagroup.id]}/${
            datatagsByDatagroupId[datagroup.id].length || ''
          })`
        : '-',
    }))

  // https://github.com/TanStack/table/issues/4382
  // eslint-disable-next-line
  const columns: ColumnDef<DatagroupWithMembers, any>[] = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name | ID',
      cell: ({ row, getValue }) => {
        const isSubCategory = row.original.type === 'category'
        return (
          <span
            style={{
              paddingLeft: isSubCategory ? 8 : undefined,
              display: 'flex',
              alignItems: 'center',
              gap: 4,
            }}
          >
            {isSubCategory && <CornerDownRight width={16} />}
            {getValue()}
          </span>
        )
      },
    }),
    columnHelper.accessor((row) => row.members, {
      id: 'members',
      header: 'Members',
    }),
    columnHelper.display({
      id: 'actions',
      cell: (info) => {
        const isDisabled = !info.row.getCanSelect()
        return (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              onClick={() => {
                setSubtableDatagroup(info.row.original)
              }}
              icon={<FaListUl />}
              style={{ width: 24, height: 24 }}
              disabled={isDisabled}
            />
          </div>
        )
      },
      meta: {
        width: 36,
      },
    }),
  ]

  const handleSelect: OnChangeFn<RowSelectionState> = (updaterOrValue) => {
    setIsDatatagColumnsDirty(true)
    setSelectedDatatagColumns((prevDatatags) => {
      const newSelection =
        typeof updaterOrValue === 'function'
          ? updaterOrValue(getSelectedRows(prevDatatags))
          : updaterOrValue

      const newSelectedRows = Object.keys(newSelection).filter(
        (key) => newSelection[key] === true,
      )
      const addedRows = newSelectedRows.filter(
        (rowId) =>
          prevDatatags.length === 0 ||
          !prevDatatags.some((d) => String(d.id) === rowId),
      )
      const addedDatagroups = datagroups.filter((datagroup) =>
        addedRows.includes(String(datagroup.id)),
      )
      if (addedDatagroups.some((d) => d.id !== subtableDatagroup?.id)) {
        setSubtableDatagroup(null)
      }
      const addedDatagroupsItems = addedDatagroups.map((datagroup) => {
        return {
          ...datagroup,
          id: datagroup.id,
          name: datagroup.name,
          members: datatagsByDatagroupId[datagroup.id].map((d) => d.id),
          itemGroup: COLUMN_GROUPS.columns,
        } as DatagroupItem
      })

      const onlySubcategories = addedDatagroups.filter(
        (d) => d.type === 'category',
      )
      const onlyDatagroups = addedDatagroups.filter(
        (d) => d.type !== 'category',
      )
      const filteredPrevDatatags = prevDatatags.filter((d) => {
        const isSelected = newSelectedRows.includes(String(d.id))
        const hasRelatedDatagroup = onlyDatagroups.some(
          (dg) => dg.id === d.parent_id,
        )
        const hasRelatedSubcategory = onlySubcategories.some(
          (sc) => sc.parent_id === d.id,
        )
        return isSelected && !hasRelatedDatagroup && !hasRelatedSubcategory
      })
      return [...filteredPrevDatatags, ...addedDatagroupsItems]
    })
  }

  const selectedRows = getSelectedRows(selectedDatatagColumns)

  return (
    <>
      <TableWidget<DatagroupWithMembers>
        {...rest}
        title="Data Tags"
        data={tableData}
        columns={columns}
        filterOptions={filterOptions}
        onSelect={handleSelect}
        selectedRows={selectedRows}
        getRowId={(row) => row.id}
        enableRowSelection={(row) => {
          const hasRelatedDatagroup = selectedDatatagColumns.some(
            (d) => d.id === row.original.parent_id,
          )
          const hasRelatedSubcategory = selectedDatatagColumns.some(
            (d) => d.parent_id === row.original.id,
          )
          return !hasRelatedDatagroup && !hasRelatedSubcategory
        }}
        withCheckbox
        disableCheckAll
        withIndex
      />
      {subtableDatagroup && <ColumnsSubtable datagroup={subtableDatagroup} />}
    </>
  )
}

export default DatatagsTableWidget
