import {
  CreateMenuButton,
  SidebarFilterRowLayout,
  SidebarFilterSelect,
  SidebarFiltersByDatatag,
  SidebarToolbarLayout,
} from '@components'
import useFilterParams from '@hooks/useFilterParams'
import { trpc } from '@services/trpc'
import { MenuProps } from 'antd'
import { useMemo } from 'react'

const MENU_ITEM_KEYS = {
  VIEW: 'view',
} as const

interface AccountSidebarToolbarNormalProps {
  currentDataTagClass: string
  onDataTagClassChange: (dataTagClass: string) => void
  onAccountdriverChange: (id: number) => void
}

const AccountSidebarToolbarNormal: React.FC<
  AccountSidebarToolbarNormalProps
> = ({ currentDataTagClass, onDataTagClassChange, onAccountdriverChange }) => {
  const { data: accountdrivers = [] } = trpc.api.accountdrivers.list.useQuery()
  const accountDriverOptions = [
    { label: 'All', value: '' },
    ...accountdrivers.map((accountdriver) => ({
      label: accountdriver.name,
      value: String(accountdriver.id),
    })),
  ]

  const { params } = useFilterParams()

  const menuItems = useMemo<MenuProps['items']>(() => {
    return [
      {
        key: MENU_ITEM_KEYS.VIEW,
        label: 'Create View',
        disabled: true,
      },
    ]
  }, [])

  const handleCreateItemClick = () => {}

  return (
    <SidebarToolbarLayout>
      <SidebarFilterRowLayout>
        <SidebarFilterSelect
          label="Account driver"
          options={accountDriverOptions}
          onChange={onAccountdriverChange}
          value={params.accountdriver || ''}
        />
      </SidebarFilterRowLayout>
      <SidebarFilterRowLayout>
        <SidebarFiltersByDatatag
          currentDataTagClass={currentDataTagClass}
          onDataTagClassChange={onDataTagClassChange}
          disabled={!params.accountdriver}
        />
        <CreateMenuButton items={menuItems} onClick={handleCreateItemClick} />
      </SidebarFilterRowLayout>
    </SidebarToolbarLayout>
  )
}

export default AccountSidebarToolbarNormal
