import { AccountCategory as SelectedCategory } from '@modules/account'
import { useConfigPageContext } from '@context/ConfigPageContext'
import { SidebarList, SidebarCollapsibleItem } from '@components'
import {
  ACCOUNT_CATEGORIES,
  ACCOUNT_CATEGORIES_LABELS,
} from '@constants/accountCategories'
import { ACCOUNT_TYPES, ACCOUNT_TYPES_LABELS } from '@constants/accountTypes'
import { AccountCategory } from '@constants/accountCategories'
import { LayersThree01, LayersTwo01 } from '@untitled-ui/icons-react'

const categories = Object.values([
  ACCOUNT_CATEGORIES.profitsAndLoss,
  ACCOUNT_CATEGORIES.balanceSheet,
  ACCOUNT_CATEGORIES.cashFlow,
  ACCOUNT_CATEGORIES.statistical,
]).map((value) => ({
  id: value,
  name: ACCOUNT_CATEGORIES_LABELS[value],
}))

export type CategoryItem = {
  id: string | number
  name: string
  icon?: React.ReactNode
}

interface AccountsSidebarCategoriesListProps {
  allowedCategories?: AccountCategory[]
}

const AccountsSidebarCategoriesList = <T extends object>({
  allowedCategories,
}: AccountsSidebarCategoriesListProps) => {
  const { selectedCategory, setSelectedCategory } = useConfigPageContext<
    T,
    SelectedCategory
  >()

  return categories
    .filter(
      (category) =>
        !allowedCategories || allowedCategories.includes(category.id),
    )
    .map((category) => {
      const items = Object.values(ACCOUNT_TYPES[category.id]).map((value) => ({
        id: value,
        name: ACCOUNT_TYPES_LABELS[value],
        icon: <LayersTwo01 width={16} />,
      }))
      return (
        <SidebarCollapsibleItem
          key={category.id}
          label={
            <>
              <LayersThree01 width={16} style={{ marginRight: 4 }} />
              {category.name}
            </>
          }
          onLabelClick={() => {
            setSelectedCategory({ ...category, propertyType: 'category' })
          }}
          defaultOpen={false}
        >
          <SidebarList<CategoryItem>
            items={items}
            onItemClick={(item) => {
              setSelectedCategory({ ...item, propertyType: 'type' })
            }}
            selectedItemId={selectedCategory?.id || undefined}
          />
        </SidebarCollapsibleItem>
      )
    })
}

export default AccountsSidebarCategoriesList
