import {
  StaticTreeDataProvider,
  TreeEnvironmentRef,
  TreeRef,
  UncontrolledTreeEnvironment,
  Tree,
  TreeItemIndex,
} from 'react-complex-tree'
import { FC, Ref, useRef } from 'react'
import { Button, Space } from 'antd'
import useStyles from './ViewTree.styles'
import 'react-complex-tree/lib/style-modern.css'
import { TreeData, TreeItemData } from '@modules/view/view.types'
import { File01, Folder, X } from '@untitled-ui/icons-react'

const treeId = 'treeId'
const rootIndex = 'root'

type ViewTreeProps = {
  data: TreeData
  onDrop?: (index: TreeItemIndex) => void
  onRemove?: (index: TreeItemIndex) => void
  isDragging?: boolean
  treeEnvironment?: Ref<TreeEnvironmentRef<TreeItemData>>
}

const ViewTree: FC<ViewTreeProps> = ({
  data,
  onDrop,
  onRemove,
  isDragging,
  treeEnvironment,
}) => {
  console.log('data', data)
  const isRootOnly = Object.keys(data).length === 1
  const { styles, cx } = useStyles()
  const tree = useRef<TreeRef>(null)

  const initialExpandedItems = Object.values(data)
    .filter((item) => item.isFolder && item.data.name !== 'root')
    .map((item) => item.index)

  return (
    <div
      className={cx(styles.treeContainer, {
        [styles.dragOverTree]: isDragging && isRootOnly,
      })}
      onDrop={() => {
        if (isDragging) {
          onDrop?.(rootIndex)
        }
      }}
      onDragOver={(e) => {
        e.preventDefault()
      }}
    >
      <UncontrolledTreeEnvironment<TreeItemData>
        ref={treeEnvironment}
        canDragAndDrop
        canDropOnFolder
        canReorderItems
        dataProvider={
          new StaticTreeDataProvider(data, (item, newName) => ({
            ...item,
            data: { ...item.data, name: newName },
          }))
        }
        getItemTitle={(item) => item.data.name}
        viewState={{
          [treeId]: {
            expandedItems: initialExpandedItems,
          },
        }}
        // onDrop={(items, target) => {
        //   console.log('items', items)
        //   console.log('target', target)
        // }}
      >
        <Tree<TreeItemData>
          treeId={treeId}
          rootItem={rootIndex}
          // treeLabel={viewName}
          ref={tree}
          renderItemTitle={({ item }) => {
            return (
              <div
                className={styles.item}
                onDrop={(e) => {
                  if (isDragging) {
                    onDrop?.(item.index)
                    e.stopPropagation()
                  }
                }}
                onDragOver={(e) => {
                  e.preventDefault()
                }}
              >
                <Space>
                  {item.data.type === 'folder' && (
                    <Folder width={16} height={16} />
                  )}
                  {item.data.type === 'sum' && (
                    <span className={styles.sum}>Σ</span>
                  )}
                  {(!item.data.type || item.data.type === 'item') && (
                    <File01 width={16} height={16} />
                  )}
                  {item.data.name}
                </Space>
                {onRemove && (
                  <Button
                    onClick={() => onRemove(item.index)}
                    className={styles.deleteButton}
                    type="text"
                    icon={<X width={16} height={16} />}
                  />
                )}
              </div>
            )
          }}
        />
      </UncontrolledTreeEnvironment>
    </div>
  )
}

export default ViewTree
