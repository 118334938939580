import {
  CreateMenuButton,
  SidebarFilterRowLayout,
  SidebarFilterSelect,
  SidebarToolbarLayout,
} from '@components'
import { MenuProps } from 'antd'
import { useMemo } from 'react'
import useModalState from '@hooks/useModalState'
import CreateDatagroupMemberModal from '../../CreateDatagroupMemberModal'
import CreatePicklistModal from '../../CreatePicklistModal'
import CreateDatagroupCategoryModal from '../../CreateDatagroupCategoryModal'
import { useConfigPageContext } from '@context/ConfigPageContext'
import {
  Datagroup,
  Datatag,
  ViewMetricsCategoryItem,
} from '@modules/datagroup/datagroup.types'
import { useDatagroupPageContext } from '@modules/datagroup/components/DatagroupPageContext'
import { trpc } from '@services/trpc'
import { skipToken } from '@tanstack/react-query'
import { VIEW_METRICS_CATEGORY_ITEM } from '@modules/datagroup/datagroup.constants'
import { ViewMetric } from '@server/interfaces/view'
import CreateViewMetricModal from '../../CreateViewMetricModal'
import { CreateViewModal } from '@modules/view'

const createOptions = {
  picklist: 'picklist',
  subCategory: 'subCategory',
  member: 'member',
  viewMetric: 'viewMetric',
  view: 'view',
} as const

const DatagroupSidebarToolbar = () => {
  const datagroupModalState = useModalState()
  const datagroupMemberModalState = useModalState()
  const picklistModalState = useModalState()
  const viewMetricModalState = useModalState()
  const viewModalState = useModalState()

  const { datagroup, isPicklist } = useDatagroupPageContext()
  const { selectedCategory: selectedDatagroup, setSelectedCategory } =
    useConfigPageContext<
      Datatag | ViewMetric,
      Datagroup | ViewMetricsCategoryItem
    >()

  const { data: subcategories = [] } =
    trpc.api.datatags.listDatagroupCategories.useQuery(
      datagroup ? { id: datagroup.id } : skipToken,
    )
  const { data: picklists = [] } =
    trpc.api.datatags.listDatagroupsByType.useQuery('picklist')

  const selectOptions = isPicklist
    ? picklists
    : [datagroup!, ...subcategories, VIEW_METRICS_CATEGORY_ITEM]

  const handleCreateItemClick = (key: string) => {
    if (key === createOptions.subCategory) {
      datagroupModalState.open()
    }
    if (key === createOptions.member) {
      datagroupMemberModalState.open()
    }
    if (key === createOptions.picklist) {
      picklistModalState.open()
    }
    if (key === createOptions.viewMetric) {
      viewMetricModalState.open()
    }
    if (key === createOptions.view) {
      viewModalState.open()
    }
  }

  const changeCategory = (id: string) => {
    setSelectedCategory(
      selectOptions.find((option) => String(option?.id) === id) || null,
    )
  }

  const menuItems = useMemo<MenuProps['items']>(() => {
    const options = []
    options.push({
      key: createOptions.member,
      label: `Create ${selectedDatagroup ? selectedDatagroup.name : 'member'}`,
      disabled: !selectedDatagroup,
    })
    if (isPicklist) {
      options.unshift({
        key: createOptions.picklist,
        label: 'Create pick list',
      })
    } else {
      options.unshift({
        key: createOptions.subCategory,
        label: 'Create sub category',
      })
    }
    options.push({
      key: createOptions.view,
      label: 'Create view',
    })
    return options
  }, [isPicklist, selectedDatagroup])

  return (
    <>
      <SidebarToolbarLayout>
        <SidebarFilterRowLayout>
          <SidebarFilterSelect
            label="Data group category"
            value={selectedDatagroup?.id || ''}
            onChange={changeCategory}
            options={selectOptions}
            fieldNames={{ label: 'name', value: 'id' }}
            placeholder="Select category"
          />
          <CreateMenuButton items={menuItems} onClick={handleCreateItemClick} />
        </SidebarFilterRowLayout>
      </SidebarToolbarLayout>

      <CreateDatagroupMemberModal
        isOpen={datagroupMemberModalState.isOpen}
        onClose={datagroupMemberModalState.close}
        datagroup={(selectedDatagroup as Datagroup) ?? datagroup}
      />

      <CreateViewMetricModal
        open={viewMetricModalState.isOpen}
        onClose={viewMetricModalState.close}
      />

      {isPicklist ? (
        <CreatePicklistModal
          isOpen={picklistModalState.isOpen}
          onClose={picklistModalState.close}
        />
      ) : (
        <>
          <CreateDatagroupCategoryModal
            isOpen={datagroupModalState.isOpen}
            onClose={datagroupModalState.close}
            parentId={datagroup!.id}
          />

          {/* temp */}
          <CreateViewModal
            datagroupId={datagroup!.id}
            open={viewModalState.isOpen}
            onClose={viewModalState.close}
          />
        </>
      )}
    </>
  )
}

export default DatagroupSidebarToolbar
