import { ACCOUNT_CLASSES } from './accountClasses'
import { ACCOUNT_CATEGORIES } from './accountCategories'
import {
  PROFITS_AND_LOSS_ACCOUNT_TYPES,
  BALANCE_SHEET_ACCOUNT_TYPES,
} from './accountTypes'

export const ACCOUNT_INTERPRETATIONS = {
  cashAndCashEquivalents: 'Cash and Cash Equivalents',
  accountsReceivable: 'Accounts Receivable',
  prepaidExpenses: 'Prepaid Expenses',
  otherCurrentAssets: 'Other Current Assets',
  inventory: 'Inventory',
  fixedAssets: 'Fixed Assets',
  workInProcess: 'Work in Process',
  intangibleAsset: 'Intangible Asset',
  longTermAssets: 'Long-Term Assets',
  otherAssets: 'Other Assets',
  accumulatedDepreciation: 'Accumulated Depreciation',
  accumulatedAmortization: 'Accumulated Amortization',
  leaseAssets: 'Lease Assets',
  accountsPayable: 'Accounts Payable',
  accruedExpenses: 'Accrued Expenses',
  unearnedRevenue: 'Unearned Revenue',
  debtLiability: 'Debt Liability',
  otherLiability: 'Other Liability',
  leaseLiabilities: 'Lease Liabilities',
  stocks: 'Stocks',
  dividendsPaid: 'Dividends Paid',
  retainedEarnings: 'Retained Earnings',
  currencyTranslation: 'Currency Translation',
  currentNetIncome: 'Current Net Income',
  otherEquity: 'Other Equity',
  revenueAndGains: 'Revenue and Gains',
  otherIncome: 'Other Income',
  operatingExpenses: 'Operating Expenses',
  costOfSaleGoods: 'Cost of Sale (Goods)',
  payrollAndRelatedExpenses: 'Payroll and Related Expenses',
  generalAndAdministrativeExpenses: 'General and Administrative Expenses',
  depreciation: 'Depreciation',
  amortization: 'Amortization',
  interestExpense: 'Interest Expense',
  otherExpenses: 'Other Expenses',
  unrealizedCurrencyGainLoss: 'Unrealized Currency (Gain)/Loss',
  realizedCurrencyGainLoss: 'Realized Currency (Gain)/Loss',
  nonFinancial: 'Non-Financial',
} as const

export const CASH_FLOW_TYPES = {
  none: 'None',
  operating: 'Operating',
  investing: 'Investing',
  financing: 'Financing',
  operatingInvesting: 'Operating/Investing',
  operatingFinancing: 'Operating/Financing',
  financingOperating: 'Financing/Operating',
} as const

const ACCOUNT_INTERPRETATION_DETAILS = [
  {
    value: 'cashAndCashEquivalents',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.none,
  },
  {
    value: 'accountsReceivable',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'prepaidExpenses',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'otherCurrentAssets',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.operatingInvesting,
  },
  {
    value: 'inventory',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'fixedAssets',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.investing,
  },
  {
    value: 'workInProcess',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.operatingInvesting,
  },
  {
    value: 'intangibleAsset',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.investing,
  },
  {
    value: 'longTermAssets',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.investing,
  },
  {
    value: 'otherAssets',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.operatingInvesting,
  },
  {
    value: 'accumulatedDepreciation',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.none,
  },
  {
    value: 'accumulatedAmortization',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.none,
  },
  {
    value: 'leaseAssets',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.asset,
    cash_flow: CASH_FLOW_TYPES.financing,
  },
  {
    value: 'accountsPayable',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.liability,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'accruedExpenses',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.liability,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'unearnedRevenue',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.liability,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'debtLiability',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.liability,
    cash_flow: CASH_FLOW_TYPES.financing,
  },
  {
    value: 'otherLiability',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.liability,
    cash_flow: CASH_FLOW_TYPES.operatingFinancing,
  },
  {
    value: 'leaseLiabilities',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.liability,
    cash_flow: CASH_FLOW_TYPES.financing,
  },
  {
    value: 'stocks',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.equity,
    cash_flow: CASH_FLOW_TYPES.financing,
  },
  {
    value: 'dividendsPaid',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.equity,
    cash_flow: CASH_FLOW_TYPES.financing,
  },
  {
    value: 'retainedEarnings',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.equity,
    cash_flow: CASH_FLOW_TYPES.none,
  },
  {
    value: 'currencyTranslation',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.equity,
    cash_flow: CASH_FLOW_TYPES.none,
  },
  {
    value: 'currentNetIncome',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.equity,
    cash_flow: CASH_FLOW_TYPES.none,
  },
  {
    value: 'otherEquity',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.balanceSheet,
    type: BALANCE_SHEET_ACCOUNT_TYPES.equity,
    cash_flow: CASH_FLOW_TYPES.financing,
  },
  {
    value: 'revenueAndGains',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.revenue,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'otherIncome',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.revenue,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'operatingExpenses',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.expense,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'costOfSaleGoods',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.cost,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'payrollAndRelatedExpenses',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.expense,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'generalAndAdministrativeExpenses',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.expense,
    cash_flow: CASH_FLOW_TYPES.operating,
  },
  {
    value: 'depreciation',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.expense,
    cash_flow: CASH_FLOW_TYPES.none,
  },
  {
    value: 'amortization',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.expense,
    cash_flow: CASH_FLOW_TYPES.operatingInvesting,
  },
  {
    value: 'interestExpense',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.expense,
    cash_flow: CASH_FLOW_TYPES.financingOperating,
  },
  {
    value: 'otherExpenses',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.expense,
    cash_flow: CASH_FLOW_TYPES.operatingInvesting,
  },
  {
    value: 'unrealizedCurrencyGainLoss',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.expense,
    cash_flow: CASH_FLOW_TYPES.none,
  },
  {
    value: 'realizedCurrencyGainLoss',
    class: ACCOUNT_CLASSES.financial,
    category: ACCOUNT_CATEGORIES.profitsAndLoss,
    type: PROFITS_AND_LOSS_ACCOUNT_TYPES.expense,
    cash_flow: CASH_FLOW_TYPES.operatingInvesting,
  },
  {
    value: 'nonFinancial',
    class: ACCOUNT_CLASSES.operational,
    category: ACCOUNT_CATEGORIES.statistical,
    cash_flow: CASH_FLOW_TYPES.none,
  },
]

export function getAccountInterpretations(
  accountClass: string,
  accountCategory: string,
  accountType: string,
) {
  if (
    accountClass === ACCOUNT_CLASSES.operational &&
    accountCategory === ACCOUNT_CATEGORIES.statistical
  ) {
    return [
      {
        value: 'nonFinancial',
        label: ACCOUNT_INTERPRETATIONS.nonFinancial,
      },
    ]
  }

  const filteredDetails = ACCOUNT_INTERPRETATION_DETAILS.filter(
    (detail) =>
      detail.class === accountClass &&
      detail.category === accountCategory &&
      detail.type === accountType,
  )

  return filteredDetails.map((detail) => ({
    label:
      ACCOUNT_INTERPRETATIONS[
        detail.value as keyof typeof ACCOUNT_INTERPRETATIONS
      ],
    value: detail.value,
  }))
}

export type CashFlowValue =
  (typeof CASH_FLOW_TYPES)[keyof typeof CASH_FLOW_TYPES]

export function getCashFlowByValue(value: CashFlowValue) {
  if (!value) return []
  const detail = ACCOUNT_INTERPRETATION_DETAILS.find(
    (detail) => detail.value === value,
  )

  return detail
    ? [
        {
          value: detail.cash_flow,
          label: CASH_FLOW_TYPES[detail.value as keyof typeof CASH_FLOW_TYPES],
        },
      ]
    : []
}
