import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    container: {
      backgroundColor: token.colorWhite,
      borderRadius: token.borderRadiusSM,
      // padding: token.paddingXXS,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: token.paddingXS,
      overflow: 'hidden',
    },
  }
})
const SidebarContentLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { styles } = useStyles()

  return <div className={styles.container}>{children}</div>
}

export default SidebarContentLayout
