import { FC } from 'react'
import LabeledValueSelect from '../LabeledValueSelect'
import type { LabeledValueSelectProps } from '../LabeledValueSelect'
import useStyles from './TableFilterSelect.styles'

const TableFilterSelect: FC<LabeledValueSelectProps> = ({ ...props }) => {
  const { styles } = useStyles()

  return (
    <LabeledValueSelect
      {...props}
      classNames={{
        select: styles.select,
        value: styles.value,
      }}
    />
  )
}

export default TableFilterSelect
