import { SVGProps } from 'react'

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 12H20m0 0v12.5M20 12l4.5-4.5m.5 11.837V7.8c0-.28 0-.42-.055-.527a.5.5 0 0 0-.218-.218C24.62 7 24.48 7 24.2 7H12.663c-.245 0-.367 0-.482.028a1 1 0 0 0-.29.12c-.1.061-.187.148-.36.32L7.47 11.532c-.173.173-.26.26-.322.36a1 1 0 0 0-.12.29C7 12.296 7 12.418 7 12.663V24.2c0 .28 0 .42.054.527a.5.5 0 0 0 .219.218C7.38 25 7.52 25 7.8 25h11.537c.245 0 .367 0 .482-.028a.998.998 0 0 0 .29-.12c.1-.061.187-.148.36-.32l4.062-4.063c.173-.173.26-.26.322-.36a.998.998 0 0 0 .12-.29c.027-.115.027-.237.027-.482Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m12.563 15.125-1.126 6.75m4.126-6.75-1.126 6.75M16.688 17h-6.375m6 3H9.938"
      stroke="currentColor"
      strokeWidth=".75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
