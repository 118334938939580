import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      '&& > *': {
        overflow: 'unset',
      },
    },
    primary: {
      marginBottom: token.marginXXS,
    },
  }
})

export default useStyles
