import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const useGlobalFilterParams = () => {
  const [params] = useSearchParams()

  const companyParam = params.get('company')
  const versionParam = params.get('version')

  const [company, setCompany] = useState('')
  const [version, setVersion] = useState('')

  useEffect(() => {
    setCompany(companyParam || '')
  }, [companyParam])

  useEffect(() => {
    setVersion(versionParam || '')
  }, [versionParam])

  return { company, version }
}

export default useGlobalFilterParams
