import React from 'react'
import { createStyles } from 'antd-style'
import { ViewContainerLayout } from '@components'

const Placeholder = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={500} height={500} fill="none">
    <path
      fill="#EBEBEB"
      d="M500 382.4H0v.25h500v-.25Zm-50.1 16.09h-33.12v.25h33.12v-.25Zm-118.68 2.72h-8.69v.25h8.69v-.25Zm84.56-12h-19.19v.25h19.19v-.25Zm-320.13 1.68H52.46v.25h43.19v-.25Zm15.24 0h-6.33v.25h6.33v-.25Zm114.26 4.22h-93.68v.25h93.68v-.25ZM237 337.8H43.91a5.71 5.71 0 0 1-5.7-5.71V60.66a5.71 5.71 0 0 1 5.7-5.66H237a5.712 5.712 0 0 1 5.71 5.71v271.38a5.715 5.715 0 0 1-1.672 4.038A5.715 5.715 0 0 1 237 337.8ZM43.91 55.2a5.46 5.46 0 0 0-5.45 5.46v271.43a5.459 5.459 0 0 0 5.45 5.46H237a5.475 5.475 0 0 0 3.858-1.602 5.475 5.475 0 0 0 1.602-3.858V60.66A5.473 5.473 0 0 0 237 55.2H43.91Zm409.4 282.6h-193.1a5.724 5.724 0 0 1-4.035-1.675 5.724 5.724 0 0 1-1.675-4.035V60.66a5.72 5.72 0 0 1 5.71-5.66h193.1a5.71 5.71 0 0 1 5.69 5.66v271.43a5.71 5.71 0 0 1-5.69 5.71ZM260.21 55.2a5.47 5.47 0 0 0-5.46 5.46v271.43a5.473 5.473 0 0 0 5.46 5.46h193.1a5.473 5.473 0 0 0 5.46-5.46V60.66a5.473 5.473 0 0 0-5.46-5.46h-193.1Z"
    />
    <path
      fill="#E0E0E0"
      d="M359.32 190.25c28.951 0 52.42-23.469 52.42-52.42s-23.469-52.42-52.42-52.42c-28.95 0-52.42 23.469-52.42 52.42s23.47 52.42 52.42 52.42Z"
    />
    <path
      fill="#F5F5F5"
      d="M355.34 190.25c28.951 0 52.42-23.469 52.42-52.42s-23.469-52.42-52.42-52.42-52.42 23.469-52.42 52.42 23.469 52.42 52.42 52.42Z"
    />
    <path
      fill="#fff"
      d="M389.603 172.096c18.921-18.92 18.921-49.597 0-68.518s-49.598-18.921-68.518 0c-18.921 18.921-18.921 49.598 0 68.518 18.92 18.921 49.597 18.921 68.518 0Z"
    />
    <path
      fill="#F5F5F5"
      d="M363.6 173.09a1.17 1.17 0 0 1-1.14-.9l-8.26-34.08a1.18 1.18 0 0 1 2.29-.56l8.25 34.09a1.183 1.183 0 0 1-.87 1.42c-.089.02-.179.03-.27.03Z"
    />
    <path
      fill="#EBEBEB"
      d="M355.34 140.18a2.349 2.349 0 0 1-1.66-.69l-28.35-28.35a2.34 2.34 0 0 1-.686-1.663 2.35 2.35 0 0 1 4.016-1.657l26.68 26.69 15.57-15.57a2.35 2.35 0 1 1 3.32 3.33L357 139.49a2.35 2.35 0 0 1-1.66.69Z"
    />
    <path fill="#F0F0F0" d="M221.5 375.1h7.9V321h-7.9v54.1Z" />
    <path fill="#F0F0F0" d="M61.71 382.4h162.48v-7.3H61.71v7.3Z" />
    <path fill="#F5F5F5" d="M221.5 321H56.49v54.1H221.5V321Z" />
    <path fill="#F0F0F0" d="M210.82 330.42H67.16v30.9h143.66v-30.9Z" />
    <path
      fill="#F5F5F5"
      d="M104.05 334.55h69.9a2.37 2.37 0 0 0 2.37-2.37v-.44a2.37 2.37 0 0 0-2.37-2.37h-69.9a2.37 2.37 0 0 0-2.37 2.37v.44a2.37 2.37 0 0 0 2.37 2.37Z"
    />
    <path fill="#F0F0F0" d="M52.87 321H221.5v-4.83H52.87V321Z" />
    <path fill="#E6E6E6" d="M233.19 316.17H221.5V321h11.69v-4.83Z" />
    <path fill="#F0F0F0" d="M429.69 263.88h-15.4V382.4h15.4V263.88Z" />
    <path fill="#F5F5F5" d="M279.5 382.4h134.79V263.88H279.5V382.4Z" />
    <path
      fill="#FAFAFA"
      d="M278.27 371.99h118.34V308.6H278.27v63.39Zm0-70.11h118.34v-20.87H278.27v20.87Z"
    />
    <path
      fill="#E6E6E6"
      d="M397.67 308.6h-1.06v63.39h1.06V308.6Zm0-27.59h-1.06v20.87h1.06v-20.87Zm32.02-12.13H279.16v2.68h150.53v-2.68Z"
      opacity={0.7}
      style={{
        mixBlendMode: 'multiply',
      }}
    />
    <path fill="#F0F0F0" d="M431.49 262.26h-17.2v7.75h17.2v-7.75Z" />
    <path fill="#F5F5F5" d="M277.7 270.01h136.59v-7.75H277.7v7.75Z" />
    <path
      fill="#E6E6E6"
      d="M328.94 239.44a12.451 12.451 0 0 1-1.8-4.82 24.337 24.337 0 0 1-.26-5.18 34.138 34.138 0 0 1 2.18-10.06 49.682 49.682 0 0 1 2-4.7c.8-1.5 1.6-3 2.5-4.43.9-1.43 1.89-2.81 2.91-4.15 1.02-1.34 2.17-2.59 3.36-3.8-.58 1.61-1.27 3.13-2 4.65-.73 1.52-1.3 3.05-2 4.53l-1.94 4.5-.89 2.27-.46 1.13-.4 1.14a61.755 61.755 0 0 0-2.71 9.24 34.584 34.584 0 0 0-.7 4.77 18.446 18.446 0 0 0 .21 4.91Zm-19.41 2.65a25.792 25.792 0 0 0-.63-7.19 14.275 14.275 0 0 0-2.93-5.84 19.71 19.71 0 0 0-5.28-4.16c-2.05-1.19-4.31-2.12-6.64-3.22a18.297 18.297 0 0 1 7.68.82c1.277.418 2.499.989 3.64 1.7a14.673 14.673 0 0 1 3.13 2.71 12.7 12.7 0 0 1 3 7.63 12.907 12.907 0 0 1-1.97 7.55Z"
    />
    <path
      fill="#E6E6E6"
      d="M310.4 241.74a66.05 66.05 0 0 0-.79-8.4 32.866 32.866 0 0 0-2.19-7.71 24.265 24.265 0 0 0-4.24-6.64 38.895 38.895 0 0 0-6.32-5.44 17.435 17.435 0 0 1 8.1 3.52 18.463 18.463 0 0 1 5.51 7.24 21.68 21.68 0 0 1 1.73 8.83 20.845 20.845 0 0 1-1.8 8.6Zm17.21-5.11a47.051 47.051 0 0 1-5.5-9.11 65.352 65.352 0 0 1-3.71-10 56.773 56.773 0 0 1-1.93-10.52 38.916 38.916 0 0 1 .49-10.66c.73 3.51 1.29 6.9 2.09 10.27.8 3.37 1.6 6.69 2.55 10 .95 3.31 1.91 6.62 2.94 9.93 1.03 3.31 2.08 6.67 3.07 10.09Z"
    />
    <path
      fill="#E6E6E6"
      d="M326.71 236.38a47.987 47.987 0 0 1-6.58-8 66.179 66.179 0 0 1-5-9.07 56.148 56.148 0 0 1-3.44-9.81 38.21 38.21 0 0 1-1.15-10.33c1.26 3.26 2.35 6.42 3.64 9.53 1.29 3.11 2.57 6.19 4 9.24 1.43 3.05 2.81 6.09 4.27 9.15 1.46 3.06 2.87 6.11 4.26 9.29Z"
    />
    <path
      fill="#E6E6E6"
      d="M330.43 239.58a37.526 37.526 0 0 1-7.75-5.46 50.465 50.465 0 0 1-6.43-7 43.66 43.66 0 0 1-5-8.12 30.727 30.727 0 0 1-2.7-9.11c1.72 2.69 3.24 5.32 5 7.83 1.76 2.51 3.46 5 5.33 7.39 1.87 2.39 3.74 4.79 5.68 7.17 1.94 2.38 3.91 4.8 5.87 7.3Z"
    />
    <path
      fill="#E6E6E6"
      d="M325 236.4a27.425 27.425 0 0 1-7.48-4.6 36.73 36.73 0 0 1-6-6.42 38.97 38.97 0 0 1-2.44-3.69 36.42 36.42 0 0 1-1.93-4 27.473 27.473 0 0 1-2.09-8.54c1.57 2.51 2.86 5 4.42 7.34.7 1.21 1.54 2.33 2.27 3.51.73 1.18 1.58 2.28 2.44 3.38a129.42 129.42 0 0 0 5.2 6.55c1.76 2.17 3.61 4.28 5.61 6.47Zm4.26 1.71a9.139 9.139 0 0 1-3-3.58 16.833 16.833 0 0 1-1.45-4.52c-.5-3.109-.411-6.284.26-9.36.324-1.51.724-3.001 1.2-4.47.57-1.43 1.08-2.88 1.76-4.25.68-1.37 1.4-2.71 2.21-4a44.909 44.909 0 0 1 2.69-3.7c-.32 1.51-.77 2.94-1.2 4.37-.43 1.43-.79 2.87-1.26 4.26-.47 1.39-.75 2.83-1.18 4.22l-.49 2.11c-.17.7-.37 1.4-.47 2.11a40.415 40.415 0 0 0-.92 8.45 21.67 21.67 0 0 0 .38 4.23c.25 1.451.747 2.848 1.47 4.13Z"
    />
    <path
      fill="#E6E6E6"
      d="M327.4 234.92a19.784 19.784 0 0 1-4.63-8.28 24.692 24.692 0 0 1-.87-9.64c.05-.81.25-1.6.38-2.4l.23-1.2.35-1.16c.26-.77.48-1.54.77-2.29.29-.75.67-1.47 1-2.19l.54-1.07.65-1 1.32-2a36.468 36.468 0 0 1 3.19-3.49c-.52 1.51-1.21 2.88-1.77 4.3l-.79 2.13-.4 1-.32 1.08c-.21.71-.43 1.42-.66 2.12l-.49 2.15-.26 1.07-.15 1.08c-.09.72-.27 1.44-.31 2.16a37.12 37.12 0 0 0 .12 8.75 41.375 41.375 0 0 0 2.1 8.88Zm-4.22-1.22-14.33-12.75 2.12 17.31 12.21-4.56Z"
    />
    <path
      fill="#F5F5F5"
      d="M295.56 231.37h41.58l-5.45 30.89h-30.68l-5.45-30.89Z"
    />
    <path
      fill="#EBEBEB"
      d="M321.54 231.37h15.6l-5.45 30.89h-8.19l-1.96-30.89Z"
    />
    <path
      fill="#E6E6E6"
      d="M373.86 239.5a11.245 11.245 0 0 0-.17-4.1 17.065 17.065 0 0 0-1.23-3.73 31.059 31.059 0 0 0-4.23-6.66c-.38-.55-.87-1-1.3-1.53-.43-.53-.87-1-1.33-1.53-1-1-1.86-2-2.85-3s-1.94-2-2.94-3-2-2-3-3.09c1.35.51 2.63 1.14 3.93 1.78a42.308 42.308 0 0 1 3.67 2.27c.596.409 1.17.849 1.72 1.32.56.46 1.11.94 1.66 1.41a35.018 35.018 0 0 1 3 3.21 21.018 21.018 0 0 1 4.11 7.91c.379 1.468.511 2.989.39 4.5a8.08 8.08 0 0 1-1.43 4.24Zm23.38-4.19a7.478 7.478 0 0 1-2.25-3.42 9.35 9.35 0 0 1-.45-2.11 10.09 10.09 0 0 1 0-1.1 4.486 4.486 0 0 1 .1-1.09 13.544 13.544 0 0 1 4.24-7.36c.5-.49 1.07-.88 1.6-1.32a12.78 12.78 0 0 1 1.68-1.18c.58-.34 1.16-.69 1.76-1 .29-.16.58-.34.89-.48l.92-.39c.62-.26 1.24-.5 1.86-.74.617-.249 1.252-.45 1.9-.6a37.524 37.524 0 0 1 3.91-.8 35.657 35.657 0 0 1-3.23 2.28c-.55.33-1.07.7-1.59 1.08-.52.38-1 .72-1.58 1.07-1.09.65-2 1.48-3 2.18-.499.35-.973.734-1.42 1.15-.46.4-1 .76-1.38 1.18a15.478 15.478 0 0 0-4 5.56c-.19.535-.344 1.083-.46 1.64a11.03 11.03 0 0 0 .5 5.45Z"
    />
    <path
      fill="#E6E6E6"
      d="M396.17 235.31a13.537 13.537 0 0 1-2.8-8.2 16.998 16.998 0 0 1 2.24-8.67l1.2-1.9c.46-.59.95-1.15 1.44-1.71l.75-.83.82-.72 1.68-1.41a39.169 39.169 0 0 1 3.69-2.27 39.945 39.945 0 0 1 4-1.56c-1 1.06-2 2-3.07 2.9-1.07.9-1.94 1.93-2.88 2.88-.44.52-.87 1-1.32 1.51l-.67.73-.57.8c-.38.53-.8 1-1.17 1.56l-1 1.65a21.337 21.337 0 0 0-2.53 7.19 22.804 22.804 0 0 0 .19 8.05Zm-21.03 1.48c.83-3.09 1.73-6.07 2.6-9 .87-2.93 1.72-5.92 2.5-8.88.78-2.96 1.43-5.94 2-9 .57-3.06.88-6 1.15-9.24a27.007 27.007 0 0 1 1.46 9.5 44.891 44.891 0 0 1-1.39 9.52 51.74 51.74 0 0 1-3.29 9 39.328 39.328 0 0 1-5.03 8.1Z"
    />
    <path
      fill="#E6E6E6"
      d="M376.18 236.29c1.3-3 2.7-6 4.08-8.87 1.38-2.87 2.77-5.79 4.1-8.69 1.33-2.9 2.61-5.81 3.8-8.76s2.21-6 3.21-9.14a30.37 30.37 0 0 1-.71 9.91 52.12 52.12 0 0 1-3.27 9.38 62.242 62.242 0 0 1-4.87 8.6 45.381 45.381 0 0 1-6.34 7.57Z"
    />
    <path
      fill="#E6E6E6"
      d="M372.11 240.1c1.95-2.68 4-5.21 6-7.74 2-2.53 4-5 5.94-7.55 1.94-2.55 3.84-5.08 5.66-7.7 1.82-2.62 3.48-5.32 5.17-8.18a30.4 30.4 0 0 1-2.91 9.5 51.851 51.851 0 0 1-5.28 8.41 62.228 62.228 0 0 1-6.67 7.29 45.451 45.451 0 0 1-7.91 5.97Z"
    />
    <path
      fill="#E6E6E6"
      d="M378.23 235.74c1.91-2.39 3.82-4.65 5.7-6.94 1.88-2.29 3.7-4.56 5.48-6.87 1.78-2.31 3.43-4.69 5-7.12 1.57-2.43 3-5 4.54-7.66a26.533 26.533 0 0 1-2.24 8.9 38.906 38.906 0 0 1-4.71 7.9 45.902 45.902 0 0 1-6.26 6.67 34.446 34.446 0 0 1-7.51 5.12Zm-4.89 2.79a14.473 14.473 0 0 0 1.52-7 17.463 17.463 0 0 0-1.74-6.66l-.34-.81-.43-.78-.84-1.58c-.67-1-1.27-2.07-2-3.1-.73-1.03-1.39-2.06-2.13-3.09a38.578 38.578 0 0 1-2.17-3.22 36.495 36.495 0 0 1 3.34 2.08 34.137 34.137 0 0 1 3 2.57c.474.465.919.959 1.33 1.48.427.513.847 1.033 1.26 1.56l1.07 1.73.51.88.42.94a14.42 14.42 0 0 1 1.3 8.16 9.992 9.992 0 0 1-4.1 6.84Z"
    />
    <path
      fill="#E6E6E6"
      d="M375.2 235.34a37.792 37.792 0 0 0 1.68-7.63c.27-2.419.09-4.867-.53-7.22a16.77 16.77 0 0 0-1.3-3.35c-.551-1.1-1.2-2.148-1.94-3.13a52.771 52.771 0 0 0-5.22-5.86 17.862 17.862 0 0 1 7.17 4.12 15.742 15.742 0 0 1 2.74 3.32 14.892 14.892 0 0 1 1.76 4 15.832 15.832 0 0 1-.13 8.61 16.01 16.01 0 0 1-4.23 7.14Zm4.89-2.37 15.64-15.02-.63 14.73-15.01.29Z"
    />
    <path
      fill="#F5F5F5"
      d="M362.98 231.37h41.58l-5.45 30.89h-30.68l-5.45-30.89Z"
    />
    <path
      fill="#EBEBEB"
      d="M388.95 231.37h15.61l-5.45 30.89h-8.19l-1.97-30.89Z"
    />
    <path
      fill="#F5F5F5"
      d="M103.87 90.72v162a25.398 25.398 0 0 1-25.4 25.4h118a25.411 25.411 0 0 0 25.41-25.4v-162H103.87Z"
    />
    <path fill="#F0F0F0" d="M203.36 131.73h-76.25v76.25h76.25v-76.25Z" />
    <path
      fill="#fff"
      d="M201.02 129.38h-76.25v76.25h76.25v-76.25Zm-57.53 87.54H124.1v19.39h19.39v-19.39Zm57.52 0h-19.39v19.39h19.39v-19.39Zm-28.76 0h-19.39v19.39h19.39v-19.39Z"
    />
    <path fill="#F0F0F0" d="M221.9 90.72H103.87V110H221.9V90.72Z" />
    <path
      fill="#E6E6E6"
      d="M111 84.53v12.38a3.445 3.445 0 0 0 2.128 3.19c.419.173.869.261 1.322.26a3.446 3.446 0 0 0 3.45-3.45V84.53a3.463 3.463 0 0 0-3.45-3.45 3.451 3.451 0 0 0-3.45 3.45Zm96.92 0v12.38a3.45 3.45 0 0 0 3.45 3.45 3.446 3.446 0 0 0 2.442-1.007 3.436 3.436 0 0 0 1.008-2.443V84.53a3.45 3.45 0 0 0-3.45-3.45 3.462 3.462 0 0 0-3.45 3.45Zm-19.39 0v12.38a3.457 3.457 0 0 0 1.011 2.44 3.44 3.44 0 0 0 3.76.747 3.452 3.452 0 0 0 2.129-3.187V84.53a3.458 3.458 0 0 0-3.43-3.45 3.466 3.466 0 0 0-2.449 1.006 3.48 3.48 0 0 0-.754 1.12c-.175.42-.265.87-.267 1.324Zm-19.39 0v12.38a3.449 3.449 0 0 0 2.128 3.19c.419.173.868.261 1.322.26a3.438 3.438 0 0 0 3.41-3.45V84.53a3.45 3.45 0 0 0-3.44-3.45 3.448 3.448 0 0 0-3.42 3.45Zm-19.39 0v12.38a3.451 3.451 0 0 0 1.004 2.439 3.438 3.438 0 0 0 2.436 1.011 3.446 3.446 0 0 0 2.442-1.007 3.436 3.436 0 0 0 1.008-2.443V84.53a3.45 3.45 0 0 0-3.45-3.45 3.45 3.45 0 0 0-3.44 3.45Zm-19.4 0v12.38a3.456 3.456 0 0 0 1.01 2.44 3.442 3.442 0 0 0 2.44 1.01 3.44 3.44 0 0 0 2.439-1.01 3.45 3.45 0 0 0 1.011-2.44V84.53a3.46 3.46 0 0 0-3.45-3.45 3.458 3.458 0 0 0-3.45 3.45Zm55.52 182.91a10.63 10.63 0 0 0 10.63 10.63H63.69a10.628 10.628 0 0 1-10.63-10.63h132.81Z"
    />
    <path
      fill="#F5F5F5"
      d="M250 427.56c107.083 0 193.89-5.068 193.89-11.32 0-6.252-86.807-11.32-193.89-11.32-107.082 0-193.89 5.068-193.89 11.32 0 6.252 86.808 11.32 193.89 11.32Z"
    />
    <path
      fill="#407BFF"
      d="M269.59 320.73s-73.49 43.39-73.49 84.5h147c-.01-41.11-73.51-84.5-73.51-84.5Z"
      opacity={0.2}
    />
    <path
      fill="#407BFF"
      d="M269.59 372.5s-67.86 16.81-67.86 32.73h135.72c0-15.92-67.86-32.73-67.86-32.73Z"
      opacity={0.2}
    />
    <path
      fill="#fff"
      d="M278.51 332.71c13.8 13.57 40.62 43.64 40.62 72.52h-18.39c0-28.88-14.68-58.95-22.23-72.52Z"
      opacity={0.3}
    />
    <path
      fill="#407BFF"
      d="M269.59 320.73s-73.49-43.39-73.49-84.5h147c-.01 41.11-73.51 84.5-73.51 84.5Z"
      opacity={0.2}
    />
    <path
      fill="#407BFF"
      d="M269.59 316.77s-37.52-18-37.52-35.1h75.05c0 17.07-37.53 35.1-37.53 35.1Z"
      opacity={0.2}
    />
    <path
      fill="#fff"
      d="M261.58 308.75C247.79 295.18 221 265.11 221 236.23h18.39c-.04 28.88 14.61 58.95 22.19 72.52Zm16.93 0c13.8-13.57 39.95-47.12 40.62-72.52h-4.9c-2.3 30.97-24.8 59.39-35.72 72.52Z"
      opacity={0.3}
    />
    <path
      fill="#407BFF"
      d="M333.11 221.72h-8.33v1h8.33v-1Zm28.28 13.74h-1a12.75 12.75 0 0 0-12.73-12.74h-8.79v-1h8.79a13.756 13.756 0 0 1 9.703 4.034 13.755 13.755 0 0 1 4.027 9.706Z"
    />
    <path
      fill="#407BFF"
      d="M347.65 225.22H191.53c-5.655 0-10.24 4.585-10.24 10.24V237c0 5.655 4.585 10.24 10.24 10.24h156.12c5.655 0 10.24-4.585 10.24-10.24v-1.54c0-5.655-4.585-10.24-10.24-10.24Zm0 169H191.53c-5.655 0-10.24 4.585-10.24 10.24V406c0 5.655 4.585 10.24 10.24 10.24h156.12c5.655 0 10.24-4.585 10.24-10.24v-1.54c0-5.655-4.585-10.24-10.24-10.24Z"
    />
  </svg>
)

const useStyles = createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      flex: 1,
      gap: 12,
      fontWeight: 500,
      fontSize: 24,
      textAlign: 'center',
      color: token.gray500,
    },
  }
})
const DummyPage: React.FC = () => {
  const { styles } = useStyles()
  return (
    <ViewContainerLayout>
      <div className={styles.container}>
        <p>
          We&apos;re working on this feature and will inform you as <br />
          soon as it&apos;s ready to drive your success.
        </p>
        <Placeholder />
      </div>
    </ViewContainerLayout>
  )
}
export default DummyPage
