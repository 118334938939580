import { useSearchParams } from 'react-router-dom'
import { SidebarFilterSelect } from '..'
import { trpc } from '@services/trpc'
import { skipToken } from '@tanstack/react-query'
import { capitalize } from '@utils/strings'

interface SidebarFiltersByDatatagProps {
  currentDataTagClass: string
  onDataTagClassChange: (dataTagClass: string) => void
  disabled?: boolean
}

const SidebarFiltersByDatatag: React.FC<SidebarFiltersByDatatagProps> = ({
  currentDataTagClass,
  onDataTagClassChange,
  disabled = false,
}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: dataTagClasses = [] } = trpc.api.datatags.listClasses.useQuery()
  const { data: dataTags = [] } = trpc.api.datatags.domain.useQuery(
    currentDataTagClass || skipToken,
  )

  const classOptions = [
    ...dataTagClasses.map((item) => ({
      value: item.name,
      label: capitalize(item.name),
    })),
  ]
  const dataTagOptions = [
    { value: '', label: 'All' },
    ...dataTags.map((item) => ({
      value: String(item.id),
      label: item.name,
    })),
  ]

  const handleFilterChange = (value: string) => {
    setSearchParams(
      (prev) => {
        const current = Object.fromEntries(prev.entries())
        return {
          ...current,
          [currentDataTagClass]: value,
        }
      },
      { replace: true },
    )
  }

  return (
    <>
      <SidebarFilterSelect
        label="Data group"
        placeholder="Select data group"
        options={classOptions}
        defaultValue=""
        onChange={onDataTagClassChange}
        value={currentDataTagClass}
        disabled={disabled}
      />
      <SidebarFilterSelect
        label="View by"
        options={dataTagOptions}
        onChange={handleFilterChange}
        value={searchParams.get(currentDataTagClass) || ''}
        disabled={disabled}
      />
    </>
  )
}

export default SidebarFiltersByDatatag
