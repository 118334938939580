import React from 'react'
import { PageContentLayout } from '@components'
import { LedgerProvider, LedgerSidebar, LedgerView } from '@modules/ledger'
import { DataModelPageProvider, DatamodelGroup } from '@modules/datamodel'

const LedgerPage: React.FC = () => {
  return (
    <DataModelPageProvider
      title="Ledger"
      datamodelGroup={DatamodelGroup.LEDGER}
    >
      <LedgerProvider>
        <PageContentLayout>
          <LedgerSidebar />
          <LedgerView />
        </PageContentLayout>
      </LedgerProvider>
    </DataModelPageProvider>
  )
}
export default LedgerPage
