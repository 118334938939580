import React from 'react'
import { PageContentLayout } from '@components'
import { DataModelPageProvider, DatamodelGroup } from '@modules/datamodel'
import { CompoundModelView, CompoundModelSidebar } from '@modules/compoundModel'

const IncomePage: React.FC = () => {
  return (
    <DataModelPageProvider
      title="Income"
      datamodelGroup={DatamodelGroup.INCOME}
    >
      <PageContentLayout>
        <CompoundModelSidebar />
        <CompoundModelView />
      </PageContentLayout>
    </DataModelPageProvider>
  )
}
export default IncomePage
