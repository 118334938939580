import { CreateModal } from '@components'
import CreateCalendarForm from './CreateCalendarForm'
import { Form, message } from 'antd'
import { trpc } from '@services/trpc'
import { CalendarFormValues } from '@modules/calendar/calendar.types'
import { CreateModalProps } from '@components/CreateModal'

const CreateCalendarModal: React.FC<CreateModalProps> = ({
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { refetch: fetchCalendars } = trpc.api.calendar.list.useQuery()

  const handleSuccess = () => {
    message.success('Calendar created successfully')
    fetchCalendars()
    form.resetFields()
    onClose?.()
  }

  const { mutate: saveCalendar, isPending } =
    trpc.api.calendar.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: CalendarFormValues) => {
    saveCalendar({
      ...values,
      initial_start_date: values.initial_start_date.format('YYYY-MM-DD'),
    })
  }

  const handleCancel = () => {
    form.resetFields()
    onClose?.()
  }

  return (
    <CreateModal
      {...props}
      title="Create a Financial Calendar"
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      confirmLoading={isPending}
    >
      <CreateCalendarForm form={form} onSubmit={handleSubmit} />
    </CreateModal>
  )
}

export default CreateCalendarModal
