import { SidebarList, SidebarCollapsibleItem } from '@components'
import { trpc } from '@services/trpc'
import { useModalState } from '@hooks'
import type { Lookup } from '@modules/lookup'
import CreateLookupModal from '@modules/lookup/components/CreateLookupModal'
import { useDataModelPageContext } from '@modules/datamodel'

const LookupSidebarDataModelsTab: React.FC = () => {
  const { data: lookups = [] } = trpc.api.datamodel.listByType.useQuery({
    type: 'lookup',
  })
  const lookupModalState = useModalState()
  const { selectedDataModel, changeSelectedDatamodelId } =
    useDataModelPageContext()

  const handleLookupClick = (lookup: Lookup) => {
    changeSelectedDatamodelId(lookup.id)
  }

  const handleLabelClick = () => {
    changeSelectedDatamodelId(null)
  }

  return (
    <>
      <SidebarCollapsibleItem
        label={`Lookup tables (${lookups.length})`}
        onLabelClick={handleLabelClick}
        onCreateClick={lookupModalState.open}
      >
        <SidebarList<Lookup>
          items={lookups}
          onItemClick={handleLookupClick}
          selectedItemId={selectedDataModel?.id || undefined}
        />
      </SidebarCollapsibleItem>
      <CreateLookupModal
        open={lookupModalState.isOpen}
        onClose={lookupModalState.close}
      />
    </>
  )
}

export default LookupSidebarDataModelsTab
