import { useConfigurationMode } from '@context/ConfigurationContext'
import LedgerSidebarNormal from './LedgerSidebarNormal'
import LedgerSidebarConfig from './LedgerSidebarConfig'

const LedgerSidebar = () => {
  const { isConfigurationMode } = useConfigurationMode()
  if (isConfigurationMode) {
    return <LedgerSidebarConfig />
  }
  return <LedgerSidebarNormal />
}

export default LedgerSidebar
