import { Menu } from 'antd'
import useStyles from './ViewTabsControl.styles'

export type ViewTab = {
  key: string
  label: string
  disabled?: boolean
}

type ViewTabsControlProps = {
  tabs: ViewTab[]
  currentTab: string
  onTabChange: (tab: string) => void
}

const ViewTabsControl: React.FC<ViewTabsControlProps> = ({
  tabs,
  currentTab,
  onTabChange,
}) => {
  const { styles } = useStyles()

  return (
    <div className={styles.container}>
      <Menu
        mode="horizontal"
        activeKey={currentTab}
        items={tabs}
        onClick={({ key }) => onTabChange(key)}
        className={styles.menu}
      />
    </div>
  )
}

export default ViewTabsControl
