import React from 'react'
import { message } from 'antd'
import { trpc } from '@services/trpc'
import { DatagroupInput } from '@modules/datagroup/datagroup.types.ts'
import { skipToken } from '@tanstack/react-query'
import CreateDatagroupModal, {
  CreateDatagroupModalProps,
} from '../CreateDatagroupModal'

interface CreateDatagroupCategoryModalProps extends CreateDatagroupModalProps {
  parentId: number
}

const CreateDatagroupCategoryModal: React.FC<
  CreateDatagroupCategoryModalProps
> = ({ parentId, ...rest }) => {
  const { refetch: refetchCategories } =
    trpc.api.datatags.listDatagroupCategories.useQuery(
      parentId ? { id: parentId } : skipToken,
    )

  const handleSuccess = () => {
    message.success('Sub category created successfully')
    refetchCategories()
  }

  const getSubmitValues = (values: DatagroupInput) => {
    return {
      ...values,
      parent_id: parentId,
      type: 'category',
    } as DatagroupInput
  }

  return (
    <CreateDatagroupModal
      {...rest}
      title="Create Sub category"
      getSubmitValues={getSubmitValues}
      onSuccess={handleSuccess}
    />
  )
}

export default CreateDatagroupCategoryModal
