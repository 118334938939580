import { NavLink, useLocation } from 'react-router-dom'
import useStyles from './TopNavbar.styles'
import { groupedTabs } from '@constants/navigation'

const TopNavbar = () => {
  const location = useLocation()
  const { cx, styles } = useStyles()

  const current = location.pathname.split('/')[1]

  return (
    <div className={styles.container}>
      <span className={styles.sectionName}>{current}</span>
      <ul
        className={styles.tabList}
        aria-label="Top Navbar"
        aria-disabled={!current}
      >
        {current &&
          groupedTabs[current as keyof typeof groupedTabs]?.map((tab) => {
            const isCurrent = location.pathname.split('/')[1] === tab.key
            const Element = isCurrent ? 'span' : NavLink
            return (
              <li key={tab.key}>
                <Element
                  to={tab.path}
                  className={cx(styles.tab, {
                    active: isCurrent,
                  })}
                  aria-disabled={tab.disabled}
                  aria-label={tab.label}
                >
                  {tab.label}
                </Element>
              </li>
            )
          })}
      </ul>
    </div>
  )
}

export default TopNavbar
