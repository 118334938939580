import React from 'react'
import { PageContentLayout } from '@components'
import { AccountsSidebar, AccountsView } from '@modules/account'
import { ConfigPageProvider } from '@context/ConfigPageContext'

const AccountPage: React.FC = () => {
  return (
    <ConfigPageProvider>
      <PageContentLayout>
        <AccountsSidebar />
        <AccountsView />
      </PageContentLayout>
    </ConfigPageProvider>
  )
}
export default AccountPage
