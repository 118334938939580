export const COMPANY_CATEGORIES = {
  company: 'company',
  region: 'region',
} as const

export const COMPANY_CATEGORIES_LABELS = {
  [COMPANY_CATEGORIES.company]: 'Company',
  [COMPANY_CATEGORIES.region]: 'Region',
}

export const COMPANY_CATEGORY_PLURAL_LABELS = {
  [COMPANY_CATEGORIES.company]: 'Companies',
  [COMPANY_CATEGORIES.region]: 'Regions',
}

export const ALL_COMPANY_CATEGORIES = {
  ...COMPANY_CATEGORIES,
} as const

export const COMPANY_CATEGORIES_OPTIONS = [
  ...Object.values(COMPANY_CATEGORIES).map((category) => ({
    value: category,
    label: COMPANY_CATEGORIES_LABELS[category],
  })),
]

export const ALL_COMPANY_CATEGORIES_OPTIONS = [
  { value: '', label: 'All' },
  ...COMPANY_CATEGORIES_OPTIONS,
]

export type CompanyCategory = keyof typeof COMPANY_CATEGORIES
