import App from 'App'
import ErrorPage from 'pages/error'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { DummyPage, MainPageLayout, withAuth } from '@components'
import { SignInPage } from 'pages/sign-in'
import { SignUpPage } from 'pages/sign-up'
import { CreateOrganizationPage } from 'pages/organization'
import { TruncatePage } from 'pages/system/truncate'
import WelcomePage from 'pages/welcome'
import datatagsRoutes from './datatags'
import driversRoutes from './drivers'
import financialRoutes from './financial'
import operationalRoutes from './operational'
import ExportImportPage from 'pages/export-import'

export const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    Component: withAuth(App),
    ErrorBoundary: ErrorPage,
    children: [
      {
        index: true,
        element: <Navigate to="/datatags/datagroups" replace />,
      },
      {
        path: '/datatags',
        children: datatagsRoutes,
        handle: { title: 'Data Tags' },
      },
      {
        path: '/drivers',
        children: driversRoutes,
        handle: { title: 'Drivers' },
      },
      {
        path: '/operational',
        children: operationalRoutes,
        handle: { title: 'Operational' },
      },
      {
        path: '/financial',
        children: financialRoutes,
        handle: { title: 'Financial' },
      },
      {
        path: '/insights',
        element: <MainPageLayout />,
        children: [
          {
            index: true,
            Component: DummyPage,
          },
        ],
      },
      {
        path: '/import-export',
        element: <MainPageLayout />,
        children: [
          {
            index: true,
            Component: ExportImportPage,
          },
        ],
      },

      {
        path: '/workflow',
        element: <MainPageLayout />,
        children: [
          {
            index: true,
            Component: DummyPage,
          },
        ],
      },
      {
        path: '/security',
        element: <MainPageLayout />,
        children: [
          {
            index: true,
            Component: DummyPage,
          },
        ],
      },
      {
        path: '/maintenance',
        element: <MainPageLayout />,
        children: [
          {
            index: true,
            Component: DummyPage,
          },
        ],
      },
    ],
  },
  {
    path: '/sign-in',
    Component: SignInPage,
  },
  {
    path: '/sign-up',
    Component: SignUpPage,
  },
  {
    path: '/create-org',
    Component: withAuth(CreateOrganizationPage),
  },
  {
    path: '/welcome',
    Component: WelcomePage,
  },
  // System only
  {
    path: '/system/truncate',
    Component: TruncatePage,
  },
])
