import { trpc } from '@services/trpc'
import { capitalize } from '@utils/strings'

export const usePeriodOptions = () => {
  const { data: periodClasses = [] } =
    trpc.api.periods.getPeriodClasses.useQuery()

  const periodClassValues = periodClasses.reduce<Record<string, string>>(
    (acc, periodClass) => {
      acc[periodClass] = periodClass
      return acc
    },
    {},
  )

  const periodClassOptions = periodClasses.map((periodClass) => ({
    value: periodClass,
    label: capitalize(periodClass),
  }))

  const periodClassFilters = periodClasses.map((periodClass) => ({
    name: periodClass,
    id: periodClass,
  }))

  return { periodClassOptions, periodClassValues, periodClassFilters }
}
