import { Button, Form, FormListFieldData } from 'antd'
import { FaPlus, FaRegTrashAlt } from 'react-icons/fa'
import React from 'react'
import useStyles from './BulkFormList.styles'

export type BulkFormListValues<T> = {
  items: T[]
}

type BulkFormListProps = {
  wrapped?: boolean
  rowClassName?: string
  name?: string
  children:
    | React.ReactElement
    | React.ReactElement[]
    | ((
        index: number,
        fields: FormListFieldData[],
      ) => React.ReactElement | React.ReactElement[])
}

const BulkFormList: React.FC<BulkFormListProps> = ({
  children,
  wrapped,
  name = 'items',
  rowClassName,
}) => {
  const { styles } = useStyles()
  const Wrapper = wrapped ? 'div' : React.Fragment

  return (
    <Form.List name={name} initialValue={[{}]}>
      {(fields, { add, remove }) => {
        return (
          <>
            {fields.map(({ key, name: index, ...restField }) => {
              const classNameProps = wrapped ? { className: rowClassName } : {}
              const buttons = (
                <div className={styles.buttons}>
                  {/* {fields.length > 1 && ( */}
                  {index !== fields.length - 1 && (
                    <Button
                      type="primary"
                      className={styles.addButton}
                      onClick={() => remove(index)}
                    >
                      <FaRegTrashAlt />
                    </Button>
                  )}

                  {index === fields.length - 1 && (
                    <Button
                      aria-label="Add Item"
                      type="primary"
                      className={styles.addButton}
                      onClick={() => add()}
                    >
                      <FaPlus />
                    </Button>
                  )}
                </div>
              )

              if (typeof children === 'function') {
                return (
                  <Wrapper key={key} {...classNameProps}>
                    {children(index, fields)}
                    {buttons}
                  </Wrapper>
                )
              }

              return (
                <Wrapper key={key} {...classNameProps}>
                  {React.Children.map(children, (formItem) => {
                    return React.cloneElement(formItem, {
                      ...restField,
                      name: [index, formItem.props.name],
                    })
                  })}
                  {buttons}
                </Wrapper>
              )
            })}
          </>
        )
      }}
    </Form.List>
  )
}

export default BulkFormList
