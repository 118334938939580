import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      gap: token.paddingSM,
      marginBottom: token.marginSM,
    },
  }
})
