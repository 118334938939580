import { useCallback, useEffect, useMemo, useState } from 'react'
import { useBlocker } from 'react-router-dom'

type Transactions = {
  [key: string]: string
}

const useTransactionsStore = () => {
  const [transactions, setTransactions] = useState<Transactions>({})

  const addTransaction = useCallback((datatagsId: string, value: string) => {
    setTransactions((prev) => ({ ...prev, [datatagsId]: value }))
  }, [])

  const reset = useCallback(() => {
    setTransactions({})
  }, [])

  const isDirty = Object.keys(transactions).length > 0

  const shouldBlock = useCallback(
    ({
      currentLocation,
      nextLocation,
    }: {
      currentLocation: any
      nextLocation: any
    }) => isDirty && currentLocation.pathname !== nextLocation.pathname,
    [isDirty],
  )
  const blocker = useBlocker(shouldBlock)

  const transactionsToSubmit = useMemo<[Array<number | null>, string][]>(() => {
    return transactions
      ? Object.entries(transactions).map(([key, value]) => [
          JSON.parse(key),
          value,
        ])
      : []
  }, [transactions])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault()
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])

  return {
    transactions,
    transactionsToSubmit,
    addTransaction,
    reset,
    isDirty,
    blocker,
  }
}

export default useTransactionsStore
