import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: token.paddingSM,

      '> *': {
        display: 'grid',
        gridTemplateColumns: '2fr repeat(2, 1fr) fit-content(100%)',
        padding: token.paddingSM,
        borderRadius: token.borderRadius,
        backgroundColor: token.colorWhite,

        border: `1px solid ${token.colorBorder}`,
        '> *:not(:last-child)': {
          borderRight: `1px solid ${token.colorBorder}`,
          paddingRight: token.padding,
          marginRight: token.margin,
        },
      },
    },
    dateFormItem: {
      // display: 'flex',
      '.ant-picker': {
        // flex: 1,
        width: '100%',
      },
    },
  }
})
