import React from 'react'
import { Radio, RadioChangeEvent } from 'antd'
import useStyles from './TableSheetModeRadio.styles'

type RadioOptionType = {
  icon: React.ReactNode
  value: string
  disabled?: boolean
}

interface TableSheetModeRadioProps {
  options: RadioOptionType[]
  value: string
  onChange: (value: RadioChangeEvent) => void
}

const TableSheetModeRadio: React.FC<TableSheetModeRadioProps> = ({
  options,
  value,
  onChange,
}) => {
  const { styles } = useStyles()
  return (
    <Radio.Group
      value={value}
      onChange={onChange}
      optionType="button"
      buttonStyle="solid"
      className={styles.tableSheetModeRadio}
    >
      {options.map((option) => (
        <Radio.Button
          key={option.value}
          value={option.value}
          className={styles.tableSheetModeRadioButton}
          disabled={option.disabled}
        >
          {option.icon}
        </Radio.Button>
      ))}
    </Radio.Group>
  )
}

export default TableSheetModeRadio
