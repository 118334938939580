import { FC } from 'react'
import useStyles from './SidebarFilterSelect.styles'
import LabeledValueSelect from '../LabeledValueSelect'
import { LabeledValueSelectProps } from '../LabeledValueSelect/LabeledValueSelect'
import { IoChevronDownOutline } from 'react-icons/io5'

const SidebarFilterSelect: FC<LabeledValueSelectProps> = ({ ...props }) => {
  const { styles } = useStyles()

  return (
    <LabeledValueSelect
      variant="borderless"
      suffixIcon={<IoChevronDownOutline size={14} />}
      classNames={{
        select: styles.select,
        label: styles.label,
        value: styles.value,
      }}
      {...props}
    />
  )
}

export default SidebarFilterSelect
