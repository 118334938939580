import useStyles from './PopoverLayout.styles'

interface PopoverLayoutProps {
  children: React.ReactNode
}

const PopoverLayout: React.FC<PopoverLayoutProps> = ({ children }) => {
  const { styles } = useStyles()

  return <div className={styles.popoverContainer}>{children}</div>
}

export default PopoverLayout
