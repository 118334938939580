import { MainPageLayout } from '@components'
import { Navigate } from 'react-router-dom'
import ledgerRoutes from './ledger'
import adjustmentsRoutes from './adjustments'
import currencyRoutes from './currency'
import consolidationRoutes from './consolidation'

export const financialRoutes = [
  {
    index: true,
    element: <Navigate to="/financial/ledger/summary" replace />,
  },
  {
    path: 'ledger',
    handle: { title: 'Ledger' },
    element: <MainPageLayout page="ledger" />,
    children: ledgerRoutes,
  },
  {
    path: 'adjustments',
    handle: { title: 'Adjustments' },
    element: <MainPageLayout page="adjustments" />,
    children: adjustmentsRoutes,
  },
  {
    path: 'currency',
    handle: { title: 'Currency' },
    element: <MainPageLayout page="currency" />,
    children: currencyRoutes,
  },
  {
    path: 'consolidation',
    handle: { title: 'Consolidation' },
    element: <MainPageLayout page="consolidation" />,
    children: consolidationRoutes,
  },
]

export default financialRoutes
