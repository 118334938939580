import { Schema } from '@components/SelectedListWidget/index.types'

export const DATA_MODEL_ITEMS_SCHEMA: Schema = [
  {
    name: 'inputSource',
    label: 'Input Source',
    type: 'select',
    options: [
      {
        label: 'Input',
        value: 'input',
      },
      {
        label: 'Lookup',
        value: 'lookup',
      },
      {
        label: 'Accountdriver',
        value: 'accountdriver',
      },
    ],
  },
  {
    name: 'allocate',
    label: 'Allocate',
    type: 'switch',
  },
] as const

export const getDataModelItemsSchema = (handlers: {
  [propertyName: string]: (
    value: string | number | boolean,
    id: string | number,
  ) => void
}): Schema => {
  return [
    {
      name: 'inputSource',
      label: 'Input Source',
      type: 'select',
      onChange: handlers.inputSource,
      options: [
        {
          label: 'Input',
          value: 'input',
        },
        {
          label: 'Lookup',
          value: 'lookup',
        },
        // TODO: should we add accountdriver?
        // {
        //   label: 'Accountdriver',
        //   value: 'accountdriver',
        // },
      ],
    },
    {
      name: 'allocate',
      label: 'Allocate',
      type: 'switch',
      onChange: handlers.allocate,
    },
  ]
}

export const COLUMN_GROUPS = {
  systemColumns: 'System Defined Columns',
  columns: 'Columns',
} as const

export const SYSTEM_COLUMNS = [
  {
    id: 'name',
    name: 'Name | ID',
    tagType: 'Data Group',
    allocate: false,
    disabled: true,
    itemGroup: COLUMN_GROUPS.systemColumns,
  },
  {
    id: 'description',
    name: 'Description',
    tagType: 'Long Text',
    allocate: false,
    disabled: true,
    itemGroup: COLUMN_GROUPS.systemColumns,
  },
  {
    id: 'memo',
    name: 'Memo',
    tagType: 'Long Text',
    allocate: false,
    disabled: true,
    itemGroup: COLUMN_GROUPS.systemColumns,
  },
  {
    id: 'startDate',
    name: 'Start Date',
    tagType: 'Date',
    allocate: false,
    disabled: true,
    itemGroup: COLUMN_GROUPS.systemColumns,
  },
  {
    id: 'endDate',
    name: 'End Date',
    tagType: 'Date',
    allocate: false,
    disabled: true,
    itemGroup: COLUMN_GROUPS.systemColumns,
  },
  {
    id: 'version',
    name: 'Version',
    tagType: 'Data Group',
    allocate: false,
    disabled: true,
    itemGroup: COLUMN_GROUPS.systemColumns,
  },
  {
    id: 'currency',
    name: 'Currency',
    tagType: 'Data Group',
    allocate: false,
    disabled: true,
    itemGroup: COLUMN_GROUPS.systemColumns,
  },
] as const

export const TABS = [
  {
    key: 'columns',
    label: 'Columns / Data Tags',
  },
  {
    key: 'rows',
    label: 'Rows / Data Models',
  },
]
