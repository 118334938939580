import { grays, blues, greens, error, type Colors } from './colors'
import { AliasToken } from 'antd/es/theme/internal'

type Token = Partial<AliasToken> & Colors

const token: Token = {
  colorPrimary: '#004eeb',
  colorPrimaryBg: '#d1e0ff',
  colorText: grays.gray700,
  fontSizeXS: 8,
  fontSizeSM: 10,
  fontSize: 12,
  fontSizeLG: 14,
  fontSizeXL: 16,
  fontSizeXXL: 18,
  fontFamily: 'Inter',
  colorBorder: grays.gray300,
  ...grays,
  ...blues,
  ...greens,
  ...error,
}

export const themeConfig = {
  token,
  components: {
    Button: {
      defaultShadow: 'none',
      primaryShadow: 'none',
      dangerShadow: 'none',
      contentFontSizeSM: 10,
      marginXS: 4, // Margin between button label and icon
    },
    Segmented: {
      itemActiveBg: '#096DD9',
      itemColor: '#344054',
      trackBg: 'transparent',
      itemHoverColor: '#344054',
      itemSelectedColor: '#FFFFFF',
      itemSelectedBg: '#096DD9',
      trackPadding: 0,
    },
    Menu: {
      activeBarHeight: 0,
    },
    Collapse: {
      contentPadding: 0,
      headerPadding: 0,
    },
    Form: {
      itemMarginBottom: 0,
      verticalLabelPadding: `0 0 4px`,
    },
    Radio: {
      colorBgContainerDisabled: token.gray200,
    },
    Popover: {
      colorBgElevated: token.gray200,
    },
  },
  hashed: false,
}
