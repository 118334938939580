import { useConfigurationMode } from '@context/ConfigurationContext'
import CustomComputedNormalView from './CustomComputedViewNormal'
import CustomComputedViewConfig from './CustomComputedViewConfig'
import { CustomPageType } from '@modules/customComputed'

interface CustomComputedViewProps {
  type: CustomPageType
}

const CustomComputedView: React.FC<CustomComputedViewProps> = ({ type }) => {
  const { isConfigurationMode } = useConfigurationMode()

  if (isConfigurationMode) {
    return <CustomComputedViewConfig type={type} />
  }

  return <CustomComputedNormalView type={type} />
}

export default CustomComputedView
