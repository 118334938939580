import { Button, Result, Space } from 'antd'
import { ResultStatusType } from 'antd/es/result'
import { useRouteError, Link } from 'react-router-dom'

declare type BaseError = {
  statusText?: string
  message?: string
  status?: number
}

const ErrorPage = () => {
  const error = useRouteError() as BaseError

  return (
    <Space
      style={{
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Result
        status={
          error.status ? (String(error.status) as ResultStatusType) : '500'
        }
        title={error.statusText}
        subTitle={error.message}
        extra={
          <>
            <Link to="/">
              <Button type="default">Back Home</Button>
            </Link>
            <Button
              type="primary"
              onClick={() => {
                window.location.reload()
              }}
            >
              Refresh page
            </Button>
          </>
        }
      />
    </Space>
  )
}

export default ErrorPage
