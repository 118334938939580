import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: token.paddingContentHorizontal,
    },
    viewConstructor: {
      display: 'flex',
      gap: token.paddingContentHorizontal,
    },
    section: {
      padding: `${token.paddingContentVerticalSM}px ${token.paddingContentHorizontalSM}px`,
      display: 'flex',
    },
    formContainer: {
      form: {
        display: 'flex',
        '> *': {
          paddingRight: 12,
          marginRight: 12,
          borderRight: `1px solid ${token.colorBorder}`,
        },
      },
    },
    viewBasis: {
      borderRadius: token.borderRadius,
      height: 32,
    },
    treeContainer: {
      flex: 1,
      flexDirection: 'column',
    },
    tableContainer: {
      '&&': {
        flex: 3,
        padding: 0,
      },
    },
  }
})
