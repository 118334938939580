import { TreeItemIndex } from 'react-complex-tree'
import { TreeData } from './view.types'
import { TreeItem } from '@server/interfaces/view'

export const buildTree = (
  input: TreeData,
  rootId: string = 'root',
): TreeItem[] => {
  const transformItem = (itemId: TreeItemIndex): TreeItem | null => {
    const item = input[itemId]
    if (item.isFolder) {
      return {
        name: item.data.name,
        type: item.data.type,
        children: item.children.map(transformItem) as TreeItem[],
      }
    } else if (item.data.id) {
      return {
        id: item.data.id,
        type: item.data.type,
      }
    }
    return null
  }

  return input[rootId].children.map(transformItem).filter(Boolean) as TreeItem[]
}
