import { CreateModal } from '@components'
import { Form, message } from 'antd'
import useStyles from './CreateAssumptionModal.styles'
import { trpc } from '@services/trpc'
import CreateAssumptionForm from './CreateAssumptionForm'
import { FormValues } from './CreateAssumptionForm/CreateAssumptionForm'
import type { DataModel } from '@modules/datamodel'
import { useFilterParams } from '@hooks'
import { BulkFormListValues } from '@components/BulkFormList'
import { useTablePeriodContext } from '@context/TablePeriodContext'

type CreateAssumptionModalProps = {
  isOpen: boolean
  onClose: () => void
  datamodel: DataModel
}

const CreateAssumptionModal: React.FC<CreateAssumptionModalProps> = ({
  isOpen,
  onClose,
  datamodel,
}) => {
  const [form] = Form.useForm()
  const { styles } = useStyles()
  const { periods } = useTablePeriodContext()
  const { filters } = useFilterParams()

  const { refetch: refetchAssumptions } =
    trpc.api.transactions.tableQuery.useQuery({
      preset: 'newassumptions',
      options: { datamodelId: datamodel.id, filters, periods },
    })

  const handleSuccess = () => {
    message.success('Assumption created successfully')
    refetchAssumptions()
    form.resetFields()
    onClose()
  }

  const { mutate: save, isPending } =
    trpc.api.assumptions.createOrUpdate.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: BulkFormListValues<FormValues>) => {
    values.items.forEach((value) => {
      const { startDate, endDate, name } = value
      const startDateFormatted = startDate.startOf('month').format('YYYY-MM-DD')
      const endDateFormatted = endDate.endOf('month').format('YYYY-MM-DD')
      const stringifiedPeriod = `[${startDateFormatted},${endDateFormatted}]`
      const data = {
        period: stringifiedPeriod,
        name,
        description: '',
        memo: '',
        datatags: [null, null, null, null, null, null, null, null],
        datamodel_id: datamodel.id,
      }
      save(data)
    })
  }

  const handleCancel = () => {
    form.resetFields()
    onClose()
  }

  return (
    <CreateModal
      title={`Create '${datamodel.name}' assumption`}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      centered
      width="clamp(500px, 60%, 1000px)"
      confirmLoading={isPending}
      classNames={{
        content: styles.modalContent,
      }}
    >
      <div className={styles.formWrapper}>
        <CreateAssumptionForm form={form} onSubmit={handleSubmit} />
      </div>
    </CreateModal>
  )
}

export default CreateAssumptionModal
