import { useEffect, useRef, useState, useCallback } from 'react'
import useStyles from './EditableCell.styles'

export interface EditableCellProps {
  value: number | string
  onSave: (value: string) => void
  isEditable: boolean
  isChanged?: boolean
  onClick?: (e?: React.MouseEvent<HTMLInputElement>) => void
  isSelected?: boolean
}

const EditableCell: React.FC<EditableCellProps> = ({
  value,
  isEditable,
  isChanged = false,
  onSave,
  onClick,
  isSelected = false,
}) => {
  const { cx, styles } = useStyles()
  const initialValue = Number(value).toFixed(2)

  const [currentValue, setValue] = useState<string>(initialValue)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isEditing, setIsEditing] = useState(false)

  const isNegative = Number(value) < 0

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onBlur = useCallback(() => {
    if (initialValue !== currentValue) {
      setValue(Number(currentValue).toFixed(2))
      onSave(currentValue)
    }
    setIsEditing(false)
  }, [currentValue, initialValue, onSave])

  const handleEnterPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        inputRef.current?.blur()
      }
    },
    [],
  )

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      e.stopPropagation()
      onClick?.()
    },
    [onClick],
  )

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <input
      data-selected={isSelected}
      type="number"
      value={currentValue as string}
      className={cx(
        styles.base,
        isEditable ? styles.input : styles.display,
        isNegative && !isEditing && styles.negative,
        isChanged && !isEditing && styles.changed,
      )}
      onChange={handleChange}
      onFocus={() => isEditable && setIsEditing(true)}
      onBlur={onBlur}
      onKeyDown={handleEnterPress}
      onClick={handleClick}
      ref={inputRef}
      disabled={!isEditable}
      data-is-editing={isEditing}
    />
  )
}

export default EditableCell
