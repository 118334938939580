import { Navigate } from 'react-router-dom'
import LedgerPage from 'pages/financial/ledger'

const ledgerRoutes = [
  {
    index: true,
    element: <Navigate to="/financial/ledger/summary" replace />,
  },
  {
    path: 'summary',
    handle: { title: 'Summary' },
    Component: LedgerPage,
  },
  {
    path: 'transaction',
    handle: { title: 'Transaction' },
    Component: LedgerPage,
  },
]

export default ledgerRoutes
