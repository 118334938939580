import { SVGProps } from 'react'

const LogoSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={52}
    height={20}
    fill="none"
    {...props}
  >
    <path fill="#fff" d="M6.266 3.34h40.086v14.03H6.266z" />
    <path
      fill="#00B1E6"
      d="M6.669.005h38.074c2.895-.117 5.706 1.72 6.336 4.598v10.784c-.443 2.602-2.797 4.701-5.477 4.59-12.962.042-25.93.014-38.892.008-2.714.145-5.567-1.899-5.684-4.73-.132-3.486-.153-6.98.013-10.459C1.24 1.98 4.038.185 6.67.006Zm.83 7c-.775 2.748-.235 5.676-.373 8.5.754.013 1.516.02 2.278.027.083-2.59-.222-5.206.2-7.767.81-1.767 3.712-1.698 4.432.104.374 2.526.097 5.101.18 7.649.574-.007 1.73-.014 2.305-.014-.124-2.796.402-5.675-.373-8.402-1.268-3.68-7.27-3.714-8.648-.096ZM20.954 4.61c-3.788 1.332-4.972 6.78-2.043 9.548 2.562 2.906 7.817 1.678 9.084-1.905 1.96-4.191-2.624-9.424-7.041-7.643Zm14.505-.041c-.048 2.65.153 5.309-.152 7.946-.755 1.311-3.019 1.415-3.656-.049-.353-2.61-.055-5.26-.145-7.89-.582 0-1.738 0-2.32-.007.104 2.83-.304 5.716.257 8.498 1.031 2.982 4.936 3.39 7.18 1.526 2.312 1.995 6.48 1.353 7.318-1.823.381-2.713.083-5.467.166-8.194-.581 0-1.73 0-2.305-.007-.083 2.678.221 5.391-.187 8.05-.852 1.256-3.123 1.242-3.718-.229-.291-2.595-.077-5.212-.139-7.814-.768-.007-1.537-.007-2.299-.007Z"
    />
    <path
      fill="#00B1E6"
      d="M20.911 7.199c1.877-1.691 5.096.062 5.138 2.444.408 2.237-1.794 4.473-4.05 3.797-2.763-.56-3.393-4.757-1.088-6.241Z"
    />
  </svg>
)
export default LogoSmall
