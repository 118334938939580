import React from 'react'
import { CreateOrganization } from '@clerk/clerk-react'
import { Layout, Row } from 'antd'

const ORG_REDIRECT = import.meta.env.VITE_AFTER_CREATE_ORG_REDIRECT

export const CreateOrganizationPage: React.FC = () => {
  if (!ORG_REDIRECT) {
    console.error('ORG_REDIRECT was not providedd')
  }

  return (
    <Layout
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Row justify="center">
        <CreateOrganization
          skipInvitationScreen={true}
          afterCreateOrganizationUrl={ORG_REDIRECT}
        />
      </Row>
    </Layout>
  )
}
