import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    sectionLabel: {
      color: token.gray400,
      backgroundColor: token.gray100,
      padding: `${token.paddingXXS}px ${token.paddingXXS}px ${token.paddingXXS}px ${token.paddingXS}px `,
      borderTopLeftRadius: token.borderRadius,
      borderBottomLeftRadius: token.borderRadius,
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    sectionLabelContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    arrow: {
      color: token.gray100,
    },
  }
})

export default useStyles
