import { SelectedListItem, SelectedListHeaderItem } from './SelectedListItem'
import useStyles from './SelectedList.styles'
import { BaseItem, Schema } from '../index.types'
import { groupBy } from 'lodash'
import { useMemo } from 'react'
import { Accordion } from '@components'

export interface SelectedListProps<T> {
  items: T[]
  schema?: Schema
  onRemove?: (item: T) => void
  renderItem?: (item: T) => React.ReactNode
  renderHeader?: () => React.ReactNode
}

const SelectedList = <T extends BaseItem>({
  items,
  schema,
  onRemove,
  renderItem,
  renderHeader,
}: SelectedListProps<T>) => {
  const { styles } = useStyles()

  const groupedItems = useMemo(() => {
    return groupBy(items, 'itemGroup')
  }, [items])
  const groups = Object.keys(groupedItems)

  return (
    <ul className={styles.list}>
      <SelectedListHeaderItem schema={schema} renderHeader={renderHeader} />
      <div className={styles.listWrapper}>
        {groups.map((group) => (
          <Accordion key={group} label={group}>
            <div className={styles.groupWrapper}>
              {groupedItems[group].map((item) => (
                <SelectedListItem<T>
                  key={item.id}
                  item={item}
                  schema={schema}
                  onRemove={onRemove}
                  renderItem={renderItem}
                />
              ))}
            </div>
          </Accordion>
        ))}
      </div>
    </ul>
  )
}

export default SelectedList
