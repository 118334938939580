import { Form, FormInstance } from 'antd'
import useStyles from './CreateAccountForm.styles'
import { AccountInput } from '@modules/account'
import { BulkFormLayout, BulkFormList } from '@components'
import type { BulkFormListValues } from '@components/BulkFormList'
import AccountFormRow from './AccountFormRow'

type CreateAccountModalProps = {
  form: FormInstance
  onSubmit: (values: BulkFormListValues<AccountInput>) => void
}

const CreateAccountModal: React.FC<CreateAccountModalProps> = ({
  form,
  onSubmit,
}) => {
  const { styles } = useStyles()

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <BulkFormLayout>
        <BulkFormList wrapped rowClassName={styles.row}>
          {(index) => <AccountFormRow index={index} form={form} key={index} />}
        </BulkFormList>
      </BulkFormLayout>
    </Form>
  )
}

export default CreateAccountModal
