import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@clerk/clerk-react'
import { GlobalLoading } from '..'

const withAuth = (Component: React.FC) => {
  const AuthenticatedComponent: React.FC = (props) => {
    const { isSignedIn, isLoaded, orgId } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
      if (isLoaded) {
        if (!isSignedIn) {
          navigate('/welcome')
        }
        if (isSignedIn && !orgId) {
          navigate('/create-org')
        }
      }
    }, [isSignedIn, isLoaded, orgId, navigate])

    if (!isLoaded) {
      return <GlobalLoading />
    }

    return <Component {...props} />
  }

  return AuthenticatedComponent
}

export default withAuth
