import { SVGProps } from 'react'

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      d="M12.126 18a4.002 4.002 0 0 0 7.748 0M15.018 6.764l-6.783 5.275c-.453.353-.68.53-.843.75a2 2 0 0 0-.318.65C7 13.703 7 13.991 7 14.565V21.8c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C8.52 25 9.08 25 10.2 25h11.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C25 23.48 25 22.92 25 21.8v-7.235c0-.574 0-.861-.074-1.126a2.002 2.002 0 0 0-.318-.65c-.163-.22-.39-.397-.843-.75l-6.783-5.275c-.351-.273-.527-.41-.72-.462a1 1 0 0 0-.523 0c-.194.052-.37.189-.721.462Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
