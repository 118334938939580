import { CreateModal } from '@components'
import CreateCompanyForm from './CreateCompanyForm'
import { Form, message } from 'antd'
import useStyles from './CreateCompanyModal.styles'
import { trpc } from '@services/trpc'
import type { CompanyInput } from '@modules/company'
import type { BulkFormListValues } from '@components/BulkFormList'
import { CreateModalProps } from '@components/CreateModal'

const CreateCompanyModal: React.FC<CreateModalProps> = ({
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { styles } = useStyles()
  const { refetch: fetchCompanies } = trpc.api.companies.list.useQuery()

  const handleSuccess = () => {
    message.success('Company created successfully')
    fetchCompanies()
    form.resetFields()
    onClose?.()
  }

  const { mutate: saveCompany, isPending } =
    trpc.api.companies.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: BulkFormListValues<CompanyInput>) => {
    values.items.forEach((item) => {
      saveCompany(item)
    })
  }

  const handleCancel = () => {
    form.resetFields()
    onClose?.()
  }

  return (
    <CreateModal
      {...props}
      title="Create company"
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      width="unset"
      className={styles.modal}
      confirmLoading={isPending}
    >
      <div className={styles.formWrapper}>
        <CreateCompanyForm form={form} onSubmit={handleSubmit} />
      </div>
    </CreateModal>
  )
}

export default CreateCompanyModal
