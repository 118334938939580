import { createStyles } from 'antd-style'

export default createStyles(({ token }) => ({
  sidebarRoot: {
    display: 'flex',
    overflow: 'auto',
    borderRadius: token.borderRadiusLG,
    border: `1px ${token.colorBorder} solid`,
    backgroundColor: token.colorWhite,
    // width: 358,
  },
  sidebarMain: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'width 0.3s',
    width: 322,
    flex: 1,
    overflow: 'hidden',
  },
  sidebarCollapsed: {
    width: 0,
  },
  sidebarContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'auto',
    padding: token.paddingXS,
  },
}))
