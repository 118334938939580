export const TABS = [
  {
    key: 'columns',
    label: 'Computation',
  },
  {
    key: 'rows',
    label: 'Timing & Threshold',
  },
]

export const PAGE_TYPES = {
  ACCOUNT: 'account',
  METRIC: 'metric',
} as const

export type CustomPageType = (typeof PAGE_TYPES)[keyof typeof PAGE_TYPES]

export const ASSOCIATED_MODELS = {
  REVENUE_RECEIVABLE: 'revenue_receivable',
  REVENUE_DEFERRED: 'revenue_deferred',
} as const

export const ASSOCIATED_MODELS_LABELS = {
  [ASSOCIATED_MODELS.REVENUE_RECEIVABLE]: 'Revenue Receivable',
  [ASSOCIATED_MODELS.REVENUE_DEFERRED]: 'Revenue Deferred',
} as const

export const ASSOCIATED_MODELS_OPTIONS = [
  {
    value: ASSOCIATED_MODELS.REVENUE_RECEIVABLE,
    label: ASSOCIATED_MODELS_LABELS[ASSOCIATED_MODELS.REVENUE_RECEIVABLE],
  },
  {
    value: ASSOCIATED_MODELS.REVENUE_DEFERRED,
    label: ASSOCIATED_MODELS_LABELS[ASSOCIATED_MODELS.REVENUE_DEFERRED],
  },
]
