import { Row } from '@tanstack/react-table'
import LookupsTable from '../../LookupsTable'
import type { Lookup } from '@modules/lookup'
import { useDataModelPageContext } from '@modules/datamodel'

const LookupTableView: React.FC = () => {
  const { changeSelectedDatamodelId } = useDataModelPageContext()

  const handleRowClick = (row: Row<Lookup>) => {
    changeSelectedDatamodelId(row.original.id)
  }

  return <LookupsTable onRowClick={handleRowClick} />
}

export default LookupTableView
