import { useState } from 'react'
import useModalState from '@hooks/useModalState'
import { Row } from '@tanstack/react-table'
import {
  SelectedListWidget,
  ViewContainerLayout,
  ViewContentLayout,
  ViewFooterLayout,
  ViewTabsControl,
  ViewToolbar,
  ViewToolbarAddButton,
  ViewWithPropertiesLayout,
} from '@components'
import {
  DataModel,
  DataModelPropertiesDrawer,
  useDataModelPageContext,
} from '@modules/datamodel'
import { DataModelTable } from '@modules/datamodel'
import {
  SYSTEM_COLUMNS,
  TABS,
  getDataModelItemsSchema,
} from '../../../compoundModel.constants'
import {
  DatamodelRowItem,
  DatatagColumnItem,
} from '../../../compoundModel.types'
import { Button, Space, message } from 'antd'
import { trpc } from '@services/trpc'
import { groupBy } from 'lodash'
import {
  CompoundModelDatatagHeaderItem,
  CompoundModelDatatagListItem,
} from '../../CompoundModelDatatagListItem'
import { useDatamodelRowsContext } from '@modules/datamodel/DatamodelRowsContext'
import { useDatatagColumnsContext } from '@modules/datamodel/DatatagColumnsContext'
import { DatagroupValueColumnDefinition } from '@server/interfaces/datamodel'
import CompoundModelProperties from '../../CompoundModelProperties'
import CreateCompoundModelModal from '../../CreateCompoundModelModal'

const CompoundModelViewConfig: React.FC = () => {
  const [selectedId, setSelectedId] = useState<number | null>(null)
  const drawerState = useModalState()
  const { selectedDataModel, datamodelGroup } = useDataModelPageContext()
  const [currentTab, setCurrentTab] = useState<'columns' | 'rows'>('columns')

  const {
    selectedDatatagColumns,
    isDatatagColumnsDirty,
    setIsDatatagColumnsDirty,
    resetDatatagColumns,
  } = useDatatagColumnsContext<DatatagColumnItem>()

  const selectedDatatagColumnsWithSystem = [
    ...selectedDatatagColumns,
    ...SYSTEM_COLUMNS,
  ]

  const {
    selectedDatamodelAccountdriverItems,
    updateAccountDriverInputSource,
    removeDatamodelRowItem,
    resetDatamodelRows,
    isDatamodelRowsDirty,
    setIsDatamodelRowsDirty,
  } = useDatamodelRowsContext()

  const dataModelSchema = getDataModelItemsSchema({
    inputSource: updateAccountDriverInputSource,
  })
  const { refetch: fetchModels } = trpc.api.datamodel.listByType.useQuery({
    type: datamodelGroup,
  })
  const { data: allDataModels = [] } = trpc.api.datamodel.list.useQuery()

  const handleCancel = () => {
    resetDatatagColumns()
    resetDatamodelRows()
  }

  const handleSuccess = () => {
    message.success('Model updated successfully')
    fetchModels()
  }

  const { mutate: saveModel, isPending } =
    trpc.api.datamodel.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSave = () => {
    const groupedModelsAccountDrivers = groupBy(
      selectedDatamodelAccountdriverItems,
      'itemGroup',
    )
    const columns = selectedDatatagColumns.map((column) => ({
      type: 'datagroup',
      datagroup_id: column.id,
      values: column.members,
    })) as DatagroupValueColumnDefinition[]
    const updatedDataModel = {
      id: selectedDataModel!.id,
      config: {
        columns,
        models: Object.keys(groupedModelsAccountDrivers).map((group) => ({
          name: group,
          id: allDataModels.find((model) => model.name === group)?.id,
          rows: groupedModelsAccountDrivers[group].map((accountDriver) => ({
            id: accountDriver.id,
            input: accountDriver.properties.inputSource.value,
          })),
        })),
      },
    }
    saveModel(updatedDataModel)
    setIsDatatagColumnsDirty(false)
    setIsDatamodelRowsDirty(false)
  }

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab as 'columns' | 'rows')
  }

  const handleDrawerClose = () => {
    setSelectedId(null)
    drawerState.close()
  }

  const handleRowClick = (row: Row<DataModel>) => {
    setSelectedId(row.original.id)
    drawerState.open()
  }

  return (
    <ViewWithPropertiesLayout>
      <ViewContentLayout>
        <ViewToolbar
          addButton={
            <ViewToolbarAddButton createModal={CreateCompoundModelModal} />
          }
        />

        {selectedDataModel ? (
          <>
            <ViewTabsControl
              tabs={TABS}
              currentTab={currentTab}
              onTabChange={handleTabChange}
            />

            <ViewContainerLayout>
              {currentTab === 'columns' ? (
                <SelectedListWidget<DatatagColumnItem>
                  title="Model Composition"
                  items={selectedDatatagColumnsWithSystem}
                  renderItem={(item) => (
                    <CompoundModelDatatagListItem item={item} />
                  )}
                  renderHeader={() => <CompoundModelDatatagHeaderItem />}
                />
              ) : (
                <SelectedListWidget<DatamodelRowItem>
                  title="Model Composition"
                  items={selectedDatamodelAccountdriverItems}
                  schema={dataModelSchema}
                  onRemove={removeDatamodelRowItem}
                />
              )}
            </ViewContainerLayout>
          </>
        ) : (
          <ViewContainerLayout>
            <DataModelTable
              datamodelGroup={datamodelGroup}
              onRowClick={handleRowClick}
              selectedRows={selectedId ? { [selectedId]: true } : undefined}
            />
            <DataModelPropertiesDrawer
              selectedId={selectedId!}
              onClose={handleDrawerClose}
              isOpen={drawerState.isOpen}
            />
          </ViewContainerLayout>
        )}

        <ViewFooterLayout open={isDatatagColumnsDirty || isDatamodelRowsDirty}>
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave} type="primary" loading={isPending}>
              Save
            </Button>
          </Space>
        </ViewFooterLayout>
      </ViewContentLayout>

      <CompoundModelProperties values={selectedDataModel} />
    </ViewWithPropertiesLayout>
  )
}

export default CompoundModelViewConfig
