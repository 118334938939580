import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    header: {
      height: 50,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `0 ${token.paddingMD}px`,
      borderBottom: `1px solid ${token.colorBorder}`,
      boxShadow:
        '0px 1px 3px 0px rgba(16, 24, 40, 0.10), 0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
      zIndex: 10,
    },
    separator: {
      height: 28,
      width: 1,
      backgroundColor: token.gray300,
      margin: `0 16px`,
    },
    section: {
      display: 'flex',
      alignItems: 'center',
    },
    select: {
      '&&&': {
        width: '100%',
        color: 'inherit',

        '&.ant-select-disabled': {
          color: token.gray400,
        },

        '&.ant-select-open': {},

        '& .ant-select-selector': {
          color: 'inherit',
          '& .ant-select-selection-item': {
            paddingInlineEnd: 24,
          },
        },

        '& .ant-select-arrow': {
          color: 'inherit',
        },
      },
    },
    selectWrapper: {
      height: 36,
      minWidth: 140,

      display: 'flex',
      alignItems: 'center',
      border: `1px solid ${token.colorBorder}`,
      borderRadius: token.borderRadius,
      boxSizing: 'border-box',
      overflow: 'hidden',
    },

    user: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    userInfo: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      fontSize: 14,
    },
    userName: {
      fontWeight: 600,
    },
    userEmail: {
      fontSize: 12,
      color: token.blue700,
    },
    clearDatabaseButton: {
      border: `1px solid ${token.colorWarning}`,
      color: token.colorWarning,
      '&&&:hover': {
        backgroundColor: token.colorWarning,
        color: token.colorWhite,
      },
    },
  }
})
