import { Button, Popover, Tooltip } from 'antd'
import useStyles from './ViewControls.styles'
import { FolderPlus, List, SearchMd } from '@untitled-ui/icons-react'
import ViewFolderInput from '../ViewFolderInput'
import useModalState from '@hooks/useModalState'

interface ViewControlsProps {
  onAddFolder: (name: string) => void
  onAddSum: (name: string) => void
}

const ViewControls: React.FC<ViewControlsProps> = ({
  onAddFolder,
  onAddSum,
}) => {
  const { styles } = useStyles()
  const folderInputState = useModalState()
  const sumInputState = useModalState()

  return (
    <div className={styles.toolbar}>
      <Popover
        open={folderInputState.isOpen}
        color="white"
        content={
          <ViewFolderInput
            onCreate={onAddFolder}
            onCancel={folderInputState.close}
          />
        }
        destroyTooltipOnHide
      >
        <Tooltip title="Add folder">
          <Button
            className={styles.button}
            type="text"
            onClick={folderInputState.open}
            icon={<FolderPlus width={16} />}
          />
        </Tooltip>
      </Popover>
      <span className={styles.divider} />
      <Popover
        open={sumInputState.isOpen}
        color="white"
        content={
          <ViewFolderInput
            onCreate={onAddSum}
            onCancel={sumInputState.close}
            placeholder="New sum"
          />
        }
        destroyTooltipOnHide
      >
        <Tooltip title="Add sum">
          <Button
            className={styles.button}
            type="text"
            onClick={sumInputState.open}
            icon={'Σ'}
            style={{ fontWeight: 500 }}
          />
        </Tooltip>
      </Popover>
      <span className={styles.divider} />
      <Button
        className={styles.button}
        type="text"
        icon={<SearchMd width={16} />}
      />
      Search
      <span className={styles.divider} />
      <Button
        className={styles.button}
        type="text"
        icon={<List width={16} />}
      />
    </div>
  )
}

export default ViewControls
