import {
  CreateMenuButton,
  SidebarFilterRowLayout,
  SidebarFilterSelect,
  SidebarToolbarLayout,
} from '@components'
import useModalState from '@hooks/useModalState'
import CreateLookupModal from '@modules/lookup/components/CreateLookupModal'
import { MenuProps } from 'antd'
import { useMemo } from 'react'

const MENU_ITEM_KEYS = {
  LOOKUP: 'lookup',
} as const

const LookupSidebarDataModelsToolbar = () => {
  const lookupModalState = useModalState()
  const handleCreateItemClick = (key: string) => {
    if (key === MENU_ITEM_KEYS.LOOKUP) {
      lookupModalState.open()
    }
  }
  const menuItems = useMemo<MenuProps['items']>(() => {
    return [
      {
        key: MENU_ITEM_KEYS.LOOKUP,
        label: 'Create Lookup Table',
      },
    ]
  }, [])
  return (
    <>
      <SidebarToolbarLayout>
        <SidebarFilterRowLayout isDisabled>
          <SidebarFilterSelect
            label="Data Model Group"
            options={[{ label: 'Lookup table', value: 'lookup' }]}
            value={'lookup'}
            disabled
          />
          <CreateMenuButton items={menuItems} onClick={handleCreateItemClick} />
        </SidebarFilterRowLayout>
      </SidebarToolbarLayout>
      <CreateLookupModal
        open={lookupModalState.isOpen}
        onClose={lookupModalState.close}
      />
    </>
  )
}

export default LookupSidebarDataModelsToolbar
