import {
  ACCOUNT_CATEGORIES,
  ACCOUNT_CATEGORIES_LABELS,
} from './accountCategories'

export const PROFITS_AND_LOSS_ACCOUNT_TYPES = {
  revenue: 'Revenue',
  cost: 'Cost',
  expense: 'Expense',
} as const

export const BALANCE_SHEET_ACCOUNT_TYPES = {
  asset: 'Asset',
  liability: 'Liability',
  equity: 'Equity',
} as const

export const CASH_FLOW_ACCOUNT_TYPES = {
  operating: 'Operating',
  investing: 'Investing',
  financing: 'Financing',
  adjustment: 'Adjustment',
} as const

export const STATISTICAL_ACCOUNT_TYPES = {
  financial: 'Financial',
  general: 'General Lookups',
  inventoryKPI: 'Inventory KPIs',
  workforceKPI: 'Workforce KPIs',
  otherKPI: 'Other KPIs',
  // nonFinancial: 'Non-Financial',
  // salesRelated: 'Sales Related',
  // personelRelated: 'Personel',
} as const

export const ACCOUNT_TYPES = {
  [ACCOUNT_CATEGORIES.profitsAndLoss]: PROFITS_AND_LOSS_ACCOUNT_TYPES,
  [ACCOUNT_CATEGORIES.balanceSheet]: BALANCE_SHEET_ACCOUNT_TYPES,
  [ACCOUNT_CATEGORIES.cashFlow]: CASH_FLOW_ACCOUNT_TYPES,
  [ACCOUNT_CATEGORIES.statistical]: STATISTICAL_ACCOUNT_TYPES,
} as const

export const ALL_ACCOUNT_TYPES = {
  ...PROFITS_AND_LOSS_ACCOUNT_TYPES,
  ...BALANCE_SHEET_ACCOUNT_TYPES,
  ...CASH_FLOW_ACCOUNT_TYPES,
  ...STATISTICAL_ACCOUNT_TYPES,
} as const

export const ACCOUNT_TYPES_LABELS = {
  [PROFITS_AND_LOSS_ACCOUNT_TYPES.revenue]: 'Revenue',
  [PROFITS_AND_LOSS_ACCOUNT_TYPES.cost]: 'Cost',
  [PROFITS_AND_LOSS_ACCOUNT_TYPES.expense]: 'Expense',

  [BALANCE_SHEET_ACCOUNT_TYPES.asset]: 'Asset',
  [BALANCE_SHEET_ACCOUNT_TYPES.liability]: 'Liability',
  [BALANCE_SHEET_ACCOUNT_TYPES.equity]: 'Equity',

  [CASH_FLOW_ACCOUNT_TYPES.operating]: 'Operating',
  [CASH_FLOW_ACCOUNT_TYPES.investing]: 'Investing',
  [CASH_FLOW_ACCOUNT_TYPES.financing]: 'Financing',
  [CASH_FLOW_ACCOUNT_TYPES.adjustment]: 'Adjustment',

  [STATISTICAL_ACCOUNT_TYPES.financial]: 'Financial',
  [STATISTICAL_ACCOUNT_TYPES.general]: 'General Lookups',
  [STATISTICAL_ACCOUNT_TYPES.inventoryKPI]: 'Inventory KPIs',
  [STATISTICAL_ACCOUNT_TYPES.workforceKPI]: 'Workforce KPIs',
  [STATISTICAL_ACCOUNT_TYPES.otherKPI]: 'Other KPIs',
  // [STATISTICAL_ACCOUNT_TYPES.salesRelated]: 'Sales Related',
  // [STATISTICAL_ACCOUNT_TYPES.personelRelated]: 'Personel',
  // [STATISTICAL_ACCOUNT_TYPES.nonFinancial]: 'Non-Financial',
}

export const ACCOUNT_TYPES_OPTIONS = [
  { value: '', label: 'All' },
  ...Object.values(ACCOUNT_CATEGORIES).map((category) => ({
    options: Object.values(ACCOUNT_TYPES[category]).map((type) => ({
      value: type,
      label: ACCOUNT_TYPES_LABELS[type],
    })),
    label: ACCOUNT_CATEGORIES_LABELS[category],
  })),
]
export const ACCOUNT_TYPES_WITH_CATEGORIES_OPTIONS = [
  { value: '', label: 'All' },
  ...Object.values(ACCOUNT_CATEGORIES).flatMap((category) => [
    {
      value: category,
      label: ACCOUNT_CATEGORIES_LABELS[category],
      propertyType: 'category',
    },
    ...Object.values(ACCOUNT_TYPES[category]).map((type) => ({
      value: type,
      label: ACCOUNT_TYPES_LABELS[type],
      propertyType: 'type',
    })),
  ]),
]

export type AccountCategoryValues =
  (typeof ACCOUNT_CATEGORIES)[keyof typeof ACCOUNT_CATEGORIES]

export const getTypeOptionsByCategory = (category: AccountCategoryValues) => {
  if (!category) return []

  return Object.values(ACCOUNT_TYPES[category]).map((value) => ({
    value,
    label: ACCOUNT_TYPES_LABELS[value],
  }))
}

export enum AccountSource {
  LOOKUP = 'lookup',
  CUSTOM = 'custom',
  ACCOUNT = 'account',
  METRIC = 'metric',
}

export const ACCOUNT_SOURCE_LABELS = {
  [AccountSource.LOOKUP]: 'Lookup',
  [AccountSource.CUSTOM]: 'Custom',
  [AccountSource.ACCOUNT]: 'Account',
  [AccountSource.METRIC]: 'Metric',
}

export type AccountTypeValues =
  | (typeof PROFITS_AND_LOSS_ACCOUNT_TYPES)[keyof typeof PROFITS_AND_LOSS_ACCOUNT_TYPES]
  | (typeof BALANCE_SHEET_ACCOUNT_TYPES)[keyof typeof BALANCE_SHEET_ACCOUNT_TYPES]
  | (typeof CASH_FLOW_ACCOUNT_TYPES)[keyof typeof CASH_FLOW_ACCOUNT_TYPES]
  | (typeof STATISTICAL_ACCOUNT_TYPES)[keyof typeof STATISTICAL_ACCOUNT_TYPES]
