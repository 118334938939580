import { createStyles } from 'antd-style'

export default createStyles(({ token }) => ({
  listRoot: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: token.paddingXXS,
  },
}))
