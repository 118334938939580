import { Modal, ModalProps } from 'antd'
import useStyles from './CreateModal.styles'

export interface CreateModalProps extends ModalProps {
  onClose?: () => void
}

const CreateModal: React.FC<CreateModalProps> = ({ classNames, ...props }) => {
  const { styles, cx } = useStyles()

  return (
    <Modal
      classNames={{
        ...classNames,
        content: cx(styles.content, classNames?.content),
        body: cx(styles.body, classNames?.body),
      }}
      {...props}
    />
  )
}

export default CreateModal
