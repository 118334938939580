import useStyles from './CreateLookupForm.styles'
import { Form, FormInstance, Input, Select } from 'antd'
import {
  ACCOUNT_TYPES_LABELS,
  STATISTICAL_ACCOUNT_TYPES,
} from '@constants/accountTypes'
import { VALUE_TYPES, VALUE_TYPES_OPTIONS } from '@constants/valueTypes'
import { trpc } from '@services/trpc'
import { useEffect } from 'react'
import { LookupDataModelInput } from '@server/interfaces/lookup'

const { Option } = Select

type CreateLookupFormProps = {
  form: FormInstance
  onSubmit: (values: LookupDataModelInput) => void
}

const accountTypesOptions = Object.values(STATISTICAL_ACCOUNT_TYPES).map(
  (value) => ({
    value,
    label: ACCOUNT_TYPES_LABELS[value],
  }),
)

const initialFormValues = {
  value_type: VALUE_TYPES.unitAmount,
  calendar: 1,
}

const CreateLookupForm: React.FC<CreateLookupFormProps> = ({
  form,
  onSubmit,
}) => {
  const { styles } = useStyles()
  const { data: accountMetrics = [] } = trpc.api.metrics.list.useQuery()

  const selectedMetricId = Form.useWatch('metric_id', form)
  const name = Form.useWatch('name', form)
  const selectedMetric = accountMetrics.find(
    (metric) => metric.id === selectedMetricId,
  )

  const selectedValueType = Form.useWatch('value_type', form)
  const accountMetricsOptions = accountMetrics
    .filter((metric) => metric.value_type === selectedValueType)
    .map((metric) => ({
      value: metric.id,
      label: metric.name,
    }))
  const accountMetricOptionsWithDefault = [
    {
      value: '',
      label: selectedMetricId || !name ? 'Create new metric' : name,
    },
    ...accountMetricsOptions,
  ]

  useEffect(() => {
    if (selectedMetric) {
      form.setFieldsValue({
        name: selectedMetric.name,
        value_type: selectedMetric.value_type,
      })
    } else {
      form.setFieldsValue({ name: '', value_type: '' })
    }
  }, [form, selectedMetric])

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      className={styles.form}
      initialValues={initialFormValues}
    >
      <Form.Item<LookupDataModelInput>
        name="name"
        label="Name | ID"
        rules={[
          {
            required: true,
            message: 'Please input the lookup table name or ID!',
          },
        ]}
        className={styles.formItem}
      >
        <Input placeholder="Enter name or ID" disabled={!!selectedMetric} />
      </Form.Item>

      <Form.Item<LookupDataModelInput>
        id="lookup_value_type"
        name="value_type"
        label="Value Type"
        className={styles.formItem}
        rules={[
          {
            required: true,
            message: 'Please input the lookup table name or ID!',
          },
        ]}
      >
        <Select
          aria-label="Value Type"
          options={VALUE_TYPES_OPTIONS}
          popupMatchSelectWidth={false}
          disabled={!!selectedMetric}
        />
      </Form.Item>

      <Form.Item<LookupDataModelInput>
        name="metric_id"
        label="Account Metric"
        className={styles.formItem}
      >
        <Select
          aria-label="Account Metric"
          options={accountMetricOptionsWithDefault}
          popupMatchSelectWidth={false}
        />
      </Form.Item>

      <Form.Item<LookupDataModelInput>
        name="account_type"
        label="Account Type"
        className={styles.formItem}
      >
        <Select
          aria-label="Account Type"
          options={accountTypesOptions}
          popupMatchSelectWidth={false}
        />
      </Form.Item>

      <Form.Item<LookupDataModelInput>
        name="calendar"
        label="Calendar Basis"
        className={styles.formItem}
        initialValue={1}
      >
        <Select disabled aria-label="Calendar Basis">
          <Option value={1}>CY-Calendar Year</Option>
        </Select>
      </Form.Item>
    </Form>
  )
}

export default CreateLookupForm
