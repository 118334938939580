import React from 'react'
import { PageContentLayout } from '@components'
import { MetricSidebar, MetricView } from '@modules/metric'
import { ConfigPageProvider } from '@context/ConfigPageContext'

const MetricPage: React.FC = () => {
  return (
    <ConfigPageProvider>
      <PageContentLayout>
        <MetricSidebar />
        <MetricView />
      </PageContentLayout>
    </ConfigPageProvider>
  )
}
export default MetricPage
