import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import useStyles from './SelectedListWidget.styles'
import SelectedList from './SelectedList'
import { SelectedListProps } from './SelectedList/SelectedList'
import { BaseItem } from './index.types'

interface SelectedListWidgetProps<T> extends SelectedListProps<T> {
  title: string
}

const SelectedListWidget = <T extends BaseItem>({
  title,
  ...props
}: SelectedListWidgetProps<T>) => {
  const { styles } = useStyles()

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.searchBlock}>
          <Input
            prefix={<SearchOutlined />}
            className={styles.search}
            placeholder="Search"
            variant="borderless"
          />
        </div>
      </div>
      <SelectedList<T> {...props} />
    </div>
  )
}

export default SelectedListWidget
