import React from 'react'
import { Form, Input, message } from 'antd'
import { CreateModal, ModalFormLayout } from '@components'
import { trpc } from '@services/trpc'
import useStyles from './CreateDatagroupModal.styles.ts'
import { DatagroupInput } from '@modules/datagroup/datagroup.types.ts'
import { useNavigate } from 'react-router-dom'

export interface CreateDatagroupModalProps {
  isOpen: boolean
  onClose: () => void
  title?: string
  onSuccess?: (newDatagroupId: string) => void
  getSubmitValues?: (values: DatagroupInput) => DatagroupInput
}

const CreateDatagroupModal: React.FC<CreateDatagroupModalProps> = ({
  isOpen,
  onClose,
  title,
  onSuccess,
  getSubmitValues,
}) => {
  const { styles } = useStyles()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const { refetch: refetchDatagroups } =
    trpc.api.datatags.listDatagroupsByType.useQuery('datagroup')

  const handleSuccess = (newDatagroupId: string) => {
    if (onSuccess) {
      onSuccess(newDatagroupId)
    } else {
      message.success('Data group created successfully')
      refetchDatagroups()
      navigate(`/drivers/other/${newDatagroupId}`)
    }
    onClose()
    form.resetFields()
  }

  const { mutate: save } =
    trpc.api.datatags.createOrUpdateDatagroupById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = (values: DatagroupInput) => {
    const valuesToSubmit = getSubmitValues
      ? getSubmitValues(values)
      : ({
          ...values,
          type: 'datagroup',
        } as DatagroupInput)
    save(valuesToSubmit)
  }

  const handleCancel = () => {
    onClose()
    form.resetFields()
  }

  return (
    <CreateModal
      title={title || 'Create datagroup'}
      open={isOpen}
      onOk={form.submit}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Close"
      width="clamp(500px, 50vw, 1000px)"
      centered
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <ModalFormLayout className={styles.formLayout}>
          <Form.Item
            name="name"
            label="Name | ID"
            rules={[{ required: true }]}
            initialValue=""
          >
            <Input
              onChange={() =>
                form.setFieldsValue({ plural: form.getFieldValue('name') })
              }
            />
          </Form.Item>
          <Form.Item
            name="plural"
            label="Plural name"
            // rules={[{ required: true }]}
            initialValue=""
          >
            <Input />
          </Form.Item>
        </ModalFormLayout>
      </Form>
    </CreateModal>
  )
}

export default CreateDatagroupModal
