import { FC } from 'react'
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableWidget } from '@components'
import type { TableWidgetProps } from '@components/TableWidget'
import { PERIOD_CLASS_OPTIONS } from '@modules/calendar/calendar.constants'

type PeriodClassItem = {
  id: string
  name: string
}

const columnHelper = createColumnHelper<PeriodClassItem>()

// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<PeriodClassItem, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
]

interface PeriodClassesTableProps
  extends Partial<TableWidgetProps<PeriodClassItem>> {}

const PeriodClassesTable: FC<PeriodClassesTableProps> = ({ ...rest }) => {
  return (
    <TableWidget<PeriodClassItem>
      {...rest}
      data={PERIOD_CLASS_OPTIONS}
      columns={columns}
      withIndex
    />
  )
}

export default PeriodClassesTable
