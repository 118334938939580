import React, { useState } from 'react'
import { ViewTabsControl } from '@components'
import {
  ExportImportPageLayout,
  ExportTab,
  ImportTab,
} from '@modules/export-import'
import { createStyles } from 'antd-style'

const useStyles = createStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
})

const TABS = [
  {
    key: 'export',
    label: 'Export',
  },
  {
    key: 'import',
    label: 'Import',
  },
]

const ExportImportPage: React.FC = () => {
  const [currentTab, setCurrentTab] = useState(TABS[0].key)
  const { styles } = useStyles()

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab)
  }

  return (
    <ExportImportPageLayout>
      <div className={styles.container}>
        <ViewTabsControl
          tabs={TABS}
          currentTab={currentTab}
          onTabChange={handleTabChange}
        />

        {currentTab === 'export' && <ExportTab />}
        {currentTab === 'import' && <ImportTab />}
      </div>
    </ExportImportPageLayout>
  )
}
export default ExportImportPage
