import { Form, FormInstance, Input, Radio, Select } from 'antd'
import {
  ACCOUNT_CLASSES,
  ACCOUNT_CLASSES_OPTIONS,
} from '@constants/accountClasses'
import { useAccountFormOptions } from '@hooks'
import { trpc } from '@services/trpc'
import { AccountCategoryValues } from '@constants/accountTypes'
import type { Metric } from '@modules/metric'
import { useEffect } from 'react'

interface AccountFormRowProps {
  index: number
  form: FormInstance
  formName?: string
}

const AccountFormRow: React.FC<AccountFormRowProps> = ({
  index,
  form,
  formName = 'items',
}) => {
  const name = Form.useWatch([formName, index, 'name'], form)
  const selectedClass = Form.useWatch([formName, index, 'class'], form)
  const selectedCategory = Form.useWatch(
    [formName, index, 'category'],
    form,
  ) as AccountCategoryValues
  const selectedValueType = Form.useWatch([formName, index, 'value_type'], form)

  const selectedMetricId = Form.useWatch([formName, index, 'metric_id'], form)

  const isFinancial = selectedClass === ACCOUNT_CLASSES.financial

  const { categoryOptions, typeOptions, valueOptions } = useAccountFormOptions({
    form,
    formNamePath: [formName, index],
  })
  const { data: metrics = [] } = trpc.api.metrics.list.useQuery()
  const groupedMetrics = metrics
    .filter(
      (metric) =>
        metric.value_type === selectedValueType &&
        metric.category === selectedCategory,
    )
    .reduce((acc, metric) => {
      acc[metric.type] = acc[metric.type] || []
      acc[metric.type].push(metric)
      return acc
    }, {})
  const metricOptions = Object.entries(groupedMetrics).map(
    ([type, metrics]) => ({
      label: type,
      options: metrics.map((metric: Metric) => ({
        value: metric.id,
        label: metric.name,
      })),
    }),
  )
  const metricOptionsWithNewName = isFinancial
    ? metricOptions
    : [{ label: name, value: '' }, ...metricOptions]

  useEffect(() => {
    if (selectedMetricId && isFinancial) {
      const metric = metrics.find((m) => m.id === selectedMetricId)
      if (metric) {
        form.setFieldValue([formName, index, 'type'], metric.type)
      }
    }
  }, [selectedMetricId, metrics, form, formName, index, selectedCategory])

  useEffect(() => {
    if (!isFinancial) {
      form.setFieldValue([formName, index, 'metric_id'], '')
    }
  }, [selectedClass, form, formName, index, isFinancial])

  return (
    <>
      <Form.Item
        name={[index, 'name']}
        label="Name | ID"
        rules={[
          {
            required: true,
            message: 'Please input the account name or ID!',
          },
        ]}
      >
        <Input aria-label="name" placeholder="Enter name or ID" />
      </Form.Item>

      <Form.Item
        name={[index, 'class']}
        label="Account Class"
        rules={[
          {
            required: true,
            message: 'Please select the account class!',
          },
        ]}
        initialValue="financial"
      >
        <Radio.Group options={ACCOUNT_CLASSES_OPTIONS} />
      </Form.Item>

      <Form.Item
        name={[index, 'category']}
        label="Account Category"
        rules={[
          {
            required: true,
            message: 'Please select the account category!',
          },
        ]}
      >
        <Select
          aria-label="category"
          disabled={selectedClass === ACCOUNT_CLASSES.operational}
          placeholder="Select category"
          options={categoryOptions}
        />
      </Form.Item>

      <Form.Item
        name={[index, 'metric_id']}
        label="Account Metric"
        rules={[
          {
            required: isFinancial,
            message: 'Please select the account metric!',
          },
        ]}
      >
        <Select
          aria-label="metric"
          placeholder="Select metric"
          options={metricOptionsWithNewName}
        />
      </Form.Item>

      <Form.Item
        name={[index, 'value_type']}
        label="Value type"
        rules={[
          {
            required: true,
            message: 'Please select the value type!',
          },
        ]}
      >
        <Select
          aria-label="value type"
          disabled={isFinancial || !!selectedMetricId}
          placeholder="Select value type"
          options={valueOptions}
        />
      </Form.Item>

      <Form.Item name={[index, 'type']} label="Account Type">
        <Select
          aria-label="account type"
          placeholder="Select type"
          options={typeOptions}
          disabled={isFinancial || !!selectedMetricId}
        />
      </Form.Item>
    </>
  )
}

export default AccountFormRow
