import { Button, ButtonProps } from 'antd'
import { CalendarMinus01 } from '@untitled-ui/icons-react'
import { useModalState } from '@hooks'
import TimeMetricModal from './TimeMetricModal'
import { TimeMetricConfig } from '@hooks/useTimeMetricConfig'

interface TimeButtonProps extends ButtonProps {
  timeMetricConfig: TimeMetricConfig
  onConfirm: (timeMetricConfig: TimeMetricConfig) => void
}

const TimeButton: React.FC<TimeButtonProps> = ({
  timeMetricConfig,
  onConfirm,
  ...props
}) => {
  const modalState = useModalState()
  return (
    <>
      <Button type="link" onClick={modalState.open} {...props}>
        <CalendarMinus01 width={16} />
        Time
      </Button>
      <TimeMetricModal
        open={modalState.isOpen}
        onClose={modalState.close}
        onConfirm={onConfirm}
        timeMetricConfig={timeMetricConfig}
      />
    </>
  )
}

export default TimeButton
