import { Form, FormInstance, Input, Select } from 'antd'
import useStyles from './CreateCustomComputedForm.styles'
import {
  ACCOUNT_CATEGORIES,
  ACCOUNT_CATEGORY_OPTIONS,
} from '@constants/accountCategories'
import { VALUE_TYPES } from '@constants/valueTypes'
import { ModalFormLayout, ViewContainerLayout } from '@components/index'
import OperandConstructor, { type Operand } from '../../OperandConstructor'
import { useAccountFormOptions } from '@hooks'
import { trpc } from '@services/trpc'
import { getTypeOptionsByCategory } from '@constants/accountTypes'
import { useEffect } from 'react'
import { MetricClass } from '@server/interfaces/base'
import { Metric } from '@modules/metric'
import { ASSOCIATED_MODELS_OPTIONS } from '@modules/customComputed/customComputed.constants'

export type ComputedDataModelFormValues = {
  name: string
  category: string
  class: MetricClass
  type: string
  metric_id: string
  value_type: string
  associated_model?: string
  operands: Operand[]
}

type CreateCustomComputedModelFormProps = {
  form: FormInstance
  onSubmit: (values: ComputedDataModelFormValues) => void
  type: 'account' | 'metric'
}

const CreateCustomComputedModelForm: React.FC<
  CreateCustomComputedModelFormProps
> = ({ form, onSubmit, type }) => {
  const { styles } = useStyles()

  const isMetric = type === 'metric'

  const modelName = Form.useWatch('name', form)
  const selectedCategory = Form.useWatch('category', form)
  const selectedMetricId = Form.useWatch('metric_id', form)

  const isStatistical = selectedCategory === ACCOUNT_CATEGORIES.statistical
  const hasDifferentMetric = selectedMetricId !== ''

  const { valueOptions } = useAccountFormOptions({ form })

  const { data: metrics = [] as Metric[] } = trpc.api.metrics.list.useQuery()

  const selectedMetric = metrics.find(
    (metric) => metric.id === selectedMetricId,
  )

  const statisticalAccountTypeOptions = getTypeOptionsByCategory(
    ACCOUNT_CATEGORIES.statistical,
  )

  const metricOptions = metrics
    .filter((metric) => metric.category === selectedCategory)
    .map((metric) => ({
      value: metric.id,
      label: metric.name,
    }))
  const metricOptionsWithModelName = [
    ...(isStatistical ? [{ label: modelName, value: '' }] : []),
    ...metricOptions,
  ]

  useEffect(() => {
    if (isStatistical) {
      form.setFieldValue('value_type', '')
      form.setFieldValue('metric_id', '')
    }
  }, [isStatistical, modelName, form])

  useEffect(() => {
    form.setFieldValue('type', selectedMetric ? selectedMetric.type : '')
    form.setFieldValue(
      'value_type',
      selectedMetric ? selectedMetric.value_type : '',
    )
  }, [selectedMetric, form])

  useEffect(() => {
    if (isStatistical && hasDifferentMetric) {
      form.setFieldValue('value_type', VALUE_TYPES.monetary)
    }
  }, [isStatistical, hasDifferentMetric, form])

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <ViewContainerLayout vertical>
        <ModalFormLayout className={styles.form}>
          <Form.Item<ComputedDataModelFormValues>
            name="name"
            label="Name | ID"
            rules={[
              {
                required: true,
                message: 'Please input the model name or ID!',
              },
            ]}
          >
            <Input placeholder="Enter name or ID" />
          </Form.Item>

          <Form.Item<ComputedDataModelFormValues>
            label="Account Category"
            name="category"
            initialValue={isMetric ? ACCOUNT_CATEGORIES.statistical : ''}
          >
            <Select
              placeholder="Select account category"
              aria-label="Account Category"
              options={ACCOUNT_CATEGORY_OPTIONS}
              disabled={isMetric}
            />
          </Form.Item>

          <Form.Item<ComputedDataModelFormValues>
            name="metric_id"
            label="Account Metric"
            rules={
              !isStatistical
                ? [
                    {
                      required: true,
                      message: 'Please select the account metric',
                    },
                  ]
                : undefined
            }
          >
            <Select
              placeholder="Select account metric"
              aria-label="Account Metric"
              // disabled={isMetric && !selectedValueType}
              options={metricOptionsWithModelName}
            />
          </Form.Item>

          {isStatistical ? (
            <Form.Item<ComputedDataModelFormValues>
              name="value_type"
              label="Value type"
              rules={[
                {
                  required: true,
                  message: 'Please select the value type',
                },
              ]}
            >
              <Select
                placeholder="Select value type"
                aria-label="Value Type"
                options={valueOptions}
                disabled={hasDifferentMetric}
              />
            </Form.Item>
          ) : (
            <Form.Item<ComputedDataModelFormValues>
              name="associated_model"
              label="Payment Term"
              rules={[
                {
                  required: true,
                  message: 'Please select the payment term',
                },
              ]}
            >
              <Select
                placeholder="Select payment term"
                aria-label="Payment Term"
                options={ASSOCIATED_MODELS_OPTIONS}
              />
            </Form.Item>
          )}

          <Form.Item<ComputedDataModelFormValues>
            name="type"
            label="Account Type"
            rules={[
              {
                required: true,
                message: 'Please select the account metric',
              },
            ]}
          >
            <Select
              placeholder="Select account type"
              aria-label="Account Type"
              options={
                !hasDifferentMetric ? statisticalAccountTypeOptions : undefined
              }
              disabled={hasDifferentMetric}
            />
          </Form.Item>
        </ModalFormLayout>

        <OperandConstructor />
      </ViewContainerLayout>
    </Form>
  )
}

export default CreateCustomComputedModelForm
