import useStyles from './ViewContainerLayout.styles'

interface ViewContentLayoutProps {
  children: React.ReactNode
  vertical?: boolean
}

const ViewContainerLayout: React.FC<ViewContentLayoutProps> = ({
  children,
  vertical = false,
}) => {
  const { styles, cx } = useStyles()

  return (
    <div className={cx(styles.viewContainer, vertical && styles.vertical)}>
      {children}
    </div>
  )
}

export default ViewContainerLayout
