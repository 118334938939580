import { FC, useEffect, useMemo } from 'react'
import { Checkbox, DatePicker, Form, Input, Radio, Select, message } from 'antd'
import { trpc } from '@services/trpc'
import { PropertiesAccordion, PropertiesDrawer } from '@components'
import type { Version, VersionFormValues } from '@modules/version/version.types'
import {
  DETAIL_LEVEL_OPTIONS,
  CATEGORY_OPTIONS,
} from '@modules/version/version.constants'
import dayjs from 'dayjs'

type VersionPropertiesProps = {
  values: Version | null
  onClose: () => void
  onSave: () => void
}

const VersionProperties: FC<VersionPropertiesProps> = ({
  values,
  onClose,
  onSave,
}) => {
  const [form] = Form.useForm()
  const category = Form.useWatch('category', form)
  const isLedger = category === 'ledger'

  const initialValues = useMemo(() => {
    if (!values) return null
    return {
      ...values,
      description: values.description ?? '',
      memo: values.memo ?? '',
      inactive: values.inactive ?? false,
      locked: values.locked ?? false,
      start_date: values.start_date ? dayjs(values.start_date) : null,
      end_date: values.end_date ? dayjs(values.end_date) : null,
    }
  }, [values])

  const handleSuccess = () => {
    message.success('Version updated successfully')
    onSave()
    form.resetFields()
    onClose()
  }

  const { mutate: save, isPending } =
    trpc.api.versions.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = (formValues: VersionFormValues) => {
    save({
      id: values!.id,
      ...formValues,
      memo: formValues.memo ?? '',
      start_date: formValues.start_date.format('YYYY-MM-DD'),
      end_date: formValues.end_date.format('YYYY-MM-DD'),
    })
  }

  useEffect(() => {
    if (!initialValues) return
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <PropertiesDrawer<VersionFormValues>
      onSubmit={handleSubmit}
      form={form}
      initialValues={initialValues as VersionFormValues}
      confirmLoading={isPending}
    >
      <PropertiesAccordion label="Version">
        <Form.Item
          name="name"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input the account name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input placeholder="Enter description" />
        </Form.Item>

        <Form.Item name="memo" label="Memo">
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder="Enter memo"
          />
        </Form.Item>

        <Form.Item name="category" label="Version category">
          <Radio.Group options={CATEGORY_OPTIONS} />
        </Form.Item>

        {isLedger && (
          <Form.Item name="detail_level" label="Detail level">
            <Select options={DETAIL_LEVEL_OPTIONS} aria-label="Detail level" />
          </Form.Item>
        )}

        <Form.Item name="start_date" label="Start of version">
          <DatePicker aria-label="Start of version" />
        </Form.Item>

        <Form.Item name="end_date" label="End of version">
          <DatePicker aria-label="End of version" />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="Other Settings">
        <Form.Item valuePropName="checked" name="locked" aria-label="Locked">
          <Checkbox>Locked</Checkbox>
        </Form.Item>
        <Form.Item
          valuePropName="checked"
          name="inactive"
          aria-label="Inactive"
        >
          <Checkbox>Inactive</Checkbox>
        </Form.Item>
      </PropertiesAccordion>
    </PropertiesDrawer>
  )
}

export default VersionProperties
