import React from 'react'
import { PageContentLayout } from '@components'
import { DataModelPageProvider, DatamodelGroup } from '@modules/datamodel'
import {
  CustomComputedView,
  CustomComputedSidebar,
} from '@modules/customComputed'
import { CustomPageType } from '@modules/customComputed/customComputed.constants'
import { ConfigPageProvider } from '@context/ConfigPageContext'

interface CustomPageProps {
  type: CustomPageType
  title: string
}

const CustomPage: React.FC<CustomPageProps> = ({ type, title }) => {
  return (
    <ConfigPageProvider>
      <DataModelPageProvider
        title={title}
        datamodelGroup={DatamodelGroup.COMPUTED}
      >
        <PageContentLayout>
          <CustomComputedSidebar type={type} />
          <CustomComputedView type={type} />
        </PageContentLayout>
      </DataModelPageProvider>
    </ConfigPageProvider>
  )
}
export default CustomPage
