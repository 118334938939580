import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    optionType: {
      color: token.gray400,
    },
    driverSelect: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: token.paddingXXS,
    },
    suffix: {
      display: 'flex',
      alignItems: 'center',
    },
    suffixIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 24,
      height: 24,
    },
    suffixDivider: {
      backgroundColor: token.gray400,
      width: 1,
      height: 10,
    },
    suffixButton: {
      '&&': {
        width: 24,
        height: 24,
        padding: 0,
      },
    },
  }
})
