import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: token.margin,
      padding: `22px 32px`,
    },
    main: {
      display: 'flex',
    },
    side: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      '&:not(:last-child)': {
        marginRight: token.marginSM,
        paddingRight: token.marginSM,
        borderRight: `1px solid ${token.colorBorder}`,
      },
    },
    separator: {
      margin: `${token.margin}px 0`,
      height: 1,
      backgroundColor: token.colorBorder,
      width: '100%',
    },
    block: {
      display: 'flex',
      flexDirection: 'column',
      gap: token.margin,
      padding: token.padding,
      backgroundColor: token.gray50,
      borderRadius: token.borderRadius,
    },
    columns: {
      display: 'flex',
      flexDirection: 'column',
      gap: token.marginSM,
    },
    columnsTitle: {
      backgroundColor: token.blue50,
      color: token.blue700,
      padding: `${token.paddingXXS}px ${token.paddingXS}px`,
      borderRadius: token.borderRadiusSM,
    },
  }
})
