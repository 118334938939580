export const mapSelectedRowIdsToTableSelectionState = (
  ids: Array<number | string>,
) => {
  return ids.reduce(
    (acc, memberId) => {
      acc[memberId] = true
      return acc
    },
    {} as Record<string, boolean>,
  )
}
