import { Button, Dropdown, MenuProps } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import useStyles from './CreateMenuButton.styles'

interface CreateMenuButtonProps {
  onClick?: (key: string) => void
  items?: MenuProps['items']
  disabled?: boolean
}

const CreateMenuButton: React.FC<CreateMenuButtonProps> = ({
  onClick,
  items,
  disabled = false,
}) => {
  const { styles } = useStyles()

  const handleClick = ({ key }: { key: string }) => {
    onClick?.(key)
  }

  return (
    <>
      <Dropdown
        menu={{ items, onClick: handleClick }}
        placement="bottomRight"
        trigger={['click']}
        disabled={disabled}
      >
        <Button type="text" className={styles.button}>
          <PlusOutlined size={16} />
        </Button>
      </Dropdown>
    </>
  )
}

export default CreateMenuButton
