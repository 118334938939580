import { useConfigurationMode } from '@context/ConfigurationContext'
import { DatamodelRowsProvider } from '@modules/datamodel/DatamodelRowsContext'
import { DatatagColumnsProvider } from '@modules/datamodel/DatatagColumnsContext'
import LookupConfigView from './LookupConfigView'
import LookupNormalView from './LookupNormalView'
import { useDataModelPageContext } from '@modules/datamodel'

const LookupView: React.FC = () => {
  const { isConfigurationMode } = useConfigurationMode()
  const { selectedDataModel } = useDataModelPageContext()

  if (isConfigurationMode) {
    return (
      <DatatagColumnsProvider selectedDataModel={selectedDataModel}>
        <DatamodelRowsProvider selectedDataModel={selectedDataModel}>
          <LookupConfigView />
        </DatamodelRowsProvider>
      </DatatagColumnsProvider>
    )
  }

  return <LookupNormalView />
}

export default LookupView
