import { createContext, useContext, useState, ReactNode } from 'react'
import type { Version } from '@modules/version'
import { VersionCategory } from '@server/interfaces/versions'

interface VersionPageContextType {
  selectedVersion: Version | null
  setSelectedVersion: (version: Version | null) => void
  selectedType: VersionCategory | null
  setSelectedType: (type: VersionCategory | null) => void
}

const VersionPageContext = createContext<VersionPageContextType | undefined>(
  undefined,
)

const useVersionPageContext = () => {
  const context = useContext(VersionPageContext)
  if (context === undefined) {
    throw new Error(
      'useVersionPageContext must be used within a VersionPageProvider',
    )
  }
  return context
}

const VersionPageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedVersion, setSelectedVersion] = useState<Version | null>(null)
  const [selectedType, setSelectedType] = useState<VersionCategory | null>(null)

  return (
    <VersionPageContext.Provider
      value={{
        selectedVersion,
        setSelectedVersion,
        selectedType,
        setSelectedType,
      }}
    >
      {children}
    </VersionPageContext.Provider>
  )
}

export { VersionPageProvider, useVersionPageContext }
