import React from 'react'
import { PageContentLayout } from '@components'
import { LookupSidebar, LookupView } from '@modules/lookup'
import { DataModelPageProvider, DatamodelGroup } from '@modules/datamodel'

const LookupPage: React.FC = () => {
  return (
    <DataModelPageProvider
      title="Lookup"
      datamodelGroup={DatamodelGroup.LOOKUP}
    >
      <PageContentLayout>
        <LookupSidebar />
        <LookupView />
      </PageContentLayout>
    </DataModelPageProvider>
  )
}
export default LookupPage
