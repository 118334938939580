import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      overflow: 'scroll',
      position: 'relative',
      flex: 1,
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,

      thead: {
        backgroundColor: token.colorWhite,
        position: 'sticky',
        top: 0,
        zIndex: 1,
      },

      'th, td': {
        boxSizing: 'border-box',
        borderBottom: '1px solid',
        borderRight: '1px solid',
        borderColor: token.colorBorder,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',

        padding: `${token.paddingXS}px ${token.paddingSM}px`,
        textWrap: 'nowrap',

        ':has(> [data-disabled])': {
          backgroundColor: token.gray100,
        },

        ':has(> [data-selected="true"])': {
          border: `1px solid ${token.blue700}`,
        },

        '&:first-child': {
          borderLeft: '1px solid',
          borderColor: token.colorBorder,
        },

        '&[colspan="1"]': {
          textAlign: 'left',
        },

        ':has(input)': {
          padding: 0,
        },

        '> input': {
          padding: `${token.paddingXS}px ${token.paddingSM}px`,
          maxWidth: 90,
          fontSize: token.fontSize,
        },

        '&:has(input:focus)': {
          backgroundColor: token.blue100,
        },
      },

      tr: {
        '&:first-child': {
          th: {
            borderTop: '1px solid',
            borderColor: token.colorBorder,
          },
        },
      },

      th: {
        fontWeight: 600,
      },
    },
    virtualizedTable: {
      tableLayout: 'fixed',
      width: 'unset',
      tr: {
        display: 'flex',
        width: '100%',
      },
    },
    selected: {
      backgroundColor: token.blue50,
      color: token.blue700,
    },
    clickable: {
      cursor: 'pointer',
    },
    marked: {
      '&&': {
        backgroundColor: token.gray200,
        'td:first-child': {
          borderLeft: `4px solid ${token.blue700}`,
          paddingLeft: 8,
        },
      },
    },
  }
})
