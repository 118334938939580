import { Sidebar } from '@components'
import useModalState from '@hooks/useModalState'
import { useState } from 'react'
import { DataModelSidebarToolbarConfig } from '../DataModelSidebarToolbar'
import DataModelsTab from '../DataModelsTab'
import { DatamodelGroup } from '@modules/datamodel/datamodel.types'
import { CreateModalProps } from '@components/CreateModal'
import { ViewsSidebarTab } from '@modules/view'
import { Tab } from '@context/ConfigPageContext'

const sidebarTabs = ['Data Models', 'Views'] as Tab[]

export interface DataModelSidebarConfigProps {
  dataModelGroup: DatamodelGroup
  dataModelGroupName: string
  createModalComponent?: React.FC<CreateModalProps>
}

const DataModelSidebarConfig: React.FC<DataModelSidebarConfigProps> = ({
  dataModelGroup,
  dataModelGroupName,
  createModalComponent: CreateModal,
}) => {
  const [selectedTab, setSelectedTab] = useState(sidebarTabs[0])

  const modelModalState = useModalState()
  const viewModalState = useModalState()

  const handleCreateViewClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    viewModalState.open()
  }

  return (
    <>
      <Sidebar
        title={dataModelGroupName}
        tabs={sidebarTabs}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
      >
        <DataModelSidebarToolbarConfig
          onNewModelClick={modelModalState.open}
          onNewViewClick={viewModalState.open}
          dataModelGroupName={dataModelGroupName}
        />
        {selectedTab === sidebarTabs[0] && (
          <DataModelsTab
            onNewClick={modelModalState.open}
            dataModelGroup={dataModelGroup}
          />
        )}
        {selectedTab === sidebarTabs[1] && (
          <ViewsSidebarTab onNewClick={handleCreateViewClick} />
        )}
      </Sidebar>

      {CreateModal && (
        <CreateModal
          open={modelModalState.isOpen}
          onClose={modelModalState.close}
        />
      )}

      {/* <CreateViewModal
        open={viewModalState.isOpen}
        onClose={viewModalState.close}
      /> */}
    </>
  )
}

export default DataModelSidebarConfig
