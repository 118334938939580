import { CreateModal } from '@components'
import CreateVersionForm from './CreateVersionForm'
import { Form, message } from 'antd'
import useStyles from './CreateVersionModal.styles'
import { trpc } from '@services/trpc'
import { VersionFormValues } from '@modules/version/version.types'
import { CreateModalProps } from '@components/CreateModal'

const CreateVersionModal: React.FC<CreateModalProps> = ({
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { styles } = useStyles()

  const { refetch: fetchVersions } = trpc.api.versions.list.useQuery()

  const handleSuccess = () => {
    message.success('Version created successfully')
    fetchVersions()
    form.resetFields()
    onClose?.()
  }

  const { mutate: saveVersion, isPending } =
    trpc.api.versions.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: VersionFormValues) => {
    const valuesToSubmit = {
      ...values,
      start_date: values.start_date.format('YYYY-MM-DD'),
      end_date: values.end_date.format('YYYY-MM-DD'),
      detail_level: values.detail_level,
    }
    saveVersion(valuesToSubmit)
  }

  const handleCancel = () => {
    form.resetFields()
    onClose?.()
  }

  return (
    <CreateModal
      {...props}
      title="Create version"
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      width="clamp(500px, 60vw, 1000px)"
      className={styles.modal}
      confirmLoading={isPending}
    >
      <div className={styles.formWrapper}>
        <CreateVersionForm form={form} onSubmit={handleSubmit} />
      </div>
    </CreateModal>
  )
}

export default CreateVersionModal
