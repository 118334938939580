import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      height: 46,
      padding: `0 ${token.paddingContentHorizontal}px`,
      borderTop: `1px solid ${token.gray300}`,
    },
    filter: {
      display: 'flex',
      alignItems: 'center',
      fontSize: token.fontSize,
      fontWeight: token.fontWeightStrong,
      padding: 0,
    },
    select: {
      '& .ant-select-selection-item': {
        display: 'flex',
        alignItems: 'center',
        color: token.blue600,
        fontSize: token.fontSize,
        fontWeight: token.fontWeightStrong,
      },
    },
  }
})
