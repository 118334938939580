import { Form, Tooltip } from 'antd'
import {
  AccountDriverSearch,
  SidebarFilterRowLayout,
  SidebarFilterSelect,
} from '@components'
import useStyles from './OperandConstructorRow.styles'
import { ComputedModelOperand } from '@server/interfaces/computed'
import { useEffect } from 'react'

export type OperandFormValues = {
  operands: ComputedModelOperand[]
}

const nextOperationOptions = [
  { label: 'Multiplied by', value: '*' },
  { label: 'Divided by', value: '/' },
  { label: 'Plus', value: '+' },
  { label: 'Minus', value: '-' },
]

const timeOptions = [
  { label: 'Activity', value: 'activity' },
  { label: 'Balance', value: 'balance' },
]

const driverTypeOptions = [
  { label: 'All', value: '' },
  { label: 'Account | Input', value: 'input' },
  { label: 'Custom account', value: 'computed' },
  { label: 'Metric', value: 'metric' },
  {
    label: 'Custom metric (coming soon)',
    value: 'custom_metric',
    disabled: true,
  },
  { label: 'Lookup', value: 'lookup' },
  { label: 'Tiered', value: 'tiered', disabled: true },
]

interface OperandConstructorRowProps {
  index: number
  isOperationDisabled?: boolean
}

const OperandConstructorRow: React.FC<OperandConstructorRowProps> = ({
  index,
  isOperationDisabled,
}) => {
  const { styles } = useStyles()
  const form = Form.useFormInstance()

  const selectedClass = Form.useWatch('class', form)
  const isFinancial = selectedClass === 'financial'
  const selectedType = Form.useWatch(['operands', index, 'type'], form)

  const handleDriverChange = (driverType: string) => {
    form.setFieldValue(['operands', index, 'type'], driverType)
  }

  const selectedId = Form.useWatch(['operands', index, 'id'], form)
  const [type] = selectedId?.split('_') || []

  const isNotMetric = type && type !== 'metric'

  useEffect(() => {
    if (selectedType && selectedType !== type) {
      form.setFieldValue(['operands', index, 'id'], '')
    }
  }, [form, index, selectedType, type])

  useEffect(() => {
    if (isNotMetric) {
      form.setFieldValue(['operands', index, 'time'], 'activity')
    }
  }, [form, index, isNotMetric])

  useEffect(() => {
    if (isFinancial) {
      form.setFieldValue(['operands', index, 'type'], 'metric')
    }
  }, [form, index, isFinancial])

  return (
    <div className={styles.operandRow}>
      <div className={styles.accountDriverRow}>
        <SidebarFilterRowLayout className={styles.accountDriverItem}>
          <Form.Item name={[index, 'type']} initialValue="">
            <SidebarFilterSelect
              label="Driver type"
              options={driverTypeOptions}
              disabled={isFinancial}
              popupMatchSelectWidth={false}
            />
          </Form.Item>
          <Form.Item
            name={[index, 'id']}
            initialValue=""
            rules={[
              {
                required: true,
                message: 'Please select account driver',
              },
            ]}
            className={styles.accountDriverItem}
          >
            <AccountDriverSearch
              onDriverChange={handleDriverChange}
              type={selectedType}
            />
          </Form.Item>
        </SidebarFilterRowLayout>
        <SidebarFilterRowLayout className={styles.timeValueItem}>
          <Form.Item
            name={[index, 'time']}
            initialValue=""
            rules={[{ required: true, message: 'Please select time value' }]}
            className={styles.timeValueItem}
          >
            <SidebarFilterSelect
              label="Time metric"
              placeholder="Select time metric"
              options={timeOptions}
              disabled={isNotMetric}
            />
          </Form.Item>
        </SidebarFilterRowLayout>
      </div>
      <SidebarFilterRowLayout>
        <Tooltip
          title={isOperationDisabled ? 'Please add another operand' : undefined}
        >
          <Form.Item
            name={[index, 'nextOperation']}
            initialValue=""
            rules={
              isOperationDisabled
                ? undefined
                : [
                    {
                      required: true,
                      message: 'Please select operation',
                    },
                  ]
            }
          >
            <SidebarFilterSelect
              label="Operation"
              placeholder="Select operation"
              options={nextOperationOptions}
              disabled={isOperationDisabled}
            />
          </Form.Item>
        </Tooltip>
      </SidebarFilterRowLayout>
    </div>
  )
}

export default OperandConstructorRow
