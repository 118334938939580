import {
  IconButton,
  SidebarListItem,
  SidebarListItemPrimary,
} from '@components'
import {
  Datatag,
  ViewMetricsCategoryItem,
  type Datagroup,
} from '@modules/datagroup'
import { trpc } from '@services/trpc'
import { useModalState } from '@hooks'
import {
  FilePlus01,
  LayersThree01,
  LayersTwo01,
  Settings03,
} from '@untitled-ui/icons-react'
import { SidebarActionsLayout } from '@components'
import CreateDatagroupMemberModal from '@modules/datagroup/components/CreateDatagroupMemberModal'
import useStyles from './DatagroupSidebarTab.styles'
import { useState } from 'react'
import { capitalize } from '@utils/strings'
import { useConfigPageContext } from '@context/ConfigPageContext'
import { useDatagroupPageContext } from '@modules/datagroup/components/DatagroupPageContext'
import { skipToken } from '@tanstack/react-query'
import { VIEW_METRICS_CATEGORY_ITEM } from '../../../datagroup.constants'

const DatagroupSidebarTab: React.FC = () => {
  const { styles } = useStyles()
  const datagroupMemberModalState = useModalState()
  const { datagroup } = useDatagroupPageContext()
  const { selectedCategory, setSelectedCategory } = useConfigPageContext<
    Datatag,
    Datagroup | ViewMetricsCategoryItem
  >()

  const [createMemberParent, setCreateMemberParent] =
    useState<Datagroup | null>(null)

  const { data: categories = [] } =
    trpc.api.datatags.listDatagroupCategories.useQuery(
      datagroup ? { id: datagroup.id } : skipToken,
      {
        select: (categories) =>
          categories.map((category) => ({
            ...category,
            name: capitalize(category.name),
          })),
      },
    )

  const handleCreateDatagroupClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    parentDatagroup: Datagroup,
  ) => {
    e.stopPropagation()
    setCreateMemberParent(parentDatagroup)
    datagroupMemberModalState.open()
  }

  const handleCategoryClick = (
    category: Datagroup | ViewMetricsCategoryItem,
  ) => {
    setSelectedCategory(category)
  }

  const handleClose = () => {
    setCreateMemberParent(null)
    datagroupMemberModalState.close()
  }

  const getActions = (parentDatagroup: Datagroup) => (
    <SidebarActionsLayout>
      <IconButton
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          handleCreateDatagroupClick(e, parentDatagroup)
        }
      >
        <FilePlus01 width={16} />
      </IconButton>
    </SidebarActionsLayout>
  )

  return (
    <>
      <div className={styles.listWrapper}>
        <SidebarListItemPrimary
          item={datagroup!}
          icon={<LayersThree01 width={16} />}
          onClick={handleCategoryClick}
          actions={getActions(datagroup!)}
          isSelected={selectedCategory?.id === datagroup!.id}
        />
        {categories.map((item) => (
          <SidebarListItem<Datagroup>
            key={item.id}
            item={item}
            icon={<LayersTwo01 width={16} />}
            onClick={handleCategoryClick}
            isSelected={selectedCategory?.id === item.id}
            actions={getActions(item)}
            className={styles.item}
          />
        ))}
      </div>
      <div className={styles.viewMetricsItem}>
        <SidebarListItem<ViewMetricsCategoryItem>
          item={VIEW_METRICS_CATEGORY_ITEM}
          icon={<Settings03 width={16} />}
          onClick={handleCategoryClick}
          isSelected={selectedCategory?.id === VIEW_METRICS_CATEGORY_ITEM.id}
          // actions={getActions(viewMetricsItem)}
          className={styles.item}
        />
      </div>

      <CreateDatagroupMemberModal
        isOpen={datagroupMemberModalState.isOpen && !!createMemberParent}
        onClose={handleClose}
        datagroup={createMemberParent}
      />
    </>
  )
}

export default DatagroupSidebarTab
