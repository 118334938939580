import { FC } from 'react'
import { createStyles } from 'antd-style'

const useStyles = createStyles(() => {
  return {
    pageContent: {
      display: 'flex',
      flex: 1,
      overflow: 'hidden',
      gap: 12,
    },
  }
})

const PageContentLayout: FC<React.PropsWithChildren> = ({ children }) => {
  const { styles } = useStyles()
  return <div className={styles.pageContent}>{children}</div>
}

export default PageContentLayout
