import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    form: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 5fr) fit-content(100%)',
    },
    formItem: {
      '&:not(:last-child)': {
        borderRight: `1px solid ${token.colorBorder}`,
      },
      padding: `${token.paddingContentVerticalSM}px ${token.paddingContentHorizontalSM}px`,
    },
  }
})
