import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    select: {
      minWidth: 100,
    },
    labeledValue: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      textTransform: 'uppercase',
      color: token.colorTextSecondary,
      fontSize: token.fontSizeSM,
      lineHeight: token.lineHeight,
    },
    value: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      paddingRight: token.paddingSM,
      fontWeight: token.fontWeightStrong,
      lineHeight: token.lineHeight,
    },
    placeholder: {
      color: token.colorTextPlaceholder,
    },
  }
})
