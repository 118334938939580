import React, { useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { AppHeader, AppFooter, GlobalLoading, LeftMenu } from '@components'
import { ConfigurationProvider } from '@context/ConfigurationContext'
import { TablePeriodProvider } from '@context/TablePeriodContext'
import useStyles from './App.styles'
import { useAuth } from '@clerk/clerk-react'
import * as Sentry from '@sentry/react'
import { trpc } from '@services/trpc'

const App: React.FC = () => {
  const { styles } = useStyles()
  const { isPending: isPendingAccountdrivers } =
    trpc.api.accountdrivers.list.useQuery()
  const { isPending: isPendingAccounts } = trpc.api.accounts.list.useQuery()
  const { isPending: isPendingVersions } = trpc.api.versions.list.useQuery()
  const { isPending: isPendingCompanies } = trpc.api.companies.list.useQuery()
  const { isPending: isPendingCurrencies } = trpc.api.currencies.list.useQuery()
  const { isPending: isPendingCalendar } = trpc.api.calendar.list.useQuery()

  const loaders = useMemo(
    () => [
      isPendingAccountdrivers,
      isPendingAccounts,
      isPendingVersions,
      isPendingCompanies,
      isPendingCurrencies,
      isPendingCalendar,
    ],
    [
      isPendingAccountdrivers,
      isPendingAccounts,
      isPendingVersions,
      isPendingCompanies,
      isPendingCurrencies,
      isPendingCalendar,
    ],
  )
  const loadingPercent = useMemo(() => {
    const total = loaders.length
    const completed = loaders.filter((loader) => !loader).length
    return (completed / total) * 100
  }, [loaders])

  const auth = useAuth()

  if (auth) {
    if (auth.userId) {
      Sentry.setUser({
        id: auth?.userId,
        ordId: auth.orgId,
        orgSlug: auth.orgSlug,
        orgRole: auth.orgRole,
      })
    }

    Sentry.setContext('auth', {
      userId: auth.userId,
      sessionId: auth.sessionId,
      orgId: auth.orgId,
      orgSlug: auth.orgSlug,
      orgRole: auth.orgRole,
    })
  }

  if (loadingPercent < 100) {
    return <GlobalLoading percent={loadingPercent} />
  }

  return (
    <div className={styles.root}>
      <ConfigurationProvider>
        <TablePeriodProvider>
          <LeftMenu />
          <main className={styles.main}>
            <AppHeader />
            <Outlet />
            <AppFooter />
          </main>
        </TablePeriodProvider>
      </ConfigurationProvider>
    </div>
  )
}

export default App
