import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      height: 40,
      // padding: `0 ${token.paddingContentHorizontalSM}px`,
      border: `1px solid ${token.colorBorder}`,
      borderRadius: token.borderRadiusSM,

      '& > *': {
        display: 'flex',
        alignItems: 'center',
        height: '100%',

        '&:not(:is(:first-child, :last-child))': {
          padding: `0 ${token.paddingXS}px`,
        },

        '&:not(:last-child)': {
          borderRight: `1px solid ${token.colorBorder}`,
        },
      },
    },
    disabled: {
      backgroundColor: token.gray100,
    },
    dndHandle: {
      display: 'flex',
      justifyContent: 'center',
      width: 40,
      color: token.gray500,
    },
    name: {
      flex: 6,
    },
    textContainer: {
      flex: 2,
    },
    selectContainer: {
      flex: 3,
    },
    select: {
      width: '100%',
    },
    switchContainer: {
      width: 70,
      // flex: 1,
    },
    switch: {},
    headerItem: {
      fontWeight: token.fontWeightStrong,
      borderRadius: 0,
    },
    removeButtonContainer: {
      width: 40,
      display: 'flex',
      justifyContent: 'center',
    },
    removeButton: {
      '&&': {
        padding: 0,
        width: 20,
        height: 20,
        color: token.gray700,
      },
    },
  }
})
