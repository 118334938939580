import { Schema } from '@components/SelectedListWidget/index.types'
import useStyles from './SelectedListItem.styles'
import { ImConfused } from 'react-icons/im'

interface HeaderItemProps {
  schema?: Schema
  renderHeader?: () => React.ReactNode
}

const HeaderItem = ({ schema, renderHeader }: HeaderItemProps) => {
  const { styles, cx } = useStyles()

  return (
    <li className={cx(styles.itemContainer, styles.headerItem)}>
      <div className={styles.dndHandle}>
        <ImConfused />
      </div>
      {renderHeader ? (
        renderHeader()
      ) : (
        <>
          <div className={styles.name}>Name | ID</div>
          {schema?.map(({ label, type }) => {
            let className
            switch (type) {
              case 'text':
                className = styles.textContainer
                break
              case 'select':
                className = styles.selectContainer
                break
              case 'switch':
                className = styles.switchContainer
                break
            }
            return (
              <div className={className} key={label}>
                {label}
              </div>
            )
          })}
          <div className={styles.removeButtonContainer}></div>
        </>
      )}
    </li>
  )
}

export default HeaderItem
