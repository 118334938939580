import React from 'react'
import { Form, ModalProps, message } from 'antd'
import { BulkFormLayout, BulkFormList, CreateModal } from '@components'
import { trpc } from '@services/trpc'
import useStyles from './CreateViewMetricModal.styles.ts'
import { DatatagInput } from '@modules/datagroup/datagroup.types.ts'
import ViewMetricRow from './ViewMetricRow'

interface CreateViewMetricModalProps extends ModalProps {
  onClose?: () => void
}

const CreateViewMetricModal: React.FC<CreateViewMetricModalProps> = ({
  onClose,
  ...props
}) => {
  const { styles } = useStyles()
  const [form] = Form.useForm()

  const handleSuccess = () => {
    message.success(`View Metric created successfully`)
    form.resetFields()
    onClose?.()
  }

  const { mutate: save } =
    trpc.api.datatags.createOrUpdateDatatagById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = ({ items }: { items: DatatagInput[] }) => {
    items.forEach((item) => {
      save({
        ...item,
      })
    })
  }

  const handleCancel = () => {
    onClose?.()
    form.resetFields()
  }

  return (
    <CreateModal
      {...props}
      title="Create View Metric"
      onOk={form.submit}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Close"
      width="clamp(500px, 50vw, 1000px)"
      centered
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <BulkFormLayout>
          <BulkFormList wrapped rowClassName={styles.row}>
            {(index) => {
              return <ViewMetricRow index={index} form={form} />
            }}
          </BulkFormList>
        </BulkFormLayout>
      </Form>
    </CreateModal>
  )
}

export default CreateViewMetricModal
