import { SidebarFilterRowLayout, SidebarToolbarLayout } from '@components'
import { useTablePeriodContext } from '@context/TablePeriodContext'
import { useLedgerContext } from '@modules/ledger/components/LedgerContext'
import { DatePicker } from 'antd'
import { createStyles } from 'antd-style'
import { Dayjs } from 'dayjs'

const useStyles = createStyles(({ token }) => {
  return {
    label: {
      textTransform: 'uppercase',
      fontSize: token.fontSizeSM,
      lineHeight: token.lineHeight,
      color: token.gray400,
      marginTop: 4,
    },
    datePickerContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    datePicker: {
      '& .ant-picker-input > input': {
        marginTop: -10,
        marginLeft: -10,
      },
    },
  }
})

const LedgerSidebarNormalToolbar: React.FC = () => {
  const { styles } = useStyles()
  const { selectedDate, setSelectedDate, mode } = useLedgerContext()
  const { setPeriods } = useTablePeriodContext()

  const isSummary = mode === 'summary'
  const label = isSummary ? 'Fiscal year' : 'Date'
  const picker = isSummary ? 'year' : undefined
  const format = isSummary ? 'YYYY' : 'D MMM, YYYY'

  const handleDateChange = (date: Dayjs) => {
    const newPeriods =
      picker === 'year'
        ? {
            from: date.startOf('year').format('YYYY-MM-DD'),
            to: date.endOf('year').format('YYYY-MM-DD'),
            time_span: {
              all: {
                days: false,
                weeks: false,
                months: true,
                quarters: false,
                years: false,
              },
            },
          }
        : {
            from: date.format('YYYY-MM-DD'),
            to: date.format('YYYY-MM-DD'),
            time_span: {
              all: {
                days: true,
                weeks: false,
                months: false,
                quarters: false,
                years: false,
              },
            },
          }

    setPeriods((prev) => ({
      ...prev,
      ...newPeriods,
    }))
    setSelectedDate(date)
  }

  return (
    <SidebarToolbarLayout>
      <SidebarFilterRowLayout>
        <div className={styles.datePickerContainer}>
          {selectedDate && <label className={styles.label}>{label}</label>}
          <DatePicker
            variant="borderless"
            format={format}
            value={selectedDate}
            picker={picker}
            placeholder={'Select ' + label}
            onChange={handleDateChange}
            className={selectedDate ? styles.datePicker : undefined}
            suffixIcon={null}
            allowClear={false}
          />
        </div>
      </SidebarFilterRowLayout>
    </SidebarToolbarLayout>
  )
}

export default LedgerSidebarNormalToolbar
