import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    content: {
      '&&': {
        padding: 0,
        backgroundColor: token.colorBgLayout,
        display: 'flex',
        flexDirection: 'column',
        fontSize: token.fontSizeSM,

        '& > div': {
          padding: `${token.paddingContentVertical}px ${token.paddingContentHorizontal}px`,
          margin: 0,
        },

        '& .ant-modal-close': {
          top: 7,
          color: token.gray700,
        },
      },
    },
    body: {
      overflow: 'auto',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: token.paddingContentVertical,

      '> *': {
        flexShrink: 0,
      },

      '.ant-form-item': {
        label: {
          fontWeight: 500,
          textWrap: 'nowrap',
        },
      },
    },
  }
})
