import { FC, useEffect, useMemo } from 'react'
import {
  DatePicker,
  DrawerProps,
  Form,
  Input,
  InputNumber,
  Select,
  message,
} from 'antd'
import { trpc } from '@services/trpc'
import { PropertiesAccordion, PropertiesDrawer } from '@components'
import dayjs from 'dayjs'
import { CellValues } from '@modules/ledger/ledger.types'
import { capitalize } from '@utils/strings'

type TransactionValues = CellValues & {
  description: string
  memo: string
}

interface TransactionPropertiesProps extends DrawerProps {
  values: CellValues | null
  onSave: () => void
  onClose?: () => void
  disabled?: boolean
}

const TransactionProperties: FC<TransactionPropertiesProps> = ({
  values,
  onSave,
  onClose,
  disabled,
  ...props
}) => {
  const [form] = Form.useForm()

  const { data: datagroupsWithMembers } =
    trpc.api.datatags.listDatagroupsWithMembers.useQuery()

  const initialValues = useMemo(() => {
    if (!values) return null
    const datagroups = Object.entries(values.datagroups).reduce(
      (acc, [key, value]) => {
        const datagroup = datagroupsWithMembers?.find(
          (dg) => dg.id === value.datagroup_id,
        )
        return {
          ...acc,
          [key]: datagroup?.members.find((member) => member.id === value.id),
        }
      },
      {},
    )
    return {
      ...values,
      description: '',
      memo: '',
      transactionDate: values.transactionDate
        ? dayjs(values.transactionDate)
        : null,
      ...datagroups,
    }
  }, [values, datagroupsWithMembers])

  const handleSuccess = () => {
    message.success('Transaction updated successfully')
    onSave()
    form.resetFields()
    onClose?.()
  }

  const { mutate: save, isPending } =
    trpc.api.transactions.updateValueByDatatags.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = (formValues: TransactionValues) => {
    save({
      value: String(formValues.value),
      datatags: JSON.parse(values!.datatagsId),
    })
  }

  useEffect(() => {
    if (!initialValues) return
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <PropertiesDrawer<TransactionValues>
      {...props}
      onSubmit={handleSubmit}
      form={form}
      initialValues={initialValues as TransactionValues}
      confirmLoading={isPending}
    >
      <PropertiesAccordion label="Ledger | Transaction">
        <Form.Item<TransactionValues>
          name="assumption_name"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input the account name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" disabled />
        </Form.Item>

        <Form.Item<TransactionValues>
          name="assumption_name"
          label="Description"
        >
          <Input placeholder="Enter description" disabled />
        </Form.Item>

        <Form.Item<TransactionValues> name="memo" label="Memo">
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder="Enter memo"
            disabled
          />
        </Form.Item>

        <Form.Item<TransactionValues>
          name="transactionDate"
          label="Transaction date"
        >
          <DatePicker disabled />
        </Form.Item>

        <Form.Item<TransactionValues> name="period_class" label="Period class">
          <Select placeholder="Select period class" disabled />
        </Form.Item>

        <Form.Item<TransactionValues> name="currency_name" label="Currency">
          <Select placeholder="Select currency" disabled />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="Account | Values">
        <Form.Item<TransactionValues> name="company_name" label="Company">
          <Select placeholder="Select company" disabled />
        </Form.Item>

        <Form.Item<TransactionValues> name="account_name" label="Account">
          <Select placeholder="Select account" disabled />
        </Form.Item>

        {values?.activity_class === 'DR' ? (
          <Form.Item<TransactionValues> name="value" label="Debit (+) | Amount">
            <InputNumber
              placeholder="Enter debit"
              controls={false}
              disabled={disabled}
            />
          </Form.Item>
        ) : (
          <Form.Item<TransactionValues>
            name="value"
            label="Credit (-) | Amount"
          >
            <InputNumber
              placeholder="Enter credit"
              controls={false}
              disabled={disabled}
            />
          </Form.Item>
        )}
      </PropertiesAccordion>

      {values?.datagroups && (
        <PropertiesAccordion label="Data group">
          {Object.entries(values.datagroups).map(
            ([datagroupName, datagroup]) => {
              const options = datagroupsWithMembers?.find(
                (dg) => dg.id === datagroup.datagroup_id,
              )?.members
              return (
                <Form.Item
                  key={datagroupName}
                  name={datagroupName}
                  label={capitalize(datagroupName)}
                >
                  <Select
                    placeholder={`Select ${datagroupName}`}
                    options={options}
                    disabled
                    fieldNames={{
                      label: 'value',
                      value: 'id',
                    }}
                  />
                </Form.Item>
              )
            },
          )}
        </PropertiesAccordion>
      )}
    </PropertiesDrawer>
  )
}

export default TransactionProperties
