import React from 'react'
import { Button } from 'antd'
import { Plus } from '@untitled-ui/icons-react'
import { useModalState } from '@hooks'
import { CreateDatagroupModal } from '@modules/datagroup'

const AddDatagroupButton: React.FC = () => {
  const modalState = useModalState()

  return (
    <>
      <Button
        type="text"
        aria-label="Create data group"
        onClick={modalState.open}
        icon={<Plus width={20} />}
      />
      <CreateDatagroupModal
        isOpen={modalState.isOpen}
        onClose={modalState.close}
      />
    </>
  )
}

export default AddDatagroupButton
