import {
  AssumptionsTable,
  AssumptionsTableWidgetToolbar,
  CreateTransactionModal,
  TotalsRow,
  TransactionProperties,
  ViewContainerLayout,
  ViewContentLayout,
  ViewFooterLayout,
  ViewToolbar,
  ViewWithPropertiesLayout,
} from '@components'
import { trpc } from '@services/trpc'
import { TableFiltersWithSearchParams } from '@components'
import { useSyncSelectedCompanies } from '../../../hooks'
import { useDataModelPageContext } from '@modules/datamodel'
import { useFilterParams, useModalState, useTransactionsStore } from '@hooks'
import { useTablePeriodContext } from '@context/TablePeriodContext'
import { Button, Modal, Space, message } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { skipToken } from '@tanstack/react-query'
import type { Totals } from '@components/TotalsRow'
import {
  DatagroupValueColumnDefinition,
  DatamodelColumnDefinition,
} from '@server/interfaces/datamodel'
import { Datagroup } from '@modules/datagroup'
import { capitalize } from '@utils/strings'
import {
  CellValues,
  LedgerRow,
  SelectedCellContext,
} from '@modules/ledger/ledger.types'
import { useLedgerContext } from '../../LedgerContext'
import dayjs from 'dayjs'
import { DetailLevel } from '@server/interfaces/versions'

const LedgerNormalView: React.FC = () => {
  const { selectedDataModel } = useDataModelPageContext()
  const { selectedDate, isSummary, isTransactions, mode } = useLedgerContext()
  const { periods } = useTablePeriodContext()

  const { filters } = useFilterParams()
  useSyncSelectedCompanies()
  const transactionModalState = useModalState()

  const [data, setData] = useState<LedgerRow[]>([])
  const [totals, setTotals] = useState<Totals>({})
  const [selectedItem, setSelectedItem] = useState<CellValues | null>(null)

  const setDataToState = useCallback((queryData: any) => {
    setData(
      queryData
        .filter((f: any) => f.type == 'data')
        .map((c: any) => c.datapoint),
    )
    setTotals(queryData.find((f: any) => f.type == 'totals')?.datapoint || {})
  }, [])

  const versionId = filters.version?.[0]
  const { data: versions = [] } = trpc.api.versions.list.useQuery()
  const version = versions.find((v) => v.id === Number(versionId))
  const isTransactionDetailLevel = version?.detail_level === 'transaction'
  const isAddEnabled = selectedDataModel && isTransactionDetailLevel

  const disabledFiltersList = {
    company: true,
    period_class: isSummary || (isTransactions && !selectedDate),
  }

  const queryOptions = {
    preset: 'ledger',
    options: {
      datamodelId: selectedDataModel?.id,
      filters,
      totals: true,
      periods,
      detail_level: mode as DetailLevel,
    },
  }

  const {
    data: initialQueryData = [],
    isFetching,
    isLoading,
    refetch,
  } = trpc.api.transactions.tableQuery.useQuery(
    selectedDataModel ? queryOptions : skipToken,
    { placeholderData: (prev: any) => prev },
  )
  useEffect(() => {
    setDataToState(initialQueryData)
    setSelectedItem(null)
  }, [initialQueryData])

  const { data: datagroups = [] } =
    trpc.api.datatags.listDatagroupsByType.useQuery(['datagroup', 'category'])

  const columns = useMemo(
    () =>
      initialQueryData
        .filter((f: any) => f.type == 'cols')
        .map((c: any) => c.datapoint),
    [initialQueryData],
  )

  const layout = useMemo(() => {
    const baseLayout = [
      {
        id: 'account_name',
        type: 'row',
        label: 'Account',
      },
      ...(isTransactions
        ? [
            {
              id: 'transaction_name',
              type: 'row',
              label: 'Transaction Name',
            },
            {
              id: 'period_name',
              type: 'row',
              label: 'Period Name',
            },
          ]
        : []),
      {
        id: 'company_name',
        type: 'row',
        label: 'Company',
      },
    ]

    const cols = initialQueryData
      .filter((f: any) => f.type == 'cols')
      .map((c: any) => c.datapoint)

    const colDefs = initialQueryData
      .filter((f: any) => f.type == 'grouped_cols')
      .map((c: any) => {
        if (c.datapoint.children && c.datapoint.children.length > 0) {
          const result = {
            ...c.datapoint,
            type: 'groupedColumns',
          }

          result.children = c.datapoint.children.map((child: any) => {
            const relatedCol = cols.find((col: any) => col.colid === child.id)
            return { ...child, ...relatedCol, type: 'column' }
          })
          return result
        }
        return { ...c.datapoint, type: 'column' }
      })

    const datagroupColumns =
      datagroups.length > 0 && selectedDataModel?.config?.columns
        ? (
            selectedDataModel?.config.columns.filter(
              (col: DatamodelColumnDefinition) => col.type === 'datagroup',
            ) as DatagroupValueColumnDefinition[]
          ).map((col) => {
            const datagroup = datagroups.find(
              (dg: Datagroup) => dg.id === col.datagroup_id,
            )
            return {
              id: datagroup?.id,
              key: datagroup?.name,
              type: 'datagroups',
              label: capitalize(datagroup?.name),
            }
          })
        : []
    return [...baseLayout, ...datagroupColumns, ...colDefs]
  }, [initialQueryData, datagroups])

  const { addTransaction, transactionsToSubmit, reset, isDirty, blocker } =
    useTransactionsStore()

  const addTransactionWithOptimisticUpdate = useCallback(
    (datatags: string, value: string, options?: any) => {
      setData((oldData) => {
        const { colId, rowId } = options
        const row = oldData.find((r: any) => r.rowid == rowId)
        if (!row) return oldData
        const [activityClass, groupId] = colId.split('_')
        const netCol = `NET_${groupId}`
        const netValue =
          (row.columns[netCol]?.value || 0) +
          (Number(value) - Number(row.columns[colId]?.value || 0)) *
            (activityClass === 'CR' ? -1 : 1)

        const updatedRow = {
          ...row,
          isChanged: true,
          columns: {
            ...row.columns,
            [colId]: { value, isChanged: true },
            [netCol]: { value: netValue },
          },
        }
        const newData = oldData.map((r: any) =>
          r.rowid === rowId ? updatedRow : r,
        )

        setTotals((oldTotals) => {
          const newTotalNet = newData.reduce((acc, r) => {
            return acc + (Number(r.columns[netCol]?.value || 0) || 0)
          }, 0)
          const newTotal = newData.reduce((acc, r) => {
            return acc + (Number(r.columns[colId]?.value || 0) || 0)
          }, 0)

          return {
            ...oldTotals,
            [netCol]: { value: newTotalNet },
            [colId]: { value: newTotal },
          }
        })

        return newData
      })
      addTransaction(datatags, value)
    },
    [],
  )

  const handleSuccess = () => {
    message.success('Transactions saved successfully')
    reset()
    refetch()
  }
  const { mutate: save } = trpc.api.transactions.updateMany.useMutation({
    onSuccess: handleSuccess,
    onError: (error) => {
      message.error(error.message)
    },
  })

  const handleCancel = () => {
    reset()
    setDataToState(initialQueryData)
  }

  const handleSave = () => {
    const isNotBalanced = Object.entries(totals).some(
      ([id, total]) => id.startsWith('NET') && total.value !== 0,
    )
    if (isNotBalanced) {
      Modal.confirm({
        title: 'Warning',
        content: 'Transactions do not balance!',
        closable: true,
        cancelText: 'Go back',
        okText: 'Save anyway',
        onOk: () => {
          save(transactionsToSubmit)
        },
      })
    } else {
      save(transactionsToSubmit)
    }
  }

  const handleCellClick = (cellContext: SelectedCellContext) => {
    // console.log(cellContext.column.columnDef.meta?.column)
    const cellValues = {
      datatagsId: cellContext.datatagsId,
      transactionDate: dayjs(
        cellContext.column.columnDef.meta?.column.periodName,
      ),
      activity_class: cellContext.column.columnDef.meta?.column.activity_class,
      period_class: cellContext.column.columnDef.meta?.column.period_class,
      // account_id: cellContext.row.original.account_id,
      // company_id: cellContext.row.original.company_id,
      // currency_id: cellContext.row.original.currency_id,
      // assumption_id: cellContext.row.original.assumption_id,
      // assumption_name: cellContext.row.original.assumption_name,
      // accountdriver_id: cellContext.row.original.accountdriver_id,
      // datagroups: cellContext.row.original.datagroups,
      ...cellContext.row.original,
      value:
        cellContext.row.original.columns[cellContext.column.id]?.value || 0,
    }
    setSelectedItem(cellValues)
  }

  const getRowId = useCallback((item: LedgerRow) => item.rowid, [])

  return (
    <>
      <ViewWithPropertiesLayout>
        <ViewContentLayout>
          <ViewToolbar
            onAdd={isAddEnabled ? transactionModalState.open : undefined}
          />

          <TableFiltersWithSearchParams disabledList={disabledFiltersList} />

          <ViewContainerLayout>
            <AssumptionsTable<LedgerRow>
              isLoading={isLoading}
              isUpdating={isFetching}
              data={data}
              layout={layout}
              getRowId={getRowId}
              refetch={refetch}
              datamodel={selectedDataModel}
              onChange={addTransactionWithOptimisticUpdate}
              onCellClick={selectedDate ? handleCellClick : undefined}
              selectedCellId={selectedItem?.datatagsId}
              // disabled={isTableDisabled}
              toolbar={
                <AssumptionsTableWidgetToolbar
                  columnsCount={layout.length}
                  columnsTotal={layout.length}
                />
              }
              totalsRow={<TotalsRow totals={totals} periods={columns} />}
            />
          </ViewContainerLayout>

          <ViewFooterLayout open={isDirty}>
            <Space>
              <Button onClick={handleCancel} aria-label="Cancel">
                Cancel
              </Button>
              <Button onClick={handleSave} type="primary" aria-label="Save">
                Save
              </Button>
            </Space>
          </ViewFooterLayout>
        </ViewContentLayout>

        <TransactionProperties
          values={selectedItem}
          onSave={refetch}
          // disabled={isTableDisabled}
        />
      </ViewWithPropertiesLayout>

      <CreateTransactionModal
        isOpen={transactionModalState.isOpen}
        onClose={transactionModalState.close}
        onSuccess={refetch}
        title={`Create ${selectedDataModel?.name} Ledger Transaction`}
      />

      <Modal
        open={blocker.state === 'blocked'}
        okText="Yes"
        cancelText="No"
        onOk={blocker.proceed}
        onCancel={blocker.reset}
        width={350}
        centered
      >
        You have unsaved changes. Are you sure you want to leave this page?
      </Modal>
    </>
  )
}

export default LedgerNormalView
