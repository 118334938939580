import { Row } from '@tanstack/react-table'
import useModalState from '@hooks/useModalState'
import { Company } from '@modules/company'
import { Category, useConfigPageContext } from '@context/ConfigPageContext'
import {
  ViewContainerLayout,
  ViewContentLayout,
  ViewToolbar,
  ViewToolbarAddButton,
  ViewWithPropertiesLayout,
} from '@components'
import CreateCompanyModal from '../CreateCompanyModal'
import CompanyTable from '../CompanyTable'
import CompanyProperties from '../CompanyProperties'

const CompanyView: React.FC = () => {
  const drawerState = useModalState()
  const { selectedItem, setSelectedItem } = useConfigPageContext<
    Company,
    Category
  >()

  const handleRowClick = (row: Row<Company>) => {
    setSelectedItem(row.original)
  }

  return (
    <ViewWithPropertiesLayout>
      <ViewContentLayout>
        <ViewToolbar
          addButton={<ViewToolbarAddButton createModal={CreateCompanyModal} />}
        />

        <ViewContainerLayout>
          <CompanyTable
            onRowClick={handleRowClick}
            selectedRows={
              selectedItem ? { [selectedItem.id]: true } : undefined
            }
          />
        </ViewContainerLayout>
      </ViewContentLayout>

      <CompanyProperties values={selectedItem} onClose={drawerState.close} />
    </ViewWithPropertiesLayout>
  )
}

export default CompanyView
