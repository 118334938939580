import { SidebarCollapsibleItem, SidebarList } from '@components'
import { Datatag, type Datagroup } from '@modules/datagroup'
import { trpc } from '@services/trpc'
import { useModalState } from '@hooks'
import { LayersTwo01 } from '@untitled-ui/icons-react'
import CreateDatagroupMemberModal from '@modules/datagroup/components/CreateDatagroupMemberModal'
import { useState } from 'react'
import { Flex } from 'antd'
import { useConfigPageContext } from '@context/ConfigPageContext'

const DatagroupSidebarTab: React.FC = () => {
  const datagroupMemberModalState = useModalState()
  const {
    selectedCategory: selectedDatagroup,
    setSelectedCategory: setSelectedDatagroup,
    selectedItem,
    setSelectedItem,
  } = useConfigPageContext<Datatag, Datagroup>()

  const [createMemberParent, setCreateMemberParent] =
    useState<Datagroup | null>(null)

  const { data: picklists = [] } =
    trpc.api.datatags.listDatagroupsByType.useQuery('picklist')

  const datatagQueries = trpc.useQueries((t) =>
    picklists.map((picklist) =>
      t.api.datatags.listDatagroupMembers({ id: picklist.id }),
    ),
  )
  const datatagsByDatagroupId = datatagQueries.reduce(
    (acc, query, i) => {
      acc[picklists[i].id] =
        query.data?.map((datatag) => ({
          ...datatag,
          name: datatag.value,
        })) ?? []
      return acc
    },
    {} as Record<string, (Datatag & { name: string })[]>,
  )

  const handleClose = () => {
    setCreateMemberParent(null)
    datagroupMemberModalState.close()
  }

  return (
    <>
      {picklists.map((picklist) => (
        <SidebarCollapsibleItem
          key={picklist.id}
          label={
            <Flex gap={4} align="center">
              <LayersTwo01 width={16} />
              {picklist.name}
            </Flex>
          }
          onCreateClick={() => {
            setCreateMemberParent(picklist)
            datagroupMemberModalState.open()
          }}
          onLabelClick={() => {
            setSelectedDatagroup(picklist)
          }}
          isSelected={selectedDatagroup?.id === picklist.id}
        >
          <SidebarList<Datatag & { name: string }>
            items={datatagsByDatagroupId[picklist.id]}
            selectedItemId={selectedItem?.id}
            onItemClick={(item) => {
              console.log('item', item)
              console.log('picklist', picklist)
              setSelectedDatagroup(picklist, false)
              setSelectedItem(item)
            }}
          />
        </SidebarCollapsibleItem>
      ))}

      <CreateDatagroupMemberModal
        isOpen={datagroupMemberModalState.isOpen && !!createMemberParent}
        onClose={handleClose}
        datagroup={createMemberParent}
      />
    </>
  )
}

export default DatagroupSidebarTab
