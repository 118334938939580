export const ACCOUNT_AGGREGATION = {
  sum: 'Sum',
  activity: 'Activity',
  balance: 'Balance',
} as const

export const ACCOUNT_AGGREGATION_LABELS = {
  [ACCOUNT_AGGREGATION.sum]: 'Sum',
  [ACCOUNT_AGGREGATION.activity]: 'Activity',
  [ACCOUNT_AGGREGATION.balance]: 'Balance',
}

export const getAccountAggregationOptions = () => {
  return Object.keys(ACCOUNT_AGGREGATION).map((key) => ({
    value: key,
    label:
      ACCOUNT_AGGREGATION_LABELS[
        ACCOUNT_AGGREGATION[key as keyof typeof ACCOUNT_AGGREGATION]
      ],
  }))
}
