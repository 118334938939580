import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    operandRow: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: token.paddingSM,
      padding: token.paddingXS,
      borderRight: `1px solid ${token.colorBorder}`,
    },
    operand: {
      display: 'flex',
      flexDirection: 'column',
    },
    accountDriverRow: {
      display: 'flex',
      gap: token.paddingXS,
    },
    accountDriverItem: {
      '&&': {
        flex: 3,
      },
    },
    timeValueItem: {
      '&&': {
        flex: 1,
      },
    },
  }
})
