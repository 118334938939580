import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

type InitialFilters = {
  company?: string
  version?: string
  currency?: string
  entity?: string
  account?: string
  calendar?: string
  accountdriver?: string
  accountcategory?: string
  period_class?: string
  fiscal_year?: string
}

const defaultInitialFilters = {
  company: '',
  version: '',
  currency: '',
  entity: '',
  account: '',
  calendar: '',
  accountdriver: '',
  accountcategory: '',
  period_class: '',
  fiscal_year: '',
}

const useFilterParams = (
  initialFilters: InitialFilters = defaultInitialFilters,
) => {
  const [filtersParams, setFiltersParams] = useSearchParams(initialFilters)

  const company = filtersParams.get('company')
  const version = filtersParams.get('version')
  const entity = filtersParams.get('entity')
  const account = filtersParams.get('account')
  const currency = filtersParams.get('currency')
  const accountdriver = filtersParams.get('accountdriver')
  const accountcategory = filtersParams.get('accountcategory')
  const period_class = filtersParams.get('period_class')
  const fiscal_year = filtersParams.get('fiscal_year')

  const params = {
    company,
    version,
    entity,
    account,
    currency,
    accountdriver,
    period_class,
    fiscal_year,
  }

  const filters = {
    ...(company ? { company: [company] } : {}),
    ...(version ? { version: [version] } : {}),
    ...(entity ? { entity: [entity] } : {}),
    ...(account ? { account: [account] } : {}),
    ...(currency ? { currency: [currency] } : {}),
    ...(accountdriver ? { accountdriver: [accountdriver] } : {}),
    ...(accountcategory ? { accountcategory: [accountcategory] } : {}),
    ...(period_class ? { period_class: [period_class] } : {}),
    ...(fiscal_year ? { fiscal_year: [fiscal_year] } : {}),
  }

  const changeFilterParams = useCallback(
    (params: { [key: string]: string | number | boolean }) => {
      setFiltersParams(
        (prev) => {
          const current = Object.fromEntries(prev.entries())
          console.log('current', current)
          return {
            ...current,
            ...Object.fromEntries(
              Object.entries(params).map(([key, value]) => [
                key,
                String(value),
              ]),
            ),
          }
        },
        { replace: true },
      )
    },
    [],
  )

  return { params, filters, changeFilterParams }
}

export default useFilterParams
