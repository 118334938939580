import React from 'react'

import useStyles from './PropertiesSidebar.styles.ts'
import PropertiesDrawerCollapser from '@components/PropertiesDrawerCollapser/PropertiesDrawerCollapser.tsx'
import useModalState from '@hooks/useModalState.ts'

interface PropertiesSidebarProps {
  name?: string
  children?: React.ReactNode
}

const PropertiesSidebar: React.FC<PropertiesSidebarProps> = ({
  name,
  children,
}) => {
  const { styles, cx } = useStyles()
  const sidebarState = useModalState(false)

  return (
    <aside className={styles.sidebarRoot}>
      <PropertiesDrawerCollapser
        name={name}
        onCollapse={sidebarState.toggle}
        open={sidebarState.isOpen}
      />

      <div
        className={cx(
          styles.sidebarMain,
          !sidebarState.isOpen && styles.sidebarCollapsed,
        )}
      >
        <div className={styles.sidebarContent}>{children}</div>
      </div>
    </aside>
  )
}

export default PropertiesSidebar
