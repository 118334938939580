import { FC, useEffect } from 'react'
import {
  Checkbox,
  DatePicker,
  DrawerProps,
  Form,
  Input,
  message,
  Select,
} from 'antd'
import { trpc } from '@services/trpc'
import { PropertiesAccordion, PropertiesDrawer } from '@components'
import { CalendarFormValues } from '@modules/calendar/calendar.types'
import dayjs from 'dayjs'
import { Calendar } from '@server/interfaces/period'

const years = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
]

interface CalendarPropertiesProps extends DrawerProps {
  values: Calendar | null
  onClose?: () => void
}

const CalendarProperties: FC<CalendarPropertiesProps> = ({
  values,
  onClose,
  ...drawerProps
}) => {
  const [form] = Form.useForm()
  const { refetch } = trpc.api.calendar.list.useQuery()

  const initialValues = values && {
    ...values,
    initial_start_date: values?.initial_start_date
      ? dayjs(values.initial_start_date)
      : null,
  }

  const handleSuccess = () => {
    message.success('Calendar updated successfully')
    refetch()
    form.resetFields()
    onClose?.()
  }

  const { mutate: save } = trpc.api.calendar.createOrUpdateById.useMutation({
    onSuccess: handleSuccess,
    onError: (error) => {
      message.error(error.message)
    },
  })

  const handleSubmit = (values: CalendarFormValues) => {
    save({
      ...values,
      id: values.id,
      initial_start_date: values.initial_start_date.format('YYYY-MM-DD'),
    })
  }

  useEffect(() => {
    form.setFieldsValue(values)
  }, [form, values])

  return (
    <PropertiesDrawer<CalendarFormValues>
      {...drawerProps}
      onSubmit={handleSubmit}
      form={form}
      initialValues={initialValues as CalendarFormValues}
    >
      <PropertiesAccordion label="Calendar">
        <Form.Item
          name="name"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input the calendar name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input placeholder="Enter description" />
        </Form.Item>

        <Form.Item name="memo" label="Memo">
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder="Enter memo"
          />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="General">
        <Form.Item<CalendarFormValues>
          // name="trend"
          label="Calendar Trend"
        >
          <Select value="Standard" disabled />
        </Form.Item>

        <Form.Item<CalendarFormValues>
          name="initial_start_date"
          label="Initial Start Date"
        >
          <DatePicker picker="month" format="MMMM YYYY" />
        </Form.Item>

        <Form.Item<CalendarFormValues> name="years" label="Number of years">
          <Select options={years} />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="Other Settings">
        <Form.Item
          valuePropName="checked"
          name="inactive"
          aria-label="Inactive"
        >
          <Checkbox>Inactive</Checkbox>
        </Form.Item>
      </PropertiesAccordion>
    </PropertiesDrawer>
  )
}

export default CalendarProperties
