import { CreateModal } from '@components'
import { Form, FormInstance, message } from 'antd'
import useStyles from './CreateTransactionModal.styles'
import { trpc } from '@services/trpc'
import { TransactionFormValues } from './CreateTransactionForm'
import CreateTransactionForm from './CreateTransactionForm'
import { groupBy } from 'lodash'
import { useDataModelPageContext } from '@modules/datamodel'
import { useSearchParams } from 'react-router-dom'

type CreateTransactionModalProps = {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
  title: string
}

const validateValues = (values: TransactionFormValues, form: FormInstance) => {
  let isValid = true
  const { values: transactionValues } = values
  const groupedTransactionValues = groupBy(transactionValues, 'account')
  Object.entries(groupedTransactionValues)
    .filter(([, values]) => values.length > 1)
    .forEach(([account, values]) => {
      const grouped = groupBy(values, 'name')
      const [duplicatedName] = Object.keys(grouped).filter(
        (groupKey) => grouped[groupKey].length > 1,
      )
      if (duplicatedName) {
        const indexes = transactionValues.reduce<number[]>((acc, v, index) => {
          if (
            Number(v.account) === Number(account) &&
            v.name === duplicatedName
          ) {
            return [...acc, index]
          }
          return acc
        }, [])
        const fields = indexes.map((index) => ({
          name: ['values', index, 'name'],
          errors: ['The name should be unique for the same account'],
        }))
        form.setFields(fields)
        isValid = false
      }
    })
  return isValid
}

const CreateTransactionModal: React.FC<CreateTransactionModalProps> = ({
  isOpen,
  onClose,
  onSuccess,
  title,
}) => {
  const { selectedDataModel } = useDataModelPageContext()
  const companyId = selectedDataModel?.config.company_id

  const [searchParams] = useSearchParams()
  const version = Number(searchParams.get('version'))

  const [form] = Form.useForm()
  const { styles } = useStyles()

  const handleSuccess = () => {
    message.success('Transaction created successfully')
    onSuccess()
    form.resetFields()
    onClose()
  }
  const { mutate: saveTransaction, isPending } =
    trpc.api.datamodel.createTransaction.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: TransactionFormValues) => {
    if (!validateValues(values, form)) {
      return
    }

    const { date, periodClass, values: transactionValues, ...rest } = values
    const restDatatags = Object.fromEntries(
      Object.entries(rest).filter(([, value]) => Boolean(value)),
    )

    /** TODO Values.periodClass sometimes == null */
    let formattedDate = date.format('YYYY-MM-DD')

    // todo should use fiscal year instead of 2024 and calendar type instead of (CY-Calendar Year)
    if (periodClass === 'closing' || periodClass === 'open') {
      formattedDate = `${periodClass} 2024 (CY-Calendar Year)`
    }

    transactionValues.forEach((v) => {
      if (typeof v.DR === 'number') {
        saveTransaction({
          datamodel_id: selectedDataModel!.id,
          date: formattedDate,
          period_class: periodClass,
          datatags: {
            ...restDatatags,
            account: v.account,
            drcr: 1,
            ...(selectedDataModel?.type === 'ledger' && {
              company: Number(companyId),
            }),
            version,
            ...v.datatags,
          },
          transaction_name: v.name,
          value: v.DR.toString(),
        })
      }
      if (typeof v.CR === 'number') {
        saveTransaction({
          datamodel_id: selectedDataModel!.id,
          date: formattedDate,
          period_class: periodClass,
          datatags: {
            ...restDatatags,
            account: v.account,
            drcr: -1,
            ...(selectedDataModel?.type === 'ledger' && {
              company: Number(companyId),
            }),
            version,
            ...v.datatags,
          },
          transaction_name: v.name,
          value: v.CR.toString(),
        })
      }
    })
  }

  const handleCancel = () => {
    form.resetFields()
    onClose()
  }

  return (
    <CreateModal
      title={title}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      centered
      width="clamp(700px, 80%, 1400px)"
      className={styles.modal}
      confirmLoading={isPending}
    >
      <CreateTransactionForm form={form} onSubmit={handleSubmit} />
    </CreateModal>
  )
}

export default CreateTransactionModal
