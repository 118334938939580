import { SidebarList, SidebarCollapsibleItem } from '@components'
import { DatamodelGroup, type DataModel } from '@modules/datamodel'
import { trpc } from '@services/trpc'
import { useDataModelPageContext } from '../../DataModelPageContext'
import { SidebarContent } from '@components'
import { DATAMODEL_GROUPS_LABELS } from '@modules/datamodel/datamodel.constants'

interface DataModelsTabProps {
  onNewClick: () => void
  dataModelGroup: DatamodelGroup
}

const DataModelsTab: React.FC<DataModelsTabProps> = ({
  onNewClick,
  dataModelGroup,
}) => {
  const { selectedDataModel, changeSelectedDatamodelId } =
    useDataModelPageContext()

  const { data: models = [] } = trpc.api.datamodel.listByType.useQuery({
    type: dataModelGroup,
  })

  const handleItemClick = (item: DataModel) => {
    changeSelectedDatamodelId(item.id)
  }

  const handleLabelClick = () => {
    changeSelectedDatamodelId(null)
  }

  return (
    <SidebarContent>
      <SidebarCollapsibleItem
        label={`${DATAMODEL_GROUPS_LABELS[dataModelGroup]} Models (${models.length})`}
        onCreateClick={onNewClick}
        onLabelClick={handleLabelClick}
      >
        <SidebarList<DataModel>
          items={models}
          onItemClick={handleItemClick}
          selectedItemId={selectedDataModel?.id}
        />
      </SidebarCollapsibleItem>
    </SidebarContent>
  )
}

export default DataModelsTab
