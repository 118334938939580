import useStyles from './Accordion.styles'
import { useEffect, useRef } from 'react'
import useModalState from '@hooks/useModalState'
import { BiChevronUp } from 'react-icons/bi'

export interface AccordionProps {
  label: string | React.ReactNode
  children: React.ReactNode
  classNames?: {
    header?: string
    content?: string
  }
}

const Accordion: React.FC<AccordionProps> = ({
  label,
  children,
  classNames,
}) => {
  const state = useModalState(true)
  const { styles, cx } = useStyles()
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      const timeoutId = setTimeout(() => {
        contentRef.current!.style.maxHeight = state.isOpen
          ? `${contentRef.current!.scrollHeight}px`
          : '0'
      }, 0)

      return () => clearTimeout(timeoutId)
    }
  }, [state.isOpen, children])

  return (
    <div>
      <div
        className={cx(styles.header, classNames?.header)}
        onClick={state.toggle}
      >
        <span className={styles.headerLabel}>{label}</span>
        <BiChevronUp
          size={16}
          className={cx(
            styles.headerIcon,
            state.isOpen && styles.headerIconOpened,
          )}
        />
      </div>
      <div className={cx(styles.content, classNames?.content)} ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default Accordion
