import { createStyles } from 'antd-style'

export default createStyles(() => ({
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
}))
