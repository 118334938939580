import { Sidebar } from '@components'
import { useEffect, useMemo } from 'react'
import { SidebarContent } from '@components'
import ConfigSidebarConfigToolbar from './ConfigSidebarToolbar'
import { BaseItem } from '@components/SidebarList/SidebarListItem'
import ConfigSidebarCategoriesTab from './ConfigSidebarCategoriesTab'
import ConfigSidebarItemsTab from './ConfigSidebarItemsTab'
import {
  Category,
  TABS,
  useConfigPageContext,
  type Tab,
} from '@context/ConfigPageContext'
import type { CreateModalProps } from './ConfigSidebarItemsTab'

interface ConfigSidebarProps<T extends BaseItem> {
  name: string
  items: T[]
  categories?: Category[] | false
  forceConfigMode?: boolean
  createButton?: React.ReactNode
  onAddClick?: (category?: Category) => void
  createModal?:
    | React.ReactElement<CreateModalProps>
    | React.FC<CreateModalProps>
  toolbar?: React.ReactNode
  renderCategories?: (categories: Category[]) => React.ReactNode
  onCategoryClick?: (category: Category | null) => void
  renderItems?: (items: T[]) => React.ReactNode
  onItemClick?: (item: T) => void
}

const ConfigSidebar = <T extends BaseItem>({
  name,
  categories = [],
  items,
  forceConfigMode,
  createButton,
  onAddClick,
  toolbar,
  renderCategories,
  renderItems,
  onCategoryClick,
  onItemClick,
  createModal,
}: ConfigSidebarProps<T>) => {
  const sidebarTabsList = useMemo(() => {
    const list: Tab[] = [TABS.items, TABS.views]
    if (categories !== false) {
      list.unshift(TABS.categories)
    }
    return list
  }, [categories])
  const { currentTab, setCurrentTab, selectedItem, setSelectedItem } =
    useConfigPageContext()

  const handleTypeTabChange = () => {
    setCurrentTab(TABS.items)
  }

  useEffect(() => {
    if (!selectedItem) {
      setSelectedItem(items[0])
    }
  }, [items, selectedItem, setSelectedItem])

  return (
    <Sidebar
      title={name}
      tabs={sidebarTabsList}
      selectedTab={currentTab}
      onTabChange={setCurrentTab}
      forceConfigMode={forceConfigMode}
    >
      <>
        {toolbar || (
          <ConfigSidebarConfigToolbar
            createButton={createButton}
            categories={categories || undefined}
          />
        )}
        <SidebarContent>
          {currentTab === TABS.categories && categories !== false && (
            <ConfigSidebarCategoriesTab
              name={name}
              onDoubleClick={handleTypeTabChange}
              categories={categories}
              onAddClick={onAddClick}
              renderCategories={renderCategories}
              onCategoryClick={onCategoryClick}
            />
          )}
          {currentTab === TABS.items && (
            <ConfigSidebarItemsTab
              name={name}
              items={items}
              onAddClick={onAddClick}
              renderItems={renderItems}
              onItemClick={onItemClick}
              createModal={createModal}
            />
          )}
        </SidebarContent>
      </>
    </Sidebar>
  )
}

export default ConfigSidebar
