import { useConfigurationMode } from '@context/ConfigurationContext'
import { DatatagColumnsProvider } from '@modules/datamodel/DatatagColumnsContext'
import LedgerConfigView from './LedgerConfigView'
import LedgerNormalView from './LedgerNormalView'
import { useDataModelPageContext } from '@modules/datamodel'

const LedgerView: React.FC = () => {
  const { isConfigurationMode } = useConfigurationMode()
  const { selectedDataModel } = useDataModelPageContext()

  if (isConfigurationMode) {
    return (
      <DatatagColumnsProvider selectedDataModel={selectedDataModel}>
        <LedgerConfigView />
      </DatatagColumnsProvider>
    )
  }

  return <LedgerNormalView />
}

export default LedgerView
