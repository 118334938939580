import React, { createContext, useContext } from 'react'
import useTimeMetricConfig, {
  type TimeMetricConfig,
} from '@hooks/useTimeMetricConfig'

interface TablePeriodContextType {
  periods: TimeMetricConfig
  setPeriods: React.Dispatch<React.SetStateAction<TimeMetricConfig>>
}

const TablePeriodContext = createContext<TablePeriodContextType | undefined>(
  undefined,
)

const useTablePeriodContext = () => {
  const context = useContext(TablePeriodContext)

  if (!context) {
    throw new Error(
      '[CONTEXT] "useTablePeriodContext" must be used within a TablePeriodProvider',
    )
  }

  return context
}

const TablePeriodProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [periods, setPeriods] = useTimeMetricConfig()

  return (
    <TablePeriodContext.Provider
      value={{
        periods,
        setPeriods,
      }}
    >
      {children}
    </TablePeriodContext.Provider>
  )
}

export { TablePeriodProvider, useTablePeriodContext }
