import {
  CreateMenuButton,
  SidebarFilterRowLayout,
  SidebarFilterSelect,
  SidebarFiltersByDatatag,
  SidebarToolbarLayout,
} from '@components'
import { trpc } from '@services/trpc'
import { MenuProps } from 'antd'
import { useDataModelPageContext } from '@modules/datamodel'

const MENU_ITEM_KEYS = {
  ASSUMPTION: 'assumption',
  DATA_MODEL: 'dataModel',
  VIEW: 'view',
} as const

interface LookupSidebarToolbarProps {
  onNewAssumptionClick: () => void
  currentDataTagClass: string
  onDataTagClassChange: (dataTagClass: string) => void
}

const LookupSidebarToolbar: React.FC<LookupSidebarToolbarProps> = ({
  onNewAssumptionClick,
  currentDataTagClass,
  onDataTagClassChange,
}) => {
  const { selectedDataModel, changeSelectedDatamodelId } =
    useDataModelPageContext()
  const { data: dataModels = [] } = trpc.api.datamodel.listByType.useQuery({
    type: 'lookup',
  })

  const menuItems: MenuProps['items'] = [
    {
      key: MENU_ITEM_KEYS.ASSUMPTION,
      label: 'Create Assumption',
    },
  ]

  const dataModelOptions = [
    { label: 'All', value: '' },
    ...dataModels.map((dataModel) => ({
      label: dataModel.name,
      value: dataModel.id,
    })),
  ]

  const handleCreateItemClick = (key: string) => {
    if (key === MENU_ITEM_KEYS.ASSUMPTION) {
      onNewAssumptionClick()
    }
  }

  return (
    <SidebarToolbarLayout>
      <SidebarFilterRowLayout>
        <SidebarFilterSelect
          label="Data model"
          options={dataModelOptions}
          onChange={changeSelectedDatamodelId}
          value={selectedDataModel?.id || ''}
        />
      </SidebarFilterRowLayout>
      <SidebarFilterRowLayout>
        <SidebarFiltersByDatatag
          currentDataTagClass={currentDataTagClass}
          onDataTagClassChange={onDataTagClassChange}
        />
        <CreateMenuButton
          items={menuItems}
          onClick={handleCreateItemClick}
          disabled
        />
      </SidebarFilterRowLayout>
    </SidebarToolbarLayout>
  )
}

export default LookupSidebarToolbar
