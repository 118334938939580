import { useCallback, useEffect, useState } from 'react'
import { Row } from '@tanstack/react-table'
import {
  ViewContainerLayout,
  ViewContentLayout,
  ViewFooterLayout,
  ViewToolbar,
  ViewToolbarAddButton,
  ViewWithPropertiesLayout,
} from '@components'
import {
  DataModel,
  DatamodelGroup,
  useDataModelPageContext,
} from '@modules/datamodel'
import { DataModelTable } from '@modules/datamodel'
import { Button, Form, Space, message } from 'antd'
import { trpc } from '@services/trpc'
import OperandConstructorForm, {
  type OperandFormValues,
} from '../../OperandConstructorForm'
import type { ComputedDatamodelConfig } from '@server/interfaces/computed'
import useStyles from './CustomComputedViewConfig.styles'
import AccountProperties from '@modules/account/components/AccountProperties'
import { skipToken } from '@tanstack/react-query'
import { AccountSource } from '@constants/accountTypes'
import CreateCustomComputedModal from '../../CreateCustomComputedModal'
import { CustomPageType } from '@modules/customComputed'
import { TABS, useConfigPageContext } from '@context/ConfigPageContext'

interface CustomComputedViewConfigProps {
  type: CustomPageType
}

const CustomComputedViewConfig: React.FC<CustomComputedViewConfigProps> = ({
  type,
}) => {
  const { styles } = useStyles()
  const { selectedDataModel, changeSelectedDatamodelId } =
    useDataModelPageContext()
  const { currentTab } = useConfigPageContext()

  const { data: account, refetch: refetchAccount } =
    trpc.api.accounts.getByName.useQuery(
      selectedDataModel?.name ? { name: selectedDataModel.name } : skipToken,
    )

  // const [currentTab, setCurrentTab] = useState<'columns' | 'rows'>('columns')
  const [operandsForm] = Form.useForm()
  const [isDirty, setIsDirty] = useState(false)

  const { refetch: fetchModels } = trpc.api.datamodel.listByType.useQuery({
    type: 'computed',
  })

  // TODO: pass ComputedDataModel type to useDataModelPageContext
  const selectedDataModelConfig =
    selectedDataModel?.config as ComputedDatamodelConfig
  const initialOperands = selectedDataModelConfig?.operands

  const setInitialOperands = useCallback(() => {
    if (initialOperands && initialOperands.length > 0) {
      operandsForm.setFieldsValue({
        operands: initialOperands.map((operand) => ({
          ...operand,
          id: `${operand.type}_${operand.id}`,
        })),
      })
    } else {
      operandsForm.resetFields()
    }
  }, [initialOperands, operandsForm])

  useEffect(() => {
    setInitialOperands()
  }, [initialOperands, operandsForm, setInitialOperands])

  const handleSuccess = () => {
    message.success('Model updated successfully')
    fetchModels()
    operandsForm.resetFields()
  }

  const { mutate: saveModel, isPending } =
    trpc.api.datamodel.computed.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = (values: OperandFormValues) => {
    saveModel({
      id: selectedDataModel!.id,
      config: {
        calendar: selectedDataModelConfig.calendar,
        columns: selectedDataModelConfig.columns,
        value_type: selectedDataModelConfig.value_type,
        account_type: selectedDataModelConfig.account_type,
        account_class: selectedDataModelConfig.account_class,

        operands: values.operands.map((operand) => ({
          ...operand,
          id: Number(operand.id.split('_')[1]),
          nextOperation: operand.nextOperation || undefined,
        })),
      },
    })
  }

  const handleCancel = () => {
    setInitialOperands()
    setIsDirty(false)
  }

  const handleSave = () => {
    operandsForm.submit()
  }

  const handleRowClick = (row: Row<DataModel>) => {
    changeSelectedDatamodelId(row.original.id)
  }

  return (
    <ViewWithPropertiesLayout>
      <ViewContentLayout>
        <ViewToolbar
          addButton={
            <ViewToolbarAddButton
              createModal={<CreateCustomComputedModal type={type} />}
            />
          }
        />

        <ViewContainerLayout>
          {currentTab === TABS.items && selectedDataModel ? (
            <div className={styles.operandConstructorContainer}>
              <OperandConstructorForm
                form={operandsForm}
                onSubmit={handleSubmit}
                onChange={() => setIsDirty(true)}
              />
            </div>
          ) : (
            <DataModelTable
              datamodelGroup={DatamodelGroup.COMPUTED}
              onRowClick={handleRowClick}
              selectedRows={
                selectedDataModel
                  ? { [selectedDataModel?.id]: true }
                  : undefined
              }
            />
          )}
        </ViewContainerLayout>

        <ViewFooterLayout open={isDirty}>
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave} type="primary" loading={isPending}>
              Save
            </Button>
          </Space>
        </ViewFooterLayout>
      </ViewContentLayout>

      <AccountProperties
        onSave={refetchAccount}
        account={account!}
        source={AccountSource.CUSTOM}
      />
    </ViewWithPropertiesLayout>
  )
}

export default CustomComputedViewConfig
