import { ACCOUNT_CLASSES } from './accountClasses'

export const VALUE_TYPES = {
  monetary: 'monetary',
  monetaryRate: 'monetary|rate',
  unitAmount: 'unit|amount',
  unitWhole: 'unit|whole',
  unitRate: 'unit|rate',
  percentage: 'percent',
  percentageRate: 'percent|rate',
} as const

export const VALUE_TYPES_LABELS = {
  [VALUE_TYPES.monetary]: 'Monetary',
  [VALUE_TYPES.monetaryRate]: 'Monetary | Rate',
  [VALUE_TYPES.unitAmount]: 'Unit | Amount',
  [VALUE_TYPES.unitWhole]: 'Unit | Whole',
  [VALUE_TYPES.unitRate]: 'Unit | Rate',
  [VALUE_TYPES.percentage]: 'Percentage',
  [VALUE_TYPES.percentageRate]: 'Percentage | Rate',
} as const

export const VALUE_TYPES_OPTIONS = Object.values(VALUE_TYPES).map((value) => ({
  label: VALUE_TYPES_LABELS[value],
  value,
}))

export const getValueTypeOptionsByClass = (accountClass: string) => {
  return accountClass === ACCOUNT_CLASSES.financial
    ? [
        {
          value: VALUE_TYPES.monetary,
          label: VALUE_TYPES_LABELS[VALUE_TYPES.monetary],
        },
        {
          value: VALUE_TYPES.percentageRate,
          label: VALUE_TYPES_LABELS[VALUE_TYPES.percentageRate],
        },
      ]
    : VALUE_TYPES_OPTIONS
}
