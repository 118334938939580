import { DataModel, useDataModelPageContext } from '@modules/datamodel'
import { trpc } from '@services/trpc'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

// TODO: update DataModel interface
type Ledger = DataModel & { config: { company_id: number } }

const useSyncSelectedCompanies = () => {
  const { selectedDataModel, changeSelectedDatamodelId } =
    useDataModelPageContext()
  const { data: ledgers = [] } = trpc.api.datamodel.listByType.useQuery(
    { type: 'ledger' },
    { placeholderData: (prev) => prev },
  )
  const [params, setParams] = useSearchParams({
    version: '1',
    currency: '1',
    // calendar: '1',
    fiscal_year: '1',
  })
  // ! won't work with multi selected filters
  const companyFilter = params.get('company')

  useEffect(() => {
    if (companyFilter) {
      const ledger = ledgers.find(
        (ledger) =>
          (ledger as Ledger).config.company_id === Number(companyFilter),
      )
      if (ledger) {
        console.log('Found ledger', ledger, ' by company filter')
        changeSelectedDatamodelId(ledger.id)
      } else {
        console.log('No ledger found by company filter')
      }
    }
  }, [companyFilter, ledgers])

  useEffect(() => {
    if (selectedDataModel) {
      setParams(
        (prev) => {
          const current = Object.fromEntries(prev.entries())
          return {
            ...current,
            company: String((selectedDataModel as Ledger).config.company_id),
          }
        },
        { replace: true },
      )
    }
  }, [selectedDataModel])
}

export default useSyncSelectedCompanies
