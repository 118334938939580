import { createStyles } from 'antd-style'

export default createStyles(({ token }) => ({
  searchToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&': {
      button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: token.gray600,
        fontWeight: token.fontWeightStrong,
      },
    },
  },
}))
