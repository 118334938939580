import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      '.ant-form-item': {
        '.ant-picker': {
          width: '100%',
        },
      },
    },
    row: {
      display: 'flex',
      gap: 16,
      marginRight: token.padding,
      marginTop: token.marginSM,
      padding: `${token.paddingSM}px 0`,
      borderTop: `1px solid ${token.gray200}`,
      '> *': {
        flex: 1,
      },
    },
  }
})
