import { DetailsDrawer, ViewContainerLayout } from '@components'
import { trpc } from '@services/trpc'
import { DEPRECATED_AssumptionsTable } from '@components'
import { skipToken } from '@tanstack/react-query'
import type { AssumptionRow } from '@components/DEPRECATED_AssumptionsTable'
import { Flex } from 'antd'
import { useState } from 'react'
import TableFilters, { FiltersState } from './TableFilters'

const visibleColumns = [
  'account_name',
  'accountdriver_name',
  'version_name',
  'company_name',
  'period',
]

interface AssumptionDetailsProps {
  datamodelId?: number
  assumptionId?: number
  open: boolean
}

const AssumptionDetails: React.FC<AssumptionDetailsProps> = ({
  // datamodelId,
  assumptionId,
  open,
}) => {
  const [filters, setFilters] = useState<FiltersState>({
    company: '',
    version: '',
    account: '',
    calendar: '',
    currency: '',
  })

  const {
    data: assumptions = {},
    isLoading: assumptionsIsLoading,
    refetch,
  } = trpc.api.transactions.tableQuery.useQuery(
    skipToken,
    // TODO: skip for now
    // assumptionId
    //   ? {
    //       preset: 'assumptions',
    //       options: {
    //         datamodelId,
    //         filters: {
    //           ...(filters.company ? { company: [filters.company] } : {}),
    //           ...(filters.version ? { version: [filters.version] } : {}),
    //           ...(filters.account ? { account: [filters.account] } : {}),
    //           ...(filters.calendar ? { calendar: [filters.calendar] } : {}),
    //           ...(filters.currency ? { currency: [filters.currency] } : {}),
    //           assumption: [assumptionId],
    //         },
    //       },
    //     }
    //   : skipToken,
  )
  const { tableLayout = [] as AssumptionRow[], tableData = [] } = assumptions
  const filteredLayout = tableLayout.filter((f: { key: string }) =>
    visibleColumns.includes(f.key),
  )

  const handleFilterChange = ({
    filter,
    value,
  }: {
    filter: keyof FiltersState
    value: string
  }) => {
    setFilters((prevFilters: FiltersState) => ({
      ...prevFilters,
      [filter]: value,
    }))
  }

  return (
    <DetailsDrawer open={open}>
      {assumptionId && (
        <Flex vertical gap="small">
          <TableFilters onChange={handleFilterChange} values={filters} />
          <ViewContainerLayout>
            <DEPRECATED_AssumptionsTable<AssumptionRow>
              isLoading={assumptionsIsLoading}
              data={tableData}
              layout={filteredLayout}
              getRowId={(item) =>
                `${item.assumption_id.value}_${item.accountdriver_name.value}`
              }
              refetch={refetch}
              onChange={() => {}}
            />
          </ViewContainerLayout>
        </Flex>
      )}
    </DetailsDrawer>
  )
}

export default AssumptionDetails
