import { Form, FormInstance, Input } from 'antd'
import { useDescriptionFormField } from '@hooks'

interface ViewMetricRowProps {
  index: number
  form: FormInstance
  formName?: string
}

const ViewMetricRow: React.FC<ViewMetricRowProps> = ({
  index,
  form,
  formName = 'items',
}) => {
  useDescriptionFormField({
    form,
    formNamePath: [formName, index],
  })
  return (
    <>
      <Form.Item
        name={[index, 'name']}
        label="Name | ID"
        rules={[{ required: true }]}
      >
        <Input placeholder="Enter name or ID" />
      </Form.Item>
      <Form.Item name={[index, 'description']} label="Description">
        <Input placeholder="Enter description" />
      </Form.Item>
    </>
  )
}

export default ViewMetricRow
