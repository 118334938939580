import useGlobalFilterParams from '@hooks/useGlobalFilterParams'
import React, {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

interface ConfigurationContextType {
  isConfigurationMode: boolean
  changeConfigurationMode: (value: boolean) => void
  toggleConfigurationMode: () => void
  isSidebarCollapsed: boolean
  setIsSidebarCollapsed: (value: boolean) => void
  disableConfigurationModeToggle: () => void
  enableConfigurationModeToggle: () => void
  isConfigurationModeToggleDisabled: boolean
}

const ConfigurationContext = createContext<
  ConfigurationContextType | undefined
>(undefined)

export const useConfigurationMode = () => {
  const context = useContext(ConfigurationContext)

  if (!context) {
    throw new Error(
      '[CONTEXT] "useConfigurationMode" must be used within a ConfigurationProvider',
    )
  }

  return context
}

export const ConfigurationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [params, setParams] = useSearchParams()
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false)
  const [isConfigurationMode, setIsConfigurationMode] = useState(
    () => params.get('isConfig') === 'true',
  )
  const [
    isConfigurationModeToggleDisabled,
    setIsConfigurationModeToggleDisabled,
  ] = useState(false)
  const { pathname } = useLocation()
  const { company, version } = useGlobalFilterParams()

  const companyParam = params.get('company')
  const versionParam = params.get('version')

  const syncConfigParam = useCallback(
    (value: boolean) => {
      setParams(
        (prev) => {
          const current = Object.fromEntries(prev.entries())
          if (value) {
            current.isConfig = 'true'
          } else {
            delete current.isConfig
          }
          return { ...current }
        },
        { replace: true },
      )
    },
    [setParams],
  )

  const toggleConfigurationMode = useCallback(() => {
    setIsConfigurationMode((current) => {
      const newValue = !current
      syncConfigParam(newValue)
      return newValue
    })
  }, [syncConfigParam])

  const changeConfigurationMode = useCallback(
    (value: boolean) => {
      setIsConfigurationMode(value)
      syncConfigParam(value)
    },
    [syncConfigParam],
  )

  const disableConfigurationModeToggle = useCallback(() => {
    setIsConfigurationModeToggleDisabled(true)
  }, [])

  const enableConfigurationModeToggle = useCallback(() => {
    setIsConfigurationModeToggleDisabled(false)
  }, [])

  useLayoutEffect(() => {
    setParams(
      (prev) => {
        const current = Object.fromEntries(prev.entries())
        if (isConfigurationMode) {
          current.isConfig = 'true'
        } else {
          delete current.isConfig
        }
        return {
          ...current,
          company: companyParam || company || '1',
          version: versionParam || version || '1',
        }
      },
      { replace: true },
    )
  }, [pathname])

  return (
    <ConfigurationContext.Provider
      value={{
        isConfigurationMode,
        toggleConfigurationMode,
        changeConfigurationMode,

        isSidebarCollapsed,
        setIsSidebarCollapsed,

        disableConfigurationModeToggle,
        enableConfigurationModeToggle,
        isConfigurationModeToggleDisabled,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  )
}
