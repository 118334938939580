import { Sidebar, SidebarContent, SidebarList } from '@components'
import AccountSidebarToolbarNormal from './AccountsSidebarToolbarNormal'
import { SidebarListByDatatag } from '@components'
import { useState } from 'react'
import { trpc } from '@services/trpc'
import { AccountDriver } from '@server/interfaces/accountdrivers'
import { useSearchParams } from 'react-router-dom'

interface AccountsSidebarNormalProps {
  title?: string
}

const AccountsSidebarNormal: React.FC<AccountsSidebarNormalProps> = ({
  title = 'Account',
}) => {
  const [currentDataTagClass, setCurrentDataTagClass] = useState('company')
  const [params, setParams] = useSearchParams()
  const accountdriverId = params.get('accountdriver')

  const { data: accountdrivers = [] } = trpc.api.accountdrivers.list.useQuery()

  const handleAccountdriverChange = (id: number) => {
    setParams(
      (prev) => {
        const current = Object.fromEntries(prev.entries())
        return {
          ...current,
          accountdriver: String(id),
        }
      },
      { replace: true },
    )
  }

  return (
    <Sidebar title={title}>
      <AccountSidebarToolbarNormal
        onDataTagClassChange={setCurrentDataTagClass}
        currentDataTagClass={currentDataTagClass}
        onAccountdriverChange={handleAccountdriverChange}
      />
      <SidebarContent>
        {accountdriverId ? (
          <SidebarListByDatatag
            currentDataTagClass={currentDataTagClass || null}
          />
        ) : (
          <SidebarList<AccountDriver>
            items={accountdrivers}
            onItemClick={(item) => {
              handleAccountdriverChange(item.id)
            }}
          />
        )}
      </SidebarContent>
    </Sidebar>
  )
}

export default AccountsSidebarNormal
