import { createContext, useContext, ReactNode, SetStateAction } from 'react'
import useDatamodelRows from './useDatamodelRows.hook'
import { DatamodelRowItem } from '../compoundModel/compoundModel.types'
import { RowSelectionState } from '@tanstack/react-table'
import { DataModel } from '@modules/datamodel'

interface DatamodelRowsContextType {
  selectedDatamodelAccountdriverItems: DatamodelRowItem[]
  updateAccountDriverInputSource: (
    value: string | number | boolean,
    id: string | number,
  ) => void
  selectedDatamodelTableRows: RowSelectionState
  selectRows: (updater: SetStateAction<RowSelectionState>) => void

  removeDatamodelRowItem: (item: DatamodelRowItem) => void
  resetDatamodelRows: () => void
  isDatamodelRowsDirty: boolean
  setIsDatamodelRowsDirty: (dirty: boolean) => void
}

const DatamodelRowsContext = createContext<
  DatamodelRowsContextType | undefined
>(undefined)

const useDatamodelRowsContext = () => {
  const context = useContext(DatamodelRowsContext)
  if (context === undefined) {
    throw new Error(
      'useDatamodelRowsContext must be used within a DatamodelRowsProvider',
    )
  }
  return context
}

interface DatamodelRowsProviderProps {
  children: ReactNode
  selectedDataModel?: DataModel | null
}

const DatamodelRowsProvider: React.FC<DatamodelRowsProviderProps> = ({
  children,
  selectedDataModel,
}) => {
  const {
    selectedDatamodelAccountdriverItems,
    updateAccountDriverInputSource,
    selectedDatamodelTableRows,
    selectRows,

    removeItem: removeDatamodelRowItem,
    cancel: resetDatamodelRows,
    isDirty: isDatamodelRowsDirty,
    setIsDirty: setIsDatamodelRowsDirty,
  } = useDatamodelRows(selectedDataModel)

  return (
    <DatamodelRowsContext.Provider
      value={{
        selectedDatamodelAccountdriverItems,
        updateAccountDriverInputSource,
        selectedDatamodelTableRows,
        selectRows,

        removeDatamodelRowItem,
        resetDatamodelRows,
        isDatamodelRowsDirty,
        setIsDatamodelRowsDirty,
      }}
    >
      {children}
    </DatamodelRowsContext.Provider>
  )
}

export { DatamodelRowsProvider, useDatamodelRowsContext }
