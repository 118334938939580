import {
  DatagroupValueColumnDefinition,
  DatamodelColumnDefinition,
} from '@server/interfaces/datamodel'
import { trpc } from '@services/trpc'
import { Select } from 'antd'

interface AssumptionDatatagsEditableCellProps {
  cell: any
  column: any
  getValue: Function
}

const AssumptionDatatagsEditableCell: React.FC<
  AssumptionDatatagsEditableCellProps
> = (props) => {
  const value = props.getValue()
  const { id } = value || {}
  const {
    cell: {
      row: {
        original: { assumption_id },
      },
    },
    column: {
      columnDef: {
        meta: { datamodel, column },
      },
    },
  } = props

  const { id: datagroupId } = column

  const { data: datagroupMembers = [], isFetched } =
    trpc.api.datatags.listDatagroupMembers.useQuery({
      id: datagroupId,
    })
  const updateAssumptionRow =
    trpc.api.assumptions.updateAssumptionRow.useMutation()

  const currentMember = datagroupMembers.find((d) => d.id == id)

  if (!isFetched) return null
  // TODO: show all options for pages with no datamodel selected
  // temporarily show the datagroup value if no datamodel is selected
  if (!datamodel) {
    return currentMember?.value || 'Uncategorized'
  }

  const datagroupColumn = datamodel.config.columns.find(
    (d: any) => d.type == 'datagroup' && d.datagroup_id == datagroupId,
  )

  if (!datagroupColumn) return null

  const onChange = (selectedId: string | number) => {
    updateAssumptionRow.mutate({
      assumptionrow_id: assumption_id,
      datagroup_id: datagroupId,
      datatag_id: selectedId ? Number(selectedId) : null,
    })
  }

  const options = [
    {
      label: 'Uncategorized',
      value: '',
    },
    ...datagroupMembers
      .filter((member) => {
        const datamodelColumnConfig = datamodel.config.columns.find(
          (c: DatamodelColumnDefinition) =>
            c.type == 'datagroup' && c.datagroup_id == datagroupId,
        ) as DatagroupValueColumnDefinition
        return datamodelColumnConfig.values.includes(member.id)
      })
      .map((d) => ({
        label: d.value,
        value: d.id,
      })),
  ]

  const valueHasOption = options.some((o) => o.value == id)
  const defaultValue = (valueHasOption ? id : currentMember?.value) || ''

  return (
    <Select
      options={options}
      onChange={onChange}
      defaultValue={defaultValue}
      variant="borderless"
      popupMatchSelectWidth={false}
    />
  )
}

export default AssumptionDatatagsEditableCell
