import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    root: {
      height: '100vh',
      display: 'flex',
      fontFamily: token.fontFamily,
      fontSize: token.fontSize,
      color: token.colorText,

      // Custom scrollbar styles
      '*::-webkit-scrollbar': {
        width: 12,
        height: 12,
      },
      '*::-webkit-scrollbar-track': {
        background: token.colorBgBase,
        borderRadius: 10,
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: token.gray300,
        borderRadius: 10,
        border: `3px solid ${token.colorBgBase}`,
      },
      '*::-webkit-scrollbar-thumb:hover': {
        backgroundColor: token.gray400,
      },
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      overflow: 'hidden',
    },
  }
})
