import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      '> *:not(:last-child)': {
        borderRight: `1px solid ${token.colorBorder}`,
        paddingRight: token.padding,
        marginRight: token.margin,
      },
    },
  }
})
