import { useConfigurationMode } from '@context/ConfigurationContext'
import CompoundModelNormalView from './CompoundModelViewNormal'
import CompoundModelViewConfig from './CompoundModelViewConfig'
import { DatamodelRowsProvider } from '@modules/datamodel/DatamodelRowsContext'
import { DatatagColumnsProvider } from '@modules/datamodel/DatatagColumnsContext'
import { useDataModelPageContext } from '@modules/datamodel'

const CompoundModelView: React.FC = () => {
  const { isConfigurationMode } = useConfigurationMode()
  const { selectedDataModel } = useDataModelPageContext()

  if (isConfigurationMode) {
    return (
      <DatatagColumnsProvider selectedDataModel={selectedDataModel}>
        <DatamodelRowsProvider selectedDataModel={selectedDataModel}>
          <CompoundModelViewConfig />
        </DatamodelRowsProvider>
      </DatatagColumnsProvider>
    )
  }

  return <CompoundModelNormalView />
}

export default CompoundModelView
