import {
  CreateMenuButton,
  SidebarFilterRowLayout,
  SidebarFilterSelect,
  SidebarFiltersByDatatag,
  SidebarToolbarLayout,
} from '@components'
import { trpc } from '@services/trpc'
import { MenuProps } from 'antd'
import { useMemo } from 'react'
import { useDataModelPageContext } from '../../DataModelPageContext'

const MENU_ITEM_KEYS = {
  ASSUMPTION: 'assumption',
  DATA_MODEL: 'dataModel',
  VIEW: 'view',
} as const

interface DataModelSidebarToolbarNormalProps {
  onNewAssumptionClick: () => void
  currentDataTagClass: string
  onDataTagClassChange: (dataTagClass: string) => void
  dataModelGroup: string
}

const DataModelSidebarToolbarNormal: React.FC<
  DataModelSidebarToolbarNormalProps
> = ({
  onNewAssumptionClick,
  currentDataTagClass,
  onDataTagClassChange,
  dataModelGroup,
}) => {
  const { selectedDataModel, changeSelectedDatamodelId, datamodelGroup } =
    useDataModelPageContext()
  const { data: dataModels = [] } = trpc.api.datamodel.listByType.useQuery({
    type: dataModelGroup,
  })

  // TODO: probably Custom Computed shouldn't be DataModel at all, need to clarify
  const isComputed = datamodelGroup === 'computed'

  const menuItems = useMemo<MenuProps['items']>(() => {
    return isComputed
      ? []
      : [
          {
            key: MENU_ITEM_KEYS.ASSUMPTION,
            label: 'Create Assumption',
            disabled: !selectedDataModel,
          },
        ]
  }, [isComputed, selectedDataModel])

  const dataModelOptions = [
    { label: 'All', value: '' },
    ...dataModels.map((dataModel) => ({
      label: dataModel.name,
      value: dataModel.id,
    })),
  ]

  const handleCreateItemClick = (key: string) => {
    if (key === MENU_ITEM_KEYS.ASSUMPTION) {
      onNewAssumptionClick()
    }
  }

  return (
    <SidebarToolbarLayout>
      <SidebarFilterRowLayout>
        <SidebarFilterSelect
          label="Data model"
          options={dataModelOptions}
          onChange={changeSelectedDatamodelId}
          value={selectedDataModel?.id || ''}
        />
      </SidebarFilterRowLayout>
      <SidebarFilterRowLayout>
        <SidebarFiltersByDatatag
          currentDataTagClass={currentDataTagClass}
          onDataTagClassChange={onDataTagClassChange}
        />
        <CreateMenuButton
          items={menuItems}
          onClick={handleCreateItemClick}
          disabled={isComputed}
        />
      </SidebarFilterRowLayout>
    </SidebarToolbarLayout>
  )
}

export default DataModelSidebarToolbarNormal
