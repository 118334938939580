import { Navigate } from 'react-router-dom'
import CompanyPage from 'pages/datatags/company'
import DatagroupPage from 'pages/datatags/datagroup'
import { datagroupPageLoader } from 'pages/datatags/datagroup'
import PicklistPage from 'pages/datatags/picklist'
import VersionPage from 'pages/version'
import { MainPageLayout } from '@components'

const datagroupsRoutes = [
  {
    index: true,
    element: <Navigate to="/datatags/datagroups/company" replace />,
  },
  {
    path: 'company',
    handle: { title: 'Company', forceConfigurationMode: true },
    Component: CompanyPage,
  },
  {
    path: ':id',
    loader: datagroupPageLoader,
    handle: { title: 'Data group', forceConfigurationMode: true },
    Component: DatagroupPage,
  },
]

const datatagsRoutes = [
  {
    index: true,
    element: <Navigate to="/datatags/datagroups" replace />,
  },
  {
    path: 'datagroups',
    handle: { title: 'Datagroups' },
    element: <MainPageLayout page="datagroups" />,
    children: datagroupsRoutes,
  },
  {
    path: 'versions',
    handle: { title: 'Versions', forceConfigurationMode: true },
    element: <MainPageLayout />,
    children: [
      {
        index: true,
        Component: VersionPage,
      },
    ],
  },
  {
    path: 'picklists',
    handle: { title: 'Picklist', forceConfigurationMode: true },
    element: <MainPageLayout page="picklist" />,
    children: [
      {
        index: true,
        Component: PicklistPage,
      },
    ],
  },
]

export default datatagsRoutes
