import { Form, FormInstance, Input, InputNumber, Select } from 'antd'
import { trpc } from '@services/trpc'
import { useEffect } from 'react'
import { useDataModelPageContext } from '@modules/datamodel'
import { capitalize } from '@utils/strings'
import { DatagroupWithValues } from '@server/interfaces/datagroup'
import useStyles from './TransactionFormRow.styles'

interface TransactionFormRowProps {
  index: number
  form: FormInstance
  formName?: string
  datagroupData: DatagroupWithValues[]
  currencySymbol?: string
}

const TransactionFormRow: React.FC<TransactionFormRowProps> = ({
  index,
  form,
  formName = 'items',
  datagroupData,
  currencySymbol,
}) => {
  const { styles } = useStyles()
  const { selectedDataModel } = useDataModelPageContext()
  const isLedger = selectedDataModel?.type === 'ledger'
  const datamodelLabel = isLedger ? 'company' : 'data model'

  const { data: availableAccounts, isFetched: availableAccountsFetched } =
    trpc.api.ledgers.getAvailableAccounts.useQuery(
      { company_id: selectedDataModel?.config.company_id || 0 },
      { enabled: !!selectedDataModel?.config.company_id },
    )
  const { data: accounts = [] } = trpc.api.accounts.list.useQuery(undefined, {
    enabled: availableAccountsFetched,
  })

  const accountOptions = isLedger
    ? accounts
        .filter((f) => (availableAccounts || []).indexOf(f.id) != -1)
        .map((account) => ({
          value: account.id,
          label: account.name,
        }))
    : accounts.map((account) => ({
        value: account.id,
        label: account.name,
      }))

  const selectedAccountId = Form.useWatch([formName, index, 'account'], form)
  const selectedAccount = accounts.find(
    (account) => account.id === selectedAccountId,
  )

  const drAmount = Form.useWatch([formName, index, 'DR'], form)
  const crAmount = Form.useWatch([formName, index, 'CR'], form)
  const netAmount = drAmount - crAmount || 0

  useEffect(() => {
    if (selectedAccount) {
      form.setFieldValue([formName, index, 'name'], selectedAccount.name)
    }
  }, [selectedAccount, form, formName, index])

  useEffect(() => {
    form.setFieldValue(
      [formName, index, datamodelLabel],
      selectedDataModel?.name,
    )
  }, [selectedDataModel?.name])

  return (
    <>
      <div className={styles.row}>
        <Form.Item
          name={[index, 'name']}
          label="Name | ID (Transaction)"
          aria-label="Transaction Name"
          rules={[
            {
              required: true,
              message: 'Please input transaction name',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item
          name={[index, datamodelLabel]}
          label={capitalize(datamodelLabel)}
          rules={[
            { required: true, message: `Please select ${datamodelLabel}` },
          ]}
        >
          <Select disabled />
        </Form.Item>

        <Form.Item
          name={[index, 'account']}
          label="Account"
          rules={[{ required: true, message: 'Please select account' }]}
          aria-label="Transaction Account"
        >
          <Select
            placeholder="Select account"
            options={accountOptions}
            aria-label="Transaction Account"
          />
        </Form.Item>

        <Form.Item name={[index, 'DR']} label="DR" initialValue={0}>
          <InputNumber prefix={currencySymbol} controls={false} />
        </Form.Item>

        <Form.Item name={[index, 'CR']} label="CR" initialValue={0}>
          <InputNumber prefix={currencySymbol} controls={false} />
        </Form.Item>

        <Form.Item label="Net">
          <InputNumber
            prefix={currencySymbol}
            readOnly
            value={netAmount}
            controls={false}
          />
        </Form.Item>
      </div>

      <div className={styles.row}>
        {datagroupData.map((datagroup) => {
          const label = capitalize(datagroup.name)
          const options = [
            {
              label: 'Uncategorized',
              value: '',
            },
            ...datagroup.values.map((value) => ({
              label: value.value,
              value: value.id,
            })),
          ]
          return (
            <Form.Item
              key={datagroup.id}
              name={[index, 'datatags', datagroup.name]}
              label={label}
              aria-label={`${label} Datagroup`}
              initialValue=""
            >
              <Select options={options} aria-label={`${label} Datagroup`} />
            </Form.Item>
          )
        })}
      </div>
    </>
  )
}

export default TransactionFormRow
