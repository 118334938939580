import { FC, useEffect } from 'react'
import { Checkbox, DrawerProps, Form, Input, message } from 'antd'
import { trpc } from '@services/trpc'
import { PropertiesAccordion, PropertiesDrawer } from '@components'
import type { DatatagInput } from '@modules/datagroup/datagroup.types'
import { skipToken } from '@tanstack/react-query'
import { ViewMetric } from '@server/interfaces/view'

interface ViewMetricPropertiesProps extends DrawerProps {
  viewMetric: ViewMetric | null
  onClose?: () => void
}

const ViewMetricProperties: FC<ViewMetricPropertiesProps> = ({
  viewMetric,
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { refetch } = trpc.api.datatags.listViewMetrics.useQuery(
    viewMetric ? { id: viewMetric.datagroup_id } : skipToken,
  )

  const handleSuccess = () => {
    message.success('View Metric updated successfully')
    refetch()
    form.resetFields()
    onClose?.()
  }

  const { mutate: save, isPending } =
    trpc.api.datatags.createOrUpdateViewMetric.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = (values: ViewMetric) => {
    save(values)
  }

  useEffect(() => {
    if (!viewMetric) return
    form.setFieldsValue(viewMetric)
  }, [form, viewMetric])

  return (
    <PropertiesDrawer<DatatagInput>
      {...props}
      onSubmit={handleSubmit}
      form={form}
      initialValues={viewMetric as ViewMetric}
      confirmLoading={isPending}
    >
      <PropertiesAccordion label={'View Metric'}>
        <Form.Item<ViewMetric>
          name="name"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input the view metric name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item<ViewMetric> name="description" label="Description">
          <Input placeholder="Enter description" />
        </Form.Item>

        <Form.Item<ViewMetric> name="memo" label="Memo">
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder="Enter memo"
          />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="Other Settings">
        <Form.Item
          valuePropName="checked"
          name="inactive"
          aria-label="Inactive"
        >
          <Checkbox>Inactive</Checkbox>
        </Form.Item>
      </PropertiesAccordion>
    </PropertiesDrawer>
  )
}

export default ViewMetricProperties
