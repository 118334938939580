import DummyPage from '@components/DummyPage'
import { Navigate } from 'react-router-dom'

const currencyRoutes = [
  {
    index: true,
    element: <Navigate to="/financial/currency/currency-translation" replace />,
  },
  {
    path: 'currency',
    handle: { title: 'Currency & Rate' },
    Component: DummyPage,
  },
  {
    path: 'currency-translation',
    handle: { title: 'Currency Translation' },
    Component: DummyPage,
  },
  {
    path: 'oci',
    handle: { title: 'OCI' },
    Component: DummyPage,
  },
]

export default currencyRoutes
