import { createStyles } from 'antd-style'

export default createStyles(() => {
  return {
    formLayout: {
      display: 'flex',
      '& > *': {
        flex: 1,
      },
    },
  }
})
