import { COMPANY_CATEGORY_PLURAL_LABELS } from './companyCategories'

export const CATEGORIES = {
  company: 'company',
  entity: 'entity',
  account: 'account',
  account_metric: 'account_metric',
} as const

export const CATEGORIES_LABELS = {
  [CATEGORIES.company]: 'Company',
  [CATEGORIES.entity]: 'Entity',
  [CATEGORIES.account]: 'Account',
  [CATEGORIES.account_metric]: 'Account Metric',
} as const

export const CATEGORIES_PLURAL_LABELS = {
  [CATEGORIES.company]: 'Companies',
  [CATEGORIES.entity]: 'Entities',
  [CATEGORIES.account]: 'Accounts',
  [CATEGORIES.account_metric]: 'Account Metrics',
} as const

export const CATEGORIES_OPTIONS = Object.values(CATEGORIES).map((value) => ({
  label: CATEGORIES_LABELS[value],
  value,
}))

export const ALL_CATEGORIES_PLURAL_LABELS = {
  ...CATEGORIES_PLURAL_LABELS,
  ...COMPANY_CATEGORY_PLURAL_LABELS,
} as const

// TYPES
export type CategoryKey = keyof typeof CATEGORIES
