import { Navigate } from 'react-router-dom'
import accountRoutes from './accounts'
import timeRoutes from './time'
import MainPageLayout from '@components/layouts/MainPageLayout'

const driversSectionRoutes = [
  {
    index: true,
    element: <Navigate to="/drivers/accounts" replace />,
  },
  {
    path: 'accounts',
    handle: { title: 'Accounts' },
    element: <MainPageLayout page="accounts" />,
    children: accountRoutes,
  },
  {
    path: 'time',
    handle: { title: 'Time' },
    element: <MainPageLayout page="time" />,
    children: timeRoutes,
  },
]

export default driversSectionRoutes
