import { FC } from 'react'
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import { Period } from '@server/interfaces/period'

const columnHelper = createColumnHelper<Period>()

// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<Period, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
  columnHelper.accessor((row) => row.year, {
    id: 'year',
    header: 'Year',
  }),
  columnHelper.accessor((row) => row.quarter_name, {
    id: 'quarter_name',
    header: 'Quarter',
  }),
  columnHelper.accessor((row) => row.month_name, {
    id: 'month_name',
    header: 'Month',
  }),
  columnHelper.accessor((row) => row.week_of_year, {
    id: 'week_of_year',
    header: 'Week',
  }),
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Day',
  }),
  columnHelper.accessor((row) => row.day_of_week, {
    id: 'day_of_week',
    header: 'Weekday',
  }),
]

interface PeriodsTableProps extends Partial<TableWidgetProps<Period>> {}

const PeriodsTable: FC<PeriodsTableProps> = ({ ...rest }) => {
  const { data = [] } = trpc.api.periods.list.useQuery(undefined, {
    select: (data) =>
      // TODO: just as example
      data.filter((period) => period.dt_type === 'day' && period.year === 2024),
  })

  return (
    <TableWidget<Period> {...rest} data={data} columns={columns} withIndex />
  )
}

export default PeriodsTable
