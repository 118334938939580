import { createStyles } from 'antd-style'

export default createStyles(() => ({
  emptyWrapper: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  empty: {
    margin: 0,
  },
}))
