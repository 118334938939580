import { FC } from 'react'
import useStyles from './TableWidgetHeader.styles'

interface TableWidgetHeaderProps {
  title?: string
}
const TableWidgetHeader: FC<TableWidgetHeaderProps> = ({ title }) => {
  const { styles } = useStyles()

  return (
    <div className={styles.tableWidgetHeader}>
      <div className={styles.tableWidgetHeaderTitle}>{title}</div>
    </div>
  )
}

export default TableWidgetHeader
