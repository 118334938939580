import { skipToken } from '@tanstack/react-query'
import { Datagroup } from '@modules/datagroup'
import { DataModel } from '@modules/datamodel'
import { AssumptionTableItem } from '@server/interfaces/assumptions'
import {
  DatagroupValueColumnDefinition,
  DatamodelColumnDefinition,
} from '@server/interfaces/datamodel'
import { trpc } from '@services/trpc'
import { InferQueryLikeInput } from '@trpc/react-query/shared'
import { capitalize } from '@utils/strings'
import { useMemo } from 'react'

interface HookOptions {
  datamodel: DataModel | null
  tableQuery:
    | InferQueryLikeInput<typeof trpc.api.transactions.tableQuery>
    | typeof skipToken
  rows: {
    id: string
    label: string
  }[]
}

const useColumnsLayout = ({
  datamodel,
  tableQuery,
  rows: rowsDefinition,
}: HookOptions) => {
  const { data: datagroups = [] } =
    trpc.api.datatags.listDatagroupsByType.useQuery([
      'datagroup',
      'category',
      'system',
    ])

  const { data: initialQueryData = [] as AssumptionTableItem[] } =
    trpc.api.transactions.tableQuery.useQuery(tableQuery)

  const layout = useMemo(() => {
    // TODO: define on BE
    const rows = rowsDefinition.map((row: { id: string; label: string }) => {
      return {
        id: row.id,
        label: row.label,
        type: 'row',
      }
    })

    const cols = initialQueryData
      .filter((f: AssumptionTableItem) => f.type == 'cols')
      .map((c: AssumptionTableItem) => c.datapoint)

    const colDefs = initialQueryData
      .filter((f: AssumptionTableItem) => f.type == 'grouped_cols')
      .map((c: AssumptionTableItem) => {
        if (c.datapoint.children && c.datapoint.children.length > 0) {
          const result = {
            ...c.datapoint,
            type: 'groupedColumns',
          }

          result.children = c.datapoint.children.map((child: any) => {
            const relatedCol = cols.find((col: any) => col.colid === child.id)
            return { ...child, ...relatedCol, type: 'column' }
          })
          return result
        }
        return { ...c.datapoint, type: 'column' }
      })

    const datagroupColumns =
      datagroups.length > 0 && datamodel?.config?.columns
        ? (
            datamodel?.config.columns.filter(
              (col: DatamodelColumnDefinition) => col.type === 'datagroup',
            ) as DatagroupValueColumnDefinition[]
          ).map((col) => {
            const datagroup = datagroups.find(
              (dg: Datagroup) => dg.id === col.datagroup_id,
            )
            return {
              id: datagroup?.id,
              key: datagroup?.name,
              type: 'datagroups',
              label: capitalize(datagroup?.name),
            }
          })
        : []

    return [...rows, ...datagroupColumns, ...colDefs]
  }, [rowsDefinition, initialQueryData, datagroups, datamodel?.config.columns])

  return layout
}

export default useColumnsLayout
