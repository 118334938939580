import { Form, FormInstance } from 'antd'
import PropertiesSidebar from '@components/PropertiesSidebar'
import PropertiesFormContainer from '@components/PropertiesFormContainer'

export interface PropertiesDrawerProps<T> {
  form: FormInstance
  initialValues: T
  onSubmit: (data: T) => void
  confirmLoading?: boolean
  children: React.ReactNode
  name?: string
}

const PropertiesDrawer = <T extends object>({
  form,
  initialValues,
  onSubmit,
  confirmLoading = false,
  children,
  name,
}: PropertiesDrawerProps<T>) => {
  const handleSave = async () => {
    form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
  }

  return (
    <PropertiesSidebar name={name}>
      <PropertiesFormContainer
        onCancel={handleCancel}
        onSave={handleSave}
        saveLoading={confirmLoading}
      >
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          initialValues={initialValues}
        >
          {children}
        </Form>
      </PropertiesFormContainer>
    </PropertiesSidebar>
  )
}

export default PropertiesDrawer
