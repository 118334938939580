import { createStyles } from 'antd-style'

export default createStyles(() => {
  return {
    form: {
      display: 'grid',
      gridTemplateColumns: '1.5fr repeat(3, 1fr)',
      maxWidth: 900,
    },
  }
})
