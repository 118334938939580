import {
  Button,
  CollapseProps,
  Drawer,
  Form,
  FormInstance,
  Skeleton,
  Space,
} from 'antd'
import PropertiesFormCollapse from '@components/PropertiesFormCollapse'
import useStyles from './DEPRECATED_PropertiesDrawer.styles'

type PropertiesProps<T> = {
  data: T
  form: FormInstance
  formItems: CollapseProps['items']
  onSubmit: (data: T) => void
  onClose: () => void
  isLoading?: boolean
  isOpen: boolean
  defaultActiveKey?: string | string[]
  activeKeys?: Array<string>
  confirmLoading?: boolean
}

const DEPRECATED_PropertiesDrawer = <T extends { id: number | string }>({
  data,
  form,
  formItems,
  onSubmit,
  onClose,
  isOpen,
  isLoading = false,
  defaultActiveKey,
  confirmLoading = false,
}: PropertiesProps<T>) => {
  const { styles } = useStyles()

  const handleSave = async () => {
    await form.validateFields()
    form.submit()
  }

  const actions = (
    <Space>
      <Button onClick={onClose}>Cancel</Button>
      <Button type="primary" onClick={handleSave} loading={confirmLoading}>
        Save
      </Button>
    </Space>
  )

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      extra={actions}
      className={styles.propertiesDrawer}
    >
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 8 }} title={false} />
      ) : (
        <Form
          form={form}
          onFinish={onSubmit}
          layout="vertical"
          initialValues={data}
        >
          <PropertiesFormCollapse
            items={formItems}
            defaultActiveKey={defaultActiveKey}
          />
        </Form>
      )}
    </Drawer>
  )
}

export default DEPRECATED_PropertiesDrawer
