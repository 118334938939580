import { Form, FormInstance } from 'antd'
import { trpc } from '@services/trpc'
import { useMemo } from 'react'

export const useCurrencyOptions = (form: FormInstance) => {
  const { data: currencies = [] } = trpc.api.currencies.list.useQuery()

  const currenciesOptions = currencies.map((item) => ({
    value: item.id,
    label: item.name,
  }))

  const selectedCurrency = Form.useWatch('currency', form)

  const currencySymbol = useMemo(
    () =>
      currencies.find((currency) => currency.id === selectedCurrency)?.symbol,
    [currencies, selectedCurrency],
  )

  return { currenciesOptions, currencySymbol }
}
