import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    treeContainer: {
      borderRadius: token.borderRadius,
      padding: token.paddingContentVerticalSM,
    },
    dragOverTree: {
      border: `1px solid ${token.colorPrimary}`,
    },
    rootItem: {
      color: token.colorPrimary,
    },
    item: {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: token.fontSize,
      '&:hover button': {
        display: 'flex',
      },
    },
    deleteButton: {
      '&&': {
        display: 'none',
        width: 20,
        height: 20,
      },
    },
    sum: {
      fontSize: 16,
      fontWeight: 500,
      width: 16,
      height: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }
})
