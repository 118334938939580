import useStyles from './ViewFooterLayout.styles'

interface ViewFooterLayoutProps {
  children: React.ReactNode
  open?: boolean
}

const ViewFooterLayout: React.FC<ViewFooterLayoutProps> = ({
  children,
  open = true,
}) => {
  const { styles, cx } = useStyles()

  return (
    <div className={cx(styles.container, { [styles.closed]: !open })}>
      {children}
    </div>
  )
}

export default ViewFooterLayout
