import { createStyles } from 'antd-style'

export default createStyles(({ token }) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: '100%',
    backgroundColor: token.colorWhite,
    color: token.gray700,
    '&:hover': {
      backgroundColor: token.gray25,
    },
  },
  top: {
    position: 'absolute',
    top: 0,
    paddingTop: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 24,
    maxHeight: 'calc(50% - 12px)',
  },
  title: {
    writingMode: 'vertical-lr',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'uppercase',
    fontSize: token.fontSize,
    fontWeight: 500,
    textAlign: 'right',
    alignContent: 'center',
  },
  collapseButton: {
    '&&': {
      width: 24,
      height: 24,
    },
  },
  chevron: {
    transition: 'rotate 0.3s ease',
  },
}))
