import React from 'react'
import { message } from 'antd'
import { trpc } from '@services/trpc'
import { DatagroupInput } from '@modules/datagroup/datagroup.types.ts'
import CreateDatagroupModal, {
  CreateDatagroupModalProps,
} from '../CreateDatagroupModal'

const CreatePicklistModalModal: React.FC<CreateDatagroupModalProps> = (
  props,
) => {
  const { refetch } =
    trpc.api.datatags.listDatagroupsByType.useQuery('picklist')

  const handleSuccess = () => {
    message.success('Pick list created successfully')
    refetch()
  }

  const getSubmitValues = (values: DatagroupInput) => {
    return {
      ...values,
      type: 'picklist',
    } as DatagroupInput
  }

  return (
    <CreateDatagroupModal
      {...props}
      title="Create Pick list"
      getSubmitValues={getSubmitValues}
      onSuccess={handleSuccess}
    />
  )
}

export default CreatePicklistModalModal
