import { DatePicker, Form, FormInstance, Input, Radio, Select } from 'antd'
import useStyles from './CreateVersionForm.styles'
import {
  CATEGORY_OPTIONS,
  DETAIL_LEVEL_OPTIONS,
} from '@modules/version/version.constants'
import { VersionFormValues } from '@modules/version/version.types'
import useDescriptionFormField from '@hooks/useDescriptionFormField'

interface CreateVersionFormProps {
  form: FormInstance<VersionFormValues>
  onSubmit: (values: VersionFormValues) => void
}

const CreateVersionForm: React.FC<CreateVersionFormProps> = ({
  form,
  onSubmit,
}) => {
  const { styles } = useStyles()
  const category = Form.useWatch('category', form)
  const isLedger = category === 'ledger'

  useDescriptionFormField({ form })

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      className={styles.form}
    >
      <Form.Item<VersionFormValues>
        name="category"
        label="Version category"
        rules={[
          { required: true, message: 'Please select the version category' },
        ]}
      >
        <Radio.Group options={CATEGORY_OPTIONS} />
      </Form.Item>
      <div className={styles.row}>
        <Form.Item<VersionFormValues> name="name" label="Name | ID">
          <Input aria-label="Version Name" />
        </Form.Item>
        <Form.Item<VersionFormValues> name="description" label="Description">
          <Input aria-label="Version Description" />
        </Form.Item>
        {isLedger && (
          <Form.Item<VersionFormValues>
            rules={[
              { required: true, message: 'Please input the detail level' },
            ]}
            name="detail_level"
            label="Detail level"
          >
            <Select options={DETAIL_LEVEL_OPTIONS} aria-label="Detail level" />
          </Form.Item>
        )}
        <Form.Item<VersionFormValues>
          name="start_date"
          label="Start of Version"
        >
          <DatePicker aria-label="Start of version" />
        </Form.Item>
        <Form.Item<VersionFormValues> name="end_date" label="End of Version">
          <DatePicker aria-label="End of version" />
        </Form.Item>
      </div>
    </Form>
  )
}

export default CreateVersionForm
