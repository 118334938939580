import { NavLink, useLocation } from 'react-router-dom'
import ConfigModeToggle from './ConfigModeToggle'
import useStyles from './BottomNavbar.styles'
import AddDatagroupButton from './AddDatagroupButton'
import { useConfigurationMode } from '@context/ConfigurationContext'
import { trpc } from '@services/trpc'
import { Spin } from 'antd'
import { capitalize } from '@utils/strings'
import { groupedTabs, NavItem } from '@constants/navigation'

export type BottomNavTabs = {
  label: string
  items: NavItem[]
}[]

type BottomNavbarProps = {
  page: string
}

const Tab = ({ item }: { item: NavItem }) => {
  const { styles, cx } = useStyles()

  return (
    <li key={item.key}>
      {item.disabled ? (
        <span className={cx(styles.tab, 'disabled')}>{item.label}</span>
      ) : (
        <NavLink
          to={item.path}
          className={({ isActive }) =>
            cx(styles.tab, {
              active: isActive,
            })
          }
        >
          {item.label || item.key}
        </NavLink>
      )}
    </li>
  )
}

const BottomNavbar: React.FC<BottomNavbarProps> = () => {
  const { styles, cx } = useStyles()
  const { isConfigurationMode } = useConfigurationMode()
  const location = useLocation()
  const pathSegments = location.pathname.split('/')

  const tabs = groupedTabs[pathSegments[1] as keyof typeof groupedTabs].find(
    (tab) => tab.key === pathSegments[2],
  )?.children

  const isDatagroups = pathSegments[2] === 'datagroups'

  const { data: datagroups = [], isLoading } =
    trpc.api.datatags.listDatagroupsByType.useQuery('datagroup', {
      select: (datagroups) =>
        datagroups.map((datagroup) => ({
          ...datagroup,
          name: capitalize(datagroup.name),
        })),
    })

  const datagroupTabs = datagroups.map((datagroup) => ({
    label: datagroup.name,
    key: datagroup.id.toString(),
    path: `/datatags/datagroups/${datagroup.id}`,
    disabled: false,
  })) as NavItem[]

  const loadingItem = (
    <Spin size="small">
      <Tab
        item={{
          label: 'Data group',
          key: 'loading',
          path: '',
          disabled: true,
        }}
      />
    </Spin>
  )

  return (
    <div className={styles.navbarWrapper}>
      <ConfigModeToggle />
      <div className={styles.navbarContainer}>
        {tabs?.map((tabsGroup, index) => (
          <ul className={styles.navbar} key={index}>
            {tabsGroup.map((item) => (
              <Tab key={item.key} item={item} />
            ))}
            {index < tabs.length - 1 && <div className={styles.separator} />}
          </ul>
        ))}
        {isDatagroups && (
          <ul className={cx(styles.navbar, styles.datagroups)}>
            {isLoading && (
              <>
                {loadingItem}
                {loadingItem}
                {loadingItem}
              </>
            )}
            {datagroupTabs.map((item) => (
              <Tab key={item.key} item={item} />
            ))}
          </ul>
        )}
        {isDatagroups && isConfigurationMode && <AddDatagroupButton />}
      </div>
    </div>
  )
}

export default BottomNavbar
