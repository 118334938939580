import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    item: {
      fontWeight: 600,
    },
    viewMetricsItem: {
      borderTop: `1px solid ${token.colorBorder}`,
      marginTop: 8,
      paddingTop: 8,
    },
    listWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
  }
})
