import * as XLSX from 'xlsx'

const stringToArrayBuffer = (s: string) => {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
  return buf
}

export const exportToExcel = (dataToExport: Record<string, unknown>[]) => {
  // Create a new workbook and add a worksheet
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(dataToExport)

  // Auto-fit columns
  const colWidths = dataToExport.reduce<number[]>((acc, row) => {
    Object.keys(row).forEach((key, i) => {
      const cellValue = (row as Record<string, unknown>)[key]?.toString() || ''
      acc[i] = Math.max(acc[i] || 0, cellValue.length, key.length)
    })
    return acc
  }, [])

  ws['!cols'] = colWidths.map((width) => ({ wch: width }))

  // Make all first cells in each column bold
  // ! seems not working
  // const range = XLSX.utils.decode_range(ws['!ref'] || 'A1')
  // for (let C = range.s.c; C <= range.e.c; ++C) {
  //   const address = XLSX.utils.encode_cell({ r: range.s.r, c: C })
  //   if (!ws[address]) continue
  //   ws[address].s = { font: { bold: true } }
  // }

  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

  // Generate XLSX file
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })

  // Convert to Blob
  const blob = new Blob([stringToArrayBuffer(wbout)], {
    type: 'application/octet-stream',
  })

  // Create download link
  const url = window.URL.createObjectURL(blob)

  // Trigger download
  const a = document.createElement('a')
  a.href = url
  a.download = 'export.xlsx'
  a.click()

  // Clean up
  window.URL.revokeObjectURL(url)
}
