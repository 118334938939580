import useStyles from './TotalsRow.styles'
import React from 'react'

export type Totals = Record<string, { value: number }>
interface TotalsRowProps {
  totals: Totals
  periods: string[]
  labelOffset?: number
}

const TotalsRow: React.FC<TotalsRowProps> = ({
  totals,
  periods,
  labelOffset,
}) => {
  const { styles } = useStyles()
  if (!totals ) return <></>;
  return (
    <tr id="rows-total" className={styles.totalsRow}>
      <th className={styles.totalsLabel} style={{ width: labelOffset }}>
        Overall total:
      </th>
      {periods.map((period: any) => {
        const netValue = totals[period.colid]?.value || 0
        return (
          <th
            id={`totals-${period.colid}`}
            key={period.colid}
            className={`${styles.totalsCell} ${period.activity_class === 'NET' ? (netValue === 0 ? styles.balanced : styles.imbalanced) : ''}`}
          >
            {period.activity_class === 'BALANCE'
              ? '--'
              : totals[period.colid]?.value !== undefined
                ? totals[period.colid].value.toFixed(2)
                : '0.00'}
          </th>
        )
      })}
    </tr>
  )
}

export default TotalsRow
