import { DatePicker, Form, FormInstance, Input } from 'antd'
import useStyles from './CreateAssumptionForm.styles'
import { type Dayjs } from 'dayjs'
import { BulkFormList } from '@components'
import { BulkFormListValues } from '@components/BulkFormList'

export type FormValues = {
  name: string
  startDate: Dayjs
  endDate: Dayjs
}

type CreateAssumptionFormProps = {
  form: FormInstance
  onSubmit: (values: BulkFormListValues<FormValues>) => void
}

const CreateAssumptionForm: React.FC<CreateAssumptionFormProps> = ({
  form,
  onSubmit,
}) => {
  const { styles } = useStyles()

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <div className={styles.formContainer}>
        <BulkFormList wrapped>
          <Form.Item name="name" label="Name | ID" rules={[{ required: true }]}>
            <Input placeholder="Enter name or ID..." />
          </Form.Item>

          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[{ required: true }]}
            className={styles.dateFormItem}
          >
            <DatePicker picker="month" />
          </Form.Item>
          <Form.Item
            name="endDate"
            label="End Date"
            rules={[{ required: true }]}
            className={styles.dateFormItem}
          >
            <DatePicker picker="month" />
          </Form.Item>
        </BulkFormList>
      </div>
    </Form>
  )
}

export default CreateAssumptionForm
