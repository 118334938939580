import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    popoverContainer: {
      backgroundColor: token.colorWhite,
      borderRadius: token.borderRadiusSM,
      margin: `${token.marginXS}px ${token.marginXXS}px`,
    },
  }
})
