import { createStyles } from 'antd-style'

export default createStyles(() => ({
  propertiesDrawer: {
    '.ant-form-item': {
      label: {
        fontWeight: 500,
        textWrap: 'nowrap',
      },
    },
  },
}))
