import { Sidebar, SidebarList } from '@components'
import useModalState from '@hooks/useModalState'
import { DataModelSidebarToolbarNormal } from '../DataModelSidebarToolbar'
import { CreateAssumptionModal } from '@modules/assumption'
import { SidebarContent, SidebarListByDatatag } from '@components'
import { useState } from 'react'
import { useDataModelPageContext } from '../../DataModelPageContext'
import { trpc } from '@services/trpc'
import { DataModel, DatamodelGroup } from '@modules/datamodel/datamodel.types'

interface DataModelsSidebarNormalProps {
  dataModelGroup: DatamodelGroup
  dataModelGroupName: string
}

const DataModelsSidebarNormal: React.FC<DataModelsSidebarNormalProps> = ({
  dataModelGroup,
  dataModelGroupName,
}) => {
  const assumptionModalState = useModalState()
  const [currentDataTagClass, setCurrentDataTagClass] = useState('company')
  const { selectedDataModel, changeSelectedDatamodelId } =
    useDataModelPageContext()
  const { data: datamodels = [] } = trpc.api.datamodel.listByType.useQuery({
    type: dataModelGroup,
  })

  return (
    <>
      <Sidebar title={dataModelGroupName}>
        <DataModelSidebarToolbarNormal
          onNewAssumptionClick={assumptionModalState.open}
          onDataTagClassChange={setCurrentDataTagClass}
          currentDataTagClass={currentDataTagClass}
          dataModelGroup={dataModelGroup}
        />

        <SidebarContent>
          {selectedDataModel ? (
            <SidebarListByDatatag
              currentDataTagClass={currentDataTagClass || null}
            />
          ) : (
            <SidebarList<DataModel>
              items={datamodels}
              onItemClick={(item) => changeSelectedDatamodelId(item.id)}
            />
          )}
        </SidebarContent>
      </Sidebar>

      {selectedDataModel && (
        <CreateAssumptionModal
          isOpen={assumptionModalState.isOpen}
          onClose={assumptionModalState.close}
          datamodel={selectedDataModel}
        />
      )}
    </>
  )
}

export default DataModelsSidebarNormal
