import { FC, useState } from 'react'
import useStyles from './TableWidgetToolbar.styles'
import {
  Button,
  Divider,
  Flex,
  MenuProps,
  Popover,
  RadioChangeEvent,
  Select,
} from 'antd'
import { TableSheetModeRadio } from '@components'
import { PopoverWithSearch } from '@components/Popover'
import {
  Columns03,
  Dataflow04,
  Rows02,
  SearchMd,
} from '@untitled-ui/icons-react'

interface TableWidgetToolbarProps {
  columns: any[]
  activeColumns?: MenuProps['items']
  onColumnsSelect?: (columns: any[]) => void
  onSearch?: (term: string) => void
  onAssign?: (value: string) => void
}

const TableWidgetToolbar: FC<TableWidgetToolbarProps> = ({
  columns,
  onSearch,
  onAssign,
}) => {
  const { styles } = useStyles()
  const [tableMode, setTableMode] = useState('list')
  const [isSearchPopoverOpen, setIsSearchPopoverOpen] = useState(false)

  const handleModeChange = (e: RadioChangeEvent) => {
    setTableMode(e.target.value)
  }

  const handlePopoverOpenChange = (isPopoverShow: boolean) => {
    setIsSearchPopoverOpen(isPopoverShow)
  }

  const handleOnSearch = (term: string) => {
    onSearch?.(term)
  }

  const handleOnAssign = (value: string) => {
    onAssign?.(value)
  }

  return (
    <div className={styles.tableWidgetToolbar}>
      <div className={styles.toolbarActions}>
        <Popover
          content={<PopoverWithSearch onSearch={handleOnSearch} />}
          trigger="click"
          placement="bottom"
          open={isSearchPopoverOpen}
          onOpenChange={handlePopoverOpenChange}
          arrow={false}
          overlayClassName={styles.searchPopover}
        >
          <Button type="link" icon={<SearchMd width={16} />}>
            Search
          </Button>
        </Popover>
        <Divider type="vertical" className={styles.divider} />
        <TableSheetModeRadio
          options={[
            { icon: <Rows02 width={16} />, value: 'list' },
            { icon: <Dataflow04 width={16} />, value: 'tree', disabled: true },
          ]}
          value={tableMode}
          onChange={handleModeChange}
        />
        <Divider type="vertical" className={styles.divider} />
        <Select
          placeholder="Assigned"
          variant="borderless"
          options={[
            { key: -1, label: 'All' },
            { key: 1, label: 'Assigned', disabled: true },
            { key: 2, label: 'Unassigned', disabled: true },
          ]}
          className={styles.assignedEntitiesSelect}
          onSelect={handleOnAssign}
          popupMatchSelectWidth={false}
        />
        <Divider type="vertical" className={styles.divider} />
        <Flex align="center" gap={4}>
          <Columns03 width={16} /> {columns.length} / {columns.length}
          <span>Columns</span>
        </Flex>
      </div>
    </div>
  )
}

export default TableWidgetToolbar
