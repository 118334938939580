export const CALENDAR_CATEGORY = {
  periodClasses: 'Period Classes',
  periodTypes: 'Period Types',
} as const

export const PERIOD_CLASS = {
  open: 'Open',
  operating: 'Operating',
  close: 'Close',
} as const

export const PERIOD_TYPE = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
  quarter: 'Quarter',
  year: 'Year',
} as const

export const CALENDAR_CATEGORY_OPTIONS = Object.values(CALENDAR_CATEGORY).map(
  (value) => ({
    id: value,
    name: value,
  }),
)

export const PERIOD_CLASS_OPTIONS = Object.values(PERIOD_CLASS).map(
  (value) => ({
    id: value,
    name: value,
  }),
)

export const PERIOD_TYPE_OPTIONS = Object.values(PERIOD_TYPE).map((value) => ({
  id: value,
  name: value,
}))
