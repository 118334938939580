import { Button, Select } from 'antd'
import useStyles from './LookupDatatagListItem.styles'
import { IoCloseOutline } from 'react-icons/io5'
import { DatatagColumnItem } from '@modules/compoundModel/compoundModel.types'
import { trpc } from '@services/trpc'
import { useDatatagColumnsContext } from '@modules/datamodel/DatatagColumnsContext'

interface LookupDatatagListItemProps {
  item: DatatagColumnItem
}

const LookupDatatagListItem: React.FC<LookupDatatagListItemProps> = ({
  item,
}) => {
  const { styles } = useStyles()
  const { removeDatatagColumnItem } = useDatatagColumnsContext()
  const { data: datatags = [] } =
    trpc.api.datatags.listDatagroupMembers.useQuery({ id: Number(item.id) })
  const totalMembers = datatags.length
  const membersCount = item.members?.length

  return (
    <>
      <div className={styles.name}>
        <span>{item.name}</span>
        {membersCount && (
          <span>
            ({item.members?.length} / {totalMembers})
          </span>
        )}
      </div>
      <div className={styles.tagType}>{item.tagType}</div>
      <div className={styles.inputType}>
        <Select disabled defaultActiveFirstOption value="">
          <Select.Option value="">Record context</Select.Option>
        </Select>
      </div>
      <div className={styles.remove}>
        <Button
          disabled={item.disabled}
          icon={<IoCloseOutline size={16} />}
          onClick={() => removeDatatagColumnItem(item)}
        />
      </div>
    </>
  )
}

const LookupDatatagHeaderItem: React.FC = () => {
  const { styles } = useStyles()
  return (
    <>
      <div className={styles.name}>Name | ID</div>
      <div className={styles.tagType}>Tag Type</div>
      <div className={styles.inputType}>Input Type</div>
      <div className={styles.remove}></div>
    </>
  )
}

export { LookupDatatagListItem, LookupDatatagHeaderItem }
