import { CellContext } from '@tanstack/react-table'
import { memo, useCallback, useMemo } from 'react'
import { EditableCell } from '@components/DEPRECATED_AssumptionsTable/EditableCell'
import { AssumptionRow } from '../AssumptionsTable'

export type ColumnMeta = {
  onChange: (
    datatagsId: string,
    value: string,
    options?: {
      rowId: string
      colId: string
      oldValue: string
    },
  ) => void
  onClick?: (cell: any) => void
  column: any
  disabled: boolean
  selectedCellId?: string
}

const activityClassToInt = (s: string) =>
  s == 'CR' ? -1 : s == 'DR' ? 1 : null
const coalesce = (a: any, b: any) => (a ? a : b)

const TableValueCell = memo(
  ({
    row,
    column,
    cell,
    getValue,
  }: CellContext<AssumptionRow, { value: string; isChanged: boolean }>) => {
    const {
      onChange,
      onClick,
      column: colData,
      disabled,
      selectedCellId,
    } = column.columnDef.meta as ColumnMeta
    const { value, isChanged } = getValue() || {
      value: '0.00',
      isChanged: false,
    }

    const rowData = row.original
    let activityClass = null
    if (colData['activity_class'] === 'CR') {
      activityClass = -1
    } else if (colData['activity_class'] === 'DR') {
      activityClass = 1
    }

    const datatags = useMemo(
      () => [
        activityClassToInt(
          coalesce(colData['activity_class'], rowData['activity_class']),
        ),
        coalesce(colData['period_id'], rowData['period_id']),
        rowData['account_id'],
        rowData['version_id'],
        rowData['company_id'],
        rowData['currency_id'],
        rowData['assumption_id'],
        rowData['accountdriver_id'],
      ],
      [activityClass, colData, rowData],
    )

    const datatagsId = JSON.stringify(datatags)

    const handleChange = useCallback(
      (newValue: string) => {
        onChange(datatagsId, newValue, {
          rowId: rowData.rowid,
          colId: column.id,
          oldValue: value,
        })
      },
      [datatagsId],
    )

    const isDisabled =
      disabled || !row.original.editable || colData.editable === false

    const handleClick = () => {
      if (activityClass === null) {
        return
      }
      const context = cell.getContext()
      onClick?.({
        datatagsId,
        ...context,
      })
    }

    if (isDisabled) {
      return (
        <span
          data-disabled
          data-selected={selectedCellId === datatagsId}
          onClick={handleClick}
          style={{
            cursor: 'pointer',
          }}
        >
          {Number(value).toFixed(2)}
        </span>
      )
    }

    return (
      <EditableCell
        value={value as string}
        onSave={handleChange}
        isEditable={row.original.editable && colData.editable !== false}
        isChanged={isChanged}
        isSelected={selectedCellId === datatagsId}
        onClick={handleClick}
      />
    )
  },
)

export default TableValueCell
