import {
  CreateMenuButton,
  SidebarFilterRowLayout,
  SidebarFilterSelect,
  SidebarToolbarLayout,
} from '@components'
import { MenuProps } from 'antd'
import { ACCOUNT_TYPES_WITH_CATEGORIES_OPTIONS } from '@constants/accountTypes'
import React, { useMemo } from 'react'
import useModalState from '@hooks/useModalState'
import { useConfigPageContext } from '@context/ConfigPageContext'
import { AccountCategory } from '@modules/account/account.types'
import { CreateModalProps } from '@components/CreateModal'

const MENU_ITEM_KEYS = {
  ITEM: 'metric',
  VIEW: 'view',
} as const

interface AccountSidebarConfigToolbarProps {
  name: string
  createModal?:
    | React.ReactElement<CreateModalProps>
    | React.FC<CreateModalProps>
}

const AccountSidebarConfigToolbar = <T extends object>({
  name,
  createModal,
}: AccountSidebarConfigToolbarProps) => {
  const modalState = useModalState()
  const { setSelectedCategory, selectedCategory } = useConfigPageContext<
    T,
    AccountCategory
  >()

  const handleCreateItemClick = (key: string) => {
    if (key === MENU_ITEM_KEYS.ITEM) {
      modalState.open()
    }
  }

  const menuItems = useMemo<MenuProps['items']>(() => {
    return [
      {
        key: MENU_ITEM_KEYS.ITEM,
        label: `Create ${name}`,
      },
      {
        key: MENU_ITEM_KEYS.VIEW,
        label: 'Create View',
        disabled: true,
      },
    ]
  }, [])

  return (
    <>
      <SidebarToolbarLayout>
        <SidebarFilterRowLayout>
          <SidebarFilterSelect
            label="Account Category"
            options={ACCOUNT_TYPES_WITH_CATEGORIES_OPTIONS}
            onChange={(_v, option) => {
              const optionData = option as {
                value: string
                label: string
                propertyType: string
              }
              const newCategory =
                optionData.value === ''
                  ? null
                  : {
                      id: optionData.value,
                      name: optionData.label,
                      propertyType: optionData.propertyType as
                        | 'type'
                        | 'category',
                    }
              setSelectedCategory(newCategory)
            }}
            value={selectedCategory?.id || ''}
            optionRender={(option) => {
              return option.data.propertyType === 'category' ? (
                <b>{option.label}</b>
              ) : (
                option.label
              )
            }}
          />
          <CreateMenuButton items={menuItems} onClick={handleCreateItemClick} />
        </SidebarFilterRowLayout>
      </SidebarToolbarLayout>

      {createModal &&
        (React.isValidElement(createModal)
          ? React.cloneElement(createModal, {
              open: modalState.isOpen,
              onClose: modalState.close,
            })
          : React.createElement(createModal, {
              open: modalState.isOpen,
              onClose: modalState.close,
            }))}
    </>
  )
}

export default AccountSidebarConfigToolbar
