import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      borderRadius: token.borderRadius,
      border: `1px solid ${token.colorBorder}`,

      display: 'flex',
      flexDirection: 'column',
      '> div': {
        display: 'flex',
        ':not(:last-child)': {
          borderBottom: `1px solid ${token.colorBorder}`,
        },
      },
    },
    operandForm: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: token.paddingSM,
      padding: token.paddingXS,
      borderRight: `1px solid ${token.colorBorder}`,
    },
    operand: {
      display: 'flex',
      flexDirection: 'column',
    },
    accountDriverRow: {
      display: 'flex',
      gap: token.paddingXS,
    },
    accountDriverItem: {
      '&&': {
        flex: 3,
      },
    },
    timeValueItem: {
      '&&': {
        flex: 1,
      },
    },
  }
})
