import {
  Drivers,
  Financial,
  ImportExport,
  Insights,
  Maintenance,
  Operational,
  Security,
  Workflow,
  Home,
  Datatags,
} from '@components/ui/Icons/MenuIcons'
import ledgerRoutes from '@routes/financial/ledger'
import adjustmentsRoutes from '@routes/financial/adjustments'
import currencyRoutes from '@routes/financial/currency'
import consolidationRoutes from '@routes/financial/consolidation'
import incomeRoutes from '@routes/operational/income'
import expensesRoutes from '@routes/operational/expenses'
import assetsRoutes from '@routes/operational/assets'

export type NavItem = {
  key: string
  label?: string
  path: string
  disabled?: boolean
  iconComponent?: React.FC<{ width?: number }>
  children?: NavItem[][]
}

const datatagTabs: NavItem[] = [
  {
    key: 'datagroups',
    label: 'Data Groups',
    path: '/datatags/datagroups',
    children: [
      [
        {
          key: 'company',
          label: 'Company',
          path: '/datatags/datagroups/company',
        },
      ],
    ],
  },
  {
    key: 'versions',
    label: 'Versions',
    path: '/datatags/versions',
  },
  {
    key: 'picklists',
    label: 'Picklists & Fields',
    path: '/datatags/picklists',
  },
]

const driversTabs: NavItem[] = [
  {
    key: 'accounts',
    label: 'Accounts',
    path: '/drivers/accounts',
    children: [
      [
        {
          key: 'account',
          label: 'Ledger Account',
          path: '/drivers/accounts/account',
        },
        {
          key: 'metric',
          label: 'Metric',
          path: '/drivers/accounts/metric',
        },
      ],
      [
        {
          key: 'custom-account',
          label: 'Custom Account',
          path: '/drivers/accounts/custom-account',
        },
        {
          key: 'custom-metric',
          label: 'Custom Metric',
          path: '/drivers/accounts/custom-metric',
        },
      ],
      [
        {
          key: 'lookup',
          label: 'Lookup',
          path: '/drivers/accounts/lookup',
        },
        {
          key: 'tiered',
          label: 'Tiered',
          path: '/drivers/accounts/tiered',
          disabled: true,
        },
      ],
    ],
  },
  {
    key: 'time',
    label: 'Time',
    path: '/drivers/time',
    children: [
      [
        {
          key: 'calendar',
          label: 'Calendar',
          path: '/drivers/time/calendar',
        },
      ],
    ],
  },
]

const financialTabs: NavItem[] = [
  {
    key: 'ledger',
    label: 'Ledger',
    path: '/financial/ledger',
    children: [
      ledgerRoutes
        .filter((route) => !!route.path)
        .map((route) => ({
          key: route.path!,
          label: route.handle?.title,
          path: `/financial/ledger/${route.path}`,
        })),
    ],
  },
  {
    key: 'adjustments',
    label: 'Adjustments',
    path: '/financial/adjustments',
    children: [
      adjustmentsRoutes
        .filter((route) => !!route.path)
        .map((route) => ({
          key: route.path!,
          label: route.handle?.title,
          path: `/financial/adjustments/${route.path}`,
        })),
    ],
  },
  {
    key: 'currency',
    label: 'Currency',
    path: '/financial/currency',
    children: [
      currencyRoutes
        .filter((route) => !!route.path)
        .map((route) => ({
          key: route.path!,
          label: route.handle?.title,
          path: `/financial/adjustments/${route.path}`,
        })),
    ],
  },
  {
    key: 'consolidation',
    label: 'Consolidation',
    path: '/financial/consolidation',
    children: [
      consolidationRoutes
        .filter((route) => !!route.path)
        .map((route) => ({
          key: route.path!,
          label: route.handle?.title,
          path: `/financial/consolidation/${route.path}`,
        })),
    ],
  },
]

const operationalTabs: NavItem[] = [
  {
    key: 'income',
    label: 'Income',
    path: '/operational/income',
    children: [
      incomeRoutes
        .filter((route) => !!route.path)
        .map((route) => ({
          key: route.path!,
          label: route.handle?.title,
          path: `/operational/income/${route.path}`,
        })),
    ],
  },
  {
    key: 'expenses',
    label: 'Expenses',
    path: '/operational/expenses',
    children: [
      expensesRoutes
        .filter((route) => !!route.path)
        .map((route) => ({
          key: route.path!,
          label: route.handle?.title,
          path: `/operational/expenses/${route.path}`,
        })),
    ],
  },
  {
    key: 'assets',
    label: 'Assets & Liabilities',
    path: '/operational/assets',
    children: [
      assetsRoutes
        .filter((route) => !!route.path)
        .map((route) => ({
          key: route.path!,
          label: route.handle?.title,
          path: `/operational/assets/${route.path}`,
        })),
    ],
  },
]

const insightsTabs: NavItem[] = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    path: '/insights/dashboard',
    disabled: true,
  },
  {
    key: 'interactive-analysis',
    label: 'Interactive Analysis',
    path: '/insights/interactive-analysis',
    disabled: true,
  },
  {
    key: 'report',
    label: 'Report',
    path: '/insights/report',
    disabled: true,
  },
]

export const groupedTabs = {
  datatags: datatagTabs,
  drivers: driversTabs,
  financial: financialTabs,
  operational: operationalTabs,
  insights: insightsTabs,
}

export const menuItems: NavItem[] = [
  {
    key: 'home',
    label: 'Home',
    path: '/',
    iconComponent: Home,
  },
  {
    key: 'data-tags',
    label: 'Data Tags',
    path: '/datatags',
    iconComponent: Datatags,
  },
  {
    key: 'drivers',
    label: 'Drivers',
    path: '/drivers',
    iconComponent: Drivers,
  },
  {
    key: 'operational',
    label: 'Operational',
    path: '/operational',
    iconComponent: Operational,
  },
  {
    key: 'financial',
    label: 'Financial',
    path: '/financial',
    iconComponent: Financial,
  },
  {
    key: 'insights',
    label: 'Insights',
    path: '/insights',
    iconComponent: Insights,
  },
  {
    key: 'import-export',
    label: 'Import\nExport',
    path: '/import-export',
    iconComponent: ImportExport,
    disabled: true,
  },
  {
    key: 'workflow',
    label: 'Workflow',
    path: '/workflow',
    iconComponent: Workflow,
    disabled: true,
  },
  {
    key: 'security',
    label: 'Security',
    path: '/security',
    iconComponent: Security,
    disabled: true,
  },
  {
    key: 'maintenance',
    label: 'Maintenance',
    path: '/maintenance',
    iconComponent: Maintenance,
    disabled: true,
  },
] as const
