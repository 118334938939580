import { SetStateAction, useEffect, useState } from 'react'
import { RowSelectionState } from '@tanstack/react-table'
import {
  ViewContainerLayout,
  ViewContentLayout,
  ViewFooterLayout,
  ViewToolbar,
  ViewToolbarAddButton,
  ViewWithPropertiesLayout,
} from '@components'
import { trpc } from '@services/trpc'
import { Button, Space, message } from 'antd'
import AssignAccountsView from './AssignAccountsView'
import MetricsTableView from './MetricsTableView'
import { TABS, useConfigPageContext } from '@context/ConfigPageContext'
import { MetricWithAccounts } from '@modules/metric/metric.types'
import { type AccountCategory, AccountProperties } from '@modules/account'
import { AccountSource } from '@constants/accountTypes'
import CreateMetricModal from '../../CreateMetricModal'

const getSelectedRows = (selectedAccountsIds: number[]) => {
  return selectedAccountsIds.reduce((acc, accountId) => {
    acc[accountId] = true
    return acc
  }, {} as RowSelectionState)
}

const MetricConfigView: React.FC = () => {
  const { selectedItem, currentTab } = useConfigPageContext<
    MetricWithAccounts,
    AccountCategory
  >()

  const { data: accounts = [], refetch: refetchAccounts } =
    trpc.api.accounts.list.useQuery()
  const { refetch: refetchMetrics } = trpc.api.metrics.list.useQuery()

  const { mutate: saveMetric, isPending } =
    trpc.api.metrics.createOrUpdateById.useMutation({
      onSuccess: () => {
        message.success('Metric saved')
        setIsDirty(false)
        refetchAccounts()
        refetchMetrics()
      },
      onError: (error) => {
        message.error(error.message)
      },
    })

  const [isDirty, setIsDirty] = useState(false)

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>(
    getSelectedRows(selectedItem?.accounts || []),
  )
  const assignedAccounts = accounts.filter(
    (account) => selectedRows[account.id],
  )

  const handleAssignAccount = (updater: SetStateAction<RowSelectionState>) => {
    setIsDirty(true)
    setSelectedRows(updater)
  }

  const handleCancel = () => {
    setIsDirty(false)
    const initiallySelected = getSelectedRows(selectedItem?.accounts || [])
    setSelectedRows(initiallySelected)
  }

  const handleSave = () => {
    saveMetric({
      id: selectedItem?.id,
      name: selectedItem?.name,
      accounts: assignedAccounts.map((account) => account.id),
    })
  }

  useEffect(() => {
    setSelectedRows(getSelectedRows(selectedItem?.accounts || []))
    setIsDirty(false)
  }, [selectedItem])

  return (
    <ViewWithPropertiesLayout>
      <ViewContentLayout>
        <ViewToolbar
          addButton={<ViewToolbarAddButton createModal={CreateMetricModal} />}
        />

        <ViewContainerLayout>
          {currentTab === TABS.items && selectedItem ? (
            <AssignAccountsView
              selected={selectedRows}
              onSelectChange={handleAssignAccount}
            />
          ) : (
            <MetricsTableView />
          )}
        </ViewContainerLayout>

        <ViewFooterLayout open={isDirty}>
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave} type="primary" loading={isPending}>
              Save
            </Button>
          </Space>
        </ViewFooterLayout>
      </ViewContentLayout>

      <AccountProperties
        onSave={refetchMetrics}
        account={selectedItem}
        source={AccountSource.METRIC}
      />
    </ViewWithPropertiesLayout>
  )
}

export default MetricConfigView
