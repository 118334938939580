import { FC, useEffect, useMemo } from 'react'
import { DatePicker, Form, Input, Select, Skeleton, message } from 'antd'
import { trpc } from '@services/trpc'
import dayjs, { Dayjs } from 'dayjs'
import {
  PropertiesAccordion,
  PropertiesFormContainer,
  PropertiesSidebar,
} from '@components'
import { skipToken } from '@tanstack/react-query'

const mapStringPeriodToDayjsArray = (
  period: string,
): { startDate: Dayjs; endDate: Dayjs } => {
  const [leftPart, rightPart] = period.split(',')
  const startMarker = leftPart[0]
  const startDate = leftPart.replace(startMarker, '')
  const startDayjs =
    startMarker === '(' ? dayjs(startDate).add(1, 'day') : dayjs(startDate)
  const endMarker = rightPart[rightPart.length - 1]
  const endDate = rightPart.replace(endMarker, '')
  const endDayjs =
    endMarker === ')' ? dayjs(endDate).subtract(1, 'day') : dayjs(endDate)
  return { startDate: startDayjs, endDate: endDayjs }
}

type AssumptionFormValues = {
  name: string
  description?: string
  memo?: string

  startDate: Dayjs
  endDate: Dayjs

  company?: number
  currency?: number

  config?: {
    params: {
      [key: string]: number
    }
  }
}

interface AssumptionPropertiesProps {
  assumptionId?: number
  onRefetch: () => void
}

const AssumptionProperties: FC<AssumptionPropertiesProps> = ({
  assumptionId,
  onRefetch,
}) => {
  const [form] = Form.useForm()
  // const { data: companies = [] } = trpc.api.companies.list.useQuery()
  const {
    data: assumption,
    isLoading: isLoadingAssumption,
    refetch: refetchAssumption,
  } = trpc.api.assumptions.getById.useQuery(
    assumptionId ? { id: assumptionId } : skipToken,
  )
  const { data: assumptionParams, isLoading: isLoadingAssumptionParams } =
    trpc.api.assumptions.listAssumptionParams.useQuery(
      assumptionId ? { assumption_id: assumptionId } : skipToken,
    )
  const { data: datamodel } = trpc.api.datamodel.getById.useQuery(
    assumption?.datamodel_id ? { id: assumption.datamodel_id } : skipToken,
  )
  const { data: metrics } = trpc.api.metrics.list.useQuery()
  const { data: accounts = [] } = trpc.api.accounts.list.useQuery()

  const { data: recognitionSchedules = [] } =
    trpc.api.recognition.list.useQuery()

  const handleSuccess = () => {
    message.success('Assumption updated successfully')
    onRefetch()
    refetchAssumption()
    form.resetFields()
  }

  const { mutateAsync: saveAssumption, isPending } =
    trpc.api.assumptions.createOrUpdate.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const initialValues = useMemo(
    () =>
      assumption
        ? {
            ...assumption,
            ...mapStringPeriodToDayjsArray(assumption.period),
          }
        : {},
    [assumption],
  )

  const handleSubmit = (values: AssumptionFormValues) => {
    const { startDate, endDate, name, description, memo } = values
    const config = assumption?.config
    const startDateFormatted = startDate.startOf('month').format('YYYY-MM-DD')
    const endDateFormatted = endDate.endOf('month').format('YYYY-MM-DD')
    const stringifiedPeriod = `[${startDateFormatted},${endDateFormatted}]`

    const datatags: Array<null | number> = [
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]
    // if (company) {
    //   datatags[4] = Number(company)
    // }

    const valuesToSubmit = {
      id: assumption?.assumptiongroup_id,
      name,
      description: description || '',
      memo: memo || '',
      period: stringifiedPeriod,
      datatags,
      datamodel_id: assumption!.datamodel_id,
      config: { ...config, ...values.config },
    }
    saveAssumption(valuesToSubmit)
  }

  const handleCancel = () => {
    form.resetFields()
  }

  const handleSave = () => {
    form.submit()
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <PropertiesSidebar name={assumption?.name}>
      <PropertiesFormContainer
        onCancel={handleCancel}
        onSave={handleSave}
        saveLoading={isPending}
      >
        {isLoadingAssumption || isLoadingAssumptionParams ? (
          <Skeleton title={false} active />
        ) : (
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={initialValues}
            // className={styles.form}
          >
            <PropertiesAccordion label={datamodel?.name}>
              <Form.Item<AssumptionFormValues>
                name="name"
                label="Name"
                rules={[{ required: true }]}
              >
                <Input placeholder="Enter name or ID..." />
              </Form.Item>
              <Form.Item<AssumptionFormValues>
                name="description"
                label="Description"
              >
                <Input placeholder="Enter a description..." />
              </Form.Item>
              <Form.Item<AssumptionFormValues>
                name="memo"
                label="Memo/keywords"
              >
                <Input.TextArea
                  autoSize={{ minRows: 4, maxRows: 4 }}
                  placeholder="Enter memo or keywords..."
                />
              </Form.Item>
              <Form.Item<AssumptionFormValues>
                name="startDate"
                label="Start Date"
                rules={[{ required: true }]}
              >
                <DatePicker picker="month" />
              </Form.Item>
              <Form.Item<AssumptionFormValues>
                name="endDate"
                label="End Date"
                rules={[{ required: true }]}
              >
                <DatePicker picker="month" />
              </Form.Item>
            </PropertiesAccordion>

            <PropertiesAccordion label="Data Groups">
              <Form.Item<AssumptionFormValues> name="company" label="Company">
                <Select placeholder="Select a company" options={[]} />
              </Form.Item>
              <Form.Item<AssumptionFormValues> name="currency" label="Currency">
                <Select placeholder="Select currency" disabled />
              </Form.Item>
              {/* {datamodel?.config?.columns?.map((row) => (
              // TODO: add datamodel datagroups
              ))} */}
            </PropertiesAccordion>

            <PropertiesAccordion label="[from params]">
              {assumptionParams?.map((param: any) => {
                let options
                if (param.type === 'account' && param.metric_id) {
                  const metric = metrics?.find((m) => m.id === param.metric_id)
                  options = accounts.filter((a) =>
                    metric?.accounts?.includes(a.id),
                  )
                }
                if (param.type == 'recognitionSchedule') {
                  options = recognitionSchedules as any
                }
                // TODO: add recognition schedule options
                return (
                  <Form.Item<AssumptionFormValues>
                    key={param.name}
                    name={['config', 'params', param.name]}
                    label={param.name}
                  >
                    <Select
                      options={options}
                      fieldNames={{ label: 'name', value: 'id' }}
                      placeholder="Select an option..."
                    />
                  </Form.Item>
                )
              })}
            </PropertiesAccordion>
          </Form>
        )}
      </PropertiesFormContainer>
    </PropertiesSidebar>
  )
}

export default AssumptionProperties
