import {
  CreateMenuButton,
  SidebarFilterRowLayout,
  SidebarFilterSelect,
  SidebarToolbarLayout,
} from '@components'
import { MenuProps } from 'antd'
import { useMemo } from 'react'

const MENU_ITEM_KEYS = {
  DATA_MODEL: 'dataModel',
  VIEW: 'view',
} as const

interface DataModelSidebarToolbarConfigProps {
  onNewModelClick: () => void
  onNewViewClick: () => void
  dataModelGroupName: string
}

const DataModelSidebarToolbarConfig: React.FC<
  DataModelSidebarToolbarConfigProps
> = ({ onNewModelClick, onNewViewClick, dataModelGroupName }) => {
  const menuItems = useMemo<MenuProps['items']>(() => {
    return [
      {
        key: MENU_ITEM_KEYS.DATA_MODEL,
        label: 'Create Data Model',
      },
      {
        key: MENU_ITEM_KEYS.VIEW,
        label: 'Create View',
      },
    ]
  }, [])

  const handleCreateItemClick = (key: string) => {
    if (key === MENU_ITEM_KEYS.DATA_MODEL) {
      onNewModelClick()
    }
    if (key === MENU_ITEM_KEYS.VIEW) {
      onNewViewClick()
    }
  }

  return (
    <SidebarToolbarLayout>
      <SidebarFilterRowLayout>
        <SidebarFilterSelect
          label="Data model group"
          options={[{ label: dataModelGroupName, value: dataModelGroupName }]}
          disabled
          defaultValue={dataModelGroupName}
        />
        <CreateMenuButton items={menuItems} onClick={handleCreateItemClick} />
      </SidebarFilterRowLayout>
    </SidebarToolbarLayout>
  )
}

export default DataModelSidebarToolbarConfig
