import { Button, Switch } from 'antd'
import useStyles from './CompoundModelDatatagListItem.styles'
import { IoCloseOutline } from 'react-icons/io5'
import { DatatagColumnItem } from '@modules/compoundModel/compoundModel.types'
import { trpc } from '@services/trpc'
import { useDatatagColumnsContext } from '@modules/datamodel/DatatagColumnsContext'
import { skipToken } from '@tanstack/react-query'

interface CompoundModelDatatagListItemProps {
  item: DatatagColumnItem
}

const CompoundModelDatatagListItem: React.FC<
  CompoundModelDatatagListItemProps
> = ({ item }) => {
  const { styles } = useStyles()
  const { removeDatatagColumnItem } =
    useDatatagColumnsContext<DatatagColumnItem>()

  const { data: datatags = [] } =
    trpc.api.datatags.listDatagroupMembers.useQuery(
      item.type === 'datagroup' ? { id: Number(item.id) } : skipToken,
    )
  const totalMembers = datatags.length
  const membersCount = item.members?.length

  return (
    <>
      <div className={styles.name}>
        <span>{item.name}</span>
        {membersCount && (
          <span>
            ({item.members?.length} / {totalMembers})
          </span>
        )}
      </div>
      <div className={styles.tagType}>{item.tagType}</div>
      <div className={styles.allocate}>
        <Switch disabled value={item.allocate} />
      </div>
      <div className={styles.remove}>
        <Button
          disabled={item.disabled}
          icon={<IoCloseOutline size={16} />}
          onClick={() => removeDatatagColumnItem(item)}
        />
      </div>
    </>
  )
}

const CompoundModelDatatagHeaderItem: React.FC = () => {
  const { styles } = useStyles()
  return (
    <>
      <div className={styles.name}>Name | ID</div>
      <div className={styles.tagType}>Tag Type</div>
      <div className={styles.allocate}>Allocate</div>
      <div className={styles.remove}></div>
    </>
  )
}

export { CompoundModelDatatagListItem, CompoundModelDatatagHeaderItem }
