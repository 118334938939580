import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    header: {
      height: 28,
      backgroundColor: token.blue50,
      color: token.blue700,
      padding: `0 ${token.paddingXS}px`,
      marginBottom: token.marginXXS,
      cursor: 'pointer',
      borderRadius: token.borderRadiusSM,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: token.fontSizeSM,
      fontWeight: token.fontWeightStrong,
    },
    headerLabel: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: token.fontWeightStrong,
    },
    headerIcon: {
      transition: 'rotate 0.3s ease-out',
    },
    headerIconOpened: {
      rotate: '180deg',
    },
    content: {
      overflow: 'hidden',
      transition: 'max-height 0.3s ease-out',
    },
  }
})
