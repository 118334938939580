import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    select: {
      height: 27,
      '&& .ant-select-selector': {
        padding: 0,
      },
      '& .ant-select-arrow': {
        color: token.gray600,
      },
    },
    label: {
      color: token.colorTextSecondary,
      fontSize: token.fontSizeXS,
      lineHeight: 1.2,
      marginBottom: 2,
    },
    value: {
      fontWeight: 500,
      lineHeight: 1.2,
    },
  }
})
