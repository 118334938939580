import useStyles from './PopoverWithSearch.styles'
import { Button, Input } from 'antd'
import PopoverLayout from '@components/layouts/PopoverLayout'
import { LuSearch } from 'react-icons/lu'
const { Search } = Input

interface SearchToolbarProps {
  onSearch: (value: string) => void
}

const SearchToolbar: React.FC<SearchToolbarProps> = ({ onSearch }) => {
  const { styles } = useStyles()

  return (
    <PopoverLayout>
      <div className={styles.searchToolbar}>
        <Search
          placeholder="Type to search"
          enterButton={<Button icon={<LuSearch size={16} />}>Search</Button>}
          onSearch={onSearch}
        />
      </div>
    </PopoverLayout>
  )
}

export default SearchToolbar
