import { Row } from '@tanstack/react-table'
import useModalState from '@hooks/useModalState'
import { Version } from '@modules/version'
import {
  ViewContainerLayout,
  ViewContentLayout,
  ViewToolbar,
  ViewToolbarAddButton,
  ViewWithPropertiesLayout,
} from '@components'
import { trpc } from '@services/trpc'
import { message } from 'antd'
import { Category, useConfigPageContext } from '@context/ConfigPageContext'
import VersionProperties from './VersionProperties'
import VersionsTable from './VersionTable'
import CreateVersionModal from '../CreateVersionModal'

const VersionView: React.FC = () => {
  const { selectedItem, setSelectedItem } = useConfigPageContext<
    Version,
    Category
  >()
  const drawerState = useModalState()

  const { refetch } = trpc.api.versions.list.useQuery()

  const handleSuccess = () => {
    message.success('Assumption updated successfully')
    refetch()
  }

  const { mutateAsync: updateVersion } =
    trpc.api.versions.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleRowClick = (row: Row<Version>) => {
    if (selectedItem === row.original) {
      setSelectedItem(null)
    } else {
      setSelectedItem(row.original)
    }
  }
  const handleSwitch = async (values: Version) => {
    await updateVersion(values)
  }

  // const isPropertiesPanelDisabled =
  //   !selectedItem || selectedItem.name === 'Actuals'

  return (
    <ViewWithPropertiesLayout>
      <ViewContentLayout>
        <ViewToolbar
          addButton={<ViewToolbarAddButton createModal={CreateVersionModal} />}
          forceConfigMode
        />
        <ViewContainerLayout>
          <VersionsTable
            handleSwitch={handleSwitch}
            onRowClick={handleRowClick}
            selectedRows={
              selectedItem ? { [selectedItem.id]: true } : undefined
            }
          />
        </ViewContainerLayout>
      </ViewContentLayout>

      <VersionProperties
        onSave={refetch}
        values={selectedItem}
        onClose={drawerState.close}
      />
    </ViewWithPropertiesLayout>
  )
}

export default VersionView
