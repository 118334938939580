import React, { useEffect } from 'react'
import { PageContentLayout } from '@components'
import {
  DatagroupPageProvider,
  DatagroupSidebar,
  DatagroupConfigView,
} from '@modules/datagroup'
import { ConfigPageProvider } from '@context/ConfigPageContext'
import { useConfigurationMode } from '@context/ConfigurationContext'

const PicklistPage: React.FC = () => {
  const { disableConfigurationModeToggle, enableConfigurationModeToggle } =
    useConfigurationMode()

  useEffect(() => {
    disableConfigurationModeToggle()
    return () => {
      enableConfigurationModeToggle()
    }
  }, [])

  return (
    <DatagroupPageProvider datagroup={null}>
      <ConfigPageProvider>
        <PageContentLayout>
          <DatagroupSidebar />
          <DatagroupConfigView />
        </PageContentLayout>
      </ConfigPageProvider>
    </DatagroupPageProvider>
  )
}
export default PicklistPage
