import { Spin } from 'antd'

interface GlobalLoadingProps {
  percent?: number
}

const GlobalLoading: React.FC<GlobalLoadingProps> = ({ percent }) => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Spin percent={percent} size="large" />
    </div>
  )
}

export default GlobalLoading
