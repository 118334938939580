import { useConfigurationMode } from '@context/ConfigurationContext'
import AccountsSidebarNormal from './AccountsSidebarNormal'
import AccountsSidebarConfig from './AccountsSidebarConfig'

const AccountsSidebar = () => {
  const { isConfigurationMode } = useConfigurationMode()

  if (isConfigurationMode) {
    return <AccountsSidebarConfig />
  }
  return <AccountsSidebarNormal />
}

export default AccountsSidebar
