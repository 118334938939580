import React from 'react'
import { SignUp } from '@clerk/clerk-react'
import { Layout, Row } from 'antd'

const SIGN_UP_REDIRECT = import.meta.env.VITE_AFTER_SIGN_UP_REDIRECT

export const SignUpPage: React.FC = () => {
  if (!SIGN_UP_REDIRECT) {
    console.error('SIGN_UP_REDIRECT was not provided')
  }

  console.log(SIGN_UP_REDIRECT, '==SIGN_UP_REDIRECT==')
  return (
    <Layout
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Row justify="center" style={{ width: '100%' }}>
        <SignUp signInUrl="/sign-in" fallbackRedirectUrl={SIGN_UP_REDIRECT} />
      </Row>
    </Layout>
  )
}
