import { useConfigurationMode } from '@context/ConfigurationContext'
import DataModelSidebarConfig, {
  DataModelSidebarConfigProps,
} from './DataModelSidebarConfig'
import DataModelSidebarNormal from './DataModelSidebarNormal'

interface DataModelsSidebarProps extends DataModelSidebarConfigProps {}

const DataModelsSidebar: React.FC<DataModelsSidebarProps> = ({ ...props }) => {
  const { isConfigurationMode } = useConfigurationMode()
  if (isConfigurationMode) {
    return <DataModelSidebarConfig {...props} />
  }
  return <DataModelSidebarNormal {...props} />
}

export default DataModelsSidebar
