import {
  CreateModal,
  DatatagsTableWidget,
  SelectedListWidget,
  ViewContainerLayout,
} from '@components'
import CreateLookupForm from './CreateLookupForm'
import { Form, message } from 'antd'
import { trpc } from '@services/trpc'
import useStyles from './CreateLookupModal.styles'
import { useEffect } from 'react'
import { LookupDataModelInput } from '@server/interfaces/lookup'
import {
  DatatagColumnsProvider,
  useDatatagColumnsContext,
} from '@modules/datamodel/DatatagColumnsContext'
import { DatatagColumnItem } from '@modules/compoundModel/compoundModel.types'
import { DatamodelRowsProvider } from '@modules/datamodel/DatamodelRowsContext'
import {
  LookupDatatagHeaderItem,
  LookupDatatagListItem,
} from '../LookupDatatagListItem'
import { DatagroupValueColumnDefinition } from '@server/interfaces/datamodel'
import { CreateModalProps } from '@components/CreateModal'

const CreateLookupModal: React.FC<CreateModalProps> = ({
  open,
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { styles } = useStyles()

  const { selectedDatatagColumns, resetDatatagColumns } =
    useDatatagColumnsContext<DatatagColumnItem>()

  const handleSuccess = () => {
    message.success('Lookup table has been created')
    fetchLookups()
    form.resetFields()
    onClose?.()
  }

  const { refetch: fetchLookups } = trpc.api.datamodel.listByType.useQuery({
    type: 'lookup',
  })
  const { mutate: saveLookup, isPending } =
    trpc.api.datamodel.lookup.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: LookupDataModelInput) => {
    const columns = selectedDatatagColumns.map((column) => ({
      type: 'datagroup',
      datagroup_id: column.id,
      values: column.members,
    })) as DatagroupValueColumnDefinition[]
    const newLookup = {
      name: values.name,
      metric_id: values.metric_id,
      account_type: values.account_type,
      value_type: values.value_type,
      calendar: values.calendar,
      config: {
        columns,
      },
    }
    saveLookup(newLookup)
  }

  const handleCancel = () => {
    form.resetFields()
    onClose?.()
  }

  useEffect(() => {
    if (open) {
      form.resetFields()
      resetDatatagColumns()
    }
  }, [form, open, resetDatatagColumns])

  return (
    <CreateModal
      {...props}
      title="Data Model"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      centered
      width="clamp(800px, 90%, 1800px)"
      classNames={{
        content: styles.modalContent,
      }}
      confirmLoading={isPending}
    >
      <div className={styles.formWrapper}>
        <CreateLookupForm form={form} onSubmit={handleSubmit} />
      </div>

      <ViewContainerLayout>
        <SelectedListWidget<DatatagColumnItem>
          title="Model Composition"
          items={selectedDatatagColumns}
          renderItem={(item) => <LookupDatatagListItem item={item} />}
          renderHeader={() => <LookupDatatagHeaderItem />}
        />
        <DatatagsTableWidget />
      </ViewContainerLayout>
    </CreateModal>
  )
}

const WrappedCreateLookupModal: React.FC<CreateModalProps> = (props) => {
  return (
    <DatatagColumnsProvider>
      <DatamodelRowsProvider>
        <CreateLookupModal {...props} />
      </DatamodelRowsProvider>
    </DatatagColumnsProvider>
  )
}

export default WrappedCreateLookupModal
