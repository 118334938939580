import { FC, useEffect, useMemo } from 'react'
import { Checkbox, DrawerProps, Form, Input, message, Select } from 'antd'
import { trpc } from '@services/trpc'
import { PropertiesAccordion, PropertiesDrawer } from '@components'
import type { Datatag, DatatagInput } from '@modules/datagroup/datagroup.types'
import { skipToken } from '@tanstack/react-query'
import { capitalize } from '@utils/strings'

interface DatatagPropertiesProps extends DrawerProps {
  datatag: Datatag | null
  onClose?: () => void
}

const DatatagProperties: FC<DatatagPropertiesProps> = ({
  datatag,
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { refetch } = trpc.api.datatags.listDatagroupMembers.useQuery(
    datatag ? { id: datatag.datagroup_id } : skipToken,
  )
  const { data: datagroups = [] } =
    trpc.api.datatags.listDatagroupsByType.useQuery('datagroup')
  const { data: categoriesWithMembers = [] } =
    trpc.api.datatags.listDatagroupCategories.useQuery(
      datatag ? { id: datatag.datagroup_id } : skipToken,
    )
  const datagroup = datagroups.find((d) => d.id === datatag?.datagroup_id)

  const initialValues = useMemo(() => {
    if (!datatag) return null
    return {
      ...datatag,
      associated_datatags: Object.fromEntries(
        datatag.associated_datatags.map((d) => [d.datagroup_id, d.id]),
      ),
    }
  }, [datatag])

  const datagroupName = capitalize(datagroup?.name)

  const handleSuccess = () => {
    message.success('Datagroup updated successfully')
    refetch()
    form.resetFields()
    onClose?.()
  }

  const { mutate: save, isPending } =
    trpc.api.datatags.createOrUpdateDatatagById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = (values: DatatagInput) => {
    const associated_datatags = Object.entries(values.associated_datatags).map(
      ([datagroup_id, datatag_id]) => ({
        datagroup_id: Number(datagroup_id),
        id: Number(datatag_id),
      }),
    )
    save({
      ...values,
      id: datatag!.id,
      datagroup_id: datatag!.datagroup_id,
      value: values.value || '',
      associated_datatags,
    })
  }

  useEffect(() => {
    if (!initialValues) return
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <PropertiesDrawer<DatatagInput>
      {...props}
      onSubmit={handleSubmit}
      form={form}
      initialValues={initialValues as DatatagInput}
      confirmLoading={isPending}
    >
      <PropertiesAccordion label={datagroupName}>
        <Form.Item
          name="value"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input the datatag name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input placeholder="Enter description" />
        </Form.Item>

        <Form.Item name="memo" label="Memo">
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder="Enter memo"
          />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label={datagroupName + ' Categories'}>
        <Form.Item name="value" label={datagroupName}>
          <Input disabled />
        </Form.Item>
        {categoriesWithMembers.map((category) => (
          <Form.Item
            key={category.id}
            name={['associated_datatags', String(category.id)]}
            label={capitalize(category.name)}
          >
            <Select
              options={category.members}
              fieldNames={{ label: 'value', value: 'id' }}
            />
          </Form.Item>
        ))}
      </PropertiesAccordion>

      <PropertiesAccordion label="Other Settings">
        <Form.Item
          valuePropName="checked"
          name="inactive"
          aria-label="Inactive"
        >
          <Checkbox>Inactive</Checkbox>
        </Form.Item>
      </PropertiesAccordion>
    </PropertiesDrawer>
  )
}

export default DatatagProperties
