import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    collapse: {
      '&& .ant-collapse-item': {
        marginBottom: token.marginSM,

        '& .ant-collapse-header': {
          backgroundColor: token.blue50,
          color: token.blue600,
          padding: 4,
          borderRadius: token.borderRadiusSM,
          display: 'flex',
          alignItems: 'center',
          fontSize: token.fontSizeSM,
          fontWeight: token.fontWeightStrong,
          textTransform: 'uppercase',
        },

        '& .ant-collapse-content': {
          backgroundColor: token.gray50,
          borderRadius: token.borderRadiusSM,
          padding: token.paddingSM,
          margin: `${token.marginXXS}px 0`,

          '& .ant-collapse-content-box': {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: token.marginXS,
          },
        },
      },
    },
  }
})

export default useStyles
