import useStyles from './SidebarListItem.styles'
import SidebarListItem, { SidebarListItemProps } from './SidebarListItem'

export type BaseItem = {
  id: number | string
  name: string
  icon?: React.ReactNode
  disabled?: boolean
}

interface SidebarListItemPrimaryProps<T> extends SidebarListItemProps<T> {}

const SidebarListItemPrimary = <T extends BaseItem>(
  props: SidebarListItemPrimaryProps<T>,
) => {
  const { styles } = useStyles()

  return (
    <SidebarListItem
      {...props}
      renderLabel={(item) => (
        <span className={styles.primaryLabel}>
          {props.icon || item.icon}
          {item.name}
          <span className={styles.primaryLabelText}> / Primary Category</span>
        </span>
      )}
    />
  )
}

export default SidebarListItemPrimary
