import useStyles from './SidebarActionsLayout.styles'

interface SidebarActionsLayoutProps {
  children: React.ReactNode
}

const SidebarActionsLayout: React.FC<SidebarActionsLayoutProps> = ({
  children,
}) => {
  const { styles } = useStyles()

  return <div className={styles.container}>{children}</div>
}

export default SidebarActionsLayout
