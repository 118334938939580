import { Airplay } from '@untitled-ui/icons-react'
import { Button, ButtonProps } from 'antd'

interface DisplayButtonProps extends ButtonProps {}

const DisplayButton: React.FC<DisplayButtonProps> = (props) => {
  return (
    <Button type="link" {...props}>
      <Airplay width={16} />
      Display
    </Button>
  )
}

export default DisplayButton
