import { createStyles } from 'antd-style'

export default createStyles(({ token }) => ({
  button: {
    width: 20,
    height: 20,
    padding: 0,
    borderRadius: token.borderRadiusSM,
    border: `1px solid ${token.gray300}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
