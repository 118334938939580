import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    toolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: `0 ${token.padding}px`,

      '&&& button': {
        color: token.gray700,
        fontWeight: 600,
        padding: 0,
        ':hover': {
          color: token.gray500,
        },
      },
    },
    separator: {
      margin: '0 8px',
      width: 1,
      height: 16,
      backgroundColor: token.gray300,
    },
  }
})
