import { ConfigSidebar } from '@components'
import { trpc } from '@services/trpc'
import CreateCalendarModal from '../CreateCalendarModal'
import {
  CALENDAR_CATEGORY,
  CALENDAR_CATEGORY_OPTIONS,
  PERIOD_CLASS_OPTIONS,
  PERIOD_TYPE_OPTIONS,
} from '@modules/calendar/calendar.constants'
import { useMemo } from 'react'
import { useConfigPageContext } from '@context/ConfigPageContext'

const CalendarSidebarConfig = () => {
  const { data: calendars = [] } = trpc.api.calendar.list.useQuery()
  const { selectedCategory, setSelectedItem } = useConfigPageContext()

  const items = useMemo(() => {
    if (selectedCategory?.id === CALENDAR_CATEGORY.periodClasses) {
      return PERIOD_CLASS_OPTIONS
    }
    if (selectedCategory?.id === CALENDAR_CATEGORY.periodTypes) {
      return PERIOD_TYPE_OPTIONS
    }
    return calendars
  }, [calendars, selectedCategory])

  const handleItemClick = (item: (typeof items)[number] | null) => {
    if (selectedCategory === null) {
      setSelectedItem(item)
    }
  }

  return (
    <ConfigSidebar<(typeof items)[number]>
      name="Calendars"
      items={items}
      categories={CALENDAR_CATEGORY_OPTIONS}
      onItemClick={handleItemClick}
      createModal={CreateCalendarModal}
    />
  )
}

export default CalendarSidebarConfig
