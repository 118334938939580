import { FC, useEffect } from 'react'
import { Checkbox, DrawerProps, Form, Input, message } from 'antd'
import { PropertiesAccordion, PropertiesDrawer } from '@components'
import { trpc } from '@services/trpc'
import type { DataModel } from '@modules/datamodel'
import { skipToken } from '@tanstack/react-query'
import { capitalize } from '@utils/strings'

interface LookupPropertiesProps extends DrawerProps {
  values: DataModel | null
  onClose?: () => void
}

const LookupProperties: FC<LookupPropertiesProps> = ({
  values,
  onClose,
  ...drawerProps
}) => {
  const [form] = Form.useForm()

  const { refetch } = trpc.api.datamodel.listByType.useQuery(
    values?.type ? { type: values.type } : skipToken,
  )

  const handleSuccess = () => {
    message.success('Lookup table has been updated successfully')
    form.resetFields()
    refetch()
    onClose?.()
  }

  const { mutate: updateDatamodel } =
    trpc.api.datamodel.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = (formValues: DataModel) => {
    updateDatamodel({
      ...formValues,
      id: values?.id,
    })
  }

  useEffect(() => {
    form.setFieldsValue(values)
  }, [form, values])

  return (
    <PropertiesDrawer<DataModel>
      {...drawerProps}
      onSubmit={handleSubmit}
      form={form}
      initialValues={values as DataModel}
    >
      <PropertiesAccordion label={`${capitalize(values?.type)} Model`}>
        <Form.Item
          name="name"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input placeholder="Enter description" />
        </Form.Item>

        <Form.Item name="memo" label="Memo">
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder="Enter memo"
          />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="Other Settings">
        <Form.Item
          valuePropName="checked"
          name="inactive"
          aria-label="Inactive"
        >
          <Checkbox>Inactive</Checkbox>
        </Form.Item>
      </PropertiesAccordion>
    </PropertiesDrawer>
  )
}

export default LookupProperties
