import { CreateModal } from '@components'
import CreateMetricForm from './CreateMetricForm'
import { Form, message } from 'antd'
import useStyles from './CreateMetricModal.styles'
import { trpc } from '@services/trpc'
import type { MetricInput } from '@modules/metric'
import type { BulkFormListValues } from '@components/BulkFormList'
import { CreateModalProps } from '@components/CreateModal'

const CreateMetricModal: React.FC<CreateModalProps> = ({
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { styles } = useStyles()

  const { refetch: fetchMetrics } = trpc.api.metrics.list.useQuery()

  const handleSuccess = () => {
    message.success('Metric created successfully')
    fetchMetrics()
    form.resetFields()
    onClose?.()
  }

  const { mutate: saveMetric, isPending } =
    trpc.api.metrics.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,

      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: BulkFormListValues<MetricInput>) => {
    values.items.forEach((item) => {
      saveMetric({ ...item, source: 'metric' })
    })
  }

  const handleCancel = () => {
    form.resetFields()
    onClose?.()
  }

  return (
    <CreateModal
      {...props}
      title="Create metric"
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      width="unset"
      className={styles.modal}
      confirmLoading={isPending}
    >
      <div className={styles.formWrapper}>
        <CreateMetricForm form={form} onSubmit={handleSubmit} />
      </div>
    </CreateModal>
  )
}

export default CreateMetricModal
