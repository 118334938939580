import { FC } from 'react'
import { type ColumnDef, createColumnHelper, Row } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import { Datagroup } from '@modules/datagroup'
import { skipToken } from '@tanstack/react-query'
import { useConfigPageContext } from '@context/ConfigPageContext'
import type { ViewMetric } from '@server/interfaces/view'
import { Button } from 'antd'
import useStyles from './ViewMetricTable.styles'
import CreateViewMetricModal from '../../CreateViewMetricModal'
import { useModalState } from '@hooks'
import { mockViewMetric } from '@modules/datagroup/datagroup.constants'

const filterOptions = [
  {
    type: 'view_by',
    label: 'View by',
    options: [{ label: 'All', value: '' }],
  },
]

const columnHelper = createColumnHelper<ViewMetric>()

const columns: ColumnDef<ViewMetric, string>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
]

interface DatatagTableProps
  extends Partial<TableWidgetProps<ViewMetric, string>> {}

const ViewMetricTable: FC<DatatagTableProps> = ({ ...rest }) => {
  const { styles } = useStyles()
  const { selectedCategory, setSelectedItem } = useConfigPageContext<
    ViewMetric,
    Datagroup
  >()

  const modalState = useModalState()

  // @ts-expect-error not implemented
  const { data = [], isLoading } = trpc.api.views.listViewMetrics.useQuery(
    selectedCategory ? { id: selectedCategory.id } : skipToken,
    { placeholderData: [mockViewMetric] },
  )

  const handleRowClick = (row: Row<ViewMetric>) => {
    setSelectedItem(row.original)
  }

  if (!isLoading && data.length === 0) {
    return (
      <>
        <div className={styles.emptyState}>
          <p className={styles.emptyStateText}>
            Currently you don’t have any view metrics created
          </p>
          <Button
            type="primary"
            className={styles.emptyStateButton}
            onClick={modalState.open}
          >
            Create View Metric
          </Button>
        </div>
        <CreateViewMetricModal
          open={modalState.isOpen}
          onClose={modalState.close}
        />
      </>
    )
  }

  return (
    <TableWidget<ViewMetric, string>
      {...rest}
      data={data}
      columns={columns}
      isLoading={isLoading}
      filterOptions={filterOptions}
      getRowId={(row) => row.id}
      onRowClick={handleRowClick}
      withIndex
    />
  )
}

export default ViewMetricTable
