import React from 'react'
import { Select, Button, Modal } from 'antd'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useStyles from './AppHeader.styles'
import { trpc } from '@services/trpc'
import {
  SignedIn,
  SignedOut,
  SignInButton,
  UserButton,
  useUser,
} from '@clerk/clerk-react'
import TopNavbar from '../TopNavbar'
import { ChevronDown, Trash03 } from '@untitled-ui/icons-react'

const AppHeader: React.FC = () => {
  const { styles } = useStyles()
  const navigate = useNavigate()

  const { user } = useUser()

  const { data: versions = [] } = trpc.api.versions.list.useQuery()
  const { data: companies = [] } = trpc.api.companies.list.useQuery()
  const versionOptions = versions.map((version) => ({
    label: version.name,
    value: String(version.id),
  }))
  const companyOptions = companies.map((company) => ({
    label: company.name,
    value: String(company.id),
  }))

  const [searchParams, setSearchParams] = useSearchParams()
  const company = searchParams.get('company')
  const version = searchParams.get('version')

  const handleClearDatabaseClick = () => {
    Modal.confirm({
      title: 'Warning',
      content:
        'Are you sure you want to clear the database? All data will be lost.',
      okText: 'Yes',
      okType: 'danger',

      onOk: () => {
        navigate('/system/truncate')
      },
    })
  }

  return (
    <div className={styles.header}>
      <div className={styles.section}>
        <TopNavbar />
      </div>
      <div className={styles.section}>
        <Select
          value={version}
          options={versionOptions}
          onChange={(value) => {
            setSearchParams(
              (prev) => {
                const current = Object.fromEntries(prev.entries())
                return {
                  ...current,
                  version: value,
                }
              },
              { replace: true },
            )
          }}
          className={styles.select}
          variant="borderless"
          suffixIcon={<ChevronDown width={20} />}
        />

        <div className={styles.separator} />

        <Select
          value={company}
          options={companyOptions}
          onChange={(value) => {
            setSearchParams(
              (prev) => {
                const current = Object.fromEntries(prev.entries())
                return {
                  ...current,
                  company: value,
                }
              },
              { replace: true },
            )
          }}
          className={styles.select}
          variant="borderless"
          suffixIcon={<ChevronDown width={20} />}
        />

        <div className={styles.separator} />

        <div className={styles.user}>
          <SignedOut>
            <SignInButton />
          </SignedOut>

          <SignedIn>
            <UserButton />
          </SignedIn>

          <div className={styles.userInfo}>
            <span className={styles.userName}>{user?.username}</span>
            <span className={styles.userEmail}>
              {user?.emailAddresses[0].emailAddress}
            </span>
          </div>

          <Button
            className={styles.clearDatabaseButton}
            type="text"
            icon={<Trash03 width={20} />}
            onClick={handleClearDatabaseClick}
          />
        </div>
      </div>
    </div>
  )
}

export default AppHeader
