import { ConfigSidebar } from '@components'
import { trpc } from '@services/trpc'
import { CATEGORY_OPTIONS } from '@modules/version/version.constants'
import VersionCreateMenuButton from './VersionCreateMenuButton'
import { Category, useConfigPageContext } from '@context/ConfigPageContext'
import { Version } from '@server/interfaces/versions'

const VersionsSidebar = () => {
  const { selectedCategory } = useConfigPageContext<Version, Category>()

  const { data = [] } = trpc.api.versions.list.useQuery()
  const versions = selectedCategory
    ? data.filter((version) => version.category === selectedCategory?.id)
    : data

  const categories = CATEGORY_OPTIONS.map((category) => ({
    ...category,
    id: category.value,
    name: category.label,
  }))

  return (
    <ConfigSidebar
      name="Versions"
      items={versions}
      categories={categories}
      createButton={<VersionCreateMenuButton />}
      forceConfigMode
    />
  )
}

export default VersionsSidebar
