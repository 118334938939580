import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: token.colorWhite,
      borderRadius: token.borderRadius,
      border: `1px solid ${token.colorBorder}`,
    },
    header: {
      '& > *': {
        height: 46,
        display: 'flex',
        alignItems: 'center',
        padding: `0 ${token.paddingContentHorizontalSM}px`,
      },
    },
    title: {
      borderBottom: `1px solid ${token.colorBorder}`,
      fontWeight: token.fontWeightStrong,
      margin: 0,
    },
    searchBlock: {
      borderBottom: `1px solid ${token.colorBorder}`,
    },
    search: {
      padding: 0,
      '& input::placeholder': {
        color: token.colorText,
        fontWeight: token.fontWeightStrong,
      },
    },
  }
})
