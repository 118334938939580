import { createStyles } from 'antd-style'

export default createStyles(() => {
  return {
    row: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) fit-content(100%)',
    },
  }
})
