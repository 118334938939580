import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Form, Skeleton } from 'antd'
import type { FormInstance } from 'antd'
import {
  SidebarFilterRowLayout,
  SidebarFilterSelect,
  TableWidget,
  ViewContainerLayout,
} from '@components'
import {
  ColumnDef,
  Row,
  RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table'
import ViewControls from './ViewControls'
import ViewForm, { CreateViewFormValues } from './CreateViewForm'
import ViewTree from '../../ViewTree'
import useStyles from './CreateView.styles'
import { trpc } from '@services/trpc'
import {
  StaticTreeDataProvider,
  TreeEnvironmentRef as RCTTreeEnvironmentRef,
  TreeItemIndex,
} from 'react-complex-tree'
import { TreeData, TreeItemData } from '@modules/view/view.types'
import { DataGroupId } from '@server/interfaces/datagroup'
import { capitalize } from '@utils/strings'

type BaseItem = {
  id: number
  name: string
}

const columnHelper = createColumnHelper<BaseItem>()

const columns: ColumnDef<BaseItem, string>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
]

type CreateViewProps = {
  form: FormInstance
  viewId?: number
  treeData: TreeData
  isFetching: boolean
  onChange: (treeData: TreeData) => void
  onSubmit: (values: CreateViewFormValues) => void
  datagroupId: DataGroupId
}

interface TreeEnvironmentRef<T> extends RCTTreeEnvironmentRef<T> {
  dataProvider: StaticTreeDataProvider<T>
}

const CreateView: FC<CreateViewProps> = ({
  form,
  treeData,
  isFetching,
  onChange,
  onSubmit,
  datagroupId,
}) => {
  const { styles, cx } = useStyles()
  const treeEnvironment = useRef<TreeEnvironmentRef<TreeItemData>>(null)
  const [isDragging, setIsDragging] = useState(false)

  const { data: datagroup } = trpc.api.datatags.getDatagroupById.useQuery({
    id: datagroupId,
  })

  const { data: items = [] } = trpc.api.datatags.listDatagroupMembers.useQuery(
    { id: datagroupId },
    {
      select: (data) =>
        data.map((item) => ({
          ...item,
          name: item.value,
        })),
    },
  )
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})
  const selectedItems = items.filter((item) =>
    Object.keys(selectedRows).includes(item.id.toString()),
  )
  const assignedIds = Object.values(treeData)
    .map((item) => item.data.id)
    .filter(Boolean) as number[]

  const categoryOptions = useMemo(
    () => [
      {
        label: `${capitalize(datagroup?.name)} / primary`,
        value: datagroup?.id,
      },
      {
        label: 'Any',
        value: null,
      },
    ],
    [datagroup],
  )

  const createFolder = (type: 'folder' | 'sum') => (folderName: string) => {
    const newData = {
      ...treeData,
      root: {
        ...treeData.root,
        children: [...treeData.root.children, folderName],
      },
      [folderName]: {
        index: folderName,
        canMove: true,
        canRename: false,
        data: { name: folderName, isNew: true, type },
        isFolder: true,
        children: [],
      },
    }
    onChange(newData)
  }

  const handleDropOnTree = (targetIndex: TreeItemIndex) => {
    const newTreeData = { ...treeData }
    selectedItems.forEach((item) => {
      const index = item.id.toString()
      newTreeData[index] = {
        data: { name: item.name, id: item.id, isNew: true, type: 'item' },
        index: index,
        canMove: true,
        isFolder: false,
        canRename: false,
        children: [],
      }
      if (treeData[targetIndex].isFolder) {
        newTreeData[targetIndex].children.push(index)
        treeEnvironment.current?.expandItem(
          targetIndex,
          treeEnvironment.current?.treeIds[0],
        )
      } else {
        const parent = Object.values(treeData).find((item) =>
          item.children.includes(targetIndex),
        )
        if (parent) {
          newTreeData[parent.index].children.push(index)
        }
      }
    })
    onChange(newTreeData)
    setSelectedRows({})
  }

  const handleRemoveItem = (index: TreeItemIndex) => {
    const newTreeData = { ...treeData }
    delete newTreeData[index]

    const parent = Object.values(treeData).find((item) =>
      item.children.includes(index),
    )
    if (parent) {
      parent.children = parent.children.filter((item) => item !== index)
    }
    onChange(newTreeData)
  }

  const handleDragStart = (row: Row<BaseItem>) => {
    setSelectedRows((selectedRows) => ({ ...selectedRows, [row.id]: true }))
    setIsDragging(true)
  }

  const handleDragEnd = () => {
    setIsDragging(false)
  }

  useEffect(() => {
    if (treeEnvironment.current) {
      const treeKeys = Object.keys(treeData)
      treeEnvironment.current.dataProvider.onDidChangeTreeDataEmitter.emit(
        treeKeys,
      )
    }
  }, [treeData])

  return (
    <div className={styles.container}>
      <ViewContainerLayout>
        <div className={cx(styles.section, styles.formContainer)}>
          <ViewForm form={form} onSubmit={onSubmit} />
          <Form.Item layout="vertical" label="View basis">
            <SidebarFilterRowLayout className={styles.viewBasis}>
              <SidebarFilterSelect
                label="Model class"
                value={capitalize(datagroup?.name || '')}
                disabled
                placeholder="Select model class"
              />
              <Form.Item name="category" initialValue={datagroup?.id}>
                <SidebarFilterSelect
                  label="Category"
                  options={categoryOptions}
                  placeholder="Select category"
                />
              </Form.Item>
            </SidebarFilterRowLayout>
          </Form.Item>
        </div>
      </ViewContainerLayout>

      <ViewContainerLayout>
        <div className={cx(styles.section, styles.treeContainer)}>
          <ViewControls
            onAddFolder={createFolder('folder')}
            onAddSum={createFolder('sum')}
          />

          {isFetching ? (
            <Skeleton active />
          ) : (
            <ViewTree
              data={treeData}
              onDrop={handleDropOnTree}
              isDragging={isDragging}
              onRemove={handleRemoveItem}
              treeEnvironment={treeEnvironment}
            />
          )}
        </div>

        <div className={cx(styles.section, styles.tableContainer)}>
          <TableWidget<BaseItem>
            data={items}
            columns={columns}
            selectedRows={selectedRows}
            onSelect={setSelectedRows}
            draggable
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            withCheckbox
            withIndex
            getRowId={(row) => row.id.toString()}
            getRowMarked={(row) => assignedIds.includes(Number(row.id))}
            enableRowSelection={(row) => !assignedIds.includes(Number(row.id))}
            enableRowDragging={(row) => !assignedIds.includes(Number(row.id))}
          />
        </div>
      </ViewContainerLayout>
    </div>
  )
}

export default CreateView
