import { FC } from 'react'
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import { Company } from '@modules/company'

const companyViewOptions = [{ value: 'all', label: 'All' }]

const filterOptions = [
  {
    type: 'view_by',
    label: 'View by',
    options: companyViewOptions,
  },
]

const columnHelper = createColumnHelper<Company>()

// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<Company, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: () => 'Name | ID',
  }),
]

interface LedgerTableProps extends Partial<TableWidgetProps<Company>> {}

const LedgerTable: FC<LedgerTableProps> = ({ ...rest }) => {
  const { data: companies = [] } = trpc.api.companies.list.useQuery()

  return (
    <TableWidget<Company>
      {...rest}
      data={companies}
      columns={columns}
      filterOptions={filterOptions}
      getRowId={(row) => row.id}
      withIndex
    />
  )
}

export default LedgerTable
