import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    description: {
      margin: `${token.marginXS}px 0 0 0`,
      color: token.gray400,
    },
  }
})
