import { trpc } from '@services/trpc'
import { LedgerAccountRowItem, COLUMN_GROUPS } from '@modules/ledger'
import { useEffect, useMemo, useState } from 'react'
import { RowSelectionState } from '@tanstack/react-table'
import { DataModel } from '@modules/datamodel'

const useLedgerRows = (selectedCompany?: DataModel | null) => {
  const { data: accountdrivers = [] } = trpc.api.accountdrivers.list.useQuery()
  // ROWS - ACCOUNTS
  const initialAccountTableRows = useMemo(() => {
    if (!selectedCompany) {
      return {}
    }
    return (selectedCompany.config.rows || []).reduce((acc, accountdriver) => {
      acc[accountdriver.id] = true
      return acc
    }, {} as RowSelectionState)
  }, [selectedCompany])

  const [selectedAccountTableRows, setSelectedAccountTableRows] =
    useState<RowSelectionState>(initialAccountTableRows)

  useEffect(() => {
    setSelectedAccountTableRows(initialAccountTableRows)
  }, [initialAccountTableRows])
  const selectedAccountDrivers = accountdrivers
    .filter((ad) => selectedAccountTableRows[ad.id] === true)
    .map((ad) => ({
      id: ad.id,
      name: ad.name,
      properties: {},
      itemGroup: COLUMN_GROUPS.rows,
    }))

  const handleItemRemove = (item: LedgerAccountRowItem) => {
    setSelectedAccountTableRows((prev) => {
      const newState = { ...prev }
      delete newState[item.id]
      return newState
    })
  }

  return useMemo(() => {
    return {
      initialAccountTableRows,
      selectedAccounts: selectedAccountDrivers,
      selectedAccountTableRows,
      setSelectedAccountTableRows,
      onItemRemove: handleItemRemove,
    }
  }, [
    initialAccountTableRows,
    selectedAccountDrivers,
    selectedAccountTableRows,
  ])
}

export default useLedgerRows
