import { FC } from 'react'
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import type { Lookup } from '@modules/lookup'

const lookupViewOptions = [{ value: 'master', label: 'Master list' }]

const filterOptions = [
  {
    type: 'view_by',
    label: 'View by',
    options: lookupViewOptions,
  },
]

const columnHelper = createColumnHelper<Lookup>()
// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<Lookup, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
]

interface LookupsTableProps extends Partial<TableWidgetProps<Lookup>> {}

const LookupsTable: FC<LookupsTableProps> = ({ ...rest }) => {
  const { data: lookups = [] } = trpc.api.datamodel.listByType.useQuery({
    type: 'lookup',
  })

  return (
    <TableWidget<Lookup>
      {...rest}
      data={lookups}
      columns={columns}
      filterOptions={filterOptions}
      withIndex
    />
  )
}

export default LookupsTable
