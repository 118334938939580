import { httpBatchLink, httpLink, splitLink } from '@trpc/client'
import { createTRPCReact } from '@trpc/react-query'

import type { AppRouter } from '@server'

const url = '/api/trpc'
const isDev = process.env.NODE_ENV === 'development'

export const trpc = createTRPCReact<AppRouter>({ abortOnUnmount: true })

const links = isDev
  ? [httpLink({ url })]
  : [
      splitLink({
        condition(op) {
          return op.context.skipBatch === true
        },
        true: httpLink({ url }),
        false: httpBatchLink({ url }),
      }),
    ]

export const trpcClient = trpc.createClient({ links })
