import { View } from '@server/interfaces/view'
import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
} from 'react'

export type Category = {
  id: string | number
  name: string
  icon?: React.ReactNode
}
export const TABS = {
  categories: 'Categories',
  items: 'Members',
  views: 'Views',
  dataModels: 'Data Models',
  ledgers: 'Ledgers',
} as const
export type Tab = (typeof TABS)[keyof typeof TABS]

interface ConfigPageContextType<T, C extends Category> {
  selectedItem: T | null
  setSelectedItem: (item: T | null) => void
  selectedCategory: C | null
  setSelectedCategory: (category: C | null, resetItem?: boolean) => void
  selectedView: View | null
  setSelectedView: (view: View | null) => void
  currentTab: Tab
  setCurrentTab: (tab: Tab) => void
}

const ConfigPageContext = createContext<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ConfigPageContextType<any, any> | undefined
>(undefined)

const useConfigPageContext = <T, C extends Category>() => {
  const context = useContext(
    ConfigPageContext as React.Context<ConfigPageContextType<T, C> | undefined>,
  )
  if (context === undefined) {
    throw new Error(
      'useConfigPageContext must be used within a ConfigPageProvider',
    )
  }
  return context
}

const ConfigPageProvider = <T, C extends Category>({
  children,
  category,
}: {
  children: ReactNode
  category?: C
}) => {
  const [currentTab, setCurrentTab] = useState<Tab>(TABS.categories)
  const [selectedCategory, setSelectedCategory] = useState<C | null>(
    category || null,
  )
  const [selectedItem, setSelectedItem] = useState<T | null>(null)
  const [selectedView, setSelectedView] = useState<View | null>(null)

  const changeSelectedCategory = useCallback(
    (category: C | null, resetItem = true) => {
      setSelectedCategory(category || null)
      if (resetItem) {
        setSelectedItem(null)
      }
    },
    [setSelectedCategory, setSelectedItem],
  )

  useEffect(() => {
    setSelectedCategory(category || null)
  }, [category])

  return (
    <ConfigPageContext.Provider
      value={{
        selectedItem,
        setSelectedItem,
        selectedCategory,
        setSelectedCategory: changeSelectedCategory,
        selectedView,
        setSelectedView,
        currentTab,
        setCurrentTab,
      }}
    >
      {children}
    </ConfigPageContext.Provider>
  )
}

export { ConfigPageProvider, useConfigPageContext }
