import { FC } from 'react'
import useStyles from './ViewToolbar.styles'
import {
  CloseOutlined,
  DownOutlined,
  FullscreenOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Input, Radio } from 'antd'
import { useConfigurationMode } from '@context/ConfigurationContext'
import {
  PlusSquare,
  Save01,
  SearchMd,
  Share05,
  Trash03,
  Upload04,
} from '@untitled-ui/icons-react'
import { useLocation } from 'react-router-dom'

interface ViewToolbarProps {
  onAdd?: () => void
  addButton?: React.ReactNode
  forceConfigMode?: boolean
}

const ViewToolbar: FC<ViewToolbarProps> = ({
  onAdd,
  addButton,
  forceConfigMode,
}) => {
  const { isConfigurationMode } = useConfigurationMode()
  const location = useLocation()
  const isConfig = forceConfigMode || isConfigurationMode
  const { styles } = useStyles({ isConfig })
  const isLedger = location.pathname.includes('ledger')

  return (
    <div className={styles.toolbar}>
      {isLedger && (
        <Radio.Group
          options={[
            { label: 'Transactions', value: 'transactions' },
            { label: 'Pivot sheet', value: 'pivot', disabled: true },
          ]}
          value="transactions"
          optionType="button"
          buttonStyle="solid"
          className={styles.modeButtons}
        />
      )}
      <div className={styles.buttons}>
        {addButton && (
          <>
            {addButton}
            <div className={styles.divider} />
          </>
        )}

        {onAdd && (
          <>
            <Button
              type="link"
              icon={<PlusSquare width={16} />}
              onClick={onAdd}
            >
              Add
            </Button>
            <div className={styles.divider} />
          </>
        )}

        <Button type="link" icon={<Save01 width={16} />}>
          Save
        </Button>

        <div className={styles.divider} />

        <Button type="link" icon={<Trash03 width={16} />}>
          Delete
        </Button>

        <div className={styles.divider} />

        <Dropdown menu={{ items: [] }}>
          <Button type="link">
            <Share05 width={16} /> Export As
            <DownOutlined />
          </Button>
        </Dropdown>

        <div className={styles.divider} />

        <Button type="link" icon={<Upload04 width={16} />}>
          Import
        </Button>
      </div>
      <div className={styles.dividerFull} />
      <Input
        prefix={<SearchMd width={20} />}
        suffix={<CloseOutlined />}
        placeholder="Search"
        disabled
        className={styles.searchInput}
        size="small"
      />
      <Button className={styles.iconButton} icon={<SearchMd width={20} />} />
      <div className={styles.dividerFull} />
      <Button
        className={styles.iconButton}
        icon={<FullscreenOutlined size={16} />}
      />
    </div>
  )
}

export default ViewToolbar
