import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    tableSheetModeRadio: {
      display: 'flex',

      label: {
        display: 'flex',
        justifyContent: 'center',
        padding: `${token.paddingXXS}px ${token.paddingXS}px`,

        span: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      },
    },
    tableSheetModeRadioButton: {
      width: 28,
      height: 24,
    },
  }
})
