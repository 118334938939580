import { SVGProps } from 'react'

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21 13-5.434 5.434c-.198.198-.297.297-.412.334a.499.499 0 0 1-.309 0c-.114-.037-.213-.136-.41-.334l-1.87-1.868c-.197-.198-.296-.297-.41-.334a.499.499 0 0 0-.31 0c-.114.037-.213.136-.41.334L7 21m14-8h-4m4 0v4m-9.2 8h8.4c1.68 0 2.52 0 3.162-.327a3 3 0 0 0 1.311-1.311C25 22.72 25 21.88 25 20.2v-8.4c0-1.68 0-2.52-.327-3.162a3 3 0 0 0-1.311-1.311C22.72 7 21.88 7 20.2 7h-8.4c-1.68 0-2.52 0-3.162.327a3 3 0 0 0-1.311 1.311C7 9.28 7 10.12 7 11.8v8.4c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C9.28 25 10.12 25 11.8 25Z"
    />
  </svg>
)
