import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    formItem: {
      '&:not(:last-child)': {
        borderRight: `1px solid ${token.colorBorder}`,
      },
      padding: `${token.paddingContentVerticalSM}px ${token.paddingContentHorizontalSM}px`,
    },
  }
})
