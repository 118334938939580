import { createStyles } from 'antd-style'

export default createStyles(({ token }) => ({
  sidebarRoot: {
    display: 'flex',
    overflow: 'hidden',
    borderRadius: token.borderRadiusLG,
    border: `1px ${token.colorBorder} solid`,
    backgroundColor: token.colorWhite,
  },
  sidebarMain: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'width 0.3s',
    width: 322,
    borderRight: `1px ${token.colorBorder} solid`,
  },
  sidebarPaddingWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: token.paddingSM,
    gap: token.marginSM,
  },
  sidebarCollapsed: {
    width: 0,
    borderRight: 'none',
  },
  sidebarContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    gap: token.marginSM,
  },
  tabs: {
    display: 'flex',
    width: '100%',
    height: 28,
    borderRadius: token.borderRadiusSM,
    backgroundColor: token.gray100,
    overflow: 'hidden',
  },
  tab: {
    border: 'none',
    color: token.gray500,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 0%',
    cursor: 'pointer',

    '&:hover': {
      color: token.blue700,
    },

    '&.active': {
      backgroundColor: token.blue100,
      color: token.blue800,
    },
  },
}))
