import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    section: {
      display: 'flex',
      flexDirection: 'column',
      gap: token.paddingXS,
      backgroundColor: token.gray50,
      padding: `${token.paddingXS}px ${token.paddingXS}px`,
      borderRadius: token.borderRadius,
    },
  }
})
