import { useConfigurationMode } from '@context/ConfigurationContext'
import MetricNormalView from './MetricNormalView'
import MetricConfigView from './MetricConfigView'

const MetricView: React.FC = () => {
  const { isConfigurationMode } = useConfigurationMode()

  if (isConfigurationMode) {
    return <MetricConfigView />
  }

  return <MetricNormalView />
}

export default MetricView
