import React from 'react'
import SidebarHeader from './SidebarHeader'

import useStyles from './Sidebar.styles.ts'
import { Tab } from '@context/ConfigPageContext'
import SidebarCollapser from './SidebarCollapser'
import { useConfigurationMode } from '@context/ConfigurationContext'

interface SidebarProps {
  title: string
  children?: React.ReactNode
  tabs?: Tab[]
  selectedTab?: Tab
  onTabChange?: (tab: Tab) => void
  forceConfigMode?: boolean
}

const Sidebar: React.FC<SidebarProps> = ({
  title,
  children,
  tabs,
  selectedTab,
  onTabChange,
  forceConfigMode,
}) => {
  const { styles, cx } = useStyles()
  const { isSidebarCollapsed } = useConfigurationMode()

  return (
    <aside className={styles.sidebarRoot}>
      <div
        className={cx(
          styles.sidebarMain,
          isSidebarCollapsed && styles.sidebarCollapsed,
        )}
      >
        <div className={styles.sidebarPaddingWrapper}>
          <SidebarHeader title={title} forceConfigMode={forceConfigMode} />
          {tabs && (
            <div className={styles.tabs}>
              {tabs.map((tab) => (
                <button
                  key={tab}
                  aria-label={tab}
                  className={cx(styles.tab, selectedTab === tab && 'active')}
                  onClick={() => onTabChange?.(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
          )}
          <div className={styles.sidebarContent}>{children}</div>
        </div>
      </div>
      <SidebarCollapser title={title} />
    </aside>
  )
}

export default Sidebar
