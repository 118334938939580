import { FC } from 'react'
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableWidget } from '@components'
import type { TableWidgetProps } from '@components/TableWidget'
import { PERIOD_TYPE_OPTIONS } from '@modules/calendar/calendar.constants'

type PeriodTypeItem = {
  id: string
  name: string
}

const columnHelper = createColumnHelper<PeriodTypeItem>()

// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<PeriodTypeItem, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
]

interface PeriodTypesTableProps
  extends Partial<TableWidgetProps<PeriodTypeItem>> {}

const PeriodTypesTable: FC<PeriodTypesTableProps> = ({ ...props }) => {
  return (
    <TableWidget<PeriodTypeItem>
      {...props}
      data={PERIOD_TYPE_OPTIONS}
      columns={columns}
      withIndex
    />
  )
}

export default PeriodTypesTable
