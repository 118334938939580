import { FC } from 'react'
import { type ColumnDef, createColumnHelper, Row } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import { Datagroup, Datatag } from '@modules/datagroup'

import { skipToken } from '@tanstack/react-query'
import { useConfigPageContext } from '@context/ConfigPageContext'

const filterOptions = [
  {
    type: 'view_by',
    label: 'View by',
    options: [{ label: 'All', value: '' }],
  },
]

const columnHelper = createColumnHelper<Datatag>()

const columns: ColumnDef<Datatag, string>[] = [
  columnHelper.accessor((row) => row.value, {
    id: 'value',
    header: 'Name | ID',
  }),
]

interface DatatagTableProps
  extends Partial<TableWidgetProps<Datatag, string>> {}

const DatatagTable: FC<DatatagTableProps> = ({ ...rest }) => {
  const { selectedCategory, selectedItem, setSelectedItem } =
    useConfigPageContext<Datatag, Datagroup>()
  const { data = [] } = trpc.api.datatags.listDatagroupMembers.useQuery(
    selectedCategory ? { id: selectedCategory.id } : skipToken,
  )

  const handleRowClick = (row: Row<Datatag>) => {
    setSelectedItem(row.original)
  }

  return (
    <TableWidget<Datatag, string>
      {...rest}
      data={data}
      columns={columns}
      filterOptions={filterOptions}
      getRowId={(row) => row.id}
      onRowClick={handleRowClick}
      selectedRows={selectedItem ? { [selectedItem.id]: true } : undefined}
      withIndex
    />
  )
}

export default DatatagTable
