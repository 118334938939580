import { FC } from 'react'
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import { useConfigPageContext } from '@context/ConfigPageContext'
import { Calendar } from '@server/interfaces/period'

const columnHelper = createColumnHelper<Calendar>()

// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<Calendar, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
]

interface CalendarsTableProps extends Partial<TableWidgetProps<Calendar>> {}

const CalendarsTable: FC<CalendarsTableProps> = ({ ...rest }) => {
  const { data = [] } = trpc.api.calendar.list.useQuery()
  const { setSelectedItem } = useConfigPageContext()

  return (
    <TableWidget<Calendar>
      {...rest}
      data={data}
      columns={columns}
      withIndex
      onRowClick={(row) => {
        setSelectedItem(row.original)
      }}
    />
  )
}

export default CalendarsTable
