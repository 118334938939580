import { useEffect } from 'react'
import { PageContentLayout } from '@components'
import { CompanySidebar, CompanyView } from '@modules/company'
import { ConfigPageProvider } from '@context/ConfigPageContext'
import { useConfigurationMode } from '@context/ConfigurationContext'

const CompanyPage: React.FC = () => {
  const { enableConfigurationModeToggle, disableConfigurationModeToggle } =
    useConfigurationMode()

  useEffect(() => {
    disableConfigurationModeToggle()
    return () => {
      enableConfigurationModeToggle()
    }
  }, [])

  return (
    <ConfigPageProvider>
      <PageContentLayout>
        <CompanySidebar />
        <CompanyView />
      </PageContentLayout>
    </ConfigPageProvider>
  )
}
export default CompanyPage
