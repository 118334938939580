import React from 'react'
import clsx from 'clsx'
import useStyles from './SidebarHeader.styles.ts'
import { useConfigurationMode } from '@context/ConfigurationContext'

interface SidebarHeaderProps {
  title: string
  forceConfigMode?: boolean
}

const SidebarHeader: React.FC<SidebarHeaderProps> = ({
  title,
  forceConfigMode,
}) => {
  const { isConfigurationMode } = useConfigurationMode()
  const { styles } = useStyles()

  const isConfig = forceConfigMode || isConfigurationMode

  return (
    <div className={clsx(styles.sidebarHeaderWrapper, {})}>
      <div className={styles.sidebarHeader}>
        <div className={styles.headerContent}>
          <span>{title}</span>
          &nbsp;
          {isConfig && <span className={styles.config}> / Configuration</span>}
        </div>
      </div>
    </div>
  )
}

export default SidebarHeader
