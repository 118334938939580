import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    select: {
      height: 42,
    },
    value: {
      color: token.colorPrimary,
    },
  }
})
