import logoLarge from '@assets/images/logo-large.png'
import useStyles from './WelcomePage.styles'
import { Link } from 'react-router-dom'
import { Button } from 'antd'

const WelcomePage: React.FC = () => {
  const { styles } = useStyles()

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <img src={logoLarge} alt="Budget Now" className={styles.logo} />
      </header>
      <main className={styles.main}>
        <h1>Welcome to BudgetNOW</h1>
        <p>
          BudgetNOW is a leading FP&A application that empowers organizations
          with precise budgeting, forecasting, and financial reporting tools.
          Designed for businesses of all sizes, BudgetNow seamlessly integrates
          with financial systems to streamline processes and support data-driven
          decisions. With features like real-time analytics and customizable
          dashboards, BudgetNow helps companies align financial activities with
          strategic goals, ensuring efficiency and growth in a dynamic market.
        </p>
        <div className={styles.signInSection}>
          <h2>Sign in to explore BudgetNOW</h2>
          <Link to="/sign-in">
            <Button className={styles.button} size="large" type="primary">
              Sign In
            </Button>
          </Link>
          <div className={styles.divider} />
          <p>
            Don&apos;t have an account?{' '}
            <Link className={styles.link} to="/sign-up">
              Sign Up
            </Link>
          </p>
        </div>
      </main>
    </div>
  )
}

export default WelcomePage
