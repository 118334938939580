import React, { FC, useEffect, useRef } from 'react'
import { Button, Input, Space } from 'antd'
import type { InputRef } from 'antd'

type NewFolderInputProps = {
  onCreate: (name: string) => void
  onCancel: () => void
  placeholder?: string
}

const ViewFolderInput: FC<NewFolderInputProps> = ({
  onCreate,
  onCancel,
  placeholder = 'New folder',
}) => {
  const folderInputRef = useRef<InputRef>(null)

  useEffect(() => {
    folderInputRef.current?.focus()
    folderInputRef.current?.select()
  }, [])

  const handleCreate = () => {
    onCreate(folderInputRef.current?.input?.value || '')
    onCancel()
  }

  return (
    <Space.Compact block size="small">
      <Input
        defaultValue={placeholder}
        ref={folderInputRef}
        onKeyDown={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter') {
            handleCreate()
          }
          if (e.key === 'Escape') {
            onCancel()
          }
        }}
        onBlur={() => {
          // Delay the execution of onCancel to allow other events like button click to be processed first.
          setTimeout(() => {
            if (
              !document.activeElement ||
              document.activeElement.tagName !== 'BUTTON'
            ) {
              onCancel()
            }
          }, 0)
        }}
      />
      <Button type="primary" onClick={handleCreate}>
        Create
      </Button>
    </Space.Compact>
  )
}

export default ViewFolderInput
