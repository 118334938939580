import { useSearchParams } from 'react-router-dom'
import { trpc } from '@services/trpc'
import { SidebarList, SidebarListItem } from '@components'
import { LayersTwo01 } from '@untitled-ui/icons-react'
import useStyles from './SidebarPeriodList.styles'
import { NoData } from '@components'
import { useMemo } from 'react'
import { useLedgerContext } from '@modules/ledger'

const dateFormat = 'D MMM, YYYY'

type SidebarListItem = {
  id: string
  name: string
}

interface SidebarPeriodListProps {}

const SidebarPeriodList: React.FC<SidebarPeriodListProps> = () => {
  const { styles } = useStyles()

  const [searchParams, setSearchParams] = useSearchParams()

  const { selectedDate } = useLedgerContext()

  // TODO: replace with actual periods for the date
  const { data: periodsClasses = [] } =
    trpc.api.periods.getPeriodClasses.useQuery()
  const periodItems = periodsClasses.map((periodClass) => ({
    id: periodClass,
    name: periodClass,
  }))

  const rootItem = useMemo(() => {
    return {
      id: 'root',
      name: selectedDate
        ? `${selectedDate.format(dateFormat)} (${periodItems.length})`
        : 'Select date',
      icon: <LayersTwo01 width={16} />,
    }
  }, [periodItems.length, selectedDate])

  const handleItemClick = (item: SidebarListItem | null) => {
    setSearchParams(
      (prev) => {
        const current = Object.fromEntries(prev.entries())
        return {
          ...current,
          period_class: String(item?.id || ''),
        }
      },
      { replace: true },
    )
  }

  if (!selectedDate)
    return (
      <div className={styles.emptyWrapper}>
        <NoData description="Please select date" small />
      </div>
    )

  return (
    <>
      <SidebarListItem
        item={rootItem}
        onClick={() => handleItemClick(null)}
        isSelected={!searchParams.get('period_class')}
      />
      <SidebarList
        items={periodItems}
        onItemClick={handleItemClick}
        selectedItemId={searchParams.get('period_class') || undefined}
      />
    </>
  )
}

export default SidebarPeriodList
