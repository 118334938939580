import { Form, Select, Spin } from 'antd'
import { useState } from 'react'
import type { FormInstance, UploadFile, UploadProps } from 'antd'
import { Upload } from 'antd'
import { Upload01 } from '@untitled-ui/icons-react'
import * as XLSX from 'xlsx'

const { Dragger } = Upload

interface ImportFormProps {
  form: FormInstance
}

type ImportFormValues = {
  importType: string
  modelGroup: string
  datamodel: string

  sheetName: string
  version: string
  currency: string
  from: string
  to: string
}

const dataTypeOptions = [
  {
    label: 'Accounts',
    value: 'accounts',
  },
  {
    label: 'Ledger Values',
    value: 'ledgerValues',
  },
]

const ImportForm: React.FC<ImportFormProps> = ({ form }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [fileContent, setFileContent] = useState<XLSX.WorkSheet | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  console.log('fileContent', fileContent)

  const readFile = (file: File) => {
    setIsLoading(true)
    const reader = new FileReader()
    reader.onload = (e) => {
      const data = e.target?.result
      const workbook = XLSX.read(data, { type: 'binary' })
      console.log('workbook', workbook)
      const sheetName = workbook.SheetNames[0]
      console.log('sheetName', sheetName)
      const worksheet = workbook.Sheets[sheetName]
      console.log('worksheet', worksheet)
      const json = XLSX.utils.sheet_to_json(worksheet)
      console.log('File content:', json)

      setFileContent(workbook)
      setIsLoading(false)
    }
    reader.readAsBinaryString(file)
  }

  const uploadProps: UploadProps = {
    // accept: '.csv, .xlsx, .xls',
    multiple: false,
    onRemove: (file) => {
      const index = fileList.indexOf(file)
      const newFileList = fileList.slice()
      newFileList.splice(index, 1)
      setFileList(newFileList)
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file])
      readFile(file)
      return false
    },
    fileList,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files)
    },
    onChange(info) {
      console.log('info', info)
    },
    onPreview(file) {
      console.log('file', file)
    },
  }

  const handleSubmit = (values: ImportFormValues) => {
    console.log(values)
  }

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <div>
        <div>
          <Form.Item<ImportFormValues>
            label="Import Type"
            name="importType"
            rules={[{ required: true }]}
          >
            <Select options={dataTypeOptions} />
          </Form.Item>
          <Form.Item<ImportFormValues>
            label="Model Group"
            name="modelGroup"
            rules={[{ required: true }]}
          >
            <Select options={[]} />
          </Form.Item>
          <Form.Item<ImportFormValues>
            label="Data Model"
            name="datamodel"
            rules={[{ required: true }]}
          >
            <Select options={[]} />
          </Form.Item>
        </div>

        <div>
          <Spin spinning={isLoading} tip="Parsing file...">
            <Dragger {...uploadProps}>
              <Upload01 />
              <p>Click or drag file to this area to upload</p>
              <p>
                Support for a single or bulk upload. Strictly prohibited from
                uploading company data or other banned files.
              </p>
            </Dragger>
          </Spin>

          {fileContent && !isLoading && (
            <div>
              <Form.Item<ImportFormValues>
                label="Sheet Name"
                name="sheetName"
                rules={[{ required: true }]}
              >
                <Select
                  options={fileContent.SheetNames.map((sheetName: string) => ({
                    label: sheetName,
                    value: sheetName,
                  }))}
                />
              </Form.Item>
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}

export default ImportForm
