import {
  AssumptionProperties,
  AssumptionsView,
  ViewContentLayout,
  ViewToolbar,
  ViewWithPropertiesLayout,
} from '@components'
import { useTablePeriodContext } from '@context/TablePeriodContext'
import { useColumnsLayout, useFilterParams } from '@hooks'
import { AssumptionTableItem } from '@server/interfaces/assumptions'
import { trpc } from '@services/trpc'

const rows = [
  {
    id: 'metric_name',
    label: 'Account Metric',
  },
]

const MetricNormalView: React.FC = () => {
  const { filters } = useFilterParams()
  const { periods } = useTablePeriodContext()

  const tableQuery = {
    preset: 'metrics',
    options: {
      periods,
      filters,
      totals: true,
    },
  }

  const {
    data: initialQueryData = [] as AssumptionTableItem[],
    isLoading,
    isFetching,
    refetch,
  } = trpc.api.transactions.tableQuery.useQuery(tableQuery)

  const layout = useColumnsLayout({
    datamodel: null,
    tableQuery,
    rows,
  })

  return (
    <ViewWithPropertiesLayout>
      <ViewContentLayout>
        <ViewToolbar />
        <AssumptionsView
          data={initialQueryData}
          layout={layout}
          isLoading={isLoading}
          isUpdating={isFetching}
          refetch={refetch}
        />
      </ViewContentLayout>

      <AssumptionProperties onRefetch={refetch} />
    </ViewWithPropertiesLayout>
  )
}

export default MetricNormalView
