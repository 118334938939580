import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
} from 'react'
import { useLocation } from 'react-router-dom'
import type { Dayjs } from 'dayjs'
import { DetailLevel } from '@server/interfaces/versions'

interface LedgerContextType {
  mode: DetailLevel
  isSummary: boolean
  isTransactions: boolean
  selectedDate: Dayjs | null
  setSelectedDate: (date: Dayjs | null) => void
}

const LedgerContext = createContext<LedgerContextType | undefined>(undefined)

const useLedgerContext = () => {
  const context = useContext(LedgerContext)
  if (context === undefined) {
    throw new Error('useLedgerContext must be used within a LedgerProvider')
  }
  return context
}

interface LedgerProviderProps {
  children: ReactNode
}

const LedgerProvider: React.FC<LedgerProviderProps> = ({ children }) => {
  const location = useLocation()
  const mode = location.pathname.includes('summary')
    ? 'summary'
    : ('transaction' as DetailLevel)

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null)

  useEffect(() => {
    setSelectedDate(null)
  }, [mode])

  return (
    <LedgerContext.Provider
      value={{
        mode,
        isSummary: mode === 'summary',
        isTransactions: mode === 'transaction',
        selectedDate,
        setSelectedDate,
      }}
    >
      {children}
    </LedgerContext.Provider>
  )
}

export { LedgerProvider, useLedgerContext }
