import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    tableWidgetToolbar: {
      display: 'flex',
      alignItems: 'center',
      height: 46,
      padding: `0 ${token.paddingContentHorizontal}px`,
    },
    toolbarActions: {
      display: 'flex',
      alignItems: 'center',
      fontSize: token.fontSize,
      fontWeight: token.fontWeightStrong,
      color: token.gray600,

      '& > button': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: token.gray600,
        fontWeight: token.fontWeightStrong,
        padding: 0,
      },
    },
    assignedEntitiesSelect: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& .ant-select-selection-placeholder': {
        color: token.gray700,
        fontWeight: token.fontWeightStrong,
        paddingInlineEnd: token.paddingXXS,
      },
    },
    searchButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
    },
    searchPopover: {
      '& .ant-popover-inner': {
        padding: `${token.paddingXS}px`,
      },
    },
    divider: {
      background: token.gray600,
      height: 16,
    },
    tableColumnsMetric: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
    },
  }
})
