import { CustomPageType } from '@modules/customComputed'
import { useConfigurationMode } from '@context/ConfigurationContext'
import CustomComputedSidebarConfig from './CustomComputedSidebarConfig'
import DataModelSidebarNormal from '@modules/datamodel/components/DataModelSidebar/DataModelSidebarNormal'
import { DatamodelGroup } from '@modules/datamodel'

interface CustomComputedSidebarProps {
  type: CustomPageType
}

const CustomComputedSidebar: React.FC<CustomComputedSidebarProps> = ({
  type,
}) => {
  const { isConfigurationMode } = useConfigurationMode()
  if (isConfigurationMode) {
    return <CustomComputedSidebarConfig type={type} />
  }
  return (
    <DataModelSidebarNormal
      dataModelGroup={DatamodelGroup.COMPUTED}
      dataModelGroupName="Custom"
    />
  )
}

export default CustomComputedSidebar
