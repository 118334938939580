import { Navigate } from 'react-router-dom'
import DummyPage from '@components/DummyPage'
import IncomePage from 'pages/operational/income/IncomePage'

const incomeRoutes = [
  {
    index: true,
    element: <Navigate to="/operational/income/revenue" replace />,
  },
  {
    path: 'revenue',
    handle: { title: 'Revenue' },
    // TODO: rename to  revenue
    Component: IncomePage,
  },
  {
    path: 'product-services',
    handle: { title: 'Product/Services' },
    Component: DummyPage,
  },
  {
    path: 'cost',
    handle: { title: 'Cost' },
    Component: DummyPage,
  },
  {
    path: 'inventory',
    handle: { title: 'Inventory' },
    Component: DummyPage,
  },
]

export default incomeRoutes
