import { Company } from '@modules/company'
import { ConfigSidebar } from '@components'
import { trpc } from '@services/trpc'
import CompanyCreateMenuButton from './CompanyCreateMenuButton'
import useModalState from '@hooks/useModalState'
import CreateCompanyModal from '../CreateCompanyModal'

const CompanySidebar = () => {
  const { data = [] } = trpc.api.companies.list.useQuery()
  const createItemModalState = useModalState()

  return (
    <>
      <ConfigSidebar<Company>
        name="Company"
        items={data}
        categories={[]}
        createButton={<CompanyCreateMenuButton />}
        onAddClick={createItemModalState.open}
        forceConfigMode
      />
      <CreateCompanyModal
        open={createItemModalState.isOpen}
        onClose={createItemModalState.close}
      />
    </>
  )
}

export default CompanySidebar
