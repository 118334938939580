import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    viewContainer: {
      flex: 1,
      display: 'flex',
      gap: token.paddingXS,
      overflow: 'auto',

      '> *': {
        flex: 1,
        backgroundColor: token.colorWhite,
        borderRadius: token.borderRadius,
        border: `1px solid ${token.colorBorder}`,
      },
    },
    vertical: {
      flexDirection: 'column',
    },
  }
})
