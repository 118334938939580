import { Checkbox, Form, FormInstance, Radio, Select } from 'antd'
import { ViewTabsControl } from '@components'
import { useEffect, useMemo, useState } from 'react'
import { trpc } from '@services/trpc'
import useStyles from './ExportForm.styles'
import { exportToExcel } from './exportHelpers'

interface ExportFormProps {
  form: FormInstance
}

type ExportFormValues = {
  dataType: string
  modelFunction: string
  datamodel: string

  fileFormat: string
  columns: string[]
  rows: string[]

  withData: boolean
}

const TABS = [
  { label: 'Columns', key: 'columns' },
  { label: 'Rows', key: 'rows' },
]

const DATA_TYPE_OPTIONS = [
  { label: 'Accounts', value: 'accounts' },
  { label: 'Ledger values', value: 'ledger' },
]

const ACCOUNTS_MODEL_FUNCTION_OPTIONS = [
  { label: 'Ledger account', value: 'account' },
  { label: 'Ledger metric', value: 'metric' },
]

const LEDGER_MODEL_FUNCTION_OPTIONS = [
  { label: 'Ledger summary', value: 'summary' },
  { label: 'Ledger transaction', value: 'transaction', disabled: true },
]

const initialValues = {
  dataType: 'accounts',
  modelFunction: 'account',
  withData: false,
}

const ACCOUNTS_DEFAULT_COLUMNS = [
  'Ledger Account',
  'Account Description',
  'Ledger Metric',
  'Intercompany',
  'Fixed Expense',
  'FX Rate Type',
  'Inactive',
]

const LEDGER_DEFAULT_COLUMNS = ['Company', 'Ledger Account', 'Entity']

const LEDGER_TRANSACTION_DEFAULT_COLUMNS = [
  ...LEDGER_DEFAULT_COLUMNS,
  'Date',
  'Transaction Name',
  'DR',
  'CR',
]

const ExportForm: React.FC<ExportFormProps> = ({ form }) => {
  const { styles, cx } = useStyles()
  const [currentTab, setCurrentTab] = useState(TABS[0].key)
  const { data: accounts } = trpc.api.accounts.listWithMetrics.useQuery()
  const dataToExport =
    accounts?.map((account) => ({
      'Ledger Account': account.name,
      'Account Description': account.description,
      'Ledger Metric': account.metric.name,
      Intercompany: account.metric.intercompany ? '✅' : '',
      'Fixed Expense': account.metric.fixed_expenses ? '✅' : '',
      'FX Rate Type': 'n/a',
      Inactive: account.metric.inactive ? '✅' : '',
    })) || []

  const { data: ledgers = [] } = trpc.api.datamodel.listByType.useQuery({
    type: 'ledger',
  })

  const dataType = Form.useWatch('dataType', form)
  const isAccounts = dataType === 'accounts'
  const isLedger = dataType === 'ledger'

  const modelFunctionOptions = useMemo(() => {
    if (isAccounts) return ACCOUNTS_MODEL_FUNCTION_OPTIONS
    if (isLedger) return LEDGER_MODEL_FUNCTION_OPTIONS
    return []
  }, [isAccounts, isLedger])

  const defaultColumns = useMemo(() => {
    if (isAccounts) return ACCOUNTS_DEFAULT_COLUMNS
    if (isLedger) return LEDGER_TRANSACTION_DEFAULT_COLUMNS
    return []
  }, [isAccounts, isLedger])

  const handleExport = (values: ExportFormValues) => {
    if (values.withData) {
      exportToExcel(dataToExport)
    } else {
      const headers = Object.keys(dataToExport[0]).reduce<
        Record<string, unknown>
      >((acc, key) => {
        acc[key] = ''
        return acc
      }, {})
      exportToExcel([headers])
    }
  }

  useEffect(() => {
    form.setFieldValue('datamodel', '')
    form.setFieldValue('modelFunction', modelFunctionOptions[0]?.value || '')
  }, [dataType])

  return (
    <Form
      form={form}
      onFinish={handleExport}
      layout="vertical"
      className={styles.container}
      initialValues={initialValues}
    >
      <Form.Item<ExportFormValues> name="withData">
        <Radio.Group
          options={[
            { label: 'Export to import', value: false },
            { label: 'Export with data', value: true },
          ]}
        />
      </Form.Item>

      <div className={styles.main}>
        <div className={styles.side}>
          <div className={styles.block}>
            <Form.Item<ExportFormValues>
              label="Data Type"
              name="dataType"
              //rules={[{ required: true }]}
            >
              <Select options={DATA_TYPE_OPTIONS} />
            </Form.Item>

            <Form.Item<ExportFormValues>
              label="Model Function"
              name="modelFunction"
              //rules={[{ required: true }]}
            >
              <Select options={modelFunctionOptions} />
            </Form.Item>

            {isAccounts && (
              <>
                <Form.Item label="Ledger Model">
                  <Select value="Not applicable" disabled />
                </Form.Item>
                <Form.Item label="Version">
                  <Select value="Not applicable" disabled />
                </Form.Item>
              </>
            )}
            {isLedger && (
              <Form.Item<ExportFormValues>
                label="Ledger Model"
                name="datamodel"
                rules={[{ required: true }]}
              >
                <Select
                  options={ledgers}
                  fieldNames={{ label: 'name', value: 'id' }}
                />
              </Form.Item>
            )}
          </div>

          <div className={styles.separator} />

          <Form.Item label="File Format">
            <Select value="Excel" disabled />
          </Form.Item>
        </div>

        <div className={cx(styles.side, styles.block)}>
          <ViewTabsControl
            tabs={TABS}
            currentTab={currentTab}
            onTabChange={setCurrentTab}
          />
          <div className={styles.columns}>
            <div className={styles.columnsTitle}>Required columns</div>
            {defaultColumns.map((column) => (
              <div key={column}>
                <Checkbox defaultChecked disabled />
                <label htmlFor={`column-${column}`} style={{ marginLeft: 8 }}>
                  {column}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Form>
  )
}

export default ExportForm
