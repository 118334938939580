import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    bulkFormLayout: {
      '.ant-form-item:not(:last-child)': {
        borderRight: 'none',
      },
      '.ant-form-item:nth-child(2) .datePicker': {
        maxWidth: '270px',
      },
      '.ant-form-item': {
        padding: `${token.paddingXS}px`,
      },
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      gap: 16,
    },
    headerRow: {
      display: 'grid',
      gridTemplateColumns: '160px 270px 160px',
    },
    row: {
      display: 'grid',
      gridTemplateColumns: '90% 10%',

      '& > :last-child': {
        gridColumn: '2/3',
      },
    },

    transactionFormWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      '.ant-form-item': {
        width: '100%',
        maxWidth: '200px',
      },

      '& .ant-input-number-affix-wrapper': {
        width: '100%',
      },
    },

    datePicker: {
      position: 'relative',
      width: '100%',
      // '&:before': {
      //   content: `''`,
      //   position: 'absolute',
      //   top: 0,
      //   left: 0,
      //   width: '87%',
      //   height: '100%',
      //   backgroundColor: 'rgba(0, 0, 0, 0.04)',
      //   borderRadius: '6px 0 0 6px',
      // },
      // '&.ant-picker-outlined.ant-picker-disabled': {
      //   backgroundColor: 'transparent',
      // },
    },
  }
})
