import { DataModelSidebar, useDataModelPageContext } from '@modules/datamodel'
import CreateCompoundModelModal from '../CreateCompoundModelModal'

const CompoundModelSidebar: React.FC = () => {
  const { datamodelGroup, title } = useDataModelPageContext()
  return (
    <DataModelSidebar
      dataModelGroup={datamodelGroup}
      dataModelGroupName={title}
      createModalComponent={CreateCompoundModelModal}
    />
  )
}

export default CompoundModelSidebar
