import React, { useEffect } from 'react'
import { SignIn, useUser } from '@clerk/clerk-react'
import { useNavigate } from 'react-router-dom'
import { Layout, Row } from 'antd'

export const SignInPage: React.FC = () => {
  const { isSignedIn } = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (isSignedIn) {
      navigate('/')
    }
  }, [isSignedIn, navigate])

  return (
    <Layout
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Row justify="center">
        <SignIn signUpUrl="/sign-up" />
      </Row>
    </Layout>
  )
}
