import { SVGProps } from 'react'

export default (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M16 8v11.2c0 1.68 0 2.52.327 3.162a3 3 0 0 0 1.311 1.311C18.28 24 19.12 24 20.8 24h.2m0 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0ZM11 8h10M11 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm10 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0Zm-5 8h5m0 0a2 2 0 1 0 4 0 2 2 0 0 0-4 0Z"
    />
  </svg>
)
