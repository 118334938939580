import { Form, message } from 'antd'
import CreateView from './CreateView'
import { useView } from '../../useView.hook'
import { CreateModal } from '@components'
import { CreateModalProps } from '@components/CreateModal'
import { trpc } from '@services/trpc'
import { buildTree } from '@modules/view/view.utils'
import { CreateViewFormValues } from './CreateView/CreateViewForm'
import { DataGroupId } from '@server/interfaces/datagroup'

interface CreateViewModalProps extends CreateModalProps {
  datagroupId: DataGroupId
}

const CreateViewModal: React.FC<CreateViewModalProps> = ({
  onClose,
  datagroupId,
  ...props
}) => {
  const [form] = Form.useForm()
  const { treeData, isFetching, onChange, resetTree } = useView(null)

  const { refetch: refetchViews } = trpc.api.views.list.useQuery()

  const { mutate: saveView } = trpc.api.views.createOrUpdate.useMutation({
    onSuccess: () => {
      message.success('View created')
      refetchViews()
      onClose?.()
    },
  })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: CreateViewFormValues) => {
    const tree = buildTree(treeData)
    saveView({
      ...values,
      tree,
    })
  }

  const handleCancel = () => {
    form.resetFields()
    resetTree()
    onClose?.()
  }

  return (
    <CreateModal
      {...props}
      title="Create Tag View"
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Create"
      width="95%"
      centered
    >
      <CreateView
        datagroupId={datagroupId}
        form={form}
        treeData={treeData}
        isFetching={isFetching}
        onChange={onChange}
        onSubmit={handleSubmit}
      />
    </CreateModal>
  )
}

export default CreateViewModal
