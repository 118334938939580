import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    footer: {
      color: `${token.colorPrimary}`,
      backgroundColor: `${token.colorPrimaryBg}`,
      padding: `0 ${token.paddingLG}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 40,
    },
  }
})
