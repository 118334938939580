import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    container: {
      backgroundColor: token.colorWhite,
      display: 'flex',
      flexDirection: 'column',
      gap: token.paddingXS,
    },
  }
})

const SidebarToolbarLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { styles } = useStyles()

  return <div className={styles.container}>{children}</div>
}

export default SidebarToolbarLayout
