export const ACCOUNT_CURRENCY_FX_RATE = {
  spotRate: 'Spot Rate',
  averageRate: 'Average Rate',
  endingBalanceRate: 'Ending Balance Rate',
} as const

export const ACCOUNT_CURRENCY_FX_RATE_LABELS = {
  [ACCOUNT_CURRENCY_FX_RATE.spotRate]: 'Spot Rate',
  [ACCOUNT_CURRENCY_FX_RATE.averageRate]: 'Average Rate',
  [ACCOUNT_CURRENCY_FX_RATE.endingBalanceRate]: 'Ending Balance Rate',
}

export const getAccountCurrencyFXRateOptions = () => {
  return Object.keys(ACCOUNT_CURRENCY_FX_RATE).map((key) => ({
    value: key,
    label:
      ACCOUNT_CURRENCY_FX_RATE_LABELS[
        ACCOUNT_CURRENCY_FX_RATE[key as keyof typeof ACCOUNT_CURRENCY_FX_RATE]
      ],
  }))
}
