import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    form: {
      display: 'grid',
      gridTemplateColumns: '1.5fr fit-content(100%) repeat(3, 1fr)',
      padding: token.paddingXS,
    },
  }
})
