import { PageContentLayout } from '@components'
import { ConfigPageProvider } from '@context/ConfigPageContext'
import {
  VersionSidebar,
  VersionView,
  VersionPageProvider,
} from '@modules/version'

const VersionPage: React.FC = () => {
  return (
    <ConfigPageProvider>
      <VersionPageProvider>
        <PageContentLayout>
          <VersionSidebar />
          <VersionView />
        </PageContentLayout>
      </VersionPageProvider>
    </ConfigPageProvider>
  )
}
export default VersionPage
