import { trpc } from '@services/trpc'
import { Space } from 'antd'
import { TableFilterSelect } from '@components'

export type FiltersState = {
  company: string
  version: string
  calendar: string
  currency: string
  account: string
}

interface TableFiltersProps {
  onChange: ({
    filter,
    value,
  }: {
    filter: keyof FiltersState
    value: string
  }) => void
  values: FiltersState
}

const getOptions = (data: { id: number; name: string }[], withAll = true) => {
  const options = data.map((item) => ({
    value: item.id.toString(),
    label: item.name,
  }))
  if (withAll) {
    options.unshift({ value: '', label: 'All' })
  }
  return options
}

const TableFilters: React.FC<TableFiltersProps> = ({ onChange, values }) => {
  const { data: currencies = [] } = trpc.api.currencies.list.useQuery()
  const { data: accounts = [] } = trpc.api.accounts.list.useQuery()
  const { data: companies = [] } = trpc.api.companies.list.useQuery()
  const { data: versions = [] } = trpc.api.versions.list.useQuery()
  const companyOptions = getOptions(companies)
  const versionOptions = getOptions(versions, false)
  const accountOptions = getOptions(accounts)
  const calendarOptions = [{ id: 1, name: 'CY 2024' }]
  const currencyOptions = getOptions(currencies)

  return (
    <Space>
      <TableFilterSelect
        label="Company"
        value={values.company}
        options={companyOptions}
        onChange={(value: string) => onChange({ filter: 'company', value })}
      />
      <TableFilterSelect
        label="Version"
        value={values.version}
        options={versionOptions}
        onChange={(value: string) => onChange({ filter: 'version', value })}
      />
      <TableFilterSelect
        label="Calendar Period"
        value={values.calendar}
        options={calendarOptions}
        onChange={(value: string) => onChange({ filter: 'calendar', value })}
      />
      <TableFilterSelect
        label="Currency"
        value={values.currency}
        options={currencyOptions}
        onChange={(value: string) => onChange({ filter: 'currency', value })}
      />
      <TableFilterSelect
        label="Account"
        value={values.account}
        options={accountOptions}
        onChange={(value: string) => onChange({ filter: 'account', value })}
      />
    </Space>
  )
}
export default TableFilters
