import React, { useEffect } from 'react'
import { PageContentLayout } from '@components'
import {
  Datagroup,
  DatagroupPageProvider,
  DatagroupSidebar,
  DatagroupConfigView,
  Datatag,
} from '@modules/datagroup'
import { LoaderFunction, useLoaderData } from 'react-router-dom'
import { trpcClient } from '@services/trpc'
import { QueryClient } from '@tanstack/react-query'
import { createTRPCQueryUtils } from '@trpc/react-query'
import { capitalize } from '@utils/strings'
import { ConfigPageProvider } from '@context/ConfigPageContext'
import { useConfigurationMode } from '@context/ConfigurationContext'

const queryClient = new QueryClient()
const clientUtils = createTRPCQueryUtils({ queryClient, client: trpcClient })

export const datagroupPageLoader: LoaderFunction = async ({ params }) => {
  const datagroupId = Number(params.id)
  const datagroup = await clientUtils.api.datatags.getDatagroupById.ensureData({
    id: datagroupId,
  })
  if (!datagroup) {
    throw new Response(null, { status: 404, statusText: 'Not Found' })
  }
  return { ...datagroup, name: capitalize(datagroup.name) }
}

const DatagroupPage: React.FC = () => {
  const datagroup = useLoaderData() as Awaited<
    ReturnType<typeof datagroupPageLoader>
  > as Datagroup

  const { disableConfigurationModeToggle, enableConfigurationModeToggle } =
    useConfigurationMode()

  useEffect(() => {
    disableConfigurationModeToggle()
    return () => {
      enableConfigurationModeToggle()
    }
  }, [])

  return (
    <DatagroupPageProvider datagroup={datagroup}>
      <ConfigPageProvider<Datatag, Datagroup> category={datagroup || null}>
        <PageContentLayout>
          <DatagroupSidebar />
          <DatagroupConfigView />
        </PageContentLayout>
      </ConfigPageProvider>
    </DatagroupPageProvider>
  )
}
export default DatagroupPage
