import { Navigate } from 'react-router-dom'
import { MainPageLayout } from '@components'
import incomeRoutes from './income'
import expensesRoutes from './expenses'
import assetsRoutes from './assets'

export const operationalRoutes = [
  {
    index: true,
    element: <Navigate to="/operational/income/revenue" replace />,
  },
  {
    path: 'income',
    handle: { title: 'Income' },
    element: <MainPageLayout page="income" />,
    children: incomeRoutes,
  },
  {
    path: 'expenses',
    handle: { title: 'Expenses' },
    element: <MainPageLayout page="expenses" />,
    children: expensesRoutes,
  },
  {
    path: 'assets',
    handle: { title: 'Assets & Liabilities' },
    element: <MainPageLayout page="assets" />,
    children: assetsRoutes,
  },
]

export default operationalRoutes
