interface Grays {
  gray25: string
  gray50: string
  gray100: string
  gray200: string
  gray300: string
  gray400: string
  gray500: string
  gray600: string
  gray700: string
  gray800: string
  gray900: string
}
interface Blues {
  blue25: string
  blue50: string
  blue100: string
  blue200: string
  blue300: string
  blue400: string
  blue500: string
  blue600: string
  blue700: string
  blue800: string
  blue900: string
}

interface Greens {
  green50: string
  green100: string
  green200: string
  green400: string
  green500: string
  green600: string
}

type Error = {
  error25: string
  error50: string
  error100: string
  error200: string
  error300: string
  error400: string
  error500: string
  error600: string
  error700: string
  error800: string
  error900: string
}

export const grays = {
  gray25: '#FCFCFD',
  gray50: '#F9FAFB',
  gray100: '#F2F4F7',
  gray200: '#EAECF0',
  gray300: '#D0D5DD',
  gray400: '#98A2B3',
  gray500: '#667085',
  gray600: '#475467',
  gray700: '#344054',
  gray800: '#1D2939',
  gray900: '#101828',
}
export const blues = {
  blue25: '#F5F8FF',
  blue50: '#EFF4FF',
  blue100: '#D1E0FF',
  blue200: '#B2CCFF',
  blue300: '#84ADFF',
  blue400: '#528BFF',
  blue500: '#2970FF',
  blue600: '#155EEF',
  blue700: '#004EEB',
  blue800: '#0040C1',
  blue900: '#00359E',
}

export const greens = {
  green50: '#EDFCF2',
  green100: '#D3F8DF',
  green200: '#B7E4C7',
  green400: '#3CCB7F',
  green500: '#039855',
  green600: '#099250',
}

export const error = {
  error25: '#FFFBFA',
  error50: '#FEF3F2',
  error100: '#FEE4E2',
  error200: '#FECDCA',
  error300: '#FDA29B',
  error400: '#F97066',
  error500: '#F04438',
  error600: '#D92D20',
  error700: '#B42318',
  error800: '#912018',
  error900: '#7A271A',
}

export interface Colors extends Grays, Blues, Greens, Error {}
