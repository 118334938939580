import {
  CellContext,
  ColumnDef,
  Row,
  RowData,
  createColumnHelper,
} from '@tanstack/react-table'
import { memo, useCallback, useMemo } from 'react'
import { TableWidget } from '@components'
import type { TableWidgetProps } from '@components/TableWidget'
import { TableValueCell, AssumptionDatatagsEditableCell } from './Cell'
import { ColumnMeta } from './Cell/TableValueCell'
import { DataModel } from '@modules/datamodel'
import useStyles from './AssumptionsTable.styles'

export type AssumptionRow = {
  rowid: string
  columns: Record<string, { value: number }>
  editable: boolean
  account_id: number
  company_id: number
  datagroups: Record<
    string,
    {
      id: number
      datagroup_id: number
      assumptionrow_id: number
    }
  >
  activity_class?: string
  period_id?: number
  version_id: number
  account_name: string
  company_name: string
  version_name: string
  assumption_id: number
  assumption_name: string
  accountdriver_id: number
  accountdriver_name: string
  currency_id: number
  currency_name: string
}

export type SelectedCellContext = CellContext<AssumptionRow, unknown> & {
  datatagsId: string
}

export type TableLayoutDef = {
  id: string
  type: 'datagroups' | 'row' | 'column' | 'groupedColumns' | undefined
  key: string
  label: string
  children?: Array<TableLayoutDef>
}

interface AssumptionsTableProps<T extends RowData>
  extends Omit<TableWidgetProps<T>, 'columns'> {
  onRowClick?: (row: Row<T>) => void
  data: T[]
  layout: TableLayoutDef[]
  isLoading: boolean
  refetch: () => void
  datamodel?: DataModel | null
  onChange: (datatagsId: string, value: string) => void
  onCellClick?: (cell: SelectedCellContext) => void
  disabled?: boolean
  selectedCellId?: string
}

const TableSimpleCell = memo(
  ({ getValue }: CellContext<AssumptionRow, unknown>) => {
    const value = getValue()
    return <>{value}</>
  },
)

const AssumptionsTable = <T extends RowData>({
  onRowClick,
  data,
  layout = [],
  onChange,
  selectedCellId,
  onCellClick,
  datamodel,
  disabled,
  ...restTableProps
}: AssumptionsTableProps<T>) => {
  const { styles } = useStyles()
  const columnHelper = createColumnHelper<T>()
  const totalsRowColumnsOffset = layout.findIndex(
    (l) => l.type === 'groupedColumns',
  )
  const mapColumnDefs = useCallback(
    (path: Array<string>) =>
      (
        c: TableLayoutDef,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ): ColumnDef<T, any> => {
        if (c.children && c.children.length > 0) {
          return columnHelper.group({
            id: c.id,
            header: c.label,
            columns: c.children.map(mapColumnDefs([...path, c.key])),
          })
        } else {
          const meta = {
            column: c,
            onChange,
            disabled,
            onClick: onCellClick,
            selectedCellId,
            datamodel,
          } as ColumnMeta
          if (c?.type == 'row') {
            return columnHelper.accessor((r: any) => r[c?.id], {
              id: c.id,
              header: c.label,
              meta,
              cell: TableSimpleCell as any,
              size: 140,
            })
          }

          if (c?.type == 'datagroups') {
            return columnHelper.accessor((r: any) => r.datagroups?.[c.key], {
              id: c.id,
              header: c.label,
              meta,
              size: 120,
              cell: AssumptionDatatagsEditableCell as any,
            })
          }
          if (c?.type == 'column') {
            return columnHelper.accessor(
              (row) => {
                return (row as any).columns[c.id]
              },
              {
                id: c.id,
                header: c.label,
                meta,
                cell: TableValueCell as any,
                size: 90,
              },
            )
          }

          return {
            accessorKey: c.id,
          }
        }
      },
    [columnHelper, disabled, onCellClick, onChange, selectedCellId],
  )
  const columns = useMemo(
    () => layout.map(mapColumnDefs([])),
    [layout, mapColumnDefs],
  )

  return (
    <>
      <TableWidget<T>
        {...restTableProps}
        className={styles.table}
        data={data}
        columns={columns}
        onRowClick={onRowClick}
        totalsRowColumnsOffset={totalsRowColumnsOffset}
        withIndex
        virtualized
      />
    </>
  )
}

export default AssumptionsTable
