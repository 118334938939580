import { DataModelInput } from '@modules/datamodel'
import { Form, FormInstance, Input, Select, Switch } from 'antd'
import useStyles from './CreateCompoundModelForm.styles'
import { ModalFormLayout } from '@components/index'

interface CreateCompoundModelFormProps {
  form: FormInstance
  onSubmit: (values: DataModelInput) => void
}

const CreateCompoundModelForm: React.FC<CreateCompoundModelFormProps> = ({
  form,
  onSubmit,
}) => {
  const { styles } = useStyles()

  return (
    <Form form={form} onFinish={onSubmit} layout="vertical">
      <ModalFormLayout className={styles.form}>
        <Form.Item
          name="name"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input the model name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item name="accountDriver" label="Primary Account Driver">
          <Select placeholder="Select account driver" />
        </Form.Item>

        <Form.Item name="calendar" label="Input: Calendar Basis">
          <Select placeholder="Select calendar basis" />
        </Form.Item>

        <Form.Item name="allocation" label="Record Allocation">
          <Switch disabled />
        </Form.Item>
      </ModalFormLayout>
    </Form>
  )
}

export default CreateCompoundModelForm
