import useStyles from './PropertiesAccordion.styles'

import Accordion, { AccordionProps } from '@components/Accordion'

interface PropertiesAccordionProps extends AccordionProps {
  label: string | React.ReactNode
  children: React.ReactNode
  classNames?: {
    header?: string
    content?: string
  }
}

const PropertiesAccordion: React.FC<PropertiesAccordionProps> = ({
  children,
  ...props
}) => {
  const { styles } = useStyles()

  return (
    <Accordion {...props}>
      <div className={styles.section}>{children}</div>
    </Accordion>
  )
}

export default PropertiesAccordion
