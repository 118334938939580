import DummyPage from '@components/DummyPage'
import { Navigate } from 'react-router-dom'

const consolidationRoutes = [
  {
    index: true,
    element: <Navigate to="/financial/consolidation/intercompany" replace />,
  },
  {
    path: 'intercompany',
    handle: { title: 'Intercompany' },
    Component: DummyPage,
  },
  {
    path: 'consolidation-factors',
    handle: { title: 'Consolidation Factors' },
    Component: DummyPage,
  },
]

export default consolidationRoutes
