import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      overflow: 'hidden',
    },
    containerOpen: {
      marginTop: token.margin,
    },
    containerFullScreen: {
      '*:has(~ &)': {
        display: 'none',
      },
    },
    header: {
      height: 32,
      overflow: 'hidden',
      backgroundColor: token.gray700,
      color: token.colorWhite,
      padding: `0 ${token.paddingXS}px`,
      marginBottom: token.marginXXS,
      cursor: 'pointer',
      borderRadius: token.borderRadius,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: token.fontSizeSM,
      fontWeight: token.fontWeightStrong,
      transition: 'height 0.3s ease-out',

      '&&& button': {
        color: token.colorWhite,
        ':hover': {
          color: token.gray200,
        },
      },
    },
    headerClosed: {
      height: 0,
    },
    headerLabel: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: token.fontWeightStrong,
    },
    headerActions: {
      display: 'flex',
      alignItems: 'center',
      gap: token.marginXS,
    },
    headerIcon: {
      transition: 'rotate 0.3s ease-out',
    },
    headerIconOpened: {
      rotate: '180deg',
    },
    content: {
      overflow: 'auto',
      transition: 'max-height 0.3s ease-out',
    },
    contentFullScreen: {
      maxHeight: 'unset',
    },
    contentMaximized: {
      maxHeight: 300,
    },
    contentMinimized: {
      maxHeight: 0,
    },
  }
})
