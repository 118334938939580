import { useConfigurationMode } from '@context/ConfigurationContext'
// import MetricSidebarNormal from './MetricSidebarNormal'
import MetricSidebarConfig from './MetricSidebarConfig'
import { AccountsSidebarNormal } from '@modules/account'

const MetricSidebar = () => {
  const { isConfigurationMode } = useConfigurationMode()

  if (isConfigurationMode) {
    return <MetricSidebarConfig />
  }
  return <AccountsSidebarNormal title="Metric" />
}

export default MetricSidebar
