import useStyles from './ModalFormLayout.styles'

interface ModalFormLayoutProps {
  children: React.ReactNode
  className?: string
}

const ModalFormLayout: React.FC<ModalFormLayoutProps> = ({
  children,
  className,
}) => {
  const { styles, cx } = useStyles()

  return <div className={cx(styles.container, className)}>{children}</div>
}

export default ModalFormLayout
