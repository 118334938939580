import { Schema } from '@components/SelectedListWidget/index.types'

export const ACCOUNT_ITEMS_SCHEMA: Schema = [] as const

export const COLUMN_GROUPS = {
  columns: 'Columns',
  rows: 'Accounts',
} as const

export const SYSTEM_COLUMNS = [
  {
    id: 'version',
    name: 'Version',
    inputValue: 'Record context',
    disabled: true,
    itemGroup: COLUMN_GROUPS.columns,
  },
  {
    id: 'currency',
    name: 'Currency',
    inputValue: 'Record context',
    disabled: true,
    itemGroup: COLUMN_GROUPS.columns,
  },
] as const

export const getSystemColumns = (companyName: string) => {
  return [
    ...SYSTEM_COLUMNS,
    {
      id: 'company',
      name: 'Company',
      inputValue: companyName,
      disabled: true,
      itemGroup: COLUMN_GROUPS.columns,
    },
  ]
}
