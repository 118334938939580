import { Row } from '@tanstack/react-table'
import MetricsTable from './MetricsTable'
import { Metric } from '@modules/metric'
import { useConfigPageContext } from '@context/ConfigPageContext'
import { AccountCategory } from '@modules/account'

const MetricsTableView: React.FC = () => {
  const { selectedItem, setSelectedItem } = useConfigPageContext<
    Metric,
    AccountCategory
  >()

  const handleRowClick = (row: Row<Metric>) => {
    setSelectedItem(row.original)
  }

  return (
    <MetricsTable
      onRowClick={handleRowClick}
      selectedRows={selectedItem ? { [selectedItem.id]: true } : undefined}
    />
  )
}

export default MetricsTableView
