import React from 'react'
import { Form, ModalProps, message } from 'antd'
import { BulkFormLayout, BulkFormList, CreateModal } from '@components'
import { trpc } from '@services/trpc'
import useStyles from './CreateDatagroupMemberModal.styles.ts'
import { Datagroup, DatatagInput } from '@modules/datagroup/datagroup.types.ts'
import { skipToken } from '@tanstack/react-query'
import DatatagFormRow from './DatatagFormRow/DatatagFormRow.tsx'

interface CreateDatagroupMemberModalProps extends ModalProps {
  isOpen?: boolean
  onClose?: () => void
  datagroup: Datagroup | null
}

const CreateDatagroupMemberModal: React.FC<CreateDatagroupMemberModalProps> = ({
  isOpen = false,
  onClose,
  datagroup,
  ...props
}) => {
  const { styles } = useStyles()
  const [form] = Form.useForm()

  const { refetch } = trpc.api.datatags.listDatagroupMembers.useQuery(
    datagroup ? { id: datagroup.id } : skipToken,
  )

  const handleSuccess = () => {
    message.success(`${datagroup?.name} created successfully`)
    refetch()
    form.resetFields()
    onClose?.()
  }

  const { mutate: save } =
    trpc.api.datatags.createOrUpdateDatatagById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleSubmit = ({ items }: { items: DatatagInput[] }) => {
    items.forEach((item) => {
      save({
        ...item,
        datagroup_id: datagroup?.id,
      })
    })
  }

  const handleCancel = () => {
    onClose?.()
    form.resetFields()
  }

  return (
    <CreateModal
      {...props}
      open={isOpen}
      title={`Create ${datagroup?.name}`}
      onOk={form.submit}
      onCancel={handleCancel}
      okText="Create"
      cancelText="Close"
      width="clamp(500px, 50vw, 1000px)"
      centered
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <BulkFormLayout>
          <BulkFormList wrapped rowClassName={styles.row}>
            {(index) => {
              return <DatatagFormRow index={index} form={form} />
            }}
          </BulkFormList>
        </BulkFormLayout>
      </Form>
    </CreateModal>
  )
}

export default CreateDatagroupMemberModal
