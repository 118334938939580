import { useMemo } from 'react'
import useStyles from './SidebarList.styles'
import SidebarListItem, { type BaseItem } from './SidebarListItem'
import { File01 } from '@untitled-ui/icons-react'

type SidebarListProps<T extends BaseItem> = {
  onItemClick?: (item: T, e: React.MouseEvent<HTMLDivElement>) => void
  items: T[]
  selectedItemId?: T['id'] | string | null
  actions?: React.ReactNode
  classNames?: {
    item?: string
  }
  onAddClick?: (item: T) => void
}

const SidebarList = <T extends BaseItem>({
  onItemClick,
  items,
  selectedItemId,
  actions,
  classNames,
  onAddClick,
}: SidebarListProps<T>) => {
  const { styles } = useStyles()

  const itemsWithIcons = useMemo(
    () =>
      items.map((item) => ({
        ...item,
        icon: item.icon || <File01 width={16} />,
      })),
    [items],
  )

  return (
    <div className={styles.listRoot}>
      {itemsWithIcons.map((item) => {
        return (
          <SidebarListItem<T>
            key={item.id}
            item={item}
            onClick={onItemClick}
            isSelected={selectedItemId === item.id}
            actions={actions}
            className={classNames?.item}
            onAddClick={onAddClick}
          />
        )
      })}
    </div>
  )
}

export default SidebarList
