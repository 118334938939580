import { MetricInput } from '@modules/metric'
import { Form, FormInstance } from 'antd'
import useStyles from './CreateMetricForm.styles'
import { BulkFormList } from '@components'
import type { BulkFormListValues } from '@components/BulkFormList'
import MetricFormRow from './MetricFormRow'

interface CreateMetricFormProps {
  form: FormInstance
  onSubmit: (values: BulkFormListValues<MetricInput>) => void
}

const CreateMetricForm: React.FC<CreateMetricFormProps> = ({
  form,
  onSubmit,
}) => {
  const { styles } = useStyles()

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      className={styles.form}
    >
      <BulkFormList>
        {(index) => <MetricFormRow index={index} form={form} key={index} />}
      </BulkFormList>
    </Form>
  )
}

export default CreateMetricForm
