import { Form, FormInstance, Input } from 'antd'
import { useDescriptionFormField } from '@hooks'

interface DatatagFormRowProps {
  index: number
  form: FormInstance
  formName?: string
}

const DatatagFormRow: React.FC<DatatagFormRowProps> = ({
  index,
  form,
  formName = 'items',
}) => {
  useDescriptionFormField({
    form,
    nameLabel: 'value',
    formNamePath: [formName, index],
  })
  return (
    <>
      <Form.Item
        name={[index, 'value']}
        label="Name | ID"
        rules={[{ required: true }]}
      >
        <Input placeholder="Enter name or ID" />
      </Form.Item>
      <Form.Item name={[index, 'description']} label="Description">
        <Input placeholder="Enter description" />
      </Form.Item>
    </>
  )
}

export default DatatagFormRow
