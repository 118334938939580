import { CreateModal, TableWithData } from '@components'
import useStyles from './TimeMetricModal.styles'
import {
  TimeMetricViewConfig,
  TimeMetricViewYearConfig,
} from '@server/interfaces/period'
import { useEffect, useMemo, useState } from 'react'
import { Checkbox, DatePicker, Form, Select } from 'antd'
import { trpc } from '@services/trpc'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { capitalize } from '@utils/strings'
import dayjs, { type Dayjs } from 'dayjs'
import { CreateModalProps } from '@components/CreateModal'

type TimeMetricYearRow = {
  year: string

  days: boolean
  weeks: boolean
  quarters: boolean
  months: boolean
  years: boolean
}

type TimeMetricConfig = Omit<TimeMetricViewConfig, 'calendar'> & {
  calendar: string | number
}

type TimeSpan = {
  [key: string]: TimeMetricViewYearConfig
}

type FormValues = TimeMetricViewConfig & {
  from: Dayjs
  to: Dayjs
}

const columnHelper = createColumnHelper<TimeMetricYearRow>()

interface TimeMetricModalProps extends CreateModalProps {
  onConfirm: (values: TimeMetricConfig) => void
  timeMetricConfig: TimeMetricConfig
}

const tableCheckboxColumns: (keyof TimeMetricYearRow)[] = [
  'days',
  'weeks',
  'months',
  'quarters',
  'years',
]

const TimeMetricModal: React.FC<TimeMetricModalProps> = ({
  onClose,
  onConfirm,
  timeMetricConfig,
  ...props
}) => {
  const { styles } = useStyles()
  const { data: calendars = [] } = trpc.api.calendar.list.useQuery()
  const calendarOptions = calendars.map((calendar) => ({
    label: calendar.name,
    value: calendar.id,
  }))

  const [form] = Form.useForm()
  const initialFormValues = useMemo(() => {
    return {
      ...timeMetricConfig,
      from: dayjs(timeMetricConfig.from),
      to: dayjs(timeMetricConfig.to),
    }
  }, [timeMetricConfig])
  const initialTimeSpan = (timeMetricConfig.time_span || {}) as TimeSpan

  const [timeSpan, setTimeSpan] = useState(initialTimeSpan)
  const tableData = Object.entries(timeSpan).map(([year, timeSpan]) => ({
    year,
    ...timeSpan,
  })) as TimeMetricYearRow[]

  const values = Form.useWatch((values) => values, form)
  const { calendar } = values || {}

  const handleOk = async () => {
    form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
    setTimeSpan(initialTimeSpan)
    onClose?.()
  }
  const handleSubmit = (values: FormValues) => {
    onConfirm({
      ...values,
      from: values.from.format('YYYY-MM-DD'),
      to: values.to.format('YYYY-MM-DD'),
      time_span: timeSpan,
    })
    onClose?.()
  }

  const handleCheck = (year: string, column: string, value: boolean) => {
    setTimeSpan({
      ...timeSpan,
      [year]: {
        ...timeSpan[year],
        [column]: value,
      },
    })
  }

  const handleAllCheck = (year: string, value: boolean) => {
    setTimeSpan({
      ...timeSpan,
      [year]: {
        ...timeSpan[year],
        days: value,
        weeks: value,
        months: value,
        quarters: value,
        years: value,
      },
    })
  }

  const columns = [
    columnHelper.accessor((row) => row.year, {
      id: 'year',
      header: 'Fiscal Year',
      cell: (info) => {
        const cellsValues = Object.values(
          timeSpan[info.cell.row.id] as TimeMetricViewYearConfig,
        )
        const isChecked = cellsValues.every(Boolean)
        const isIndeterminate = !isChecked && cellsValues.some(Boolean)
        return (
          <div className={styles.cell}>
            <Checkbox
              checked={isChecked}
              indeterminate={isIndeterminate}
              onChange={(e) =>
                handleAllCheck(info.cell.row.id, e.target.checked)
              }
            />
            {info.getValue()}
          </div>
        )
      },
    }),
    ...tableCheckboxColumns.map((column: keyof TimeMetricYearRow) =>
      columnHelper.accessor((row) => row[column], {
        id: column,
        header: capitalize(column),
        cell: (info) => (
          <Checkbox
            checked={info.getValue() as boolean}
            onChange={(e) =>
              handleCheck(
                info.cell.row.id,
                info.cell.column.id,
                e.target.checked,
              )
            }
            className={styles.cell}
          />
        ),
      }),
    ),
  ]

  useEffect(() => {
    if (!calendar && calendars.length > 0) {
      form.setFieldValue('calendar', calendars[0].id)
    }
  }, [calendar, calendars, form])

  return (
    <CreateModal
      {...props}
      title="Time metric view"
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Apply"
      width="clamp(500px, 50vw, 1000px)"
      centered
      className={styles.modal}
      classNames={{
        body: styles.modalBody,
      }}
    >
      <p className={styles.info}>Select calendar and time range to display</p>
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={initialFormValues}
        className={styles.form}
      >
        <Form.Item
          name="calendar"
          label="Calendar"
          rules={[{ required: true }]}
        >
          <Select options={calendarOptions} />
        </Form.Item>
        <div className={styles.dates}>
          <Form.Item name="from" label="Start" rules={[{ required: true }]}>
            <DatePicker />
          </Form.Item>
          <Form.Item name="to" label="End" rules={[{ required: true }]}>
            <DatePicker />
          </Form.Item>
        </div>
        <TableWithData<TimeMetricYearRow>
          columns={columns as ColumnDef<TimeMetricYearRow>[]}
          data={tableData}
          getRowId={(row) => row.year}
          withIndex
        />
        <div className={styles.options}>
          <div className={styles.checkbox}>
            <Form.Item valuePropName="checked" name="suppress_zero_rows">
              <Checkbox />
            </Form.Item>
            <label htmlFor="suppress_zero_rows">Suppress zero rows</label>
          </div>
          <div className={styles.checkbox}>
            <Form.Item valuePropName="checked" name="display_period_total">
              <Checkbox />
            </Form.Item>
            <label htmlFor="display_period_total">Display period total</label>
          </div>
          <div className={styles.checkbox}>
            <Form.Item valuePropName="checked" name="default">
              <Checkbox />
            </Form.Item>
            <label htmlFor="default">Set as default</label>
          </div>
        </div>
      </Form>
    </CreateModal>
  )
}

export default TimeMetricModal
