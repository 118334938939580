import { FC, useEffect } from 'react'
import { Checkbox, Form, Input, Radio, Select, message } from 'antd'
import { trpc } from '@services/trpc'
import { useAccountFormOptions } from '@hooks'
import { ACCOUNT_CLASSES } from '@constants/accountClasses'
import { PropertiesAccordion, PropertiesFormContainer } from '@components'
import {
  ACCOUNT_TYPES,
  AccountSource,
  ACCOUNT_SOURCE_LABELS,
} from '@constants/accountTypes'
import { VALUE_TYPES } from '@constants/valueTypes'
import { Account, Metric } from '@server/interfaces/account'
import { ACCOUNT_CATEGORIES } from '@constants/accountCategories'
import { MetricWithAccounts } from '@modules/metric'

export interface AccountPropertiesContentProps {
  account: Account | Metric | MetricWithAccounts | null
  source: string
  onSave?: () => void
  onClose?: () => void
}

type AccountFormValues = Account & {
  class: string
  category: string
  type: string
  value_type: string
}

const AccountPropertiesContent: FC<AccountPropertiesContentProps> = ({
  account,
  onClose,
  source,
  onSave,
}) => {
  const [form] = Form.useForm()
  const isAllowedToEdit = account?.source === source

  const isCustomComputed = account?.source === AccountSource.CUSTOM
  const isAccount =
    account?.source === AccountSource.ACCOUNT || account?.source === null
  const isLookup = account?.source === AccountSource.LOOKUP
  const isMetric = account?.source === AccountSource.METRIC

  const selectedClass = Form.useWatch('class', form)
  const selectedCategory = Form.useWatch('category', form)
  const selectedType = Form.useWatch('type', form)
  const selectedValueType = Form.useWatch('value_type', form)
  const selectedMetricId = Form.useWatch('metric_id', form)

  const {
    categoryOptions,
    typeOptions,
    valueOptions,
    metricOptions,
    accountInterpretationOptions,
    cashFlowMetricOptions,
    currencyFxRateOptions,
    aggregationOptions,
  } = useAccountFormOptions({
    form,
    isCustomComputed,
    isMetric: isMetric,
    isEditMode: true,
    isAccount,
    metricId: account
      ? 'metric' in account
        ? account.metric_id
        : undefined
      : undefined,
  })

  useEffect(() => {
    if (!account) return
    const values = {
      ...account,
      class: 'metric' in account ? account.metric.class : account.class,
      category:
        'metric' in account ? account.metric.category : account.category,
      type: 'metric' in account ? account.metric.type : account.type,
      value_type:
        'metric' in account ? account.metric.value_type : account.value_type,
      metric_id: 'metric' in account ? account?.metric_id : account.id,
      reset_annualy:
        'metric' in account
          ? account.metric.reset_annualy
          : account.reset_annualy,
      intercompany:
        'metric' in account
          ? account.metric.intercompany
          : account.intercompany,
      increase_favorable:
        'metric' in account
          ? account.metric.increase_favorable
          : account.increase_favorable,
      fixed_expenses:
        'metric' in account
          ? account.metric.fixed_expenses
          : account.fixed_expenses,
      report_display:
        'metric' in account
          ? account.metric.report_display
          : account.report_display,
      account_aggregation:
        'metric' in account
          ? account.metric.account_aggregation
          : account.account_aggregation,
      account_interpretation:
        'metric' in account
          ? account.metric.account_interpretation
          : account.account_interpretation,
      currency:
        'metric' in account ? account.metric.currency : account.currency,
      inactive:
        'metric' in account ? account?.metric.inactive : account.inactive,
    }
    form.setFieldsValue(values)
  }, [form, account])

  const handleSuccess = async () => {
    message.success('Account updated successfully')
    form.resetFields()
    onSave?.()
    onClose?.()
  }

  const { mutate: saveAccount, isPending: isPendingAcc } =
    trpc.api.accounts.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const { mutate: saveMetric, isPending: isPendingMetric } =
    trpc.api.metrics.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  if (!account) {
    return null
  }

  const isFinancial = selectedClass === ACCOUNT_CLASSES.financial
  const isOperational = selectedClass === ACCOUNT_CLASSES.operational

  const isAccountCreatedMetric =
    'metric' in account &&
    account?.name === account?.metric.name &&
    selectedMetricId === account?.metric_id

  const accountOption = [{ label: account?.name, value: '' }]
  const initialValues = {
    ...account,
    class: 'metric' in account ? account?.metric.class : account.class,
    category: 'metric' in account ? account?.metric.category : account.category,
    type: 'metric' in account ? account?.metric.type : account.type,
    value_type:
      'metric' in account ? account?.metric.value_type : account.value_type,
    reset_annualy:
      'metric' in account
        ? account?.metric.reset_annualy
        : account.reset_annualy,
    intercompany:
      'metric' in account ? account?.metric.intercompany : account.intercompany,
    metric_id: 'metric' in account ? account?.metric_id : account.id,
    increase_favorable:
      'metric' in account
        ? account?.metric.increase_favorable
        : account.increase_favorable,
    fixed_expenses:
      'metric' in account
        ? account?.metric.fixed_expenses
        : account.fixed_expenses,
    report_display:
      'metric' in account
        ? account?.metric.report_display
        : account.report_display,
    account_interpretation:
      'metric' in account
        ? account?.metric.account_interpretation
        : account.account_interpretation,
    account_aggregation:
      'metric' in account
        ? account?.metric.account_aggregation
        : account.account_aggregation,
    currency: 'metric' in account ? account?.metric.currency : account.currency,
    inactive: 'metric' in account ? account?.metric.inactive : account.inactive,
  }

  const handleSave = () => {
    form.submit()
  }

  const handleSubmit = async (values: AccountFormValues) => {
    if (isMetric) {
      saveMetric({
        ...values,
        id: account!.id,
        description: values.description || '',
        memo: values.memo || '',
        source: values.source || undefined,
      })
    } else {
      saveAccount({
        ...values,
        id: account!.id,
        description: values.description || '',
        memo: values.memo || '',
        source: values.source || undefined,
      })
    }
  }

  const isAccountCategoryDisabled =
    (isMetric && isOperational) ||
    (isCustomComputed && isFinancial) ||
    (isLookup && isOperational) ||
    (isAccount && isOperational) ||
    isCustomComputed ||
    isLookup

  const isMetricDisabled =
    isMetric || (isFinancial && isCustomComputed) || !isAllowedToEdit

  const isAccountHidden = (isFinancial && isCustomComputed) || isMetric

  const isValueTypeHidden =
    isMetric &&
    selectedCategory === ACCOUNT_CATEGORIES.cashFlow &&
    selectedClass === ACCOUNT_CLASSES.financial

  const isValueTypeDisabled =
    (isFinancial && isAccount) ||
    (isOperational && isAccount && !isAccountCreatedMetric) ||
    (!isAccountCreatedMetric && isCustomComputed && isOperational) ||
    !isAllowedToEdit ||
    (isMetric && isFinancial)

  const isAccountTypeDisabled =
    (isFinancial && isAccount) ||
    (!isAccountCreatedMetric && !isMetric) ||
    !isAllowedToEdit

  const isFinStandartsCashFlowSectionVisible =
    (isMetric &&
      isFinancial &&
      selectedCategory !== ACCOUNT_CATEGORIES.cashFlow) ||
    (isAccount && isFinancial)

  const isFinancialInterpretationHidden =
    isMetric &&
    (selectedCategory === ACCOUNT_CATEGORIES.statistical ||
      selectedCategory === ACCOUNT_CATEGORIES.cashFlow)

  const isFinancialInterpretationDisabled =
    (isFinancial && isAccount) || !isAllowedToEdit

  const isCacheFlowMetricVisible =
    (isAccount && isFinancial) || (isMetric && isFinancial)

  const isCacheFlowMetricDisabled =
    (isAccount && isFinancial) || !isAllowedToEdit

  const isResetBalanceVisible =
    isAccount ||
    (isMetric &&
      isFinancial &&
      selectedCategory !== ACCOUNT_CATEGORIES.cashFlow) ||
    (isMetric && selectedClass === ACCOUNT_CLASSES.operational)

  const isResetBalanceDisabled =
    !isAllowedToEdit ||
    (isAccount && isFinancial) ||
    (!isAccountCreatedMetric && isAccount) ||
    (isMetric && isFinancial)

  const isReportDisplayHidden =
    (isAccount && selectedClass === ACCOUNT_CLASSES.operational) || isLookup

  const isReportDisplayDisabled =
    (isFinancial && isAccount) || !isAllowedToEdit || (isMetric && isFinancial)

  const isIncreaseFavorableDisabled =
    (isFinancial && isAccount) ||
    (!isAccountCreatedMetric && isAccount && isOperational) ||
    !isAllowedToEdit ||
    (isMetric &&
      isFinancial &&
      selectedCategory !== ACCOUNT_CATEGORIES.cashFlow)

  const isAccountAggregationHidden =
    (isFinancial &&
      isMetric &&
      selectedCategory === ACCOUNT_CATEGORIES.cashFlow) ||
    (isCustomComputed &&
      isFinancial &&
      selectedValueType === VALUE_TYPES.percentageRate)
  const isAccountAggregationDisabled =
    (isAccount && isFinancial) ||
    (!isAccountCreatedMetric && isAccount) ||
    !isAllowedToEdit ||
    (isCustomComputed &&
      selectedValueType === VALUE_TYPES.percentageRate &&
      isFinancial) ||
    (isMetric &&
      isFinancial &&
      selectedCategory !== ACCOUNT_CATEGORIES.cashFlow)

  const isCurrencyHidden =
    (isAccount &&
      selectedClass === ACCOUNT_CLASSES.operational &&
      selectedValueType === VALUE_TYPES.monetary) ||
    (isCustomComputed && isFinancial) ||
    (isMetric &&
      isFinancial &&
      selectedCategory === ACCOUNT_CATEGORIES.cashFlow) ||
    (isMetric &&
      selectedValueType !== VALUE_TYPES.monetary &&
      selectedCategory === ACCOUNT_CATEGORIES.statistical) ||
    (isCustomComputed &&
      isOperational &&
      selectedValueType !== VALUE_TYPES.monetary) ||
    (isLookup && selectedValueType !== VALUE_TYPES.monetary)

  const isCurrencyDisabled =
    isAccount && isOperational && !isAccountCreatedMetric

  //schould be set default metric value
  const isFixedExpenseVisible =
    (isFinancial &&
      isAccount &&
      [
        ACCOUNT_TYPES['Profit & Loss'].cost,
        ACCOUNT_TYPES['Profit & Loss'].expense,
      ].includes(selectedType)) ||
    (isMetric &&
      isFinancial &&
      [
        ACCOUNT_TYPES['Profit & Loss'].cost,
        ACCOUNT_TYPES['Profit & Loss'].expense,
      ].includes(selectedType))
  !isCustomComputed && !isLookup

  const isIntercompanyVisible =
    (isFinancial && isAccount) ||
    (isMetric &&
      isFinancial &&
      selectedCategory !== ACCOUNT_CATEGORIES.cashFlow)

  const label =
    ACCOUNT_SOURCE_LABELS[source as keyof typeof ACCOUNT_SOURCE_LABELS]

  return (
    <PropertiesFormContainer
      onCancel={onClose}
      onSave={handleSave}
      saveLoading={isPendingAcc || isPendingMetric}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={initialValues}
      >
        <PropertiesAccordion label={`Account Driver | ${label}`}>
          <Form.Item
            name="name"
            label="Name | ID"
            rules={[
              {
                required: true,
                message: 'Please input the account name or ID!',
              },
            ]}
          >
            <Input placeholder="Enter name or ID" disabled={!isAllowedToEdit} />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input
              placeholder="Enter description"
              disabled={!isAllowedToEdit}
            />
          </Form.Item>

          <Form.Item name="memo" label="Memo">
            <Input.TextArea
              autoSize={{ minRows: 4, maxRows: 4 }}
              placeholder="Enter memo"
              disabled={!isAllowedToEdit}
            />
          </Form.Item>
        </PropertiesAccordion>

        <PropertiesAccordion label="General">
          <Form.Item name="class" label="Account Class">
            <Radio.Group aria-label="Account Class" disabled={!isAllowedToEdit}>
              <Radio value="financial" aria-label="Financial">
                Financial
              </Radio>
              <Radio value="operational" aria-label="Operational">
                Operational
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="category"
            label="Account Category"
            aria-label="Account Category"
          >
            <Select
              aria-label="Account Category Edit"
              disabled={isAccountCategoryDisabled}
              placeholder="Select category"
              options={categoryOptions}
            />
          </Form.Item>

          <Form.Item
            name="metric_id"
            label="Metric (Roll up)"
            aria-label="Metric (Roll up)"
            rules={[
              {
                required: true,
                message: 'Please input the metric',
              },
            ]}
          >
            <Select
              aria-label="Metric (Roll up)"
              placeholder="Select metric"
              options={metricOptions}
              disabled={isMetricDisabled}
            />
          </Form.Item>

          {!isAccountHidden && (
            <Form.Item label="Account" aria-label="Account">
              <Select
                aria-label="Account"
                options={accountOption}
                disabled
                value=""
              />
            </Form.Item>
          )}
          {!isValueTypeHidden && (
            <Form.Item name="value_type" label="Value Type">
              <Select
                aria-label="Value Type Edit"
                disabled={isValueTypeDisabled}
                placeholder="Select value type"
                options={valueOptions}
              />
            </Form.Item>
          )}

          <Form.Item
            name="type"
            label="Account Type"
            rules={[
              {
                required: true,
                message: 'Please select the account type',
              },
            ]}
          >
            <Select
              aria-label="Account Type Edit"
              placeholder="Select type"
              options={typeOptions}
              disabled={isAccountTypeDisabled}
            />
          </Form.Item>
        </PropertiesAccordion>

        {isFinStandartsCashFlowSectionVisible && (
          <PropertiesAccordion label="Financial Standards / Cash Flow Mappings">
            {!isFinancialInterpretationHidden && (
              <Form.Item
                name="account_interpretation"
                label="Account Interpretation"
                aria-label="Account Interpretation"
                rules={[
                  {
                    required: isMetric,
                    message: 'Please input the account interpretation!',
                  },
                ]}
              >
                <Select
                  options={accountInterpretationOptions}
                  placeholder="Select account interpretation"
                  aria-label="Account Interpretation"
                  disabled={isFinancialInterpretationDisabled}
                />
              </Form.Item>
            )}
            {isCacheFlowMetricVisible && (
              <Form.Item
                name="cash_flow_metric"
                label="Cash Flow Metric"
                aria-label="Cash Flow Metric"
                rules={[
                  {
                    required: isMetric,
                    message: 'Please input the cash flow metric!',
                  },
                ]}
              >
                <Select
                  options={cashFlowMetricOptions}
                  placeholder="Select cash flow metric"
                  aria-label="Cash Flow Metric"
                  disabled={isCacheFlowMetricDisabled}
                />
              </Form.Item>
            )}
          </PropertiesAccordion>
        )}

        <PropertiesAccordion label="Reporting / Display Defaults">
          {isResetBalanceVisible && (
            <Form.Item valuePropName="checked" name="reset_annualy">
              <Checkbox
                aria-label="Reset balance annually"
                disabled={isResetBalanceDisabled}
              >
                Reset balance annually
              </Checkbox>
            </Form.Item>
          )}
          {!isReportDisplayHidden && (
            <Form.Item valuePropName="checked" name="report_display">
              <Checkbox
                aria-label="Report Display"
                disabled={isReportDisplayDisabled}
              >
                Report display / Reverse sign
              </Checkbox>
            </Form.Item>
          )}

          <Form.Item valuePropName="checked" name="increase_favorable">
            <Checkbox
              aria-label="Increase in value is favorable"
              disabled={isIncreaseFavorableDisabled}
            >
              Increase in value is favorable
            </Checkbox>
          </Form.Item>
          {!isAccountAggregationHidden && (
            <Form.Item name="account_aggregation" label="Account Aggregation">
              <Select
                options={aggregationOptions}
                aria-label="Account Aggregation"
                placeholder="Select aggregation"
                disabled={isAccountAggregationDisabled}
              />
            </Form.Item>
          )}
          {!isCurrencyHidden && (
            <Form.Item
              name="currency"
              label="Currency | FX Rate Type"
              aria-label="Currency | FX Rate Type"
              rules={[
                {
                  required: isMetric,
                  message: 'Please input the currency!',
                },
              ]}
            >
              <Select
                options={currencyFxRateOptions}
                aria-label="Currency | FX Rate Type"
                placeholder="Select currency"
                disabled={isCurrencyDisabled}
              />
            </Form.Item>
          )}
        </PropertiesAccordion>

        <PropertiesAccordion label="Other Settings">
          {isFixedExpenseVisible && (
            <Form.Item valuePropName="checked" name="fixed_expenses">
              <Checkbox aria-label="Fixed expense" disabled={!isAllowedToEdit}>
                Fixed expense
              </Checkbox>
            </Form.Item>
          )}
          {isIntercompanyVisible && (
            <Form.Item valuePropName="checked" name="intercompany">
              <Checkbox aria-label="Intercompany">Intercompany</Checkbox>
            </Form.Item>
          )}
          <Form.Item valuePropName="checked" name="inactive">
            <Checkbox aria-label="Inactive" disabled={!isAllowedToEdit}>
              Inactive
            </Checkbox>
          </Form.Item>
        </PropertiesAccordion>
      </Form>
    </PropertiesFormContainer>
  )
}

export default AccountPropertiesContent
