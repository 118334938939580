import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    name: {
      flex: 6,
      display: 'flex',
      alignItems: 'center',
      gap: token.paddingXS,
    },
    tagType: {
      flex: 2,
    },
    inputType: {
      flex: 3,
    },
    remove: {
      width: 40,
      display: 'flex',
      justifyContent: 'center',

      button: {
        '&&': {
          padding: 0,
          width: 20,
          height: 20,
          color: token.gray700,
        },
      },
    },
  }
})
