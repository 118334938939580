import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    table: {
      '& :is(td, th):last-child': {
        borderRight: `1px solid ${token.colorBorder}`,
      },
    },
  }
})
