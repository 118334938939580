import { useSearchParams } from 'react-router-dom'

const useDatamodelParams = () => {
  const [params, setParams] = useSearchParams()

  const datamodelId = params.get('datamodel')

  const changeDatamodelId = (datamodelId: string | number | null) => {
    if (datamodelId === null) {
      params.delete('datamodel')
      setParams(params)
    } else {
      setParams(
        (prev) => {
          const current = Object.fromEntries(prev.entries())
          return {
            ...current,
            datamodel: String(datamodelId),
          }
        },
        { replace: true },
      )
    }
  }

  return { datamodelId, changeDatamodelId }
}

export default useDatamodelParams
