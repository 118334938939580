import { Form, FormInstance } from 'antd'
import OperandConstructor, { type Operand } from '../OperandConstructor'

export type OperandFormValues = {
  operands: Operand[]
}

type CreateModelModalProps = {
  form: FormInstance
  onSubmit: (values: OperandFormValues) => void
  onChange: () => void
  formName?: string
}

const OperandConstructorForm: React.FC<CreateModelModalProps> = ({
  form,
  onSubmit,
  onChange,
}) => {
  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      onValuesChange={onChange}
    >
      <OperandConstructor />
    </Form>
  )
}

export default OperandConstructorForm
