import { CreateMenuButton } from '@components'
import { MenuProps } from 'antd'
import { useMemo } from 'react'
import useModalState from '@hooks/useModalState'
import CreateCompanyModal from '../../CreateCompanyModal'

const MENU_ITEM_KEYS = { item: 'item' } as const

const CompanyCreateMenuButton: React.FC = () => {
  const createItemModalState = useModalState()

  const handleCreateItemClick = (key: string) => {
    if (key === MENU_ITEM_KEYS.item) {
      createItemModalState.open()
    }
  }

  const menuItems = useMemo<MenuProps['items']>(() => {
    return [
      {
        key: MENU_ITEM_KEYS.item,
        label: 'Create Company',
      },
    ]
  }, [])

  return (
    <>
      <CreateMenuButton items={menuItems} onClick={handleCreateItemClick} />
      <CreateCompanyModal
        open={createItemModalState.isOpen}
        onClose={createItemModalState.close}
      />
    </>
  )
}

export default CompanyCreateMenuButton
