import { createStyles } from 'antd-style'

export default createStyles(() => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
      flex: 1,
      overflow: 'hidden',
      padding: 12,
      background: 'linear-gradient(180deg, #D2DFFF 0%, #F7F9FF 99.98%)',
      justifyContent: 'flex-end',
    },
    configMode: {
      background: 'linear-gradient(180deg, #D2FFE9 0%, #F9FFFC 99.98%)',
    },
  }
})
