import { FC } from 'react'
import useStyles from './TableWidgetFilter.styles'
import { Select } from 'antd'
import { FilterOption } from '../index.types'

interface TableWidgetTypeFilterProps {
  currentFilter?: string
  filterOptions?: FilterOption[]
  onFilterChange?: (value: string) => void
}

const TableWidgetTypeFilter: FC<TableWidgetTypeFilterProps> = ({
  currentFilter,
  filterOptions,
  onFilterChange,
}) => {
  const { styles } = useStyles()

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        View by:
        <Select
          value={currentFilter}
          variant="borderless"
          options={filterOptions}
          className={styles.select}
          onSelect={onFilterChange}
          popupMatchSelectWidth={false}
        />
      </div>
    </div>
  )
}

export default TableWidgetTypeFilter
