import { AccountsTable, AccountCategory } from '@modules/account'
import AccountProperties from '../../AccountProperties'
import { Row } from '@tanstack/react-table'
import {
  ViewContainerLayout,
  ViewContentLayout,
  ViewToolbar,
  ViewToolbarAddButton,
  ViewWithPropertiesLayout,
} from '@components'
import { AccountSource } from '@constants/accountTypes'
import { trpc } from '@services/trpc'
import { useConfigPageContext } from '@context/ConfigPageContext'
import CreateAccountModal from '../../CreateAccountModal'
import { Account } from '@server/interfaces/account'

const AccountsConfigView: React.FC = () => {
  const { selectedItem, setSelectedItem } = useConfigPageContext<
    Account,
    AccountCategory
  >()

  const { refetch: fetchAccounts } =
    trpc.api.accounts.listWithMetrics.useQuery()

  const handleRowClick = (row: Row<Account>) => {
    setSelectedItem(row.original)
  }

  return (
    <ViewWithPropertiesLayout>
      <ViewContentLayout>
        <ViewToolbar
          addButton={<ViewToolbarAddButton createModal={CreateAccountModal} />}
        />
        <ViewContainerLayout>
          <AccountsTable
            onRowClick={handleRowClick}
            selectedRows={
              selectedItem ? { [selectedItem.id]: true } : undefined
            }
          />
        </ViewContainerLayout>
      </ViewContentLayout>

      <AccountProperties
        onSave={fetchAccounts}
        account={selectedItem}
        source={AccountSource.ACCOUNT}
      />
    </ViewWithPropertiesLayout>
  )
}

export default AccountsConfigView
