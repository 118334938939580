import useStyles from './BulkFormLayout.styles'

interface BulkFormLayoutProps {
  children: React.ReactNode
  className?: string
}

const BulkFormLayout: React.FC<BulkFormLayoutProps> = ({
  children,
  className,
}) => {
  const { styles, cx } = useStyles()

  return <div className={cx(styles.container, className)}>{children}</div>
}

export default BulkFormLayout
