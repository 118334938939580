import { Form } from 'antd'
import ExportImportFormLayout from '../layouts/ExportImportFormLayout'
import ExportForm from '../ExportForm'

interface ExportTabProps {}

const ExportTab: React.FC<ExportTabProps> = () => {
  const [form] = Form.useForm()

  const handleSubmit = async () => {
    form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
  }

  return (
    <ExportImportFormLayout
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      submitText="Export"
    >
      <ExportForm form={form} />
    </ExportImportFormLayout>
  )
}

export default ExportTab
