import React, { FC } from 'react'
import { Button, ButtonProps } from 'antd'
import { PlusSquare } from '@untitled-ui/icons-react'
import useModalState from '@hooks/useModalState'
import { CreateModalProps } from '@components/CreateModal'

interface ViewToolbarAddButtonProps extends ButtonProps {
  createModal: React.ReactElement<CreateModalProps> | React.FC<CreateModalProps>
}

const ViewToolbarAddButton: FC<ViewToolbarAddButtonProps> = ({
  createModal,
  ...props
}) => {
  const modalState = useModalState()

  return (
    <>
      <Button
        {...props}
        type="text"
        icon={<PlusSquare width={16} />}
        onClick={modalState.open}
      >
        Add
      </Button>

      {React.isValidElement(createModal)
        ? React.cloneElement(createModal, {
            open: modalState.isOpen,
            onClose: modalState.close,
          })
        : React.createElement(createModal, {
            open: modalState.isOpen,
            onClose: modalState.close,
          })}
    </>
  )
}

export default ViewToolbarAddButton
