import { createContext, useContext, ReactNode, SetStateAction } from 'react'
import useDatatagColumns from './useDatatagColumns.hook'
import { DataModel } from '@modules/datamodel'
import { BaseItem } from '@components/SelectedListWidget/index.types'

interface DatatagColumnsContextType<T extends BaseItem> {
  selectedDatatagColumns: T[]
  setSelectedDatatagColumns: (updater: SetStateAction<T[]>) => void

  isDatatagColumnsDirty: boolean
  setIsDatatagColumnsDirty: (dirty: boolean) => void
  removeDatatagColumnItem: (item: T) => void
  resetDatatagColumns: () => void
}

const DatatagColumnsContext = createContext<
  DatatagColumnsContextType<BaseItem> | undefined
>(undefined)

const useDatatagColumnsContext = <T extends BaseItem>() => {
  const context = useContext(
    DatatagColumnsContext as React.Context<
      DatatagColumnsContextType<T> | undefined
    >,
  )
  if (context === undefined) {
    throw new Error(
      'useDatatagColumnsContext must be used within a DatatagColumnsProvider',
    )
  }
  return context
}

interface DatatagColumnsProviderProps {
  children: ReactNode
  selectedDataModel?: DataModel | null
}

const DatatagColumnsProvider: React.FC<DatatagColumnsProviderProps> = ({
  children,
  selectedDataModel,
}) => {
  const {
    selectedDatatagColumns,
    setSelectedDatatagColumns,

    removeItem: removeDatatagColumnItem,
    cancel: resetDatatagColumns,

    isDirty: isDatatagColumnsDirty,
    setIsDirty: setIsDatatagColumnsDirty,
  } = useDatatagColumns(selectedDataModel)

  return (
    <DatatagColumnsContext.Provider
      value={{
        selectedDatatagColumns,
        setSelectedDatatagColumns,

        isDatatagColumnsDirty,
        setIsDatatagColumnsDirty,
        removeDatatagColumnItem,
        resetDatatagColumns,
      }}
    >
      {children}
    </DatatagColumnsContext.Provider>
  )
}

export { DatatagColumnsProvider, useDatatagColumnsContext }
