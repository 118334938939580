import { ConfigSidebar } from '@components'
import { trpc } from '@services/trpc'
import {
  AccountSidebarConfigToolbar,
  AccountsSidebarCategoriesList,
} from '@modules/account'
import CreateCustomComputedModal from '../../CreateCustomComputedModal'
import { ACCOUNT_CATEGORIES } from '@constants/accountCategories'
import { DataModel, useDataModelPageContext } from '@modules/datamodel'

interface CustomComputedSidebarConfigProps {
  type: 'account' | 'metric'
}
const CustomComputedSidebarConfig = ({
  type,
}: CustomComputedSidebarConfigProps) => {
  // TODO: get custom accounts or metrics
  const { data: customs = [] } = trpc.api.datamodel.listByType.useQuery({
    type: 'computed',
  })
  const { changeSelectedDatamodelId } = useDataModelPageContext()

  const allowedCategories =
    type === 'account'
      ? [
          ACCOUNT_CATEGORIES.balanceSheet,
          ACCOUNT_CATEGORIES.profitsAndLoss,
          ACCOUNT_CATEGORIES.statistical,
        ]
      : [ACCOUNT_CATEGORIES.statistical]

  const handleItemClick = (item: DataModel) => {
    changeSelectedDatamodelId(item.id)
  }

  return (
    <ConfigSidebar
      name={`Custom ${type}`}
      items={customs}
      toolbar={
        <AccountSidebarConfigToolbar
          name={`Custom ${type}`}
          createModal={<CreateCustomComputedModal type={type} />}
        />
      }
      renderCategories={() => (
        <AccountsSidebarCategoriesList allowedCategories={allowedCategories} />
      )}
      createModal={<CreateCustomComputedModal type={type} />}
      onItemClick={handleItemClick}
    />
  )
}

export default CustomComputedSidebarConfig
