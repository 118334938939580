import { Button } from 'antd'
import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 900,
      gap: token.marginSM,

      '& > *': {
        backgroundColor: token.colorWhite,
        borderRadius: token.borderRadius,
        border: `1px ${token.colorBorder} solid`,
        padding: token.paddingSM,
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    footer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 16,
    },
  }
})

interface ExportImportFormLayoutProps {
  onSubmit: () => void
  onCancel: () => void
  submitText?: string
}

const ExportImportFormLayout: React.FC<
  React.PropsWithChildren<ExportImportFormLayoutProps>
> = ({ children, onSubmit, onCancel, submitText }) => {
  const { styles } = useStyles()

  return (
    <div className={styles.root}>
      <div className={styles.content}>{children}</div>
      <div className={styles.footer}>
        <Button onClick={onCancel}>Clear</Button>
        <Button type="primary" onClick={onSubmit}>
          {submitText || 'Submit'}
        </Button>
      </div>
    </div>
  )
}

export default ExportImportFormLayout
