import { createStyles } from 'antd-style'

// TODO: replace values with theme variables.
export default createStyles(({ token }) => ({
  switchContainer: {
    height: 48,
    paddingLeft: 12,
    paddingRight: 12,
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    color: token.blue900,
    fontSize: 14,
    fontWeight: 500,
    borderRadius: token.borderRadius,
    background: 'linear-gradient(96deg, #D1E0FF 1.38%, #F3E0FF 96.04%)',
  },
  switchContainerOn: {
    color: '#05603A',
    background: 'linear-gradient(96deg, #A6FFD4 1.38%, #EEFFC8 96.04%)',
  },

  switch: {
    borderRadius: 12,
    overflow: 'hidden',
    '& .ant-switch-handle::before': {
      backgroundColor: token.blue300,
    },
    '& .ant-switch-inner': {
      // background: '#D9D9D9',
      backgroundColor: token.colorWhite,
    },
  },
  switchOn: {
    '& .ant-switch-handle::before': {
      backgroundColor: token.colorWhite,
    },
    '& .ant-switch-inner': {
      backgroundColor: '#2CE78F',
    },
  },

  // switchLabel: {
  //   color: '#000',
  //   fontSize: 14,
  //   fontWeight: 500,
  //   lineHeight: '20px',
  // },
  // switchLabelOn: {
  //   color: '#054F31',
  //   fontSize: 14,
  //   fontWeight: 500,
  //   lineHeight: '20px',
  // },
}))
