import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    viewContent: {
      display: 'flex',
      // padding: token.paddingXS,
      // borderRadius: token.borderRadius,
      flex: 1,
      gap: token.marginSM,
      overflow: 'auto',
    },
  }
})

const ViewWithPropertiesLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { styles } = useStyles()

  return <div className={styles.viewContent}>{children}</div>
}

export default ViewWithPropertiesLayout
