import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    button: {
      height: 27,
      width: 27,
      padding: 0,
      borderRadius: token.borderRadiusSM,
      backgroundColor: token.colorWhite,
      border: `1px solid ${token.colorBorder}`,
    },
  }
})
