import React from 'react'
import { Switch, Tooltip } from 'antd'
import useStyles from './ConfigModeToggle.styles.ts'
import { useConfigurationMode } from '@context/ConfigurationContext'
import { useLocation, useMatches } from 'react-router-dom'

type Handle = {
  title?: string
  forceConfigurationMode?: boolean
}

const ConfigModeToggle: React.FC = () => {
  const location = useLocation()
  const matches = useMatches()

  const handle = matches.find((match) => match.pathname === location.pathname)
    ?.handle as Handle
  const forceConfigurationMode = handle?.forceConfigurationMode
  const {
    isConfigurationMode: isConfig,
    changeConfigurationMode,
    isConfigurationModeToggleDisabled,
  } = useConfigurationMode()
  const isConfigurationMode = forceConfigurationMode || isConfig
  const { styles, cx } = useStyles()

  const handleChange = (value: boolean) => {
    changeConfigurationMode(value)
  }

  return (
    <div
      className={cx(styles.switchContainer, {
        [styles.switchContainerOn]: isConfigurationMode,
      })}
    >
      <Tooltip
        title={
          isConfigurationModeToggleDisabled
            ? 'This page supports only configuration mode'
            : undefined
        }
      >
        <Switch
          checked={isConfigurationMode}
          onChange={handleChange}
          disabled={isConfigurationModeToggleDisabled}
          className={cx(styles.switch, {
            [styles.switchOn]: isConfigurationMode,
          })}
          aria-label="Configure mode"
        />
      </Tooltip>

      <span>Configure</span>
    </div>
  )
}

export default ConfigModeToggle
