import { SetStateAction } from 'react'
import {
  ColumnDef,
  RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import { Account } from '@server/interfaces/account'

type AccountRow = Account & {
  metricName: string
}

const columnHelper = createColumnHelper<AccountRow>()
const accountsColumns: ColumnDef<AccountRow, string>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: () => 'Name | ID',
  }),
  columnHelper.accessor((row) => row.metricName, {
    id: 'metricName',
    header: () => 'Account Metric',
  }),
]

const assignedAccountsColumnHelper = createColumnHelper<Account>()
const assignedAccountsColumns: ColumnDef<Account, string>[] = [
  assignedAccountsColumnHelper.accessor((row) => row.name, {
    id: 'name',
    header: () => 'Name | ID',
  }),
]

interface AssignAccountsViewProps {
  selected: RowSelectionState
  onSelectChange: (updater: SetStateAction<RowSelectionState>) => void
}

const AssignAccountsView: React.FC<AssignAccountsViewProps> = ({
  selected,
  onSelectChange,
}) => {
  const { data: accounts = [] } = trpc.api.accounts.listWithMetrics.useQuery()
  const { data: metrics = [] } = trpc.api.metrics.list.useQuery()

  const assignedAccounts = accounts.filter((account) => selected[account.id])

  const accountRows = accounts.map((account) => ({
    ...account,
    metricName:
      metrics.find((metric) => metric.id === account.metric_id)?.name || '',
  }))

  return (
    <>
      <TableWidget<Account>
        title="Assigned Accounts"
        data={assignedAccounts}
        columns={assignedAccountsColumns}
        getRowId={(row) => row.id}
        withIndex
      />
      <TableWidget<AccountRow>
        title="Available Accounts"
        data={accountRows}
        columns={accountsColumns}
        onSelect={onSelectChange}
        getRowId={(row) => row.id}
        selectedRows={selected}
        withIndex
        withCheckbox
      />
    </>
  )
}

export default AssignAccountsView
