import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    form: {
      display: 'grid',
      gridTemplateColumns: '1.5fr repeat(4, 1fr)',
      maxWidth: 1000,
    },

    formItem: {
      '&:not(:last-child)': {
        borderRight: `1px solid ${token.colorBorder}`,
        paddingRight: token.padding,
        marginRight: token.margin,
      },
    },
  }
})
