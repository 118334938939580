import { SidebarListItemPrimary, SidebarList } from '@components'
import useStyles from './ConfigSidebarCategoriesTab.styles'
import { LayersThree01, LayersTwo01 } from '@untitled-ui/icons-react'
import { Category, useConfigPageContext } from '@context/ConfigPageContext'
import { BaseItem } from '@components/SidebarList/SidebarListItem'

interface ConfigSidebarCategoriesTabProps {
  name: string
  onDoubleClick: () => void
  categories: Category[]
  onAddClick?: (category: Category) => void
  renderCategories?: (categories: Category[]) => React.ReactNode
  onCategoryClick?: (category: Category | null) => void
}

const ConfigSidebarCategoriesTab = <T extends BaseItem>({
  name,
  onDoubleClick,
  categories,
  onAddClick,
  renderCategories,
  onCategoryClick,
}: ConfigSidebarCategoriesTabProps) => {
  const { styles } = useStyles()
  const { selectedCategory, setSelectedCategory } = useConfigPageContext<
    T,
    Category
  >()

  const handleItemClick = (
    category: Category | null,
    e: React.MouseEvent<HTMLDivElement>,
  ) => {
    const isDoubleClick = e.detail === 2
    if (isDoubleClick) {
      onDoubleClick()
    }
    if (onCategoryClick) {
      onCategoryClick(category)
    } else {
      setSelectedCategory(category)
    }
  }

  const items = categories.map((category: Category) => ({
    ...category,
    icon: category.icon || <LayersTwo01 width={16} />,
  }))

  return (
    <div className={styles.content}>
      <SidebarListItemPrimary
        icon={<LayersThree01 width={16} />}
        onClick={(_, e) => handleItemClick(null, e)}
        isSelected={!selectedCategory}
        item={{ id: '', name }}
        className={styles.primary}
        onAddClick={onAddClick}
      />
      {renderCategories ? (
        renderCategories(items)
      ) : (
        <SidebarList
          items={items}
          onItemClick={(item, e) => handleItemClick(item, e)}
          selectedItemId={selectedCategory?.id}
          onAddClick={onAddClick}
        />
      )}
    </div>
  )
}

export default ConfigSidebarCategoriesTab
