import { Sidebar, SidebarList } from '@components'
import { useConfigurationMode } from '@context/ConfigurationContext'
import { SidebarContent } from '@components'
import DatagroupSidebarToolbar from './DatagroupSidebarToolbar'
import DatagroupSidebarTab from './DatagroupSidebarTab'
import PicklistSidebarTab from './PicklistSidebarTab'
import { trpc } from '@services/trpc'
import { skipToken } from '@tanstack/react-query'

import ConfigSidebarItemsTab from '@components/ConfigSidebar/ConfigSidebarItemsTab'
import { useDatagroupPageContext } from '../DatagroupPageContext'
import { TABS, useConfigPageContext } from '@context/ConfigPageContext'
import { View } from '@server/interfaces/view'
import { useEffect } from 'react'

const sidebarTabsList = [TABS.categories, TABS.items, TABS.views]

const DatagroupsSidebar = () => {
  const { isConfigurationMode } = useConfigurationMode()
  const { datagroup, isPicklist } = useDatagroupPageContext()
  const {
    selectedCategory,
    selectedView,
    setSelectedView,
    currentTab,
    setCurrentTab,
  } = useConfigPageContext()

  let title = ''
  if (isPicklist) {
    title = 'Pick list'
  } else if (datagroup) {
    title = datagroup.name
  }

  const DatagroupTab = isPicklist ? PicklistSidebarTab : DatagroupSidebarTab

  const { data: datagroupMembers = [] } =
    trpc.api.datatags.listDatagroupMembers.useQuery(
      selectedCategory ? { id: Number(selectedCategory.id) } : skipToken,
      { select: (data) => data.map((item) => ({ ...item, name: item.value })) },
    )

  const { data: views = [] } = trpc.api.views.list.useQuery()

  useEffect(() => {
    if (views.length > 0 && !selectedView) {
      setSelectedView(views[0])
    }
  }, [selectedView, setSelectedView, views])

  return (
    <Sidebar
      title={title}
      tabs={isConfigurationMode ? sidebarTabsList : undefined}
      selectedTab={currentTab}
      onTabChange={setCurrentTab}
    >
      <DatagroupSidebarToolbar />
      <SidebarContent>
        {currentTab === TABS.categories && <DatagroupTab />}
        {currentTab === TABS.items && (
          <ConfigSidebarItemsTab name="Members" items={datagroupMembers} />
        )}

        {currentTab === TABS.views && (
          <SidebarList<View>
            items={views}
            onItemClick={setSelectedView}
            selectedItemId={selectedView?.id}
          />
        )}
      </SidebarContent>
    </Sidebar>
  )
}

export default DatagroupsSidebar
