import useStyles from './SidebarCollapser.styles'
import { useConfigurationMode } from '@context/ConfigurationContext'
import { ChevronRightDouble } from '@untitled-ui/icons-react'
import { Button } from 'antd'

const SidebarCollapser: React.FC<{ title: string }> = ({ title }) => {
  const { isSidebarCollapsed, setIsSidebarCollapsed } = useConfigurationMode()
  const { styles } = useStyles()

  const handleCollapse = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    setIsSidebarCollapsed(!isSidebarCollapsed)
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Button
          className={styles.collapseButton}
          type="text"
          icon={
            <ChevronRightDouble
              width={16}
              style={{ rotate: isSidebarCollapsed ? '0deg' : '180deg' }}
            />
          }
          onClick={handleCollapse}
        />
      </div>
      <span className={styles.title}>{title}</span>
    </div>
  )
}

export default SidebarCollapser
