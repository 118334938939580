import type { ColumnDef, RowData } from '@tanstack/react-table'
import { NoData, TableWithData } from '@components'
import type { TableWithDataProps } from '@components/TableWithData'
import TableWidgetHeader from './TableWidgetHeader'
import TableWidgetToolbar from './TableWidgetToolbar'
import TableWidgetFilter from './TableWidgetFilter'
import useStyles from './TableWidget.styles'
import { FilterOption } from './index.types'
import { Skeleton, Spin } from 'antd'

export interface TableWidgetProps<
  T extends RowData,
  K extends string | object = string,
> extends TableWithDataProps<T, K> {
  data: T[]
  columns: ColumnDef<T, K>[]
  title?: string
  searchTerm?: string
  tableMode?: 'list' | 'tree'

  currentFilter?: string
  filterOptions?: FilterOption[]
  onFilterChange?: (value: string) => void

  onValueSelect?: (value: string) => void
  onSearch?: (term: string) => void
  onAssign?: (value: string) => void
  onTableModeChange?: (mode: 'list' | 'tree') => void

  toolbar?: React.ReactNode
  isLoading?: boolean
  isUpdating?: boolean
}

const TableWidget = <T extends RowData, K extends string | object = string>({
  columns,
  data,
  title,
  // searchTerm,
  // tableMode,
  currentFilter,
  filterOptions,

  onSearch,
  onAssign,
  // onTableModeChange,
  onFilterChange,
  toolbar,
  isLoading = false,
  isUpdating = false,
  className,
  ...rest
}: TableWidgetProps<T, K>) => {
  const { styles, cx } = useStyles()
  const isNoData = data.length === 0

  const renderContent = () => {
    if (isLoading) {
      return (
        <Skeleton
          active
          paragraph={{ rows: 8 }}
          title={false}
          className={styles.skeleton}
        />
      )
    }

    if (isNoData) {
      return <NoData className={styles.noData} />
    }

    return (
      <>
        <TableWithData<T, K>
          {...rest}
          columns={columns}
          data={data}
          className={cx(styles.table, className)}
        />
        {isUpdating && (
          <div className={styles.updating}>
            <Spin size="large" />
          </div>
        )}
      </>
    )
  }

  return (
    <div className={styles.tableWidget}>
      {title && <TableWidgetHeader title={title} />}
      <div className={styles.toolbarWrapper}>
        {toolbar || (
          <>
            <TableWidgetToolbar
              columns={columns}
              onSearch={onSearch}
              onAssign={onAssign}
            />
            {filterOptions && (
              <TableWidgetFilter
                currentFilter={currentFilter}
                filterOptions={filterOptions}
                onFilterChange={onFilterChange}
              />
            )}
          </>
        )}
      </div>
      <div className={styles.tableWrapper} id="table-wrapper">
        {renderContent()}
      </div>
    </div>
  )
}

export default TableWidget
