import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      height: 52,
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: `0 ${token.paddingContentHorizontal}px`,
      backgroundColor: token.colorWhite,
      borderTop: `1px ${token.colorBorder} solid`,
      transition: 'height 0.3s ease-out',
      overflow: 'hidden',
      gap: 16,
    },
    closed: {
      height: 0,
      borderTop: 'none',
    },
  }
})
