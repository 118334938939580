import { Outlet, useLocation, useMatches } from 'react-router-dom'
import { BottomNavbar } from '@components'
import useStyles from './MainPageLayout.styles'
import { useConfigurationMode } from '@context/ConfigurationContext'

type MainPageLayoutProps = {
  page?: string
}

type Handle = {
  title: string
  forceConfigurationMode?: boolean
}

const MainPageLayout: React.FC<MainPageLayoutProps> = ({ page }) => {
  const { styles, cx } = useStyles()
  const { isConfigurationMode } = useConfigurationMode()
  const location = useLocation()
  const matches = useMatches()

  const handle = matches.find((match) => match.pathname === location.pathname)
    ?.handle as Handle
  const forceConfigurationMode = handle?.forceConfigurationMode

  return (
    <>
      <div
        className={cx(
          styles.container,
          (isConfigurationMode || forceConfigurationMode) && styles.configMode,
        )}
      >
        <Outlet />
        {page && <BottomNavbar page={page} />}
      </div>
    </>
  )
}
export default MainPageLayout
