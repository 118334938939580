import { DetailLevel, VersionCategory } from '@server/interfaces/versions'

export const CATEGORY_OPTIONS = [
  { label: 'Ledger', value: 'ledger' as VersionCategory },
  { label: 'Standard', value: 'standard' as VersionCategory },
  {
    label: 'Reforecast',
    value: 'reforecast' as VersionCategory,
    disabled: true,
  },
]

export const DETAIL_LEVEL_OPTIONS = [
  { label: 'Summary', value: 'summary' as DetailLevel },
  { label: 'Transaction', value: 'transaction' as DetailLevel },
]
