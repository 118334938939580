import useStyles from './DetailsDrawer.styles'
import { useEffect, useRef, useState } from 'react'
import { Button } from 'antd'
import { RiPushpinLine } from 'react-icons/ri'
import { IoClose } from 'react-icons/io5'
import { LuMaximize2, LuMinimize2 } from 'react-icons/lu'

type DetailsDrawerProps = {
  open: boolean
  children?: React.ReactNode
}

const states = {
  minimized: 'minimized',
  maximized: 'maximized',
  fullScreen: 'fullScreen',
} as const

const DetailsDrawer: React.FC<DetailsDrawerProps> = ({ children, open }) => {
  const { styles, cx } = useStyles()
  const contentRef = useRef<HTMLDivElement>(null)
  const [state, setState] = useState<(typeof states)[keyof typeof states]>(
    states.minimized,
  )
  const isFullScreen = state === states.fullScreen
  const isMinimized = state === states.minimized
  const isMaximized = state === states.maximized

  useEffect(() => {
    if (!open) {
      setState(states.minimized)
    }
  }, [open])

  return (
    <div
      className={cx(
        styles.container,
        isFullScreen && styles.containerFullScreen,
        open && styles.containerOpen,
      )}
    >
      <div
        className={cx(styles.header, !open && styles.headerClosed)}
        onClick={() => {
          if (state === states.minimized) {
            setState(states.maximized)
          }
        }}
      >
        <span className={styles.headerLabel}>Details</span>
        <div className={styles.headerActions}>
          <Button type="text" icon={<RiPushpinLine size={16} />} />

          {isFullScreen ? (
            <Button
              type="text"
              icon={<LuMinimize2 size={16} />}
              onClick={(e) => {
                e.stopPropagation()
                setState(states.maximized)
              }}
            />
          ) : (
            <Button
              type="text"
              icon={<LuMaximize2 size={16} />}
              onClick={(e) => {
                e.stopPropagation()
                setState(states.fullScreen)
              }}
            />
          )}
          <Button
            type="text"
            icon={<IoClose size={16} />}
            onClick={(e) => {
              e.stopPropagation()
              setState(states.minimized)
            }}
          />
        </div>
      </div>
      <div
        className={cx(
          styles.content,
          isFullScreen && styles.contentFullScreen,
          isMaximized && styles.contentMaximized,
          isMinimized && styles.contentMinimized,
        )}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  )
}

export default DetailsDrawer
