import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    menu: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: token.blue800,
      width: 68,
      height: '100vh',
    },
    menuItems: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    logoItem: {
      height: 50,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logoItemSeparator: {
      height: 1,
      width: 40,
      backgroundColor: token.blue400,
      marginTop: 10,
    },
    menuItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 4,

      height: 50,

      color: token.blue100,
      textDecoration: 'none',
      transition: 'background-color 0.3s',

      fontSize: 8,
      fontWeight: 600,
      textTransform: 'uppercase',

      whiteSpace: 'pre-line',
      textAlign: 'center',
      lineHeight: 1.2,

      '&:hover': {
        backgroundColor: token.blue700,
        color: token.colorWhite,
      },

      '&.active': {
        backgroundColor: token.blue900,
        color: '#2CE78F',
        borderLeft: `4px solid #2CE78F`,
        // paddingLeft: 4,
        boxSizing: 'border-box',
      },
    },
    dropdown: {
      '& .ant-dropdown-menu': {
        backgroundColor: token.blue800,
        border: `1px solid ${token.colorWhite}`,

        '& .ant-dropdown-menu-item': {
          color: token.colorWhite,
          fontWeight: 600,
          '&:hover': {
            backgroundColor: token.blue700,
          },
        },
      },
    },
  }
})
