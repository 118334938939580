import dayjs from 'dayjs'
import useStyles from './AppFooter.styles'

const AppFooter: React.FC = () => {
  const { styles } = useStyles()
  const buildDate = dayjs(BUILD_DATE).format('MMMM D, YYYY - h:mm A	')
  return (
    <footer className={styles.footer}>
      <div>
        Copyright ©BudgetNOW Inc., 2020-{new Date().getFullYear()}. All rights
        reserved
      </div>
      <div>
        <b> 🏗️ Build #{RUN_NUMBER || 'dev'}:</b> {buildDate}, {RUN_ID || 'dev'}
      </div>
    </footer>
  )
}

export default AppFooter
