import { createContext, useContext, ReactNode } from 'react'
import type { Datagroup } from '@modules/datagroup'

interface DatagroupPageContextType {
  datagroup: Datagroup | null
  isPicklist: boolean
}

const DatagroupPageContext = createContext<
  DatagroupPageContextType | undefined
>(undefined)

const useDatagroupPageContext = () => {
  const context = useContext(DatagroupPageContext)
  if (context === undefined) {
    throw new Error(
      'useDatagroupPageContext must be used within a DatagroupPageProvider',
    )
  }
  return context
}

const DatagroupPageProvider: React.FC<{
  datagroup: Datagroup | null
  children: ReactNode
}> = ({ datagroup, children }) => {
  return (
    <DatagroupPageContext.Provider
      value={{
        datagroup,
        isPicklist: !datagroup,
      }}
    >
      {children}
    </DatagroupPageContext.Provider>
  )
}

export { DatagroupPageProvider, useDatagroupPageContext }
