import { Form, FormInstance } from 'antd'
import { useEffect } from 'react'

interface HookOptions {
  form: FormInstance
  formNamePath?: [string, number] | []
  nameLabel?: string
}

const useDescriptionFormField = ({
  form,
  formNamePath = [],
  nameLabel = 'name',
}: HookOptions) => {
  const name = Form.useWatch([...formNamePath, nameLabel], form)

  useEffect(() => {
    form.setFieldValue([...formNamePath, 'description'], name)
  }, [name, form, formNamePath])
}

export default useDescriptionFormField
