import { SidebarList } from '@components'
import { trpc } from '@services/trpc'
import { SidebarContent } from '@components'
import { View } from '@server/interfaces/view'
import { useConfigPageContext } from '@context/ConfigPageContext'

interface ViewsTabProps {
  onNewClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ViewsSidebarTab: React.FC<ViewsTabProps> = () => {
  const { data: views = [] } = trpc.api.views.list.useQuery()
  const { setSelectedView, selectedView } = useConfigPageContext()

  const handleItemClick = (view: View) => {
    setSelectedView(view)
  }

  return (
    <>
      <SidebarContent>
        {/* <SidebarCollapsibleItem
          label={`Views (${views.length})`}
          actions={
            <>
              <IconButton onClick={onNewClick}>
                <FilePlus01 width={16} />
              </IconButton>
            </>
          }
        >
          <SidebarList<View>
            items={views}
            onItemClick={handleItemClick}
            selectedItemId={selectedView?.id}
          />
        </SidebarCollapsibleItem> */}
        <SidebarList<View>
          items={views}
          onItemClick={handleItemClick}
          selectedItemId={selectedView?.id}
        />
      </SidebarContent>
    </>
  )
}

export default ViewsSidebarTab
