import { DataModel } from '@modules/datamodel'
import { trpc } from '@services/trpc'
import { Select } from 'antd'
import { skipToken } from '@tanstack/react-query'

interface AssumptionDatatagsEditableCellProps {
  value: {
    datagroup_id: number
    id: number
    assumptionrow_id: number
  }
  datamodel?: DataModel | null
}

export const AssumptionDatatagsEditableCell: React.FC<
  AssumptionDatatagsEditableCellProps
> = ({ value, datamodel }) => {
  const { data: datagroupMembers = [], isFetched } =
    trpc.api.datatags.listDatagroupMembers.useQuery(
      value?.datagroup_id ? { id: value.datagroup_id } : skipToken,
    )
  const updateAssumptionRow =
    trpc.api.assumptions.updateAssumptionRow.useMutation()

  if (!isFetched) return null

  // TODO: show all options for pages with no datamodel selected
  // temporarily show the datagroup value if no datamodel is selected
  if (!datamodel) {
    const member = datagroupMembers.find((d) => d.id == value.id)
    return member?.value || 'Uncategorized'
  }

  const datagroupColumn = datamodel.config.columns.find(
    (d) => d.type == 'datagroup' && d.datagroup_id == value.datagroup_id,
  )

  if (!datagroupColumn) return null

  const onChange = (selectedId: string | number) => {
    updateAssumptionRow.mutate({
      assumptionrow_id: value.assumptionrow_id,
      datagroup_id: value.datagroup_id,
      datatag_id: selectedId ? Number(selectedId) : null,
    })
  }

  const options = [
    {
      label: 'Uncategorized',
      value: '',
    },
    ...datagroupMembers.map((d) => ({
      label: d.value,
      value: d.id,
    })),
  ]

  return (
    <Select
      options={options}
      onChange={onChange}
      defaultValue={value.id || ''}
      variant="borderless"
      popupMatchSelectWidth={false}
    />
  )
}
