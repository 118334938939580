import { ConfigSidebar } from '@components'
import { trpc } from '@services/trpc'
import { useConfigPageContext } from '@context/ConfigPageContext'
import { AccountCategory } from '@modules/account/account.types'
import CreateMetricModal from '../../CreateMetricModal'
import {
  AccountSidebarConfigToolbar,
  AccountsSidebarCategoriesList,
} from '@modules/account'
import { Metric } from '@modules/metric/metric.types'

const MetricSidebarConfig = <T extends object>() => {
  const { selectedCategory } = useConfigPageContext<T, AccountCategory>()

  const { data: metrics = [] } = trpc.api.metrics.list.useQuery(undefined, {
    select: (data) =>
      data.filter(
        (metric) =>
          !selectedCategory ||
          metric[selectedCategory.propertyType] === selectedCategory.id,
      ) as Metric[],
  })

  return (
    <ConfigSidebar
      name="Metrics"
      items={metrics}
      toolbar={
        <AccountSidebarConfigToolbar
          name="Metrics"
          createModal={CreateMetricModal}
        />
      }
      renderCategories={() => <AccountsSidebarCategoriesList />}
      createModal={CreateMetricModal}
    />
  )
}

export default MetricSidebarConfig
