import type { DataModel } from '@modules/datamodel'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DatamodelRowItem } from '../compoundModel/compoundModel.types'
import { COLUMN_GROUPS } from '../compoundModel/compoundModel.constants'
import { BaseItem } from '@components/SelectedListWidget/index.types'
import { DatagroupValueColumnDefinition } from '@server/interfaces/datamodel'
import { trpc } from '@services/trpc'

const useDatatagColumns = (selectedModel?: DataModel | null) => {
  const { data: datagroupsAndCategories } =
    trpc.api.datatags.listDatagroupsByType.useQuery(['datagroup', 'category'])

  const initialDatatagColumns = useMemo<BaseItem[]>(() => {
    if (!selectedModel || !datagroupsAndCategories) return []
    const columns = (selectedModel.config.columns || []).filter(
      (column) => column.type === 'datagroup',
    ) as DatagroupValueColumnDefinition[]
    return columns.map((columnDef) => {
      const datagroup = datagroupsAndCategories.find(
        (dg) => dg.id === columnDef.datagroup_id,
      )
      return {
        id: columnDef.datagroup_id,
        name: datagroup?.name || '',
        members: columnDef.values,
        tagType: 'Data Group',
        allocate: false,
        itemGroup: COLUMN_GROUPS.columns,
      }
    })
  }, [selectedModel, datagroupsAndCategories])

  const [isDirty, setIsDirty] = useState(false)
  const [selectedDatatagColumns, setSelectedDatatagColumns] = useState<
    BaseItem[]
  >(initialDatatagColumns)

  useEffect(() => {
    setSelectedDatatagColumns(initialDatatagColumns)
  }, [initialDatatagColumns])

  const removeItem = useCallback((item: BaseItem | DatamodelRowItem) => {
    setIsDirty(true)
    setSelectedDatatagColumns((prev) => {
      return prev.filter((c) => c.id !== item.id)
    })
  }, [])

  const cancel = useCallback(() => {
    setIsDirty(false)
    setSelectedDatatagColumns(initialDatatagColumns)
  }, [initialDatatagColumns])

  return {
    selectedDatatagColumns,
    setSelectedDatatagColumns,

    removeItem,
    cancel,

    isDirty,
    setIsDirty,
  }
}

export default useDatatagColumns
