import { CreateMenuButton } from '@components'
import { MenuProps } from 'antd'
import { useMemo } from 'react'
import useModalState from '@hooks/useModalState'
import CreateVersionModal from '../../CreateVersionModal'

const MENU_ITEM_KEYS = {
  version: 'version',
  duplicate: 'duplicate',
} as const

const VersionCreateMenuButton: React.FC = () => {
  const createVersionModalState = useModalState()

  const handleCreateItemClick = (key: string) => {
    if (key === MENU_ITEM_KEYS.version) {
      createVersionModalState.open()
    }
  }

  const menuItems = useMemo<MenuProps['items']>(() => {
    return [
      {
        key: MENU_ITEM_KEYS.version,
        label: 'Create Version',
      },

      {
        key: MENU_ITEM_KEYS.duplicate,
        label: 'Duplicate Version',
        disabled: true,
      },
    ]
  }, [])

  return (
    <>
      <CreateMenuButton items={menuItems} onClick={handleCreateItemClick} />
      <CreateVersionModal
        open={createVersionModalState.isOpen}
        onClose={createVersionModalState.close}
      />
    </>
  )
}

export default VersionCreateMenuButton
