import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    list: {
      margin: 0,
      listStyle: 'none',
      display: 'flex',
      flexDirection: 'column',
      gap: token.paddingXXS,
      padding: token.paddingXS,
      overflowY: 'hidden',
      '> *': {
        flexShrink: 0,
      },
    },
    listWrapper: {
      overflowY: 'auto',
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: token.paddingXXS,
    },
    groupWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: token.paddingXXS,
    },
  }
})
