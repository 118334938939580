import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    emptyState: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 16,
    },
    emptyStateText: {
      fontSize: token.fontSizeLG,
      color: token.gray400,
    },
    emptyStateButton: {},
  }
})
