import { SidebarList, SidebarCollapsibleItem } from '@components'
import { useModalState } from '@hooks'
import { capitalize } from '@utils/strings'
import { Category, useConfigPageContext } from '@context/ConfigPageContext'
import { BaseItem } from '@components/SidebarList/SidebarListItem'
import React from 'react'
import { ModalProps } from 'antd'

export interface CreateModalProps extends ModalProps {
  onClose?: () => void
}

interface ConfigSidebarItemsTabProps<T> {
  name: string
  createModal?:
    | React.ReactElement<CreateModalProps>
    | React.FC<CreateModalProps>
  items: T[]
  onAddClick?: (category?: Category) => void
  renderItems?: (items: T[]) => React.ReactNode
  onItemClick?: (item: T) => void
}

const ConfigSidebarItemsTab = <T extends BaseItem>({
  name,
  createModal,
  items,
  onAddClick,
  renderItems,
  onItemClick,
}: ConfigSidebarItemsTabProps<T>) => {
  const modalState = useModalState()

  const { setSelectedItem, selectedItem, selectedCategory } =
    useConfigPageContext<T, Category>()

  const handleItemClick = (item: T) => {
    if (onItemClick) {
      onItemClick(item)
    } else {
      setSelectedItem(item)
    }
  }

  const handleAddClick = () => {
    if (onAddClick) {
      onAddClick(selectedCategory || undefined)
      return
    }

    if (createModal) {
      modalState.open()
      return
    }
  }

  return (
    <>
      {renderItems ? (
        renderItems(items)
      ) : (
        <SidebarCollapsibleItem
          label={`${capitalize(selectedCategory?.name || name)} (${items.length})`}
          onCreateClick={onAddClick || createModal ? handleAddClick : undefined}
        >
          <SidebarList<T>
            items={items}
            onItemClick={handleItemClick}
            selectedItemId={selectedItem?.id || undefined}
          />
        </SidebarCollapsibleItem>
      )}

      {createModal &&
        (React.isValidElement(createModal)
          ? React.cloneElement(createModal, {
              open: modalState.isOpen,
              onClose: modalState.close,
            })
          : React.createElement(createModal, {
              open: modalState.isOpen,
              onClose: modalState.close,
            }))}
    </>
  )
}

export default ConfigSidebarItemsTab
