import React, { useEffect } from 'react'
import { trpc } from '@services/trpc'
import cleaning from '@assets/clean_animation.webm'

export const TruncatePage: React.FC = () => {
  const wipeAll = trpc.api.system.truncateDatabase.useMutation({
    onSuccess: () => {
      setTimeout(() => {
        window.location.href = '/'
      }, 5000)
    },
  })

  useEffect(() => {
    wipeAll.mutateAsync()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
      }}
    >
      <video src={cleaning} autoPlay loop muted />
      <p>Cleaning up database...</p>
    </div>
  )
}
