import { Form } from 'antd'
import ExportImportFormLayout from '../layouts/ExportImportFormLayout'
import ImportForm from '../ImportForm'

interface ImportTabProps {}

const ImportTab: React.FC<ImportTabProps> = () => {
  const [form] = Form.useForm()

  const handleSubmit = async () => {
    form.submit()
  }

  const handleCancel = () => {
    form.resetFields()
  }

  return (
    <ExportImportFormLayout
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      submitText="Preview"
    >
      <ImportForm form={form} />
    </ExportImportFormLayout>
  )
}

export default ImportTab
