import {
  CreateModal,
  DatatagsTableWidget,
  SelectedListWidget,
  ViewContainerLayout,
  ViewTabsControl,
} from '@components'
import CreateModelForm from './CreateCompoundModelForm'
import { Form, message } from 'antd'
import useStyles from './CreateCompoundModelModal.styles'
import { trpc } from '@services/trpc'
import type {
  DatamodelRowItem,
  DatatagColumnItem,
} from '../../compoundModel.types'
import {
  SYSTEM_COLUMNS,
  TABS,
  getDataModelItemsSchema,
} from '../../compoundModel.constants'
import { useEffect, useState } from 'react'
import DataModelsTableWidget from './DataModelsTableWidget'
import { groupBy } from 'lodash'
import { DataModelInput, useDataModelPageContext } from '@modules/datamodel'
import {
  CompoundModelDatatagHeaderItem,
  CompoundModelDatatagListItem,
} from '../CompoundModelDatatagListItem'
import {
  DatatagColumnsProvider,
  useDatatagColumnsContext,
} from '@modules/datamodel/DatatagColumnsContext'
import {
  DatamodelRowsProvider,
  useDatamodelRowsContext,
} from '@modules/datamodel/DatamodelRowsContext'
import { DatagroupValueColumnDefinition } from '@server/interfaces/datamodel'
import { CreateModalProps } from '@components/CreateModal'

const CreateCompoundModelModal: React.FC<CreateModalProps> = ({
  open,
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { styles } = useStyles()
  const { datamodelGroup } = useDataModelPageContext()
  const { refetch: fetchModels } = trpc.api.datamodel.listByType.useQuery({
    type: datamodelGroup,
  })
  const { data: allDataModels = [] } = trpc.api.datamodel.list.useQuery()
  const [currentTab, setCurrentTab] = useState<'columns' | 'rows'>('columns')

  const {
    selectedDatamodelAccountdriverItems,
    updateAccountDriverInputSource,
    selectedDatamodelTableRows,
    selectRows,

    removeDatamodelRowItem,
    resetDatamodelRows,
  } = useDatamodelRowsContext()

  const { selectedDatatagColumns, resetDatatagColumns } =
    useDatatagColumnsContext<DatatagColumnItem>()

  const selectedDatagroupColumnsWithSystem = [
    ...selectedDatatagColumns,
    ...SYSTEM_COLUMNS,
  ]

  const handleSuccess = () => {
    message.success('Model created successfully')
    fetchModels()
    form.resetFields()
    onClose?.()
  }

  const { mutate: saveModel, isPending } =
    trpc.api.datamodel.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: DataModelInput) => {
    const groupedModelsAccountDrivers = groupBy(
      selectedDatamodelAccountdriverItems,
      'itemGroup',
    )
    const columns = selectedDatatagColumns.map((column) => ({
      type: 'datagroup',
      datagroup_id: column.id,
      values: column.members,
    })) as DatagroupValueColumnDefinition[]
    const newDataModel = {
      name: values.name,
      type: datamodelGroup,
      config: {
        columns,
        models: Object.keys(groupedModelsAccountDrivers).map((group) => ({
          name: group,
          id: allDataModels.find((model) => model.name === group)?.id as number,
          rows: groupedModelsAccountDrivers[group].map((accountDriver) => ({
            id: accountDriver.id,
            input: accountDriver.properties.inputSource.value,
          })),
        })),
      },
    }
    saveModel(newDataModel)
  }

  const handleCancel = () => {
    form.resetFields()
    onClose?.()
  }

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab as 'columns' | 'rows')
  }

  useEffect(() => {
    if (open) {
      form.resetFields()
      setCurrentTab('columns')
      resetDatatagColumns()
      resetDatamodelRows()
    }
  }, [form, open, resetDatamodelRows, resetDatatagColumns])

  const dataModelSchema = getDataModelItemsSchema({
    inputSource: updateAccountDriverInputSource,
  })

  return (
    <CreateModal
      {...props}
      title="Create model"
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      centered
      width="clamp(800px, 90%, 1800px)"
      classNames={{
        content: styles.modalContent,
      }}
      confirmLoading={isPending}
    >
      <div className={styles.formWrapper}>
        <CreateModelForm form={form} onSubmit={handleSubmit} />
      </div>

      <ViewTabsControl
        tabs={TABS}
        currentTab={currentTab}
        onTabChange={handleTabChange}
      />

      <ViewContainerLayout>
        {currentTab === 'columns' ? (
          <>
            <SelectedListWidget<DatatagColumnItem>
              title="Model Composition"
              items={selectedDatagroupColumnsWithSystem}
              renderItem={(item) => (
                <CompoundModelDatatagListItem item={item} />
              )}
              renderHeader={() => <CompoundModelDatatagHeaderItem />}
            />
            <DatatagsTableWidget />
          </>
        ) : (
          <>
            <SelectedListWidget<DatamodelRowItem>
              title="Model Composition"
              items={selectedDatamodelAccountdriverItems}
              schema={dataModelSchema}
              onRemove={removeDatamodelRowItem}
            />
            <DataModelsTableWidget
              title="Data Models"
              onSelect={selectRows}
              selectedRows={selectedDatamodelTableRows}
              withCheckbox
            />
          </>
        )}
      </ViewContainerLayout>
    </CreateModal>
  )
}

const WrappedCreateCompoundModelModal: React.FC<CreateModalProps> = (props) => {
  return (
    <DatatagColumnsProvider>
      <DatamodelRowsProvider>
        <CreateCompoundModelModal {...props} />
      </DatamodelRowsProvider>
    </DatatagColumnsProvider>
  )
}

export default WrappedCreateCompoundModelModal
