import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'
import type { DataModel, DatamodelGroup } from '@modules/datamodel'
import useDatamodelParams from '@hooks/useDatamodelParams'
import { trpc } from '@services/trpc'

interface DataModelPageContextType {
  selectedDataModel: DataModel | null
  changeSelectedDatamodelId: (datamodelId: string | number | null) => void
  title: string
  datamodelGroup: DatamodelGroup
  selectedDatagroup: string | null
  setSelectedDatagroup: (datagroup: string | null) => void
}

const DataModelPageContext = createContext<
  DataModelPageContextType | undefined
>(undefined)

const useDataModelPageContext = () => {
  const context = useContext(DataModelPageContext)
  if (context === undefined) {
    throw new Error(
      'useDataModelPageContext must be used within a DataModelPageProvider',
    )
  }
  return context
}

interface DataModelPageProviderProps {
  children: ReactNode
  title: DataModelPageContextType['title']
  datamodelGroup: DataModelPageContextType['datamodelGroup']
}

const DataModelPageProvider: React.FC<DataModelPageProviderProps> = ({
  children,
  title,
  datamodelGroup,
}) => {
  const { data: datamodels, isFetched } =
    trpc.api.datamodel.listByType.useQuery({
      type: datamodelGroup,
    })
  const [selectedDataModel, setSelectedDataModel] = useState<DataModel | null>(
    null,
  )
  const { datamodelId, changeDatamodelId } = useDatamodelParams()
  const [selectedDatagroup, setSelectedDatagroup] = useState<string | null>(
    null,
  )

  useEffect(() => {
    if (isFetched && datamodels) {
      const datamodel = datamodelId
        ? datamodels?.find((model) => model.id === Number(datamodelId)) || null
        : null

      if (datamodel) {
        console.log('Found datamodel', datamodel, ' will set it up')
      } else {
        console.log('No datamodel found, will set it up to null')
      }

      setSelectedDataModel(datamodel)
    }
  }, [isFetched, datamodelId, datamodels])

  return (
    <DataModelPageContext.Provider
      value={{
        title,
        datamodelGroup,

        selectedDataModel,
        changeSelectedDatamodelId: changeDatamodelId,

        selectedDatagroup,
        setSelectedDatagroup,
      }}
    >
      {children}
    </DataModelPageContext.Provider>
  )
}

export { DataModelPageProvider, useDataModelPageContext }
