import { Collapse, type CollapseProps } from 'antd'
import useStyles from './PropertiesFormCollapse.styles'

interface PropertiesFormCollapseProps extends CollapseProps {}

const PropertiesFormCollapse: React.FC<PropertiesFormCollapseProps> = ({
  items,
  defaultActiveKey,
}) => {
  const { styles } = useStyles()
  const activeKey = defaultActiveKey ?? items!.map((item) => item.key as string)

  return (
    <Collapse
      ghost
      items={items}
      defaultActiveKey={activeKey}
      className={styles.collapse}
    />
  )
}

export default PropertiesFormCollapse
