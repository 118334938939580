import { Navigate } from 'react-router-dom'
import CalendarPage from 'pages/drivers/time/calendar'

const otherRoutes = [
  {
    index: true,
    element: <Navigate to="/drivers/time/calendar" replace />,
  },
  {
    path: 'calendar',
    Component: CalendarPage,
    handle: { title: 'Calendar' },
  },
]

export default otherRoutes
