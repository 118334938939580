import { BulkFormList } from '@components'
import useStyles from './OperandConstructor.styles'
import { ComputedModelOperand } from '@server/interfaces/computed'
import OperandConstructorRow from '../OperandConstructorRow'

export type Operand = Omit<ComputedModelOperand, 'id'> & { id: string }

const OperandConstructor: React.FC = () => {
  const { styles } = useStyles()

  return (
    <div className={styles.container}>
      <BulkFormList wrapped name="operands">
        {(index, fields) => {
          const isOperationDisabled = index === fields.length - 1
          return (
            <OperandConstructorRow
              index={index}
              isOperationDisabled={isOperationDisabled}
            />
          )
        }}
      </BulkFormList>
    </div>
  )
}

export default OperandConstructor
