import { Navigate } from 'react-router-dom'
import DummyPage from '@components/DummyPage'

const expensesRoutes = [
  {
    index: true,
    element: <Navigate to="/operational/expenses/opex" replace />,
  },
  {
    path: 'opex',
    handle: { title: 'OPEX' },
    Component: DummyPage,
  },
  {
    path: 'workforce',
    handle: { title: 'Workforce' },
    Component: DummyPage,
  },
  {
    path: 'tax',
    handle: { title: 'Business Tax' },
    Component: DummyPage,
  },
]

export default expensesRoutes
