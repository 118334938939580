import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  type RowSelectionState,
  type ColumnDef,
  type CellContext,
  type OnChangeFn,
  type RowData,
  Row,
} from '@tanstack/react-table'
import { Table } from '@components'
import type { TableProps } from '@components/Table'
import { Checkbox } from 'antd'

export interface TableWithDataProps<
  T extends RowData,
  K extends string | object = string,
> extends Partial<TableProps<T>> {
  data: T[]
  columns: ColumnDef<T, K>[]
  selectedRows?: { [key: string]: boolean }
  onSelect?: OnChangeFn<RowSelectionState>
  // onSelect?: (updater: (prev: RowSelectionState) => RowSelectionState) => void
  getRowId?: (row: T) => string | number
  withCheckbox?: boolean
  disableCheckAll?: boolean
  withIndex?: boolean
  totalsRowColumnsOffset?: number
  enableRowSelection?: (row: Row<T>) => boolean
  // editedCells?: { [key: string]: string }
  // onEditValue?: (id: string, value: string) => void
}

const TableWithData = <T extends RowData, K extends string | object = string>({
  data,
  selectedRows,
  onSelect,
  columns: initialColumns,
  withCheckbox,
  disableCheckAll,
  withIndex,
  getRowId,
  totalsRowColumnsOffset,
  enableRowSelection,
  // editedCells,
  // onEditValue,
  ...rest
}: TableWithDataProps<T, K>) => {
  let columns = initialColumns
  const columnHelper = createColumnHelper<T>()
  // TODO: get back to it later
  // const [editCellId, setEditCellId] = useState<string | null>(null)

  if (withCheckbox) {
    const checkboxColumn = columnHelper.display({
      id: 'selection',
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
          disabled={disableCheckAll}
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      ),
      cell: (info) => (
        <Checkbox
          checked={info.row.getIsSelected()}
          indeterminate={info.row.getIsSomeSelected()}
          onChange={info.row.getToggleSelectedHandler()}
          disabled={!info.row.getCanSelect()}
          style={{ display: 'flex', justifyContent: 'center' }}
          aria-label={`Select row ${info.row.id}`}
        />
      ),
      meta: { style: { minWidth: 30, width: 40 } },
    })
    columns = [checkboxColumn, ...columns]
  }

  if (withIndex) {
    const indexColumn = columnHelper.display({
      id: '#',
      header: '#',
      cell: (info: CellContext<T, unknown>) => info.row.index + 1,
      meta: { style: { textAlign: 'center', width: 20 } },
      size: 40,
    })
    columns = [indexColumn, ...columns]
  }

  const selectProps = selectedRows
    ? {
        enableRowSelection: enableRowSelection || true,
        onRowSelectionChange: onSelect,
        state: {
          rowSelection: selectedRows,
        },
      }
    : {}

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getRowId: getRowId ? (row) => String(getRowId(row)) : undefined,

    // TODO: get back to it later
    // meta: {
    //   editCellId,
    //   onCellClick: setEditCellId,

    //   editedCells,
    //   onEditValue,
    // },
    ...selectProps,
  })

  return (
    <Table
      {...rest}
      table={table}
      totalsRowColumnsOffset={
        totalsRowColumnsOffset && withIndex
          ? totalsRowColumnsOffset + 1
          : totalsRowColumnsOffset
      }
    />
  )
}

export default TableWithData
