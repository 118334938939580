import { createStyles } from 'antd-style'

export default createStyles(({ token }) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: token.paddingSM,
    gap: token.paddingSM,
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
}))
