import useStyles from './AssumptionsTableWidgetToolbar.styles'
import TimeButton from './TimeButton'
import CompareButton from './CompareButton'
import DisplayButton from './DisplayButton'
import { Button } from 'antd'
import { Columns03 } from '@untitled-ui/icons-react'
import { useTablePeriodContext } from '@context/TablePeriodContext'

export interface AssumptionsTableWidgetToolbarProps {
  columnsCount: number
  columnsTotal: number
}

const AssumptionsTableWidgetToolbar: React.FC<
  AssumptionsTableWidgetToolbarProps
> = ({ columnsCount, columnsTotal }) => {
  const { styles } = useStyles()
  const { periods, setPeriods } = useTablePeriodContext()

  return (
    <div className={styles.toolbar}>
      <TimeButton timeMetricConfig={periods} onConfirm={setPeriods} />
      <div className={styles.separator} />
      <CompareButton />
      <div className={styles.separator} />
      <DisplayButton />
      <div className={styles.separator} />
      <Button type="link">
        <Columns03 width={16} />
        {columnsCount} / {columnsTotal} Visible columns
      </Button>
    </div>
  )
}

export default AssumptionsTableWidgetToolbar
