export const ACCOUNT_CLASSES = {
  financial: 'financial',
  operational: 'operational',
} as const

export const ACCOUNT_CLASSES_LABELS = {
  [ACCOUNT_CLASSES.financial]: 'Financial',
  [ACCOUNT_CLASSES.operational]: 'Operational',
} as const

export const ACCOUNT_CLASSES_OPTIONS = Object.values(ACCOUNT_CLASSES).map(
  (value) => ({
    value,
    label: ACCOUNT_CLASSES_LABELS[value],
  }),
)
