import { Button, ButtonProps } from 'antd'
import useStyles from './IconButton.styles'

interface IconButtonProps extends ButtonProps {}

const IconButton: React.FC<IconButtonProps> = ({ ...props }) => {
  const { styles } = useStyles()

  return <Button className={styles.button} {...props} />
}

export default IconButton
