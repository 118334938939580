import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ConfigProvider } from 'antd'
import './index.css'
import { RouterProvider } from 'react-router-dom'
import { trpc, trpcClient } from '@services/trpc'
import { router } from 'routes/router'
import { themeConfig } from 'theme'
import { ClerkProvider } from '@clerk/clerk-react'
import * as Sentry from '@sentry/react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const isDev = process.env.NODE_ENV === 'development'

if (!isDev) {
  console.log(`Build  #${RUN_ID}(${RUN_NUMBER}) built at ${BUILD_DATE}`) // eslint-disable-line no-console

  Sentry.init({
    dsn: 'https://ec6c5242f9957b2ee5e15b236dc9f4b4@o4507412279656448.ingest.us.sentry.io/4507442167021568',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration(),
    ],
    environment: isDev ? 'development' : 'production',
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'app.d.budgetnow.com/', /^\//],
    // Session Replay
    replaysSessionSampleRate: 0.75, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY
if (!PUBLISHABLE_KEY) {
  console.error('PUBLISHABLE_KEY was not provided')
}

const datePickerProps = {
  style: {
    width: '100%',
  },
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000, // 30 seconds
      refetchOnWindowFocus: !isDev,
    },
  },
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ConfigProvider theme={themeConfig} datePicker={datePickerProps}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
            <RouterProvider router={router} />
          </ClerkProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </trpc.Provider>
    </ConfigProvider>
  </React.StrictMode>,
)
