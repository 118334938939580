import { Button, Select, Switch } from 'antd'
import useStyles from './SelectedListItem.styles'
import { BaseItem, Schema } from '../../index.types'
import { IoCloseOutline } from 'react-icons/io5'
import { RiDraggable } from 'react-icons/ri'

interface SelectedListItemProps<T> {
  item: T
  schema?: Schema
  onRemove?: (item: T) => void
  // TODO: rework to use `renderItem` instead of `schema`
  renderItem?: (item: T) => React.ReactNode
}

const SelectedListItem = <T extends BaseItem>({
  item,
  schema,
  onRemove,
  renderItem,
}: SelectedListItemProps<T>) => {
  const { styles, cx } = useStyles()
  const { id, name, properties, disabled } = item

  return (
    <li className={cx(styles.itemContainer, { [styles.disabled]: disabled })}>
      <div className={styles.dndHandle}>
        <RiDraggable size={16} />
      </div>
      {renderItem ? (
        renderItem(item)
      ) : (
        <>
          <div className={styles.name}>{name}</div>
          {schema?.map(({ name: propertyName, type, options, onChange }) => {
            if (!properties) return null

            const { value, disabled: propertyDisabled } =
              properties[propertyName]

            switch (type) {
              case 'text':
                return (
                  <div className={styles.textContainer} key={propertyName}>
                    {value}
                  </div>
                )
              case 'select':
                return (
                  <div className={styles.selectContainer} key={propertyName}>
                    <Select
                      value={value}
                      options={options}
                      onChange={
                        onChange ? (value) => onChange(value, id) : undefined
                      }
                      disabled={propertyDisabled}
                      className={styles.select}
                    />
                  </div>
                )
              case 'switch':
                return (
                  <div className={styles.switchContainer} key={propertyName}>
                    <Switch
                      // className={styles.switch}
                      disabled={propertyDisabled}
                      value={value as boolean}
                      onChange={
                        onChange
                          ? (checked) => onChange(checked, id)
                          : undefined
                      }
                    />
                  </div>
                )
            }
          })}
          <div className={styles.removeButtonContainer}>
            <Button
              className={styles.removeButton}
              disabled={disabled}
              icon={<IoCloseOutline size={16} />}
              onClick={() => onRemove?.(item)}
            />
          </div>
        </>
      )}
    </li>
  )
}

export default SelectedListItem
