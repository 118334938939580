import NoDataSmallIcon from '@components/ui/Icons/NoDataSmall'
import NoDataIcon from '@components/ui/Icons/NoData'
import useStyles from './NoData.styles'

interface NoDataProps {
  style?: React.CSSProperties
  className?: string
  description?: string
  small?: boolean
}
const NoData: React.FC<NoDataProps> = ({
  description = 'No data',
  small,
  style,
  className,
}) => {
  const { styles, cx } = useStyles()

  return (
    <div className={cx(styles.container, className)} style={style}>
      {small ? <NoDataSmallIcon /> : <NoDataIcon />}
      <p className={styles.description}>{description}</p>
    </div>
  )
}

export default NoData
