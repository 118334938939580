import { NavLink } from 'react-router-dom'
import { menuItems } from '@constants/navigation'
import { LogoSmall } from '@components/ui/Icons'
import useStyles from './LeftMenu.styles'

const LeftMenu: React.FC = () => {
  const { styles } = useStyles()

  return (
    <ul className={styles.menu} aria-label="Main Menu">
      <li>
        <NavLink to="/" className={styles.logoItem}>
          <LogoSmall />
          <div className={styles.logoItemSeparator} />
        </NavLink>
      </li>
      <div className={styles.menuItems}>
        {menuItems.map((item) => {
          return (
            <li key={item.key}>
              <NavLink
                to={item.path}
                className={styles.menuItem}
                aria-disabled={item.disabled}
                aria-label={item.label}
              >
                {item.iconComponent && <item.iconComponent />}
                <span>{item.label}</span>
              </NavLink>
            </li>
          )
        })}
      </div>
    </ul>
  )
}

export default LeftMenu
