import { useMemo, useState } from 'react'
import {
  ColumnDef,
  OnChangeFn,
  RowSelectionState,
  createColumnHelper,
} from '@tanstack/react-table'
import {
  TableWidget,
  ViewContainerLayout,
  ViewContentLayout,
  ViewFooterLayout,
} from '@components'
import { trpc } from '@services/trpc'
import { Button } from 'antd'
import { Datatag } from '@modules/datagroup/datagroup.types'
import { ViewMetric } from '@server/interfaces/view'
import { mapSelectedRowIdsToTableSelectionState } from '@utils/tables'

const columnHelper = createColumnHelper<Datatag>()
const datatagsColumns: ColumnDef<Datatag, string>[] = [
  columnHelper.accessor((row) => row.value, {
    id: 'value',
    header: () => 'Name | ID',
  }),
]

const assignedDatatagsColumnHelper = createColumnHelper<Datatag>()
const assignedDatatagsColumns: ColumnDef<Datatag, string>[] = [
  assignedDatatagsColumnHelper.accessor((row) => row.value, {
    id: 'value',
    header: () => 'Value',
  }),
]

interface AssignDatatagsViewProps {
  datagroupId: number
  viewMetric: ViewMetric
}

const AssignDatatagsView: React.FC<AssignDatatagsViewProps> = ({
  datagroupId,
  viewMetric,
}) => {
  const { data: datatags = [] } =
    trpc.api.datatags.listDatagroupMembers.useQuery({
      id: datagroupId,
    })

  const { mutate: save, isPending } =
    trpc.api.datatags.createOrUpdateViewMetric.useMutation()

  const [selectedDatatags, setSelectedDatatags] = useState<Datatag[]>(
    viewMetric.datatags || [],
  )
  const selectedRows = useMemo(
    () =>
      mapSelectedRowIdsToTableSelectionState(selectedDatatags.map((d) => d.id)),
    [selectedDatatags],
  )

  const [isDirty, setIsDirty] = useState(false)

  const handleCancel = () => {
    setSelectedDatatags(viewMetric.datatags || [])
    setIsDirty(false)
  }

  const handleSave = () => {
    save({
      id: viewMetric.id,
      datatags: selectedDatatags,
    })
    setIsDirty(false)
  }

  const handleSelect: OnChangeFn<RowSelectionState> = (updaterOrValue) => {
    setIsDirty(true)

    setSelectedDatatags((prevDatatags) => {
      const newSelection =
        typeof updaterOrValue === 'function'
          ? updaterOrValue(
              mapSelectedRowIdsToTableSelectionState(
                prevDatatags.map((d) => d.id),
              ),
            )
          : updaterOrValue

      const newSelectedIds = Object.keys(newSelection).filter(
        (key) => newSelection[key] === true,
      )

      const newSelectedDatatags = datatags.filter((d) =>
        newSelectedIds.includes(String(d.id)),
      )
      return newSelectedDatatags
    })
  }

  return (
    <ViewContentLayout>
      <ViewContainerLayout>
        <TableWidget<Datatag>
          title="Assigned Members"
          data={selectedDatatags}
          columns={assignedDatatagsColumns}
          getRowId={(row) => row.id}
          withIndex
        />
        <TableWidget<Datatag>
          title="Available Members"
          data={datatags}
          columns={datatagsColumns}
          onSelect={handleSelect}
          selectedRows={selectedRows}
          getRowId={(row) => row.id}
          withIndex
          withCheckbox
        />
      </ViewContainerLayout>
      {isDirty && (
        <ViewFooterLayout open={true}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave} type="primary" loading={isPending}>
            Save
          </Button>
        </ViewFooterLayout>
      )}
    </ViewContentLayout>
  )
}

export default AssignDatatagsView
