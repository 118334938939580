import { trpc } from '@services/trpc'
import type { DataModel } from '@modules/datamodel'
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { RowSelectionState } from '@tanstack/react-table'
import { DatamodelRowItem } from '../compoundModel/compoundModel.types'
import type { DatamodelConfigurationDefinition } from '@server/interfaces/datamodel'
import { AccountDriver } from '@server/interfaces/accountdrivers'

type DatamodelRow = DatamodelConfigurationDefinition | { id: number }
type DatamodelWithRows = Omit<DatamodelConfigurationDefinition, 'rows'> & {
  rows: DatamodelConfigurationDefinition['rows'] | DatamodelRow[]
}
const getDatamodelsAccountdriverRowsItems = (
  datamodels: DatamodelWithRows[],
  accountDrivers: AccountDriver[],
) => {
  return datamodels.flatMap((datamodel) => {
    const dataModelAccountdriverRows = datamodel.rows || []

    return dataModelAccountdriverRows.map((accountDriver) => {
      const relatedAccountDriver = accountDrivers.find(
        (driver) => driver.id === accountDriver.id,
      )
      const inputSource: string =
        'class' in accountDriver
          ? (accountDriver.class as string)
          : relatedAccountDriver?.class || ''

      return {
        id: accountDriver.id as number,
        name: relatedAccountDriver?.name || '',
        disabled: relatedAccountDriver?.name !== datamodel.name,
        properties: {
          inputSource: {
            value: inputSource,
            disabled: !['input', 'lookup'].includes(inputSource),
          },
          allocate: {
            value: false,
            disabled: true,
          },
        },
        itemGroup: datamodel.name,
      }
    })
  })
}

const useDatamodelRows = (selectedModel?: DataModel | null) => {
  const { data: dataModels = [] as DataModel[] } =
    trpc.api.datamodel.list.useQuery()
  const { data: accountDrivers = [] } = trpc.api.accountdrivers.list.useQuery()

  const [isDirty, setIsDirty] = useState(false)

  const initialDataModelTableRows = useMemo(() => {
    if (!selectedModel) return {}
    return (selectedModel.config.models || []).reduce((acc, dataModel) => {
      acc[dataModel.id!] = true
      return acc
    }, {} as RowSelectionState)
  }, [selectedModel])

  // TODO: refactor to use only selectedDatamodelAccountdriverItems
  const [selectedDatamodelTableRows, setSelectedDatamodelTableRows] =
    useState<RowSelectionState>(initialDataModelTableRows)

  const initialDatamodelAccountdriverItems = useMemo(() => {
    return getDatamodelsAccountdriverRowsItems(
      selectedModel?.config.models || [],
      accountDrivers,
    )
  }, [accountDrivers, selectedModel?.config.models])

  const [
    selectedDatamodelAccountdriverItems,
    setSelectedDatamodelAccountdriverItems,
  ] = useState<DatamodelRowItem[]>(initialDatamodelAccountdriverItems)

  useEffect(() => {
    setSelectedDatamodelTableRows(initialDataModelTableRows)
  }, [initialDataModelTableRows])

  useEffect(() => {
    setSelectedDatamodelAccountdriverItems(initialDatamodelAccountdriverItems)
  }, [initialDatamodelAccountdriverItems])

  useEffect(() => {
    setSelectedDatamodelAccountdriverItems((prevSelectedAccountdrivers) => {
      const selectedDatamodels = dataModels
        .filter((model) => selectedDatamodelTableRows[model.id] === true)
        .map((model) => ({
          ...model,
          rows: model.config.rows as Array<DatamodelRow>,
        }))

      const selectedAccountdrivers = getDatamodelsAccountdriverRowsItems(
        selectedDatamodels,
        accountDrivers,
      )

      const newSelectedAccountdrivers = selectedAccountdrivers.map((driver) => {
        const existingDriver = prevSelectedAccountdrivers.find(
          (item) => item.id === driver.id,
        )
        if (existingDriver) {
          return existingDriver
        }
        return driver
      })

      return newSelectedAccountdrivers
    })
  }, [accountDrivers, dataModels, selectedDatamodelTableRows])

  const updateAccountDriverInputSource = useCallback(
    (value: string | number | boolean, id: string | number) => {
      setIsDirty(true)
      setSelectedDatamodelAccountdriverItems((prevDrivers) => {
        const driver = prevDrivers.find((driver) => driver.id === id)
        if (!driver) {
          return prevDrivers
        }
        return prevDrivers.map((d) => {
          if (d.id === driver.id) {
            return {
              ...driver,
              properties: {
                ...driver.properties,
                inputSource: {
                  ...driver.properties.inputSource,
                  value: String(value),
                },
              },
            }
          }
          return d
        })
      })
    },
    [],
  )

  const removeItem = useCallback(
    (item: DatamodelRowItem) => {
      setIsDirty(true)
      const datamodelToRemove = dataModels.find(
        (model) => model.name === item.itemGroup,
      )
      if (datamodelToRemove) {
        setSelectedDatamodelTableRows((prev) => {
          const newState = { ...prev }
          delete newState[datamodelToRemove.id]
          return newState
        })
      }
    },
    [dataModels],
  )

  const cancel = useCallback(() => {
    setIsDirty(false)
    setSelectedDatamodelTableRows(initialDataModelTableRows)
    setSelectedDatamodelAccountdriverItems(initialDatamodelAccountdriverItems)
  }, [initialDataModelTableRows, initialDatamodelAccountdriverItems])

  const selectRows = (updater: SetStateAction<RowSelectionState>) => {
    setIsDirty(true)
    setSelectedDatamodelTableRows(updater)
  }

  return {
    selectedDatamodelAccountdriverItems,
    selectedDatamodelTableRows,
    selectRows,

    updateAccountDriverInputSource,
    removeItem,

    cancel,
    isDirty,
    setIsDirty,
  }
}

export default useDatamodelRows
