import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    sectionName: {
      lineHeight: '28px',
      fontSize: 14,
      fontWeight: 600,
      color: token.gray400,
      textTransform: 'uppercase',
      borderRight: `1px solid ${token.gray400}`,
      paddingRight: 16,
      marginRight: 16,
    },
    tabList: {
      margin: 0,
      padding: 0,
      textIndent: 0,
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      li: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    tab: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 30,
      padding: '0 12px',
      borderRadius: 8,
      color: token.gray800,
      fontWeight: 500,

      '&.active': {
        backgroundColor: token.blue800,
        color: token.colorWhite,
        cursor: 'default',
        '&:hover': {
          color: token.colorWhite,
        },
      },
      '&:hover': {
        color: token.blue800,
      },
    },
  }
})
