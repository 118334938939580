import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    toolbar: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: token.colorBorderSecondary,
      borderRadius: token.borderRadius,
      border: `1px solid ${token.colorBorder}`,
      padding: '0 8px',
      height: 30,
    },
    button: {
      '&&': {
        width: 24,
        height: 24,
      },
    },
    divider: {
      margin: '0 2px',
      height: 16,
      width: 1,
      backgroundColor: token.colorBorder,
    },
  }
})
