import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: token.paddingXS,
    },
  }
})
