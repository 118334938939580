import { SidebarList, SidebarCollapsibleItem } from '@components'
import { trpc } from '@services/trpc'
import ActionIconButton from '@components/IconButton'
import { LuTrash2 } from 'react-icons/lu'
import { Flex, message } from 'antd'
import { File01 } from '@untitled-ui/icons-react'
import { SidebarActionsLayout, SidebarContent } from '@components'
import { useDataModelPageContext, type DataModel } from '@modules/datamodel'
import LedgerSidebarCompaniesToolbar from './LedgerSidebarCompaniesToolbar'

const LedgerSidebarCompaniesTab: React.FC = () => {
  const { data: models = [] } = trpc.api.datamodel.listByType.useQuery({
    type: 'ledger',
  })
  const { selectedDataModel, changeSelectedDatamodelId } =
    useDataModelPageContext()

  const handleOnDeleteCompany = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    message.info('That feature is not implemented yet')
  }

  const handleCompanyClick = (company: DataModel) => {
    changeSelectedDatamodelId(company.id)
  }

  return (
    <>
      <LedgerSidebarCompaniesToolbar />
      <SidebarContent>
        <SidebarCollapsibleItem
          label={
            <Flex gap={4} align="center">
              <File01 width={16} />
              {`Companies (${models.length})`}
            </Flex>
          }
          actions={
            <SidebarActionsLayout>
              <ActionIconButton onClick={handleOnDeleteCompany}>
                <LuTrash2 />
              </ActionIconButton>
            </SidebarActionsLayout>
          }
        >
          <SidebarList<DataModel>
            items={models}
            onItemClick={handleCompanyClick}
            selectedItemId={selectedDataModel?.id || undefined}
          />
        </SidebarCollapsibleItem>
      </SidebarContent>
    </>
  )
}

export default LedgerSidebarCompaniesTab
