import type { AppRouter } from '@server'
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server'

type RouterInput = inferRouterInputs<AppRouter>
type RouterOutput = inferRouterOutputs<AppRouter>

export type DataModelInput =
  RouterInput['api']['datamodel']['createOrUpdateById']
export type DataModelOutput = RouterOutput['api']['datamodel']['getById']
export type DataModelsListOutput = RouterOutput['api']['datamodel']['list']

export type DataModel = NonNullable<DataModelOutput>

export enum DatamodelGroup {
  LOOKUP = 'lookup',
  COMPUTED = 'computed',
  INCOME = 'income',
  OPEX = 'opex',
  LEDGER = 'ledger',
}
