import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    navbarWrapper: {
      display: 'flex',
      gap: token.marginSM,
    },
    navbarContainer: {
      display: 'flex',
      alignItems: 'center',
      border: `1px ${token.colorBorder} solid`,
      backgroundColor: token.colorWhite,
      borderRadius: token.borderRadiusLG,
      flex: 1,
      overflow: 'hidden',
    },
    navbar: {
      height: 48,
      // margin: 0,
      // padding: `0 ${token.paddingSM}px`,
      textIndent: 0,
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: 8,

      li: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      },
    },
    datagroups: {
      paddingLeft: 0,
    },
    tab: {
      height: '100%',
      padding: `0 ${token.paddingSM}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: token.gray700,
      fontWeight: 600,
      textWrap: 'nowrap',

      ':hover:not(.disabled, .active)': {
        backgroundColor: token.blue25,
        color: token.blue700,
      },

      '&.active': {
        color: token.blue25,
        backgroundColor: token.blue700,
        // borderTop: `4px ${token.blue500} solid`,
        // boxSizing: 'border-box',
        // paddingBottom: 4,
      },

      '&.disabled': {
        color: token.gray300,
        cursor: 'not-allowed',
      },
    },
    separator: {
      width: 6,
      height: 32,
      borderRight: `1px ${token.colorBorder} solid`,
      borderLeft: `1px ${token.colorBorder} solid`,
      boxSizing: 'border-box',
    },
  }
})
