import { Sidebar, SidebarPeriodList } from '@components'
import { SidebarContent } from '@components'
import LedgerSidebarToolbar from './LedgerSidebarNormalToolbar'
import { useLedgerContext } from '../../LedgerContext'

const LedgerSidebarNormal = () => {
  const { isTransactions } = useLedgerContext()
  return (
    <Sidebar title="Ledger">
      <LedgerSidebarToolbar />
      <SidebarContent>
        {isTransactions && <SidebarPeriodList />}
        {/* {isSummary && <>months list</>} */}
      </SidebarContent>
    </Sidebar>
  )
}

export default LedgerSidebarNormal
