import useStyles from './AccountDriverSearch.styles'
import { useRef } from 'react'
import { SidebarFilterSelect } from '..'
import { trpc } from '@services/trpc'
import { BaseOptionType } from 'antd/es/select'
import { capitalize } from '@utils/strings'
import { FiSearch } from 'react-icons/fi'
import { LuSettings2 } from 'react-icons/lu'
import { Button } from 'antd'
import { ACCOUNT_CLASSES } from '@constants/accountClasses'

type AccountDriverSearchProps = {
  type: string
  onDriverChange: (type: string) => void
}

const AccountDriverSearch: React.FC<AccountDriverSearchProps> = ({
  type,
  onDriverChange,
  ...props
}) => {
  const { styles } = useStyles()
  const driverInputRef = useRef(null)

  const { data: accountdrivers = [] } = trpc.api.accountdrivers.list.useQuery()
  const { data: accounts = [] } = trpc.api.accounts.listWithMetrics.useQuery()

  const { data: metrics = [] } = trpc.api.metrics.list.useQuery()
  const { data: lookups = [] } = trpc.api.datamodel.listByType.useQuery({
    type: 'lookup',
  })
  const { data: computeds = [] } = trpc.api.datamodel.listByType.useQuery({
    type: 'computed',
  })

  const inputOptions = accountdrivers
    .filter((accountdriver) => {
      const isInput = accountdriver.class === 'input'
      const relatedAccount = accounts.find(
        (account) => account.id === accountdriver.account_id,
      )

      const isOperationalAccount =
        relatedAccount?.metric?.class === ACCOUNT_CLASSES.operational
      return isInput && isOperationalAccount
    })
    .map((accountDriver) => ({
      label: accountDriver.name,
      value: `input_${accountDriver.id}`,
      type: 'input',
      typeLabel: 'Account',
    }))

  const metricOptions = metrics.map((metric) => ({
    label: metric.name,
    value: `metric_${metric.id}`,
    type: 'metric',
    typeLabel: 'Metric',
  }))
  const lookupOptions = lookups.map((lookup) => ({
    label: lookup.name,
    value: `lookup_${lookup.id}`,
    type: 'lookup',
    typeLabel: 'Lookup',
  }))
  const computedOptions = computeds.map((computed) => ({
    label: computed.name,
    value: `computed_${computed.id}`,
    type: 'computed',
    typeLabel: 'Custom',
  }))

  let options = []
  switch (type) {
    case 'input':
      options = inputOptions
      break
    case 'metric':
      options = metricOptions
      break
    case 'lookup':
      options = lookupOptions
      break
    case 'computed':
      options = computedOptions
      break
    default:
      options = [
        ...inputOptions,
        ...metricOptions,
        ...lookupOptions,
        ...computedOptions,
      ]
      break
  }

  const renderOption = (option: BaseOptionType) => {
    return (
      <span>
        {option.label}
        <span className={styles.optionType}>
          &nbsp;/ {capitalize(option.data.typeLabel)}
        </span>
      </span>
    )
  }

  return (
    <>
      <div className={styles.driverSelect}>
        <SidebarFilterSelect
          label="Driver name"
          placeholder="Select account driver"
          options={options}
          showSearch
          filterOption
          optionFilterProp="label"
          optionRender={renderOption}
          suffixIcon={null}
          selectRef={driverInputRef}
          onSelect={(_value, option) => {
            onDriverChange(option.type)
            const current = driverInputRef.current as HTMLElement | null
            current?.blur()
          }}
          {...props}
        />
        <div className={styles.suffix}>
          <span className={styles.suffixIcon}>
            <FiSearch size={16} />
          </span>
          <span className={styles.suffixDivider} />
          <Button
            className={styles.suffixButton}
            type="text"
            icon={<LuSettings2 size={16} />}
          />
        </div>
      </div>
    </>
  )
}

export default AccountDriverSearch
