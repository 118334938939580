import { createStyles } from 'antd-style'

const width = 28

type Props = {
  name: string | undefined
}

export default createStyles(({ token }, { name }: Props) => ({
  drawerCollapserContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: width,
    background: token.colorWhite,
    transition: 'width 0.3s ease-out',
    overflow: 'hidden',
  },
  drawerCollapserOptions: {
    rotate: '-90deg',
    transform: 'translateX(calc(-50% + 16px))',
  },
  drawerCollapserOption: {
    display: 'flex',
    alignItems: 'center',

    background: token.colorPrimary,
    border: 'none',
    height: width,
    width: name ? 180 : 120,
    justifyContent: name ? 'flex-start' : 'center',
    boxSizing: 'border-box',
    position: 'relative',
    borderRadius: 0,
    borderTopLeftRadius: 10,
    padding: '0 8px 0 16px',
    color: name ? token.blue300 : token.colorWhite,
    fontWeight: 500,
    fontSize: token.fontSizeSM,
    transition: 'width 0.3s ease-out',
    cursor: 'pointer',

    '> *': {
      display: 'flex',
      maxWidth: '100%',
    },

    '&:after': {
      content: '" "',
      width: 18,
      height: 80,
      background: token.colorPrimary,
      position: 'absolute',
      left: -12,
      top: -4,
      transform: 'rotate(20deg)',
      borderTopLeftRadius: 37,
    },
    ':hover': {
      background: token.blue600,
      '&:after': {
        background: token.blue600,
      },
    },

    '&:disabled': {
      color: token.gray400,
      background: token.gray300,
      cursor: 'not-allowed',
      width: 120,
      justifyContent: 'center',

      '&:after': {
        background: token.gray300,
      },
    },
  },

  drawerCollapserOptionName: {
    color: token.colorWhite,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  collapseButton: {
    '&&': {
      width: 24,
      height: 24,
    },
  },
}))
