import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    buttons: {
      display: 'flex',
      gap: token.paddingContentHorizontalSM,
      justifyContent: 'center',
      alignSelf: 'center',
      margin: `0 ${token.marginMD}px`,
    },
    addButton: {
      borderRadius: token.borderRadius,
      border: 'none',
      color: token.colorPrimary,
      backgroundColor: token.blue50,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      width: 32,
      height: 32,
      alignSelf: 'center',
      justifySelf: 'center',
    },
  }
})

export default useStyles
