import {
  CreateMenuButton,
  SidebarFilterRowLayout,
  SidebarFilterSelect,
  SidebarToolbarLayout,
} from '@components'

const LedgerSidebarCompaniesToolbar = () => {
  return (
    <>
      <SidebarToolbarLayout>
        <SidebarFilterRowLayout isDisabled>
          <SidebarFilterSelect
            label="Data Model Group"
            options={[{ label: 'Ledger', value: 'ledger' }]}
            value={'ledger'}
            disabled
          />
          <CreateMenuButton disabled />
        </SidebarFilterRowLayout>
      </SidebarToolbarLayout>
    </>
  )
}

export default LedgerSidebarCompaniesToolbar
