import DummyPage from '@components/DummyPage'
import { Navigate } from 'react-router-dom'

const adjustmentsRoutes = [
  {
    index: true,
    element: <Navigate to="/financial/adjustments/reclassification" replace />,
  },
  {
    path: 'reclassification',
    handle: { title: 'Reclassification' },
    Component: DummyPage,
  },
  {
    path: 'adjustment-journal',
    handle: { title: 'Adjustment Journal' },
    Component: DummyPage,
  },
  {
    path: 'top-down-allocation',
    handle: { title: 'Top Down Allocation' },
    Component: DummyPage,
  },
  {
    path: 'retained-earning',
    handle: { title: 'Retained Earning' },
    Component: DummyPage,
  },
]

export default adjustmentsRoutes
