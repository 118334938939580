import { Button } from 'antd'
import useStyles from './PropertiesDrawerCollapser.styles'
import { ChevronRightDouble } from '@untitled-ui/icons-react'

interface PropertiesDrawerCollapserProps {
  onCollapse: () => void
  open?: boolean
  disabled?: boolean
  name?: string
}

const PropertiesDrawerCollapser: React.FC<PropertiesDrawerCollapserProps> = ({
  onCollapse,
  open = true,
  disabled = false,
  name,
}) => {
  const { styles } = useStyles({ name })
  return (
    <div className={styles.drawerCollapserContainer}>
      <Button
        className={styles.collapseButton}
        type="text"
        icon={
          <ChevronRightDouble
            width={16}
            style={{ rotate: open ? '0deg' : '180deg' }}
          />
        }
        onClick={onCollapse}
      />
      <div className={styles.drawerCollapserOptions}>
        <button
          className={styles.drawerCollapserOption}
          onClick={onCollapse}
          disabled={disabled}
          aria-label="Properties"
        >
          <span>
            PROPERTIES
            {name && (
              <>
                :&nbsp;&nbsp;
                <span className={styles.drawerCollapserOptionName}>{name}</span>
              </>
            )}
          </span>
        </button>
      </div>
    </div>
  )
}

export default PropertiesDrawerCollapser
