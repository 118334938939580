import { useEffect, useState } from 'react'
import { trpc } from '@services/trpc'
import { TreeData } from '@modules/view/view.types'
import { skipToken } from '@tanstack/react-query'
import { TreeItem, TreeItemType } from '@server/interfaces/view'

const emptyTree = {
  root: {
    index: 'root',
    canMove: false,
    isFolder: true,
    children: [],
    data: {
      name: 'root',
      type: 'root' as TreeItemType,
    },
    canRename: false,
  },
}

const transformData = (input: TreeItem[] = []): TreeData => {
  const result: TreeData = {}
  const rootChildren: string[] = []

  function processItem(item: TreeItem, parentId: string = 'root') {
    if (item.type === 'folder') {
      const folderId = `folder_${item.name}_${Date.now()}`
      result[folderId] = {
        index: folderId,
        canMove: true,
        isFolder: true,
        canRename: false,
        data: { name: item.name || 'Untitled', type: 'folder' },
        children: [],
      }

      if (parentId !== 'root') {
        result[parentId].children.push(folderId)
      } else {
        rootChildren.push(folderId)
      }

      item.children?.forEach((child) => processItem(child, folderId))
    } else if (item.type === 'item' && item.id) {
      result[item.id] = {
        data: {
          name: item.name || 'Untitled',
          type: 'item',
          id: item.id,
        },
        index: item.id,
        canMove: true,
        isFolder: false,
        canRename: false,
        children: [],
      }

      result[parentId].children.push(item.id)
    }
  }

  // Initialize root
  result['root'] = {
    index: 'root',
    canMove: false,
    isFolder: true,
    children: rootChildren,
    data: { name: 'root', type: 'root' },
    canRename: false,
  }

  input.forEach((item) => processItem(item))

  return result
}

export function useView(viewId: number | null) {
  const [treeData, setTreeData] = useState<TreeData>(emptyTree)
  const treeSize = Object.keys(treeData).length
  const isDataReady = viewId ? treeSize > 1 : treeSize > 0

  const { data, isFetching: isViewFetching } = trpc.api.views.getById.useQuery(
    viewId ? { id: viewId } : skipToken,
  )

  // const {
  //   data: metrics = [],
  //   isSuccess: metricsSuccess,
  //   isFetching: isMetricsFetching,
  // } = trpc.api.metrics.list.useQuery()

  const resetTree = () => {
    setTreeData(emptyTree)
  }

  useEffect(() => {
    if (data) {
      const transformedData = transformData(data.tree)
      setTreeData(transformedData)
    }
  }, [data])

  useEffect(() => {
    if (viewId === null) {
      setTreeData(emptyTree)
    }
  }, [viewId])

  return {
    treeData,
    onChange: setTreeData,
    resetTree,
    isFetching: isViewFetching || !isDataReady,
  }
}
