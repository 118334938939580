import {
  AssumptionDetails,
  AssumptionsTable,
  AssumptionsTableWidgetToolbar,
  TableFiltersWithSearchParams,
  TotalsRow,
  ViewContainerLayout,
  ViewFooterLayout,
} from '@components'
import { trpc } from '@services/trpc'
import { skipToken } from '@tanstack/react-query'
import { Row } from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Modal, Space, message } from 'antd'
import useTransactionsStore from '@hooks/useTransactionsStore'
import { DataModel } from '@modules/datamodel'
import { AssumptionRow, TableLayoutDef } from '@components/AssumptionsTable'
import { AssumptionTableItem } from '@server/interfaces/assumptions'

interface AssumptionsViewProps {
  datamodel?: DataModel | null
  data: AssumptionTableItem[]
  layout: TableLayoutDef[]
  isLoading: boolean
  isUpdating?: boolean
  disabled?: boolean
  refetch: () => void
}

const AssumptionsView = <T extends AssumptionRow>({
  data: initialData,
  layout,
  isLoading,
  isUpdating,
  refetch,
  datamodel,
  disabled,
}: AssumptionsViewProps) => {
  // TODO: implement updates to data on cell change
  const [data, setData] = useState<T[]>([])
  const totals = initialData.find((f) => f.type == 'totals')?.datapoint

  const [selectedRow, setSelectedRow] = useState<Row<T> | null>(null)
  const selectedRowData = selectedRow?.original

  // prefetch
  trpc.api.assumptions.getById.useQuery(
    selectedRowData ? { id: selectedRowData.assumption_id } : skipToken,
  )
  trpc.api.assumptions.listAssumptionParams.useQuery(
    selectedRowData
      ? { assumption_id: selectedRowData.assumption_id }
      : skipToken,
  )

  const { addTransaction, transactionsToSubmit, reset, isDirty, blocker } =
    useTransactionsStore()

  const handleSuccess = () => {
    message.success('Transactions saved successfully')
    reset()
    refetch()
  }
  const { mutate: save } = trpc.api.transactions.updateMany.useMutation({
    onSuccess: handleSuccess,
    onError: (error) => {
      message.error(error.message)
    },
  })

  const handleRowClick = useCallback(
    (row: Row<T>) => {
      if (selectedRow?.id !== row.id) {
        setSelectedRow(row)
      } else {
        setSelectedRow(null)
      }
    },
    [selectedRow],
  )

  const selectedRows = useMemo(() => {
    return selectedRow ? { [selectedRow.id]: true } : undefined
  }, [selectedRow])

  const columns = useMemo(
    () => initialData.filter((f) => f.type == 'cols').map((c) => c.datapoint),
    [initialData],
  )

  const getRowId = useCallback((item: T) => item.rowid, [])

  const handleSave = () => {
    save(transactionsToSubmit)
  }

  useEffect(() => {
    setData(
      initialData
        .filter((f: AssumptionTableItem) => f.type == 'data')
        .map((c: AssumptionTableItem) => c.datapoint),
    )
  }, [initialData])

  return (
    <>
      <TableFiltersWithSearchParams datamodelId={datamodel?.id} />

      <ViewContainerLayout>
        <AssumptionsTable<T>
          isLoading={isLoading}
          isUpdating={isUpdating}
          data={data}
          layout={layout}
          getRowId={getRowId}
          refetch={refetch}
          datamodel={datamodel}
          onChange={addTransaction}
          onRowClick={handleRowClick}
          selectedRows={selectedRows}
          disabled={disabled}
          toolbar={
            <AssumptionsTableWidgetToolbar
              columnsCount={layout.length}
              columnsTotal={layout.length}
            />
          }
          totalsRow={
            totals ? <TotalsRow totals={totals} periods={columns} /> : undefined
          }
        />
      </ViewContainerLayout>

      <AssumptionDetails
        assumptionId={selectedRowData?.assumption_id}
        open={!!selectedRowData}
      />

      <ViewFooterLayout open={isDirty}>
        <Space>
          <Button onClick={reset} aria-label="Cancel">
            Cancel
          </Button>
          <Button onClick={handleSave} type="primary" aria-label="Save">
            Save
          </Button>
        </Space>
      </ViewFooterLayout>

      <Modal
        open={blocker.state === 'blocked'}
        okText="Yes"
        cancelText="No"
        onOk={blocker.proceed}
        onCancel={blocker.reset}
        width={350}
        centered
      >
        You have unsaved changes. Are you sure you want to leave this page?
      </Modal>
    </>
  )
}

export default AssumptionsView
