import useStyles from './FormSectionLayout.styles'

interface LayoutProps {
  children: React.ReactNode
  row?: boolean
  className?: string
}

const FormSectionLayout: React.FC<LayoutProps> = ({
  children,
  className,
  row,
}) => {
  const { styles, cx } = useStyles({ row })

  return <div className={cx(styles.container, className)}>{children}</div>
}

export default FormSectionLayout
