import { useEffect, useState } from 'react'
import {
  DatatagsTableWidget,
  SelectedListWidget,
  TableWidget,
  ViewContainerLayout,
  ViewContentLayout,
  ViewFooterLayout,
  ViewTabsControl,
  ViewToolbar,
  ViewWithPropertiesLayout,
} from '@components'
import { trpc } from '@services/trpc'
import { Button, Space, message } from 'antd'
import LedgersTable from '../../LedgerTable'
import { useLedgerRows } from '@modules/ledger/hooks'
import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import {
  ACCOUNT_ITEMS_SCHEMA,
  LedgerAccountRowItem,
  getSystemColumns,
  LedgerDatatagColumnItem,
} from '@modules/ledger'
import { useDatatagColumnsContext } from '@modules/datamodel/DatatagColumnsContext'
import { useDataModelPageContext } from '@modules/datamodel'
import { AccountDriver } from '@server/interfaces/accountdrivers'
import {
  LedgerColumnHeaderItem,
  LedgerColumnListItem,
} from '../../LedgerColumnListItem'
import {
  DatagroupValueColumnDefinition,
  DatamodelRowDefinition,
} from '@server/interfaces/datamodel'

const viewTabs = [
  {
    key: 'columns',
    label: 'Columns / Data Tags',
  },
  {
    key: 'rows',
    label: 'Rows / Data Models',
  },
]

// TODO: TBD full list of filter options
type FilterOptionConfig = {
  type: string
  label: string
  options: { value: string; label: string }[]
}

const createFilterOptions = ({ type, label, options }: FilterOptionConfig) => [
  {
    type: type,
    label: label,
    options: options,
  },
]

const accountViewOptions = [{ value: 'accounts', label: 'Accounts' }]
const accountFilterOptions = createFilterOptions({
  type: 'data_model',
  label: 'Data model type',
  options: accountViewOptions,
})

const columnHelper = createColumnHelper<AccountDriver>()
// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const tableColumns: ColumnDef<AccountDriver, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: () => 'Name | ID',
  }),
]

const LedgerConfigView: React.FC = () => {
  const { selectedDataModel } = useDataModelPageContext()
  const [currentTab, setCurrentTab] = useState<'columns' | 'rows'>('columns')
  const {
    initialAccountTableRows,
    selectedAccounts,
    selectedAccountTableRows,
    setSelectedAccountTableRows,
    onItemRemove,
  } = useLedgerRows(selectedDataModel)
  const {
    selectedDatatagColumns,
    isDatatagColumnsDirty,
    resetDatatagColumns,
    setIsDatatagColumnsDirty,
  } = useDatatagColumnsContext<LedgerDatatagColumnItem>()

  const selectedDatatagColumnsWithSystem = [
    ...selectedDatatagColumns,
    ...getSystemColumns(selectedDataModel?.name || ''),
  ]

  const [isDirty, setIsDirty] = useState(false)
  const { data: accountDrivers = [] } = trpc.api.accountdrivers.list.useQuery()
  const inputAccountDrivers = accountDrivers.filter(
    (ad) => ad.class === 'input',
  )

  const handleTabChange = (tab: string) => {
    setCurrentTab(tab as 'columns' | 'rows')
  }

  const handleCancel = () => {
    resetDatatagColumns()
    setSelectedAccountTableRows(initialAccountTableRows)
    setIsDirty(false)
  }

  const { refetch: refetchDataModels } = trpc.api.datamodel.listByType.useQuery(
    { type: 'ledger' },
  )
  const { mutate: save } = trpc.api.datamodel.createOrUpdateById.useMutation({
    onSuccess: () => {
      message.success('Company details has been updated!')
      refetchDataModels()
      setIsDirty(false)
      setIsDatatagColumnsDirty(false)
    },
    onError: (error) => {
      message.error(error.message)
    },
  })

  const handleSave = () => {
    const columns = selectedDatatagColumns.map((column) => ({
      type: 'datagroup',
      datagroup_id: column.id,
      values: column.members,
    })) as DatagroupValueColumnDefinition[]
    const rows = selectedAccounts.map((account) => ({
      id: account.id,
    })) as DatamodelRowDefinition[]
    const updatedDataModel = {
      id: selectedDataModel!.id,
      config: {
        columns,
        rows,
      },
    }
    save(updatedDataModel)
  }

  const hasDataChanged = (initialData: any, currentData: any) =>
    JSON.stringify(initialData) !== JSON.stringify(currentData)

  useEffect(() => {
    const accountsChanged = hasDataChanged(
      initialAccountTableRows,
      selectedAccountTableRows,
    )

    setIsDirty(accountsChanged)
  }, [selectedAccountTableRows, initialAccountTableRows])

  return (
    <ViewWithPropertiesLayout>
      <ViewContentLayout>
        <ViewToolbar />
        {selectedDataModel ? (
          <>
            <ViewTabsControl
              tabs={viewTabs}
              currentTab={currentTab}
              onTabChange={handleTabChange}
            />
            <ViewContainerLayout>
              {currentTab === 'columns' ? (
                <>
                  <SelectedListWidget<LedgerDatatagColumnItem>
                    title="Model Composition"
                    items={selectedDatatagColumnsWithSystem}
                    renderItem={(item) => <LedgerColumnListItem item={item} />}
                    renderHeader={() => <LedgerColumnHeaderItem />}
                  />
                  <DatatagsTableWidget />
                </>
              ) : (
                <>
                  <SelectedListWidget<LedgerAccountRowItem>
                    title="Model Composition"
                    items={selectedAccounts}
                    schema={ACCOUNT_ITEMS_SCHEMA}
                    onRemove={(item) => onItemRemove(item)}
                  />
                  <TableWidget<AccountDriver>
                    title="Accounts"
                    data={inputAccountDrivers}
                    columns={tableColumns}
                    filterOptions={accountFilterOptions}
                    onSelect={setSelectedAccountTableRows}
                    selectedRows={selectedAccountTableRows}
                    getRowId={(row) => row.id}
                    withCheckbox
                  />
                </>
              )}
            </ViewContainerLayout>
          </>
        ) : (
          <ViewContainerLayout>
            <LedgersTable />
          </ViewContainerLayout>
        )}
        <ViewFooterLayout open={isDirty || isDatatagColumnsDirty}>
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleSave} type="primary">
              Save
            </Button>
          </Space>
        </ViewFooterLayout>
      </ViewContentLayout>
    </ViewWithPropertiesLayout>
  )
}

export default LedgerConfigView
