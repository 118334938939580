import { createStyles } from 'antd-style'

export default createStyles(({ token }) => ({
  searchToolbar: {
    height: 30,
    color: token.gray700,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${token.colorBorder}`,
  },
  input: {},
  addon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: token.paddingXS,
    height: 14,
    color: token.gray400,
    borderLeft: `1px solid ${token.colorBorder}`,
  },
}))
