import { FC } from 'react'
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import { Metric } from '@modules/metric'
import { ACCOUNT_TYPES_OPTIONS } from '@constants/accountTypes'
import { useConfigPageContext } from '@context/ConfigPageContext'
import { AccountCategory } from '@modules/account'

const columnHelper = createColumnHelper<Metric>()

// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<Metric, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
]

interface MetricsTableProps extends Partial<TableWidgetProps<Metric>> {}

const MetricsTable: FC<MetricsTableProps> = ({ ...rest }) => {
  const { data = [] } = trpc.api.metrics.list.useQuery()
  const { selectedCategory, setSelectedCategory } = useConfigPageContext<
    Metric,
    AccountCategory
  >()

  const metrics = data.filter(
    (metric) =>
      !selectedCategory ||
      metric[selectedCategory.propertyType] === selectedCategory.id,
  ) as Metric[]

  const changeFilter = (type: string) => {
    setSelectedCategory({
      id: type,
      name: type,
      propertyType: 'type',
    })
  }

  return (
    <TableWidget<Metric>
      {...rest}
      data={metrics}
      columns={columns}
      filterOptions={ACCOUNT_TYPES_OPTIONS}
      currentFilter={String(selectedCategory?.id || '')}
      onFilterChange={changeFilter}
      getRowId={(row) => row.id}
      withIndex
    />
  )
}

export default MetricsTable
