import { useState } from 'react'
import {
  DatatagsTableWidget,
  SelectedListWidget,
  ViewContainerLayout,
  ViewContentLayout,
  ViewFooterLayout,
  ViewToolbar,
  ViewToolbarAddButton,
  ViewWithPropertiesLayout,
} from '@components'
import { trpc } from '@services/trpc'
import { Button, Space, message } from 'antd'
import LookupTableView from '../LookupTableView'
import { useDatatagColumnsContext } from '@modules/datamodel/DatatagColumnsContext'
import { DatatagColumnItem } from '@modules/compoundModel/compoundModel.types'
import {
  LookupDatatagHeaderItem,
  LookupDatatagListItem,
} from '../../LookupDatatagListItem'
import { useDataModelPageContext } from '@modules/datamodel'
import { DatagroupValueColumnDefinition } from '@server/interfaces/datamodel'
import LookupProperties from '../../LookupProperties'
import { Lookup } from '@modules/lookup/lookup.types'
import CreateLookupModal from '../../CreateLookupModal'

const LookupConfigView: React.FC = () => {
  const { selectedDataModel } = useDataModelPageContext()

  const { selectedDatatagColumns, resetDatatagColumns } =
    useDatatagColumnsContext<DatatagColumnItem>()

  const { mutate: updateLookup } =
    trpc.api.datamodel.lookup.createOrUpdateById.useMutation({
      onSuccess: () => {
        message.success('Lookup table details has been updated!')
        setIsDirty(false)
      },
      onError: (error) => {
        message.error(error.message)
      },
    })

  const [isDirty, setIsDirty] = useState(false)

  const handleSave = () => {
    const columns = selectedDatatagColumns.map((column) => ({
      type: 'datagroup',
      datagroup_id: column.id,
      values: column.members,
    })) as DatagroupValueColumnDefinition[]
    updateLookup({
      id: selectedDataModel?.id,
      config: {
        columns,
      },
    })
  }

  return (
    <ViewWithPropertiesLayout>
      <ViewContentLayout>
        <ViewToolbar
          addButton={<ViewToolbarAddButton createModal={CreateLookupModal} />}
        />
        <ViewContainerLayout>
          {selectedDataModel ? (
            <>
              <SelectedListWidget<DatatagColumnItem>
                title="Model Composition"
                items={selectedDatatagColumns}
                renderItem={(item) => <LookupDatatagListItem item={item} />}
                renderHeader={() => <LookupDatatagHeaderItem />}
              />
              <DatatagsTableWidget />
            </>
          ) : (
            <LookupTableView />
          )}
        </ViewContainerLayout>

        <ViewFooterLayout open={isDirty}>
          <Space>
            <Button onClick={resetDatatagColumns}>Cancel</Button>
            <Button onClick={handleSave} type="primary">
              Save
            </Button>
          </Space>
        </ViewFooterLayout>
      </ViewContentLayout>
      <LookupProperties values={selectedDataModel as Lookup} />
    </ViewWithPropertiesLayout>
  )
}

export default LookupConfigView
