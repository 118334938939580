import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      fontFamily: 'Inter',

      h1: {
        color: token.gray700,
        marginBottom: 24,
      },
      p: {
        maxWidth: 800,
        color: token.gray600,
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '28px',
        margin: 0,
        textAlign: 'center',
      },
    },
    header: {
      backgroundColor: token.colorPrimary,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
    },
    logo: {
      width: 415,
    },
    main: {
      flex: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 50,
      h2: {
        color: token.gray500,
        fontSize: 24,
        fontWeight: 500,
        marginBottom: 24,
      },
    },
    signInSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: 50,
      p: {
        fontSize: 18,
      },
    },
    button: {
      '&&': {
        minWidth: 144,
      },
    },
    link: {
      color: token.colorPrimary,
      textDecoration: 'underline',
    },
    divider: {
      width: 330,
      height: 1,
      backgroundColor: token.gray300,
      margin: '32px 0',
    },
  }
})
