import { FC } from 'react'
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import { Version } from '@modules/version'
import { Switch } from 'antd'
import { CATEGORY_OPTIONS } from '@modules/version/version.constants'
import dayjs from 'dayjs'
import { Category, useConfigPageContext } from '@context/ConfigPageContext'

const columnHelper = createColumnHelper<Version>()

interface VersionsTableProps extends Partial<TableWidgetProps<Version>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSwitch: (values: Version) => void
}

const VersionsTable: FC<VersionsTableProps> = ({ handleSwitch, ...rest }) => {
  // https://github.com/TanStack/table/issues/4382
  // eslint-disable-next-line
  const columns: ColumnDef<Version, any>[] = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name | ID',
    }),
    columnHelper.accessor((row) => row.description, {
      id: 'description',
      header: 'Description',
    }),
    columnHelper.accessor((row) => row.category, {
      id: 'category',
      header: 'Version category',
    }),
    columnHelper.accessor(
      (row) =>
        row.start_date ? dayjs(row.start_date).format('MMM D, YYYY') : null,
      {
        id: 'start_date',
        header: 'Start of version',
      },
    ),
    columnHelper.accessor(
      (row) =>
        row.end_date ? dayjs(row.end_date).format('MMM D, YYYY') : null,
      {
        id: 'end_date',
        header: 'End of version',
      },
    ),
    columnHelper.accessor((row) => row.inactive, {
      id: 'inactive',
      header: 'Inactive',
      cell: ({ row }) => (
        <Switch
          disabled={row.original.name === 'Actuals'}
          checked={row.original.inactive || false}
          onChange={(checked, e) => {
            e.stopPropagation()
            handleSwitch({
              ...row.original,
              inactive: checked,
            })
          }}
        />
      ),
    }),
    columnHelper.accessor((row) => row.locked, {
      id: 'locked',
      header: 'Locked',
      cell: ({ row }) => (
        <Switch
          disabled={row.original.name === 'Actuals'}
          checked={row.original.locked || false}
          onChange={(checked, e) => {
            e.stopPropagation()
            handleSwitch({
              ...row.original,
              locked: checked,
            })
          }}
        />
      ),
    }),
  ]

  const { data: version = [] } = trpc.api.versions.list.useQuery()
  const { selectedCategory, setSelectedCategory } = useConfigPageContext<
    Version,
    Category
  >()

  const filteredVersions = version.filter(
    (version) => !selectedCategory || version.category === selectedCategory.id,
  )

  const changeFilter = (filter: string) => {
    setSelectedCategory({ id: filter, name: filter })
  }

  const categoryFilters = [{ label: 'All', value: '' }, ...CATEGORY_OPTIONS]

  return (
    <TableWidget<Version>
      {...rest}
      data={filteredVersions}
      columns={columns}
      filterOptions={categoryFilters}
      currentFilter={String(selectedCategory?.id) || ''}
      onFilterChange={changeFilter}
      getRowId={(row) => row.id}
      withIndex
    />
  )
}

export default VersionsTable
