import { CalendarFormValues, CalendarInput } from '@modules/calendar'
import { DatePicker, Form, FormInstance, Input, Radio, Select } from 'antd'
import { FormSectionLayout } from '@components'
import useStyles from './CreateCalendarForm.styles'

type CreateCalendarModalProps = {
  form: FormInstance
  onSubmit: (values: CalendarFormValues) => void
}

const years = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
]

const CreateCalendarModal: React.FC<CreateCalendarModalProps> = ({
  form,
  onSubmit,
}) => {
  const { styles } = useStyles()

  return (
    <Form
      form={form}
      onFinish={onSubmit}
      layout="vertical"
      className={styles.form}
    >
      <FormSectionLayout row>
        <Form.Item<CalendarInput>
          name="name"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input the calendar name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item<CalendarInput>
          name="periods"
          label="Fiscal Year Periods"
          initialValue="12"
        >
          <Radio.Group aria-label="Periods">
            <Radio value="12" aria-label="12 Periods">
              12 Periods
            </Radio>
            {/* TODO: not in MVP */}
            <Radio value="13" aria-label="13 Periods" disabled>
              13 Periods
            </Radio>
          </Radio.Group>
        </Form.Item>
      </FormSectionLayout>

      <FormSectionLayout>
        <Form.Item<CalendarInput>
          // name="trend"
          label="Calendar Trend"
        >
          <Select value="Standard" disabled />
        </Form.Item>

        <Form.Item<CalendarInput>
          name="initial_start_date"
          label="Initial Start Date"
        >
          <DatePicker picker="month" format="MMMM YYYY" />
        </Form.Item>

        <Form.Item<CalendarInput> name="years" label="Number of years">
          <Select options={years} />
        </Form.Item>
      </FormSectionLayout>
    </Form>
  )
}

export default CreateCalendarModal
