import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  // const { isConfig } = props as { isConfig: boolean }

  return {
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      height: 40,
      padding: `0 12px`,
      backgroundColor: token.colorWhite,
      borderRadius: token.borderRadiusLG,
      border: `1px ${token.colorBorder} solid`,
      marginBottom: token.marginSM,
    },
    modeButtons: {
      marginLeft: -6,
      marginRight: 10,
    },
    iconButton: {
      '&&': {
        color: token.gray600,
        padding: 0,
        width: 28,
        height: 28,
        borderRadius: 4,
      },
    },
    buttons: {
      display: 'flex',
      alignItems: 'center',
      fontSize: token.fontSize,
      fontWeight: token.fontWeightStrong,

      '&&& > button': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: token.gray800,
        // color: isConfig ? token.green50 : token.blue800,
        fontWeight: token.fontWeightStrong,
        padding: 0,

        '&:hover': {
          color: token.blue700,
          // color: isConfig ? token.green200 : token.blue500,
        },
        '&:disabled': {
          color: token.gray400,
        },
      },
    },
    searchInput: {
      '&&': {
        flex: 1,
        marginRight: token.marginXS,
        backgroundColor: token.colorWhite,
      },
    },
    dividerFull: {
      backgroundColor: token.gray300,
      width: 1,
      height: 26,
      margin: `0 10px`,
    },
    divider: {
      backgroundColor: token.gray300,
      width: 1,
      height: 10,
      margin: `0 10px`,
    },
  }
})
