import { createStyles } from 'antd-style'

const useStyles = createStyles(({ token }) => {
  return {
    root: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      gap: token.padding,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
})

const ExportImportPageLayout: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { styles } = useStyles()

  return <div className={styles.root}>{children}</div>
}

export default ExportImportPageLayout
