import { Navigate } from 'react-router-dom'
import MetricPage from 'pages/drivers/accounts/metric'
import AccountPage from 'pages/drivers/accounts/account'
import CustomComputedPage from 'pages/drivers/accounts/custom'
import LookupPage from 'pages/drivers/accounts/lookup'

const accountRoutes = [
  {
    index: true,
    element: <Navigate to="/drivers/accounts/account" replace />,
  },
  {
    path: 'account',
    handle: { title: 'Account' },
    Component: AccountPage,
  },
  {
    path: 'metric',
    handle: { title: 'Metric' },
    Component: MetricPage,
  },
  {
    path: 'custom-account',
    handle: { title: 'Custom Account' },
    element: <CustomComputedPage type="account" title="Custom Account" />,
  },
  {
    path: 'custom-metric',
    handle: { title: 'Custom Metric' },
    element: <CustomComputedPage type="metric" title="Custom Metric" />,
  },
  {
    path: 'lookup',
    handle: { title: 'Lookup' },
    Component: LookupPage,
  },
]

export default accountRoutes
