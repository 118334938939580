import { Select, SelectProps } from 'antd'
import { FC, Ref } from 'react'
import useStyles from './LabeledValueSelect.styles'

export interface LabeledValueSelectProps extends SelectProps {
  label: string
  placeholder?: string
  selectRef?: Ref<any>
  classNames?: {
    label?: string
    value?: string
    select?: string
  }
}

const LabeledValueSelect: FC<LabeledValueSelectProps> = ({
  label,
  classNames,
  placeholder,
  selectRef,
  ...props
}) => {
  const { styles, cx } = useStyles()
  const customLabelRender: SelectProps['labelRender'] = (option) => {
    if (!option.label && !option.value) {
      return <span className={styles.placeholder}>{placeholder || ''}</span>
    }
    return (
      <div className={styles.labeledValue}>
        <span className={cx(styles.label, classNames?.label)}>{label}</span>
        <span className={cx(styles.value, classNames?.value)}>
          {option.label || option.value}
        </span>
      </div>
    )
  }

  return (
    <Select
      {...props}
      ref={selectRef}
      placeholder={placeholder}
      labelRender={customLabelRender}
      aria-label={label}
      className={cx(styles.select, classNames?.select)}
    />
  )
}

export default LabeledValueSelect
