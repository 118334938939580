import { FC, useEffect } from 'react'
import { Checkbox, DrawerProps, Form, Input, message } from 'antd'
import { trpc } from '@services/trpc'
import { PropertiesAccordion, PropertiesDrawer } from '@components'
import type { Datagroup, Datatag } from '@modules/datagroup/datagroup.types'
import { useConfigPageContext } from '@context/ConfigPageContext'

interface DatagroupPropertiesProps extends DrawerProps {
  datagroup: Datagroup | null
  onClose?: () => void
}

const DatagroupProperties: FC<DatagroupPropertiesProps> = ({
  datagroup,
  onClose,
  ...props
}) => {
  const [form] = Form.useForm()
  const { selectedCategory } = useConfigPageContext<Datatag, Datagroup>()
  const { refetch } =
    trpc.api.datatags.listDatagroupsByType.useQuery('datagroup')

  const handleSuccess = () => {
    message.success('Datagroup updated successfully')
    refetch()
    form.resetFields()
    onClose?.()
  }

  const { mutate: save, isPending } =
    trpc.api.datatags.createOrUpdateDatagroupById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const initialValues = {
    ...datagroup,
  }

  const handleSubmit = (values: Partial<Datagroup>) => {
    save({
      ...values,
      id: datagroup!.id,
      description: values.description || '',
      memo: values.memo || '',
    })
  }

  useEffect(() => {
    if (!datagroup) return
    form.setFieldsValue(datagroup)
  }, [form, datagroup])

  return (
    <PropertiesDrawer<Datagroup>
      {...props}
      onSubmit={handleSubmit}
      form={form}
      initialValues={initialValues as Datagroup}
      confirmLoading={isPending}
    >
      <PropertiesAccordion
        label={
          selectedCategory?.type === 'picklist' ? 'Pick list' : 'Data group'
        }
      >
        <Form.Item
          name="name"
          label="Name | ID"
          rules={[
            {
              required: true,
              message: 'Please input the account name or ID!',
            },
          ]}
        >
          <Input placeholder="Enter name or ID" />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <Input placeholder="Enter description" />
        </Form.Item>

        <Form.Item name="memo" label="Memo">
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 4 }}
            placeholder="Enter memo"
          />
        </Form.Item>
      </PropertiesAccordion>

      <PropertiesAccordion label="Other Settings">
        <Form.Item
          valuePropName="checked"
          name="inactive"
          aria-label="Inactive"
        >
          <Checkbox>Inactive</Checkbox>
        </Form.Item>
      </PropertiesAccordion>
    </PropertiesDrawer>
  )
}

export default DatagroupProperties
