import { createStyles } from 'antd-style'

export default createStyles(({ token }) => ({
  listItemRoot: {
    height: 28,
    borderRadius: token.borderRadiusSM,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: token.paddingXS,
    paddingLeft: token.paddingMD,
    paddingRight: token.paddingXS,
    '&:hover': {
      backgroundColor: token.gray100,
    },
  },
  selected: {
    backgroundColor: token.blue50,
  },
  clickable: {
    cursor: 'pointer',
  },
  disabled: {
    cursor: 'not-allowed',
    color: token.gray400,
  },

  primaryLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    fontWeight: 600,
  },
  primaryLabelText: {
    color: token.blue600,
  },
}))
