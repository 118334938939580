import CalendarsTable from '../CalendarsTable'
import {
  ViewContainerLayout,
  ViewContentLayout,
  ViewToolbar,
  ViewToolbarAddButton,
  ViewWithPropertiesLayout,
} from '@components'
import { useConfigurationMode } from '@context/ConfigurationContext'
import {
  Category,
  TABS,
  useConfigPageContext,
} from '@context/ConfigPageContext'
import CalendarProperties from '../CalendarProperties'
import CreateCalendarModal from '../CreateCalendarModal'
import { CALENDAR_CATEGORY } from '@modules/calendar/calendar.constants'
import PeriodsTable from '../PeriodsTable'
import PeriodTypesTable from '../PeriodTypesTable'
import PeriodClassesTable from '../PeriodClassesTable'
import { Calendar } from '@server/interfaces/period'

const CalendarView: React.FC = () => {
  const { selectedItem, selectedCategory, currentTab } = useConfigPageContext<
    Calendar,
    Category
  >()

  const { isConfigurationMode } = useConfigurationMode()

  if (isConfigurationMode) {
    return (
      <ViewWithPropertiesLayout>
        <ViewContentLayout>
          <ViewToolbar
            addButton={
              <ViewToolbarAddButton createModal={CreateCalendarModal} />
            }
          />

          <ViewContainerLayout>
            {currentTab === TABS.items && selectedItem ? (
              <PeriodsTable />
            ) : (
              <>
                {!selectedCategory && <CalendarsTable />}
                {selectedCategory?.id === CALENDAR_CATEGORY.periodClasses && (
                  <PeriodClassesTable />
                )}
                {selectedCategory?.id === CALENDAR_CATEGORY.periodTypes && (
                  <PeriodTypesTable />
                )}
              </>
            )}
          </ViewContainerLayout>
        </ViewContentLayout>
        <CalendarProperties values={selectedItem} />
      </ViewWithPropertiesLayout>
    )
  }

  return (
    <>
      <ViewToolbar />
      <ViewContentLayout>
        Normal mode is not available. Please, toggle Configuration Mode
      </ViewContentLayout>
    </>
  )
}

export default CalendarView
