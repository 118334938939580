import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    modal: {
      maxWidth: '1000px',
    },
    formWrapper: {
      padding: `${token.paddingContentVerticalSM}px ${token.paddingContentHorizontalSM}px`,
      paddingRight: 0,
      borderRadius: token.borderRadius,
      backgroundColor: token.colorWhite,
    },
  }
})
