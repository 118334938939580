import { CreateModal } from '@components'
import CreateModelForm, {
  type ComputedDataModelFormValues,
} from './CreateCustomComputedForm'
import { Form, message } from 'antd'
import useStyles from './CreateCustomComputedModal.styles'
import { trpc } from '@services/trpc'
import { useEffect } from 'react'
import { ACCOUNT_CATEGORIES } from '@constants/accountCategories'
import { ACCOUNT_CLASSES } from '@constants/accountClasses'
import { CreateModalProps } from '@components/CreateModal'

export interface CreateCustomComputedModalProps extends CreateModalProps {
  type: 'account' | 'metric'
}

const CreateCustomComputedModal: React.FC<CreateCustomComputedModalProps> = ({
  onClose,
  type,
  ...props
}) => {
  const [form] = Form.useForm()
  const { styles } = useStyles()
  const { refetch: fetchModels } = trpc.api.datamodel.listByType.useQuery({
    type: 'computed',
  })

  const handleSuccess = () => {
    message.success('Model created successfully')
    fetchModels()
    form.resetFields()
    onClose?.()
  }

  const { mutate: saveModel, isPending } =
    trpc.api.datamodel.computed.createOrUpdateById.useMutation({
      onSuccess: handleSuccess,
      onError: (error) => {
        message.error(error.message)
      },
    })

  const handleOk = async () => {
    form.submit()
  }

  const handleSubmit = (values: ComputedDataModelFormValues) => {
    const newDataModel = {
      name: values.name,
      config: {
        value_type: values.value_type,
        // TODO: rename properties to match account/metric
        account_class:
          values.category === ACCOUNT_CATEGORIES.statistical
            ? ACCOUNT_CLASSES.operational
            : ACCOUNT_CLASSES.financial,
        account_type: values.type,
        // If metric_id is set, use it, otherwise use name and new metric will be created
        account_metric: values.metric_id ? Number(values.metric_id) : undefined,
        account_metric_name: values.metric_id ? undefined : values.name,
        associated_model: values.associated_model as any,
        calendar: 1,
        columns: [],
        operands: values.operands.map((operand) => ({
          ...operand,
          id: Number(operand.id.split('_')[1]),
          nextOperation: operand.nextOperation || undefined,
        })),
      },
    }
    saveModel(newDataModel)
  }

  const handleCancel = () => {
    form.resetFields()
    onClose?.()
  }

  useEffect(() => {
    if (props.open) {
      form.resetFields()
    }
  }, [form, props.open])

  return (
    <CreateModal
      {...props}
      title={`Create custom ${type}`}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="Save"
      centered
      width="clamp(600px, 70%, 1400px)"
      classNames={{
        content: styles.modalContent,
      }}
      confirmLoading={isPending}
    >
      <CreateModelForm form={form} onSubmit={handleSubmit} type={type} />
    </CreateModal>
  )
}

export default CreateCustomComputedModal
