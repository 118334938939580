import {
  ViewContainerLayout,
  ViewContentLayout,
  ViewToolbar,
  ViewToolbarAddButton,
  ViewWithPropertiesLayout,
} from '@components'
import DatagroupProperties from '../DatagroupProperties'
import { TABS, useConfigPageContext } from '@context/ConfigPageContext'
import {
  Datagroup,
  Datatag,
  ViewMetricsCategoryItem,
} from '@modules/datagroup/datagroup.types'
import { useDatagroupPageContext } from '../DatagroupPageContext'
import DatatagProperties from '../DatatagProperties'
import CreateDatagroupMemberModal from '../CreateDatagroupMemberModal'
import DatatagTable from './DatatagTable'
import ViewMetricTable from './ViewMetricTable'
import AssignDatatags from './AssignDatatagsView'
import { VIEW_METRICS_CATEGORY_ITEM } from '@modules/datagroup/datagroup.constants'
import { ViewMetric } from '@server/interfaces/view'
import ViewMetricProperties from '../ViewMetricProperties'
import { useMemo } from 'react'
import CreateViewMetricModal from '../CreateViewMetricModal'
import { ViewView } from '@modules/view'

function checkViewMetric(item: unknown): item is ViewMetric {
  // TODO: find a better way to check if the item is a ViewMetric
  return (item as ViewMetric)?.name !== undefined
}

const DatagroupConfigView: React.FC = () => {
  const { datagroup } = useDatagroupPageContext()
  const { selectedCategory, selectedItem, currentTab, selectedView } =
    useConfigPageContext<
      Datatag | ViewMetric,
      Datagroup | ViewMetricsCategoryItem
    >()

  const isViewMetricsCategory =
    selectedCategory?.id === VIEW_METRICS_CATEGORY_ITEM.id
  const isViewMetric = isViewMetricsCategory && checkViewMetric(selectedItem)

  const propertiesDrawer = useMemo(() => {
    if (selectedItem) {
      if (isViewMetric) {
        return <ViewMetricProperties viewMetric={selectedItem} />
      }
      return <DatatagProperties datatag={selectedItem as Datatag} />
    }
    if (isViewMetricsCategory) {
      return undefined
    }
    return <DatagroupProperties datagroup={selectedCategory} />
  }, [selectedItem, isViewMetricsCategory, selectedCategory, isViewMetric])

  // const propertiesDrawerName = useMemo(() => {
  //   if (selectedItem) {
  //     if (isViewMetric) {
  //       return selectedItem.name
  //     }
  //     return (selectedItem as Datatag).value
  //   }
  //   return selectedCategory?.name
  // }, [selectedItem, selectedCategory?.name, isViewMetric])

  const toolbar = (
    <ViewToolbar
      addButton={
        <ViewToolbarAddButton
          createModal={
            selectedCategory?.id === VIEW_METRICS_CATEGORY_ITEM.id ? (
              <CreateViewMetricModal />
            ) : (
              <CreateDatagroupMemberModal datagroup={datagroup} />
            )
          }
        />
      }
    />
  )

  return (
    <ViewWithPropertiesLayout>
      {currentTab === TABS.categories && (
        // <PropertiesDrawerLayout
        //   disabled={
        //     !selectedItem && (!selectedCategory || isViewMetricsCategory)
        //   }
        //   name={propertiesDrawerName}
        //   drawer={propertiesDrawer}
        // >
        <ViewContentLayout>
          {toolbar}
          <ViewContainerLayout>
            {isViewMetricsCategory ? <ViewMetricTable /> : <DatatagTable />}
          </ViewContainerLayout>
        </ViewContentLayout>
        // </PropertiesDrawerLayout>
      )}

      {currentTab === TABS.items && (
        // <PropertiesDrawerLayout
        //   disabled={
        //     !selectedItem && (!selectedCategory || isViewMetricsCategory)
        //   }
        //   name={propertiesDrawerName}
        //   drawer={propertiesDrawer}
        // >
        <>
          {isViewMetric ? (
            <AssignDatatags
              datagroupId={datagroup!.id}
              viewMetric={selectedItem as ViewMetric}
            />
          ) : (
            <ViewContentLayout>
              {toolbar}
              <ViewContainerLayout>
                {isViewMetricsCategory ? <ViewMetricTable /> : <DatatagTable />}
              </ViewContainerLayout>
            </ViewContentLayout>
          )}
        </>
        // </PropertiesDrawerLayout>
      )}

      {currentTab === TABS.views && (
        <ViewView view={selectedView} datagroupId={datagroup!.id} />
      )}

      {propertiesDrawer}
    </ViewWithPropertiesLayout>
  )
}

export default DatagroupConfigView
