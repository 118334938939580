import { createStyles } from 'antd-style'

export default createStyles(({ token }) => ({
  sidebarHeaderWrapper: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 0,
  },
  sidebarHeader: {
    display: 'flex',
    flexGrow: 1,
    gap: 12,
    justifyContent: 'space-between',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 700,
    color: token.gray800,
  },
  config: {
    color: token.gray500,
    fontWeight: 400,
  },
}))
