import { ACCOUNT_CLASSES } from './accountClasses'

export const ACCOUNT_CATEGORIES = {
  profitsAndLoss: 'Profit & Loss',
  balanceSheet: 'Balance Sheet',
  cashFlow: 'Cash Flow',
  statistical: 'Statistical',
} as const

export type AccountCategory =
  (typeof ACCOUNT_CATEGORIES)[keyof typeof ACCOUNT_CATEGORIES]

export const ACCOUNT_CATEGORIES_LABELS = {
  [ACCOUNT_CATEGORIES.profitsAndLoss]: 'Profits & Loss',
  [ACCOUNT_CATEGORIES.balanceSheet]: 'Balance Sheet',
  [ACCOUNT_CATEGORIES.cashFlow]: 'Cash Flow',
  [ACCOUNT_CATEGORIES.statistical]: 'Statistical',
}

export type AccountClassValues =
  (typeof ACCOUNT_CLASSES)[keyof typeof ACCOUNT_CLASSES]

export const getCategoryOptionsByClass = (
  accountClass: AccountClassValues,
  isMetric = false,
) => {
  if (accountClass === ACCOUNT_CLASSES.operational) {
    return [
      {
        value: ACCOUNT_CATEGORIES.statistical,
        label: ACCOUNT_CATEGORIES_LABELS[ACCOUNT_CATEGORIES.statistical],
      },
    ]
  }
  if (accountClass === ACCOUNT_CLASSES.financial) {
    return [
      {
        value: ACCOUNT_CATEGORIES.profitsAndLoss,
        label: ACCOUNT_CATEGORIES_LABELS[ACCOUNT_CATEGORIES.profitsAndLoss],
      },
      {
        value: ACCOUNT_CATEGORIES.balanceSheet,
        label: ACCOUNT_CATEGORIES_LABELS[ACCOUNT_CATEGORIES.balanceSheet],
      },
      ...(isMetric
        ? [
            {
              value: ACCOUNT_CATEGORIES.cashFlow,
              label: ACCOUNT_CATEGORIES_LABELS[ACCOUNT_CATEGORIES.cashFlow],
            },
          ]
        : []),
    ]
  }
  return []
}

export const ACCOUNT_CATEGORY_OPTIONS = [
  ACCOUNT_CATEGORIES.balanceSheet,
  ACCOUNT_CATEGORIES.profitsAndLoss,
  ACCOUNT_CATEGORIES.statistical,
].map((category) => ({
  value: category,
  label: ACCOUNT_CATEGORIES_LABELS[category],
}))
