import React from 'react'
import { Button } from 'antd'
import useStyles from './PropertiesFormContainer.styles.ts'

interface PropertiesFormContainerProps {
  onCancel?: () => void
  onSave?: () => void
  saveLoading?: boolean
  children?: React.ReactNode
}

const PropertiesFormContainer: React.FC<PropertiesFormContainerProps> = ({
  children,
  onCancel,
  onSave,
  saveLoading,
}) => {
  const { styles } = useStyles()

  return (
    <div className={styles.formContainer}>
      <div className={styles.actions}>
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={onSave} loading={saveLoading}>
          Save
        </Button>
      </div>
      <div className={styles.formContent}>{children}</div>
    </div>
  )
}

export default PropertiesFormContainer
