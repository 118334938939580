import { useRef } from 'react'
import { View } from '@server/interfaces/view'
import ViewProperties from '../ViewProperties'
import { useView } from '../../useView.hook'
import ViewTree from '../ViewTree'
import { TreeEnvironmentRef } from 'react-complex-tree'
import { TreeItemData } from '@modules/view/view.types'
import {
  ViewContentLayout,
  ViewContainerLayout,
  ViewToolbar,
  ViewToolbarAddButton,
  ViewWithPropertiesLayout,
} from '@components'
import CreateViewModal from '../CreateViewModal'
import { DataGroupId } from '@server/interfaces/datagroup'

interface ViewViewProps {
  view: View | null
  datagroupId: DataGroupId | null
}

const ViewView: React.FC<ViewViewProps> = ({ view, datagroupId }) => {
  const { treeData, isFetching } = useView(view?.id ?? null)
  const treeEnvironment = useRef<TreeEnvironmentRef<TreeItemData>>(null)

  return (
    <ViewWithPropertiesLayout>
      <ViewToolbar
        addButton={
          datagroupId && (
            <ViewToolbarAddButton
              createModal={<CreateViewModal datagroupId={datagroupId} />}
            />
          )
        }
      />

      <ViewContentLayout>
        <ViewContainerLayout>
          {view && !isFetching && (
            <ViewTree data={treeData} treeEnvironment={treeEnvironment} />
          )}
        </ViewContainerLayout>
      </ViewContentLayout>

      <ViewProperties view={view} />
    </ViewWithPropertiesLayout>
  )
}

export default ViewView
