import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    base: {
      border: 'none',
      outline: 'none',
      background: 'none',
      padding: 0,

      /* Chrome, Safari, Edge, Opera */
      '&::-webkit-outer-spin-button,::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },

      /* Firefox */
      '&[type=number]': {
        MozAppearance: 'textfield',
      },
    },
    input: {},
    display: {
      backgroundColor: token.gray200,
    },
    negative: {
      color: token.colorError,
    },
    positive: {
      color: token.green500,
    },
    changed: {
      color: token.green600,
    },
  }
})
