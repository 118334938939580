import { trpc } from '@services/trpc'
import TableFilterSelect from '@components/ui/TableFilterSelect'
import { useSearchParams } from 'react-router-dom'
import { filtersList } from '@constants/transactionTable'
import { usePeriodOptions } from '@hooks/usePeriodOptions'
import useStyles from './TableFiltersWithSearchParams.styles'

interface TableFiltersWithSearchParamsProps {
  datamodelId?: number
  disabledList?: Partial<{
    [key in (typeof filtersList)[number]]: boolean
  }>
}

const getOptions = (
  data: { id: number | string; name: string }[],
  withAll = true,
) => {
  const options = data.map((item) => ({
    value: item.id.toString(),
    label: item.name,
  }))
  if (withAll) {
    options.unshift({ value: '', label: 'All' })
  }
  return options
}

const TableFiltersWithSearchParams: React.FC<
  TableFiltersWithSearchParamsProps
> = ({ datamodelId, disabledList = {} }) => {
  const { styles } = useStyles()
  const { data: currencies = [] } = trpc.api.currencies.list.useQuery()
  const { data: companies = [] } = trpc.api.companies.list.useQuery()
  const { data: versions = [] } = trpc.api.versions.list.useQuery()
  const { data: accounts = [] } = trpc.api.accounts.list.useQuery()
  // const { data: calendars } = trpc.api.calendar.list.useQuery()
  // const { data: fiscalYears } = trpc.api.fiscalYears.list.useQuery()

  const { data: accountDrivers = [] } = trpc.api.accountdrivers.list.useQuery()

  const { periodClassFilters } = usePeriodOptions()

  const [filtersParams, setFiltersParams] = useSearchParams({
    accountdriver: '',
    company: '',
    version: '',
    // calendar: '',
    currency: '',
    account: '',
    fiscal_year: '',
    period_class: '',
  })

  const companyOptions = getOptions(companies)
  const versionOptions = getOptions(versions, false)
  const accountOptions = getOptions(accounts)
  // const calendarOptions = getOptions([{ id: 1, name: 'CY 2024' }])
  const fiscalYearOptions = getOptions([{ id: 1, name: 'FY 2024' }])
  const periodClassOptions = getOptions(periodClassFilters)
  const currencyOptions = getOptions(currencies)
  const accountDriversOptions = getOptions(
    datamodelId
      ? accountDrivers.filter((driver) => driver.datamodel_id === datamodelId)
      : accountDrivers,
  )

  const filtersValues = Object.fromEntries(filtersParams.entries()) as Record<
    (typeof filtersList)[number],
    string
  >

  const handleFilterChange = ({
    filter,
    value,
  }: {
    filter: (typeof filtersList)[number]
    value: string
  }) => {
    setFiltersParams(
      (prev) => {
        const current = Object.fromEntries(prev.entries())
        return {
          ...current,
          [filter]: value,
        }
      },
      { replace: true },
    )
  }

  return (
    <div className={styles.container}>
      <TableFilterSelect
        label="Account Driver"
        value={filtersValues.accountdriver}
        options={accountDriversOptions}
        onChange={(value) =>
          handleFilterChange({ filter: 'accountdriver', value })
        }
        disabled={disabledList.accountdriver}
      />
      <TableFilterSelect
        label="Company"
        value={filtersValues.company}
        options={companyOptions}
        onChange={(value) => handleFilterChange({ filter: 'company', value })}
        disabled={disabledList.company}
      />
      <TableFilterSelect
        label="Version"
        value={filtersValues.version}
        options={versionOptions}
        onChange={(value) => handleFilterChange({ filter: 'version', value })}
        disabled={disabledList.version}
      />
      {/* <TableFilterSelect
        label="Calendar Period"
        value={filtersValues.calendar}
        options={calendarOptions}
        onChange={(value) => handleFilterChange({ filter: 'calendar', value })}
        disabled={disabledList.calendar}
      /> */}
      <TableFilterSelect
        label="Period Class"
        value={filtersValues.period_class}
        options={periodClassOptions}
        onChange={(value) =>
          handleFilterChange({ filter: 'period_class', value })
        }
        disabled={disabledList.period_class}
      />
      <TableFilterSelect
        label="Fiscal Year"
        value={filtersValues.fiscal_year}
        options={fiscalYearOptions}
        onChange={(value) =>
          handleFilterChange({ filter: 'fiscal_year', value })
        }
        disabled={disabledList.fiscal_year}
      />
      <TableFilterSelect
        label="Currency"
        value={filtersValues.currency}
        options={currencyOptions}
        onChange={(value) => handleFilterChange({ filter: 'currency', value })}
        disabled={disabledList.currency}
      />
      <TableFilterSelect
        label="Account"
        value={filtersValues.account}
        options={accountOptions}
        onChange={(value) => handleFilterChange({ filter: 'account', value })}
        disabled={disabledList.account}
      />
    </div>
  )
}

export default TableFiltersWithSearchParams
