import { Button, Select } from 'antd'
import useStyles from './LedgerColumnListItem.styles'
import { IoCloseOutline } from 'react-icons/io5'
import { trpc } from '@services/trpc'
import { useDatatagColumnsContext } from '@modules/datamodel/DatatagColumnsContext'
import { LedgerDatatagColumnItem } from '@modules/ledger/ledger.types'
import { skipToken } from '@tanstack/react-query'

interface LedgerColumnListItemProps {
  item: LedgerDatatagColumnItem
}

const LedgerColumnListItem: React.FC<LedgerColumnListItemProps> = ({
  item,
}) => {
  const { styles } = useStyles()
  const { removeDatatagColumnItem } =
    useDatatagColumnsContext<LedgerDatatagColumnItem>()
  const { data: datatags = [] } =
    trpc.api.datatags.listDatagroupMembers.useQuery(
      typeof item.id === 'number' ? { id: item.id } : skipToken,
    )
  const totalMembers = datatags.length
  const membersCount = item.members?.length

  return (
    <>
      <div className={styles.name}>
        <span>{item.name}</span>
        {membersCount && (
          <span>
            ({item.members?.length} / {totalMembers})
          </span>
        )}
      </div>
      <div className={styles.inputValue}>
        {item.inputValue && <Select disabled value={item.inputValue} />}
      </div>
      <div className={styles.remove}>
        <Button
          disabled={item.disabled}
          icon={<IoCloseOutline size={16} />}
          onClick={() => removeDatatagColumnItem(item)}
        />
      </div>
    </>
  )
}

const LedgerColumnHeaderItem: React.FC = () => {
  const { styles } = useStyles()
  return (
    <>
      <div className={styles.name}>Name | ID</div>
      <div className={styles.inputValue}>Input value</div>
      <div className={styles.remove} />
    </>
  )
}

export { LedgerColumnListItem, LedgerColumnHeaderItem }
