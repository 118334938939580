import useDescriptionFormField from '@hooks/useDescriptionFormField'
import { Form, Input, type FormInstance } from 'antd'
import { FC } from 'react'

interface CreateViewFormProps {
  form: FormInstance
  onSubmit: (values: CreateViewFormValues) => void
}

export type CreateViewFormValues = {
  name?: string
  description?: string
}

const CreateViewForm: FC<CreateViewFormProps> = ({ form, onSubmit }) => {
  useDescriptionFormField({ form })
  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item<CreateViewFormValues>
        name="name"
        label="Name | ID"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<CreateViewFormValues> name="description" label="Description">
        <Input />
      </Form.Item>
    </Form>
  )
}

export default CreateViewForm
