import { FC } from 'react'
import { type ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { TableWidget } from '@components'
import { trpc } from '@services/trpc'
import type { TableWidgetProps } from '@components/TableWidget'
import {
  ACCOUNT_TYPES_LABELS,
  ALL_ACCOUNT_TYPES,
} from '@constants/accountTypes'
import { DataModel } from '@modules/datamodel'

const metricOptions = [
  { value: '', label: 'All' },
  ...Object.values(ALL_ACCOUNT_TYPES).map((value) => ({
    value,
    label: ACCOUNT_TYPES_LABELS[value],
  })),
]

const filterOptions = [
  {
    type: 'account_category',
    label: 'Account Category',
    options: metricOptions,
  },
]

const columnHelper = createColumnHelper<DataModel>()

// https://github.com/TanStack/table/issues/4382
// eslint-disable-next-line
const columns: ColumnDef<DataModel, any>[] = [
  columnHelper.accessor((row) => row.name, {
    id: 'name',
    header: 'Name | ID',
  }),
]

interface DatamodelTableWidgetProps
  extends Partial<TableWidgetProps<DataModel>> {}

const DatamodelTableWidget: FC<DatamodelTableWidgetProps> = ({ ...rest }) => {
  const { data = [] } = trpc.api.datamodel.getComposableByType.useQuery({})

  return (
    <TableWidget<DataModel>
      {...rest}
      data={data}
      columns={columns}
      filterOptions={filterOptions}
      getRowId={(row) => row.id}
      withIndex
    />
  )
}

export default DatamodelTableWidget
