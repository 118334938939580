import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
      border: `1px ${token.colorBorder} solid`,
      borderRadius: token.borderRadius,
      backgroundColor: token.colorWhite,
      overflow: 'hidden',
    },
    menu: {
      flex: 1,
      '&& .ant-menu-item': {
        height: 48,
        color: token.gray500,
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '&:hover': {
          backgroundColor: token.blue100,
        },
      },
      '&& .ant-menu-item-active': {
        color: token.blue,
        backgroundColor: token.blue50,
        borderBottom: `4px ${token.blue700} solid`,
        paddingTop: 4,
      },
    },
  }
})
