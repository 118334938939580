import { ConfigSidebar } from '@components'
import { trpc } from '@services/trpc'
import { useConfigPageContext } from '@context/ConfigPageContext'
import CreateAccountModal from '../../CreateAccountModal'
import AccountsSidebarCategoriesList from '../AccountsSidebarCategoriesList'
import AccountSidebarConfigToolbar from './AccountSidebarConfigToolbar'
import { AccountCategory } from '@modules/account/account.types'
import { ACCOUNT_CATEGORIES } from '@constants/accountCategories'

const allowedCategories = [
  ACCOUNT_CATEGORIES.balanceSheet,
  ACCOUNT_CATEGORIES.profitsAndLoss,
  ACCOUNT_CATEGORIES.statistical,
]

const AccountsSidebarConfig = <T extends object>() => {
  const { selectedCategory } = useConfigPageContext<T, AccountCategory>()

  const { data: accounts = [] } = trpc.api.accounts.listWithMetrics.useQuery(
    undefined,
    {
      select: (data) =>
        data.filter(
          (account) =>
            !selectedCategory ||
            account.metric[selectedCategory.propertyType] ===
              selectedCategory.id,
        ),
    },
  )

  return (
    <ConfigSidebar
      name="Account"
      items={accounts}
      toolbar={
        <AccountSidebarConfigToolbar
          name="Accounts"
          createModal={CreateAccountModal}
        />
      }
      renderCategories={() => (
        <AccountsSidebarCategoriesList allowedCategories={allowedCategories} />
      )}
      createModal={CreateAccountModal}
    />
  )
}

export default AccountsSidebarConfig
