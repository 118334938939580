import { createStyles } from 'antd-style'

export default createStyles(({ token }) => {
  return {
    collapseContainer: {
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    collapseHeader: {
      height: 28,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      marginBottom: token.marginXXS,
      paddingRight: token.paddingXXS,
      borderRadius: token.borderRadiusXS,
      '&:hover': {
        backgroundColor: token.gray100,
      },
    },
    collapseHeaderLabel: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: token.fontWeightStrong,
    },
    collapseHeaderOpened: {},
    collapseHeaderSelected: {
      backgroundColor: token.blue50,
      color: token.blue700,
    },
    collapseContent: {
      overflow: 'hidden',
      transition: 'max-height 0.3s ease-out',
    },
    collapseContentWrapper: {
      flex: 1,
      overflow: 'auto',
    },
    collapseContentOpened: {
      marginBottom: token.marginSM,
    },
    collapseHeaderIconWrapper: {
      width: 20,
      height: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    collapseHeaderIcon: {
      marginRight: token.paddingXXS,
      transition: 'rotate 0.3s ease-out',
    },
    collapseHeaderIconOpened: {
      rotate: '90deg',
    },
  }
})
